import { createSelector } from 'reselect'
import { objectTools } from '../../../utilities'
/**
 * Returns an object storing all tickets by id
 * @param {object} state Redux store
 */
export const ticketsObjectSelector = state => state.tickets.items

/**
 * Get a specific ticket by objectId
 * @param {object} state Redux store
 * @param {string} objectId objectId of the ticket we want
 */
export const ticketByIdSelector = (state, objectId) =>
  state.tickets.items[objectId]

export const ticketFieldById = (state, objectId, field) => {
  const ticket = ticketByIdSelector(state, objectId)
  if (ticket) {
    return ticket[field]
  }
  return undefined
}
/**
 * Get an array of all tickets
 */
export const allTicketsArray = createSelector(
  ticketsObjectSelector,
  ticketStore => objectTools.objectToArray(ticketStore)
)

/**
 * Factory Function: Get an array of all tickets BY classification
 * @param {string} classification The string of classification
 * @returns a selector function that requires state to be input.
 */
export const allTicketsByClassification = classification =>
  createSelector(
    allTicketsArray,
    allTickets =>
      allTickets.filter(ticket => {
        if (!ticket.classifications) return false
        return ticket.classifications.includes(classification)
      })
  )

/**
 * Returns the edit object for a ticket (by objectId)
 * @param {state} state Redux store
 * @param {string} objectId ObjectId of ticket
 */
export const ticketEditByIdSelector = (state, objectId) =>
  state.tickets.editItems[objectId]

/**
 * Get a specific edit field value for a ticket.
 * @param {state} state Redux Store
 * @param {string} objectId ticket ObjectId
 * @param {*} field Name of specific edit field
 */
export const ticketEditFieldByIdSelector = (state, objectId, field) => {
  const editTicket = state.tickets.editItems[objectId]
  return editTicket ? editTicket[field] : null
}

export const userByTicketIdSelector = (state, objectId) =>
  state.tickets.items[objectId].user

export const ticketsByIdsSelector = (state, objectIds) =>
  objectIds.map(id => ticketByIdSelector(state, id))

export const idFromRouter = (state, props) => props.match.params.id
