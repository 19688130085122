import { connect } from 'react-redux'
import { notificationsSelector } from '../duck/selectors'
import { isLoggedIn } from "../../authentication/duck/selectors"
import overlayActions from '../duck/actions'
import NotificationListComponent from './NotificationListComponent'

const mapStateToProps = state => ({
  notifications: notificationsSelector(state),
  isLoggedIn: isLoggedIn(state)
})

const mapDispatchToProps = dispatch => ({
  closeFunc: obj => dispatch(overlayActions.removeNotification(obj))
})

const NotificationListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationListComponent)

export default NotificationListContainer
