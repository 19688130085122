import { connect } from 'react-redux'
import { paymentsActions, paymentsThunks, paymentsSelectors } from '../duck'
import { overlaySelectors } from '../../overlays/duck'
import BasicAddFormComponent from './BasicAddFormComponent'

const mapStateToProps = state => {
  return {
    clientToken: paymentsSelectors.clientToken(state),
    nonce: paymentsSelectors.nonce(state),
    loading: overlaySelectors.isLoadingSelector(state),
    ready: paymentsSelectors.ready(state),
    success: paymentsSelectors.success(state),
    error: paymentsSelectors.error(state),
  }
}

const mapDispatchToProps = dispatch => ({
  clearNonceAndToken: () => {
    dispatch(paymentsActions.clearUserPaymentClientToken())
    dispatch(paymentsActions.clearUserPaymentNonce())
    dispatch(paymentsActions.clearUserPaymentReady())
    dispatch(paymentsActions.clearUserPaymentSuccess())
    dispatch(paymentsActions.clearUserPaymentError())
  },
  fetchToken: userObjectId => dispatch(paymentsThunks.fetchToken(userObjectId)),
  setNonce: nonce => dispatch(paymentsActions.setUserPaymentNonce(nonce)),
  addPaymentMethod: userObjectId =>
    dispatch(paymentsThunks.addPaymentMethod(userObjectId)),
  setSuccess: () => dispatch(paymentsActions.setUserPaymentSuccess()),
  setReady: () => dispatch(paymentsActions.setUserPaymentReady()),
  setError: error => dispatch(paymentsActions.setUserPaymentError(error)),
  unsetError: () => dispatch(paymentsActions.clearUserPaymentError()),
  unsetReady: () => dispatch(paymentsActions.clearUserPaymentReady()),
})

const BasicAddFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicAddFormComponent)

export default BasicAddFormContainer
