import React, { useEffect, useState } from "react"
import SharedSubmitBtn from '../shared/SharedSubmitBtn'
import styled from "styled-components"
import { updatePaymentPlan } from "../duck/services"

const Details = ({ details, schedule }) => {
  const [note, setNote] = useState('')
  const [prevNote, setPrevNote] = useState(details.merchant_notes)
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (details.merchant_notes !== null) {
      setPrevNote(details.merchant_notes)
    }
  }, [prevNote])

  const date = new Date(details.inserted_at)
  const options = { month: 'long', day: 'numeric', year: 'numeric' }
  let formattedDate = date.toLocaleDateString('en-us', options)

  let metaDescription = details.meta.description !== null && details.meta.description.length > 10
    ? details.meta.description.slice(0, 10) + '...' : details.meta.description
  
  let scheduleDescription = schedule.description !== null && schedule.description.length > 10
    ? schedule.description.slice(0, 10) + '...' : schedule.description

  const onSubmitHandler = (ev) => {
    ev.preventDefault()

    updatePaymentPlan(details.id, {
      notes: note
    }).then(res => {
      if (res.status === 201) {
        setMessage(res.message)
      }
    })
  }

  const onChangeHandler = (ev) => {
    setNote(ev.target.value)
  }

  return (
    <DetailsWrap>
      <PlanDetails>
        <h3>Plan Details</h3>
        <p>Created <span>{formattedDate}</span></p>
        <p>Status <span className='status'
          style={{ color: details.status === 'paid' ? 'green' : details.status === 'open' ? 'blue' : 'red' }}>{details.status.toUpperCase()}</span></p>
        <p>Description (Ticket IDs) <span>{metaDescription || scheduleDescription || 'Undefined'}</span></p>
        <p>Total <span>${details.amount}</span></p>
        <p>Amount Paid <span>${details.amount_paid}</span></p>
        <p>Amount Refunded <span>${details.amount_refunded}</span></p>
        <p>Balance <span>${details.balance}</span></p>
        <p>Repay by Date <span>{schedule.repay_by_date}</span></p>
      </PlanDetails>
      <CustomerDetails>
        <h3>Customer Details</h3>
        <p>Name <span>{details.customer.first_name} {details.customer.last_name}</span></p>
        <p>Email <span>{details.customer.email}</span></p>
        {details.customer.phone && <p>Phone <span>{details.customer.phone}</span></p>}
        <p>Notes</p>
        <form onSubmit={onSubmitHandler}>
          <textarea value={prevNote ? prevNote : note} onChange={onChangeHandler} name="notes" />
          <div className="notes">
          <SharedSubmitBtn type='submit' value='Update Details' disabled={note.length < 5} className='submit' />
          {message && <span>{message}</span>}
          </div>
        </form>
      </CustomerDetails>
    </DetailsWrap>
  )
}

const DetailsWrap = styled.div`
  display: flex;
  justify-content: space-evenly;

  p {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
  }

  span {
    font-weight: 400;
  }

  .status {
    font-weight: 600;
  }
` 

const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid lightgray;
  width: 40%;
  border-radius: 5px;
`
const CustomerDetails = styled(PlanDetails)`
  form {
    textarea {
      justify-content: center;
      padding: 10px;
      resize: none;
    }
  }

  .notes {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    justify-content: center;

    span {
      margin-right: 10px;
      font-weight: 600;
      padding: 6px;
      border-radius: 10px;
      background-color: #1ba9ff;
      color: white;
    }
  }
`

export default Details