import { connect } from 'react-redux'
import connectFuncs from './connectFunctions'
import EditDOBComponent from './EditDOBComponent'


const EDIT_KEY = 'dob'

const { mapStateToProps, mapDispatchToProps } = connectFuncs(EDIT_KEY)

const EditDOBContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDOBComponent)

export default EditDOBContainer
