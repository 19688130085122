import types from './types'

const INITIAL_STATE = {
  allContactInfo: [],
  hearingDateFilteredContactInfo: [],
  selectedContactInfo: {}
}


const lookupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }
    case types.SET_ALL_CONTACT_INFO: {
      return {
        ...state,
        allContactInfo: action.value
      }
    }
    case types.SET_HEARING_DATE_CONTACT_INFO: {
      return {
        ...state,
        hearingDateFilteredContactInfo: action.value
      }
    }
    case types.SET_SINGLE_CONTACT_INFO: {
      return {
        ...state,
        selectedContactInfo: action.value
      }
    }
    case types.SET_UPDATED_CONTACT_INFO: {
      const updatedContactInfo = state.allContactInfo.map(contactInfo => {
        if (contactInfo.objectId === action.value.objectId) {
          return action.value
        }
        return contactInfo
      })
      return {
        ...state,
        allContactInfo: updatedContactInfo
      }
    }
    default:
      return state
  }
}

export default lookupReducer
