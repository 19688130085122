import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import NotFoundPage from '../404NotFound'
import LookupList from './LookupList/AllLookupContainer'
import ViewContactInfo from './ViewLookup/ViewLookupContainer'

class LookupRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/lookup" component={LookupList} />
        <Route exact path="/lookup/hearingDate" component={LookupList} />
        <Route path="/lookup/view/:id" component={ViewContactInfo} />
        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    )
  }
}

export default LookupRoutes
