import types from './types'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const INITIAL_STATE = {
  [FRONTEND_TICKET_TYPES.hearings.recent]: {
    items: [],
    retrievedAt: null,
  },
  [FRONTEND_TICKET_TYPES.hearings.needsDate]: {
    items: [],
    retrievedAt: null,
  },
  regionFilter: null,
  currentlyViewing: null,
}
const hearingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_HEARINGS_REGION_FILTER: {
      return {
        ...state,
        regionFilter: action.value
      }
    }

    case types.SET_HEARINGS: {
      const { hearingType, value } = action
      const newType = { ...state[hearingType] }
      newType.items = value
      return {
        ...state,
        [hearingType]: newType
      }
    }

    case types.SET_HEARINGS_RETRIEVED_AT: {
      const { hearingType } = action
      const newType = { ...state[hearingType] }
      newType.retrievedAt = new Date()
      return {
        ...state,
        [hearingType]: newType
      }
    }


    case types.SET_CURRENTLY_VIEWING: {
      const { value } = action
      return {
        ...state,
        currentlyViewing: value
      }
    }

    default:
      return state
  }
}

export default hearingsReducer
