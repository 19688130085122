import { connect } from 'react-redux'
import { authenticationSelectors } from '../../authentication/duck'
import {
  processingThunks,
  processingActions,
  processingSelectors,
} from '../duck'
import { overlaySelectors } from '../../overlays/duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import ProcessingListComponent from './ProcessingListComponent'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const CURRENT = FRONTEND_TICKET_TYPES.processing.all

const mapStateToProps = state => ({
  regionFilter: processingSelectors.processingRegionFilterSelector(state),
  attorney: authenticationSelectors.attorneySelector(state),
  rtSettings: reactTableSelectors.settings(CURRENT, state),
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    CURRENT
  ),
  retrievedAt: processingSelectors.retrievedAt(state, CURRENT),
})

const mapDispatchToProps = dispatch => ({
  fetchProcessingItems: () => dispatch(processingThunks.fetchProcessingItems()),
  setRegionFilter: region =>
    dispatch(processingActions.setProcessingRegionFilter(region)),
  clearRegionFilter: () =>
    dispatch(processingActions.setProcessingRegionFilter(null)),
  setResized: value => dispatch(reactTableActions.setResized(CURRENT, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(CURRENT, value)),
  setFiltered: value => dispatch(reactTableActions.setFiltered(CURRENT, value)),
})

const ProcessingListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessingListComponent)

export default ProcessingListContainer
