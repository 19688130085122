import { axios } from "../../../utilities"
import { objectToQueryString } from "../../../utilities/objectToQueryString"


export const createTrafficTicket = (ticketObject) => {
  return axios.secure.delayed.post(`/ticket-data/`, ticketObject)
    .then(res => res.data)
}

export const createAttorney = (attorneyObject) => {
  return axios.secure.delayed.post(`/attorney-data/`, attorneyObject)
    .then(res => res.data)
}

export const getTrafficTicketsExport = (queryObject) => {
  const queryString = objectToQueryString(queryObject)
  return axios.secure.get(`/ticket-data/traffic-ticket-exports?${queryString}`)
    .then(res => res.data)
}

export const getLookupTicketsExport = (queryObject) => {
  const queryString = objectToQueryString(queryObject)
  return axios.secure.get(`/ticket-data/lookup-tickets-export?${queryString}`)
    .then(res => res.data)
}