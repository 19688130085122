import React from "react"
import styled from "styled-components"

const SharedActionWords = ({ name }) => {
  return (
    <Wrapper>
      <h1>NO {name.toUpperCase()}</h1>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  margin: auto;
  justify-content: center;
  border: 2px solid lightgray;
  align-items: center;
  height: 120px;
`

export default SharedActionWords