import { axios } from "../../../utilities"

export const getRecentHearings = () => {
  return axios.secure.delayed.get("/ticket-data/recent-hearings")
    .then(res => res.data)
}

export const getNoDateHearings = () => {
  return axios.secure.delayed.get("/ticket-data/needs-hearing")
    .then(res => res.data)
}
