import types from './types'

const setFiltered = (view, filter) => ({
  type: types.SET_FILTERED,
  view,
  filter,
})

const setSorted = (view, sort) => ({
  type: types.SET_SORTED,
  view,
  sort,
})

const setResized = (view, size) => ({
  type: types.SET_RESIZED,
  view,
  size,
})

const unsetTableParameter = (view, param) => ({
  type: types.UNSET_TABLE_PARAMETER,
  view,
  param,
})

export default {
  setFiltered,
  setResized,
  setSorted,
  unsetTableParameter,
}
