import types from './types.js'

const setSpecificOverlayStatus = (key, status) => ({
  type: types.SET_SPECIFIC_OVERLAY_STATUS,
  key,
  status
})


const showLoader = () => ({ type: types.SHOW_LOADER })
const hideLoader = () => ({ type: types.HIDE_LOADER })

const setModal = (modal) => ({ type: types.SET_MODAL, modal })
const hideModal = () => ({ type: types.HIDE_MODAL })

const resetNotifications = () => ({ type: types.REMOVE_NOTIFICATION })
const addNotification = obj => ({ type: types.ADD_NOTIFICATION, value: obj })
const removeNotification = obj => ({
  type: types.REMOVE_NOTIFICATION,
  value: obj
})

export default {
  setSpecificOverlayStatus,
  showLoader,
  hideLoader,
  setModal,
  hideModal,
  resetNotifications,
  addNotification,
  removeNotification
}
