import { axios } from "../../../utilities"

export const getAssociatedUsers = () => {
  return axios.secure.delayed.get("/attorney-data/users")
    .then(res => res.data)
}

export const getAttorneyStats = () => {
  return axios.secure.delayed.get("/attorney-data/stats")
    .then(res => res.data)
}


