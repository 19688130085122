import { connect } from 'react-redux'
import { authenticationSelectors } from '../../../authentication/duck'
import { ticketsSelectors } from '../../duck'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditStatusComponent from './EditStatusComponent'

const EDIT_KEY = 'status'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  editTicketField: ticketsSelectors.ticketEditFieldByIdSelector(
    state,
    props.objectId,
    EDIT_KEY
  ),
  attorney: authenticationSelectors.attorneySelector(state),
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, value) =>
    dispatch(ticketsActions.setEditValue(objectId, EDIT_KEY, value)),
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
})

const EditStatusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStatusComponent)

export default EditStatusContainer
