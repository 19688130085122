import types from './types.js'

const setHearingsRegionFilter = value => ({
  type: types.SET_HEARINGS_REGION_FILTER,
  value,
})

const setHearings = (hearingType, value) => ({
  type: types.SET_HEARINGS,
  hearingType,
  value
})

const setHearingsRetrievedAt = hearingType => ({
  type: types.SET_HEARINGS_RETRIEVED_AT,
  hearingType
})

const setCurrentlyViewing = value => ({
  type: types.SET_CURRENTLY_VIEWING,
  value,
})


export default {
  setCurrentlyViewing,
  setHearings,
  setHearingsRetrievedAt,
  setHearingsRegionFilter
}
