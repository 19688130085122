import React, { Component, Fragment } from "react";
import { Segment } from "semantic-ui-react"

import ItemHeader from "../ItemHeader"
import { StyledElements, HelpIconTooltip } from "../../../common"
import {
  PROCESSING_PIPELINE_STATUS,
  PROCESSING_PIPELINE_STATUS_TEXT_MAP,
  PROCESSING_PIPELINE_STATUS_DESCRIPTION_MAP,
  PROCESSING_PIPELINE_CHANGES,
} from "../../../../constants"
import { LoadingComponent, UpdatingComponent } from "../ticketOverlays"
import SpecificOverlayContainer from "../../../overlays/SpecificOverlay/SpecificOverlayContainer";

class EditProcessingPipelineComponent extends Component {

  getPossibleStatuses = () => {
    const { processingPipeline } = this.props.ticket
    const possibleKeys = PROCESSING_PIPELINE_CHANGES[processingPipeline]
    if (!possibleKeys) return []
    const result = possibleKeys.map(key => {
      return {
        key,
        value: key,
        title: PROCESSING_PIPELINE_STATUS_TEXT_MAP[key],
        info: PROCESSING_PIPELINE_STATUS_DESCRIPTION_MAP[key]
      }
    })
    return result
  }

  updateStatus = newStatus => event => {
    event.preventDefault()
    const { objectId, ticket } = this.props
    if (newStatus !== ticket.processingPipeline) {
      this.props.update(objectId, { processingPipeline: newStatus })
    }
    return false
  }

  renderEdit = () => {
    return <StyledElements.EditContainer>
      {this.getPossibleStatuses().map(status => {
        return <div key={status.key}>
          <StyledElements.PaddedButton
            positive={status.value === PROCESSING_PIPELINE_STATUS.approved}
            negative={status.value === PROCESSING_PIPELINE_STATUS.reject}
            onClick={this.updateStatus(status.value)}
          >
            {status.title}
          </StyledElements.PaddedButton>
          <StyledElements.Italicize>{status.info}</StyledElements.Italicize>
        </div>
      })}
    </StyledElements.EditContainer>
  }

  renderDetails = () => {
    const { objectId, ticket } = this.props
    const isEditing = ticket.processingPipeline === PROCESSING_PIPELINE_STATUS.requiresApproval
    const RenderEdit = this.renderEdit
    return <Fragment>
      <ItemHeader
        title={"Processing Pipeline"}
        isEditable={false}
        isEditing={false}
        toggleState={() => { }}
      />
      <SpecificOverlayContainer
        overlayKey={objectId}
        LoadingComponent={LoadingComponent}
        ignoreError={true}
        UpdatingComponent={UpdatingComponent}
      >
        {
          isEditing ? <RenderEdit /> : <RenderPipelineStatus processingPipeline={ticket.processingPipeline} />
        }
      </SpecificOverlayContainer>
    </Fragment>
  }

  render() {
    const { ticket } = this.props
    const RenderDetails = this.renderDetails
    if (ticket && ticket.processingPipeline === PROCESSING_PIPELINE_STATUS.requiresApproval) {
      return <Segment color='orange' raised={true}>
        <RenderDetails />
      </Segment>
    }
    return (
      <StyledElements.DataContainer isEditing={false}>
        <RenderDetails />
      </StyledElements.DataContainer>
    );
  }
}

export default EditProcessingPipelineComponent;

const RenderPipelineStatus = ({ processingPipeline }) => {
  switch (processingPipeline) {
    default:
      return <StyledElements.Italicize>None</StyledElements.Italicize>
    case PROCESSING_PIPELINE_STATUS.requiresApproval:
    case PROCESSING_PIPELINE_STATUS.approved:
    case PROCESSING_PIPELINE_STATUS.reject:
      return <span>
        {PROCESSING_PIPELINE_STATUS_TEXT_MAP[processingPipeline]}
        <HelpIconTooltip content={PROCESSING_PIPELINE_STATUS_DESCRIPTION_MAP[processingPipeline]} />
      </span>
  }
}
