import React, { Fragment } from 'react'
import LoginContainer from './LoginContainer'

const HomeComponent = () => {
  return (
    <Fragment>
      <LoginContainer />
    </Fragment>
  )
}

export default HomeComponent
