import { connect } from 'react-redux'
import { callsSelectors, callsThunks } from '../duck'
import { overlaySelectors } from '../../overlays/duck'
import { specificOverlayTools } from '../../../utilities'
import { SPECIFIC_OVERLAYS_CATEGORY } from '../../../constants'
import RequestHistoryComponent from './RequestHistoryComponent'


const mapStateToProps = (state, props) => {
  const OVERLAY_KEY = specificOverlayTools.buildKey(
    props.objectId,
    SPECIFIC_OVERLAYS_CATEGORY.callHistory
  )
  return {
    OVERLAY_KEY,
    history: callsSelectors.requestHistoryItems(state, props.objectId),
    retrievedAt: callsSelectors.requestHistoryRetrievedAt(
      state,
      props.objectId
    ),
    loading: overlaySelectors.specificOverlayLoadingStatusSelector(
      state,
      OVERLAY_KEY
    ),
  }
}

const mapDispatchToProps = dispatch => ({
  fetch: objectId => dispatch(callsThunks.fetchTicketCallHistory(objectId)),
})

const RequestHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestHistoryComponent)

export default RequestHistoryContainer
