import React from "react"
import styled from "styled-components"

const SharedSubmitBtn = ({ type, style, className, onClick, disabled, value }) => {
  return (
    <SubmitButton type={type} value={value} style={style} className={className} onClick={onClick} disabled={disabled} />
  )
}

const SubmitButton = styled.input`
  &.submit {
    width: fit-content;
    height: fit-content;
    padding: 10px 15px;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    margin-right: 10px;
    background-color: ${props => props.disabled ? "gray" : "#00c600"};
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  
    &:hover {
      filter: brightness(90%);
    }
  }

  &.create {
    background-color: ${props => props.disabled ? "gray" : "#0089de"};
  }
`

export default SharedSubmitBtn