const RESET = "RESET"
const SET_HEARINGS = "SET_HEARINGS"
const SET_HEARINGS_RETRIEVED_AT = "SET_HEARINGS_RETRIEVED_AT"
const SET_CURRENTLY_VIEWING = "SET_CURRENTLY_VIEWING"
const SET_HEARINGS_REGION_FILTER = "SET_HEARINGS_REGION_FILTER"

export default {
  RESET,
  SET_CURRENTLY_VIEWING,
  SET_HEARINGS,
  SET_HEARINGS_RETRIEVED_AT,
  SET_HEARINGS_REGION_FILTER
}
