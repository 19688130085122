import React, { Component, useState } from 'react'
import { Helmet } from 'react-helmet'
import {Header, Grid, Icon} from 'semantic-ui-react'

// General Use Components
import ErrorBoundaryComponent from '../../Error/Boundary'
// import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'

// Specific Components Related To ViewUser
import LoadingComponent from './LoadingComponent'
import EditName from './DetailSection/EditNameContainer'
import EditEmail from './DetailSection/EditEmailContainer'
import EditPhoneNumber from './DetailSection/EditPhoneNumberContainer'
import EditLicense from './DetailSection/EditLicenseContainer'
import EditDOB from './DetailSection/EditDOBContainer'
import EditGender from './DetailSection/EditGenderContainer';
import GetTicketsComponent from './DetailSection/GetTicketsComponent'
import GetDOTTicketsComponent from './DetailSection/GetDOTTicketsComponent'
import {colors} from "../../../theme/colors"


class ViewUserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {isUserBlocked: false}
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    const { user, loading, objectId } = this.props

    if (!user && !loading) {
      this.props.fetchUser(objectId)
    }
    const result = await this.props.isUserBlocked(objectId)
    this.setState({isUserBlocked: result.isUserBlocked})
  }

  render() {
    const {user, loading} = this.props
    if (loading) return <LoadingComponent/>
    if (!user) return <div/>
    return (
        <ErrorBoundaryComponent>
          <Helmet title={`User - ${user.username}`}/>
          <Header as="h2" dividing>
            User: {user.name}
          </Header>
          {this.state.isUserBlocked ? (
              <Header as="h4">
                <Header.Content>
                  User Status
                  <Header.Subheader>
                    <span style={{color: colors.error.color}}>
                    <Icon name="alarm"/>
                    User Blocked
                  </span>
                  </Header.Subheader>
                </Header.Content>
              </Header>) : null}
          <Grid stackable stretched columns={2}>
            <Grid.Column
                computer={8}
                tablet={8}
                mobile={16}
                verticalAlign={'top'}
            >
              <EditName objectId={user.objectId}/>
              <EditEmail objectId={user.objectId}/>
              <EditPhoneNumber objectId={user.objectId}/>
              <EditLicense objectId={user.objectId}/>
              <EditDOB objectId={user.objectId}/>
              <EditGender objectId={user.objectId}/>
              <GetTicketsComponent tickets={user.tickets}/>
              <GetDOTTicketsComponent tickets={user.DOTTickets} user={user} history={this.props.history}/>
            </Grid.Column>
            <Grid.Column
                computer={8}
                tablet={8}
                mobile={16}
                verticalAlign={'top'}
            >
              ObjectId: {this.props.objectId}
            </Grid.Column>
          </Grid>
        </ErrorBoundaryComponent>
    )
  }
}

export default ViewUserComponent
