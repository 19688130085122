import types from './types.js'

const setLeadsRegionFilter = value => ({
  type: types.SET_LEADS_REGION_FILTER,
  value,
})

const setLeads = (leadType, value) => ({
  type: types.SET_LEADS,
  leadType,
  value,
})

const setLeadsRetrievedAt = leadType => ({
  type: types.SET_LEADS_RETRIEVED_AT,
  leadType,
})

const setCurrentlyViewing = value => ({
  type: types.SET_CURRENTLY_VIEWING,
  value,
})

const setClosedTicketsHearingDate = hearingDate => ({
  type: types.SET_CLOSED_TICKET_HEARING_DATE,
  hearingDate,
})

export default {
  setLeads,
  setLeadsRetrievedAt,
  setCurrentlyViewing,
  setLeadsRegionFilter,
  setClosedTicketsHearingDate,
}
