import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from '../../theme/colors'
import { stringTools } from '../../utilities'

const DescriptionContainer = styled.div`
  font-size: 1em;
  margin: 0 0 0.5em 0;
  padding: 0 0 0.5em 0;
  color: ${colors.subtleGray};
  ${'' /* border-bottom: solid 1px ${colors.borders.main}; */}
  opacity: 0.8;
  line-height: 1.4em;
  ${'' /* font-style: italic; */}
`

const Title = styled.span`
  font-weight: bold;
  padding: 0 0.5em 0 0;
`

class DescribeTabComponent extends Component {
  getTitle = () => {
    const { title } = this.props
    if (!title) return ''
    return stringTools.replaceAll(title, '-', ' ').toUpperCase()
  }
  render() {
    return (
      <DescriptionContainer>
        {this.props.title ? <Title>{this.getTitle()}</Title> : null}
        {this.props.children}
      </DescriptionContainer>
    )
  }
}

DescribeTabComponent.defaultProps = {}

DescribeTabComponent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default DescribeTabComponent
