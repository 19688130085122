import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Popup } from "semantic-ui-react";
import styled from "styled-components";
import { cancelPaymentPlan, sendPlanRequest } from "../duck/services";
import SharedCloseBtn from "../shared/SharedCloseBtn";
import Contract from "./Contract";
import PlanActions from "./PlanActions";

const Schedule = ({
  details,
  schedule,
  installments,
  allowCC,
  paymentMethods,
  setShowPlanModal,
  setShowDetailedPlan,
  success,
  error 
}) => {
  const [showActionBtns, setShowActionBtns] = useState(false)
  const [showContract, setShowContract] = useState(false)
  
  const history = useHistory()
  const options = { month: 'long', day: 'numeric', year: 'numeric' }
  const date = new Date(schedule.contract_signed_date)
  let formattedDate = date.toLocaleDateString('en-us', options)

  const sendPlanRequestHandler = () => {
    sendPlanRequest(details.id).then(res => {
      setShowPlanModal(false)
      setShowDetailedPlan(false)
      success('Success!', res.message)
    })
  }

  const cancelPlanHandler = () => {
    cancelPaymentPlan(details.id).then(res => {
      if (res.status === 200) {
        setShowPlanModal(false)
        setShowDetailedPlan(false)
        success('Plan Cancelled!', res.message)
        setTimeout(() => history.go(0), 3000)
      } else if (res.status === 400) {
        setShowPlanModal(false)
        setShowDetailedPlan(false)
        error('Failed to cancel plan', res.error)
      }
    })
  }

  const cancelPlanButton = details.status === 'open' && <CancelPlanButton className="triggerCancel" onClick={cancelPlanHandler}>Confirm</CancelPlanButton>
  const openPlanButton = details.status === 'canceled' && <OpenPlanButton onClick={() => setShowActionBtns(true)}>Open Plan</OpenPlanButton>
  const sendPlanButton = details.status === 'pending' &&
    <>
    <OpenPlanButton style={{ marginBottom: '5px', width: 'fit-content' }} onClick={() => setShowActionBtns(true)}>Open Plan</OpenPlanButton>
    <PlanRequestButton style={{ width: 'fit-content' }} onClick={sendPlanRequestHandler}>Send Plan Request</PlanRequestButton>
    </>
  
  const popup = 
    <Popup style={{ textAlign: 'center', margin: 'auto' }} basic on='click' header='Are you sure?'
      content={cancelPlanButton} trigger={<CancelPlanButton>Cancel Plan</CancelPlanButton>} />  
  
  const planActionsComponent = (details.status === 'pending' || details.status === 'canceled') &&
    <Modal open={showActionBtns}>
      <Modal.Header>Open Payment Plan</Modal.Header>
      <Modal.Content>
        <PlanActions details={details} schedule={schedule} allowCC={allowCC} paymentMethods={paymentMethods} setShowPlanModal={setShowPlanModal}
          setShowDetailedPlan={setShowDetailedPlan} setShowActionBtns={setShowActionBtns} success={success} error={error} />
      </Modal.Content>
    </Modal>
  
  const contractButton = schedule.contract_body !== null && <button className="contractBtn" onClick={() => setShowContract(true)}>See Contract</button>
  const contractComponent = schedule.contract_body !== null &&
    <Modal open={showContract}>
      <Modal.Header>Contract Agreement</Modal.Header>
      <Modal.Content>
        <Contract schedule={schedule} />
      </Modal.Content>
      <Modal.Actions>
        <SharedCloseBtn name='Close' type='button' onClick={() => setShowContract(false)} />
      </Modal.Actions>
    </Modal>

  return (
    <DetailsWrap>
      <PlanDetails>
        <h3>Plan Schedule</h3>
        <p>Down Payment <span>${schedule.down_payment_amount}</span></p>
        <p>Term <span>{schedule.term} {schedule.term_units}</span></p>
        <p>Frequency <span>{schedule.frequency} {schedule.frequency_units}</span></p>
        <p>Payments Left <span>{schedule.num_payments}</span></p>
        <p>Payment Amount <span>${schedule.payment_amount}</span></p>
        {schedule.starts_date && <p>First Payment Date <span>{schedule.starts_date}</span></p>}

        {installments.length !== 0 &&
          <PlanDetails className='installments'>
            <p>Date {installments.map(installment => {
              const date = new Date(installment.scheduled)
              let scheduledDate = date.toLocaleDateString('en-us', options)
              return <span key={installment.id}>{scheduledDate}</span>
            })}</p>
            <p>Amount {installments.map(installment => <span key={installment.id}>${installment.amount}</span>)}</p>
          </PlanDetails>
        }
      </PlanDetails>

      <DetailsWrap className='wrapper'>
        <PlanDetails className='contract'>
          <h3>Contract</h3>
          <p><span>{!schedule.contract_signature ? 'NOT SIGNED' : `Signed by ${schedule.contract_signature} on ${formattedDate}`}</span></p>
          {contractButton}
          {contractComponent}
        </PlanDetails>
        
        <PlanDetails className='contract'>
          <h3>Plan Actions</h3>
          {sendPlanButton || openPlanButton || popup}
          {planActionsComponent}
        </PlanDetails>
      </DetailsWrap>
    </DetailsWrap>
  )
}

const DetailsWrap = styled.div`
  display: flex;
  justify-content: space-evenly;

  p {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
  }

  span {
    font-weight: 400;
  }

  .status {
    font-weight: 600;
  }

  &.wrapper {
    flex-direction: column;
    align-items: baseline;
    justify-content: unset;
  }
`
const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid lightgray;
  border-radius: 5px;
  width: 60%;
  padding-bottom: 0;

  &.installments {
    margin: auto;
    margin-top: 30px;
    border-bottom: none;
    padding-bottom: 0;
    width: 60%;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;

    p {
      display: flex;
      flex-direction: column;
    }

    span {
      padding: 4px 0;
    }
  }

  &.contract {
    font-size: 16px;
    height: fit-content;
    padding-bottom: 10px;
    width: 300px;
    margin-bottom: 20px;

    p {
      justify-content: center;
    }

    .contractBtn {
      width: 125px;
      height: 45px;
      outline: none;
      border: none;
      background-color: #79c1ff;
      font-weight: 500;
      color: white;
      cursor: pointer;
      margin: 10px auto;
      border-radius: 5px;
    }
  }
`

const PlanRequestButton = styled.button`
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  color: white;
  background-color: #0089de;

  &:hover {
    filter: brightness(90%);
  }
`
const CancelPlanButton = styled(PlanRequestButton)`
  background-color: rgb(233 50 50);

  &.triggerCancel {
    margin: auto;
    margin-top: 20px;
  }
`

const OpenPlanButton = styled(PlanRequestButton)`
  background-color: #00c600;
`

export default Schedule