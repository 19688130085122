import { connect } from "react-redux"
import { NOTIFICATION_TYPES } from "../../../constants"
import { attorneySelector } from "../../authentication/duck/selectors"
import { overlaysActions } from "../../overlays/duck"
import AllowCCComponent from "./AllowCCComponent"

const mapStateToProps = state => {
    return {
        attorney: attorneySelector(state)
    }
}

const mapDispatchToProps = dispatch => ({
    success: (title, message) => {
        dispatch(
          overlaysActions.addNotification({
            type: NOTIFICATION_TYPES.success,
            duration: 10,
            title,
            message
          })
        )
      },
      error: (title, message) => {
        dispatch(
          overlaysActions.addNotification({
            type: NOTIFICATION_TYPES.error,
            duration: 10,
            title,
            message
          })
        )
      }
})

const AllowCCContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AllowCCComponent)

export default AllowCCContainer