import { lighten, darken } from 'polished'
import { sizes } from './sizes'
import { colors } from './colors'
export const overlays = {
  error: {
    backgroundColor: lighten(0.4, colors.error.color),
    color: darken(0.2, colors.error.color),
    borderRadius: sizes.radius.small
    // border: `solid 1px ${darken(.1, colors.error.color)}`
  },
  success: {
    backgroundColor: lighten(0.4, colors.success.color),
    color: darken(0.2, colors.success.color),
    borderRadius: sizes.radius.small
    // border: `solid 1px ${darken(.1, colors.success.color)}`
  }
}
