import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import { ticketTools } from '../../utilities'
import {
  FRONTEND_TICKET_TYPES,
  FRONTEND_PARENT_TYPES,
  TAB_DESCRIPTIONS,
} from '../../constants'
import LeadsRoutes from './LeadsRoutes'
import RegionFilterMenu from '../regions/FilterMenu/FilterMenuContainer'
import { CountLabel, DescribeTab } from '../common'

class LeadsComponent extends Component {
  // componentWillUnmount() {
  // this.props.setRegionFilter(null)
  // }

  render() {
    const currentLocation = this.props.location.pathname
    const secondLevel = currentLocation.split('/').filter(v => v !== '')[1]
    const { currentlyViewing, attorney } = this.props
    const currentlyViewingStatus = ticketTools.getTicketChildType(
      FRONTEND_PARENT_TYPES.leads,
      currentlyViewing
    )
    return (
      <Fragment>
        <Menu stackable={true} tabular>

          {(attorney && attorney.globalAdmin ? 
            <Menu.Item
              as={Link}
              name="All"
              active={secondLevel === 'all'}
              to="/leads/all"
            >
              All
              <CountLabel
                key={`allLeads-count-${this.props.allLeads.length}`}
                in={!!this.props.allLeadsRetrieved}
                loading={this.props.allLeadsLoading}
                value={this.props.allLeads.length}
              />
            </Menu.Item>
            : null  
          )}
        
          {(attorney && attorney.globalAdmin ? 
            <Menu.Item
              as={Link}
              name="Unassigned"
              active={
                secondLevel === 'unassigned' ||
                currentlyViewingStatus === FRONTEND_TICKET_TYPES.leads.unassigned
              }
              to="/leads/unassigned"
            >
              Unassigned
              <CountLabel
                key={`unassignedLeads-count-${this.props.unassignedLeads.length}`}
                in={!!this.props.unassignedLeadsRetrievedAt}
                value={this.props.unassignedLeads.length}
                loading={this.props.unassignedLeadsLoading}
              />
            </Menu.Item>
            : null
          )}

          {(attorney && attorney.globalAdmin ? 
            <Menu.Item
              as={Link}
              name="Active"
              active={
                secondLevel === 'active' ||
                currentlyViewingStatus === FRONTEND_TICKET_TYPES.leads.active
              }
              to="/leads/active"
            >
              Active
              <CountLabel
                key={`activeLeads-count-${this.props.activeLeads.length}`}
                in={!!this.props.activeLeadsRetrievedAt}
                value={this.props.activeLeads.length}
                loading={this.props.activeLeadsLoading}
              />
            </Menu.Item>
            : null
          )}

          <Menu.Item
            as={Link}
            name="Closed"
            active={
              secondLevel === 'closed' ||
              currentlyViewingStatus === FRONTEND_TICKET_TYPES.leads.closed
            }
            to="/leads/closed"
          >
            Closed Leads
            <CountLabel
              key={`closedLeads-count-${this.props.closedLeads.length}`}
              in={!!this.props.closedLeadsRetrievedAt}
              value={this.props.closedLeads.length}
              loading={this.props.closedLeadsLoading}
            />
          </Menu.Item>

          {(attorney && attorney.globalAdmin ? 
            <Menu.Item
            as={Link}
            name="Closed Tickets"
            active={
              secondLevel === 'closed-tickets' ||
              currentlyViewingStatus === FRONTEND_TICKET_TYPES.leads.closedTickets
            }
            to="/leads/closed-tickets"
            >
              Closed Tickets
              <CountLabel
                key={`closedTickets-count-${this.props.closedTickets.length}`}
                in={!!this.props.closedTicketsRetrievedAt}
                value={this.props.closedTickets.length}
                loading={this.props.closedTicketsLoading}
              />
            </Menu.Item> 
            : null
          )}
          
        </Menu>
        {['all', 'closed', 'unassigned', 'active', 'closed-tickets'].includes(secondLevel) ? (
          <DescribeTab title={secondLevel}>
            {TAB_DESCRIPTIONS.leads[secondLevel]}
          </DescribeTab>
        ) : null}
        {!currentlyViewing ? (
          <RegionFilterMenu
            vertical={false}
            active={this.props.regionFilter}
            setFilter={this.props.setRegionFilter}
          />
        ) : null}
        <LeadsRoutes />
      </Fragment>
    )
  }
}

export default LeadsComponent
