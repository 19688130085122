import React from 'react'
import ModalSelect from '../../modal/LeadStatusModal'

export const getPresentValues = (data, key) => {
  const values = {}
  data.forEach(ticket => {
    const target = ticket[key]
    if (target && !values[target]) {
      values[target] = true
    }
  })
  return Object.keys(values)
}

export const buildFilter = (
  handleChange,
  filter,
  data,
  key,
  displayFormatMap
) => {
  const presentValues = getPresentValues(data, key)

  return (
    <select
      onChange={event => handleChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">All</option>
      {/* {presentValues.length ? <option value="noValue">No Value</option> : null} */}
      {presentValues.map(val => {
        return (
          <option key={val} value={val}>
            {displayFormatMap ? displayFormatMap[val] : val}
          </option>
        )
      })}
    </select>
  )
}

export const filterMethod = (filter, row) => {
  if (!filter || !filter.value) return true
  if (filter.value === 'all') {
    return true
  }
  // if (filter.value === 'noValue') {
  //   if (!row[filter.id]) {
  //     return true
  //   }
  //   return false
  // }
  if (!row[filter.id]) return false
  return row[filter.id] === filter.value
}

export const buildFilterMulti = (
  handleChange,
  filter,
  data,
  key,
  displayFormatMap
) => {
  const presentValues = getPresentValues(data, key)

  const options = [
    {
      key: 'no-value',
      text: 'No Value',
      value: 'no-value',
    },
  ]
  presentValues.forEach(val => {
    options.push({
      key: val,
      value: val,
      text: displayFormatMap ? displayFormatMap[val] : val,
    })
  })

  let value
  if (!filter || !filter.value) {
    value = [...presentValues, 'no-value']
  } else if (filter && filter.value && filter.value.length) {
    value = filterValidValues(filter.value, presentValues)
  } else {
    value = []
  }
  return (
    <ModalSelect value={value} handleChange={handleChange} options={options} />
  )
}

export const filterMethodMulti = (filter, row) => {
  if (!filter || !filter.value || !filter.value.length) return true
  if (filter.value.length) {
    const value = row[filter.id] === undefined ? 'no-value' : row[filter.id]
    return filter.value.includes(value)
  }
  if (!row[filter.id]) return false
}

const filterValidValues = (values = [], presentValues) => {
  return values.filter(item => {
    return presentValues.includes(item) || item === 'no-value'
  })
}

export const sortColumnDateMethod = ((a, b, desc) => {
  if (!desc) {
    a = a === null || a === undefined ? new Date(2088, 1, 1) : new Date(a)
    b = b === null || b === undefined ? new Date(2088, 1 ,1) : new Date(b)
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  } else {
    a = a === null || a === undefined ? '' : new Date(a)
    b = b === null || b === undefined ? '' : new Date(b)
      if (a > b) {
        return 1
      }
      if (a < b) {
        return -1
      }
      return 0
    }
  })
  

  export const sortNestedNumbers = ((a, b, desc) => {
    const totalResultsA = a.reduce((acc, cur) => {
      acc += cur.results.length
      return acc
    }, 0)
    const totalResultsB = b.reduce((acc, cur) => {
      acc += cur.results.length
      return acc
    }, 0)
    if (totalResultsA > totalResultsB) {
      return 1
    }
    return -1
  })