import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'

import { createPartiallyCustomer } from '../duck/services'
import { useHistory } from 'react-router-dom'
import { emailValidation } from '../../../utilities'
import SharedHeader from '../shared/SharedHeader'
import SharedCloseBtn from '../shared/SharedCloseBtn'
import SharedSubmitBtn from '../shared/SharedSubmitBtn'

const CreateNewCustomer = ({ user, success, error }) => {
  const [showNewCustomerModal, setShowNewCustomerModal] = useState(false)
  const [email, setEmail] = useState(user.email || "")

  const history = useHistory()

  const openNewCustomerModal = () => setShowNewCustomerModal(true)
  const closeNewCustomerModal = () => setShowNewCustomerModal(false)

  const onSubmitHandler = ev => {
    ev.preventDefault()
    
    createPartiallyCustomer({
      first_name: user.name.split(' ')[0],
      last_name: user.name.split(' ')[1],
      email: email,
      phone: user.phoneNumber,
    }).then(res => {
      if (res.status === 201) {
        setShowNewCustomerModal(false)
        success(res.message)
        setTimeout(() => { history.go(0) }, 3000)
      } else if (res.status === 400 || !email) {
        setShowNewCustomerModal(false)
        error(res.error)
      }
    })
  }

  return (
    <>
      <SharedHeader onClick={openNewCustomerModal} name={'Create Customer'} />
      <Modal
        open={showNewCustomerModal}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>Create a New Customer</Modal.Header>
        <Modal.Content>
          <ModalWrap>
            <form onSubmit={onSubmitHandler}>
              <label htmlFor="full_name">First Name</label>
              <input type="text" defaultValue={user.name.split(' ')[0]} />
              <label htmlFor="full_name">Last Name</label>
              <input type="text" defaultValue={user.name.split(' ')[1]} />
              <label htmlFor="email">Email</label>
              <input type="email" onChange={(ev) => setEmail(ev.target.value)} value={email} required />
              <label htmlFor="phone">Phone Number</label>
              <input type="text" defaultValue={user.phoneNumber} />
            </form>
            <BtnWrap>
              <SharedCloseBtn type="button" className="close" onClick={closeNewCustomerModal} name='Close' />
              <SharedSubmitBtn type="submit" className="submit" onClick={onSubmitHandler} disabled={!emailValidation(email)} value='Submit' />
            </BtnWrap>
          </ModalWrap>
        </Modal.Content>
      </Modal>
    </>
  )
}

const ModalWrap = styled.div`
  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  input {
    width: 250px;
    height: 30px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0 10px;
    margin: 10px 0;
  }
`

const BtnWrap = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  align-items: center;
`

export default CreateNewCustomer