import React, { Component } from 'react';
import { Button, Icon, Modal, Loader, Message } from 'semantic-ui-react'
import styled from 'styled-components';
import PropTypes from 'prop-types'


const StyledContent = styled(Modal.Content)`
  &&& {
    display: flex;
    justify-Content: center;
    align-items: center;
    img {
      border: 0;
      padding: 0;
      margin: 0;
    }
  }
`
const StyledHeader = styled(Modal.Header)`
  &&& {
    display: flex;
    justify-Content: center;
    align-items: center;
  }
`


class ViewImageModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageStatus: "loading",
    }
  }

  handleImageLoaded = () => {
    this.setState({ imageStatus: "loaded" });
  }

  handleImageErrored = () => {
    this.setState({ imageStatus: "failed" });
  }

  render = () => {
    const {
      title,
      ticketImage,
      handleClose,
      open,
      background
    } = this.props
    return <Modal
      onClick={handleClose}
      closeOnDimmerClick={true}
      basic
      open={open}
    >
      {title ? <StyledHeader>{title}</StyledHeader> : null}
      <StyledContent>
        {ticketImage && ticketImage.url ?
          <div style={{ height: '75vh' }}>
            {this.state.imageStatus === 'loading' ? <Loader style={{ padding: '20px' }} size='huge' /> : null}
            {this.state.imageStatus === 'failed' ? <Message negative>Image failed to load. Please try again later.</Message> : null}
            {this.state.imageStatus !== 'failed' ? <img
              onLoad={this.handleImageLoaded}
              onError={this.handleImageErrored}
              alt="User submitted ticket."
              src={ticketImage.url}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                background: background
              }}
            /> : null}
          </div>

          :
          <h1>No Image Available.</h1>
        }
      </StyledContent>
      <Modal.Actions>
        <Button basic color='red' inverted onClick={this.hideImage}>
          <Icon name='close' /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  }
}

ViewImageModalComponent.propTypes = {
  title: PropTypes.string,
  ticketImage: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ViewImageModalComponent;
