import types from './types'

export const setTicket = (value) => ({
  type: types.SET_TICKET,
  value,
})

export const setCreatedAttorney = (value) => ({
  type: types.SET_CREATED_ATTORNEY,
  value,
})

export const setUnassignedActiveLeadsExport = (value) => ({
  type: types.SET_UNASSIGNED_ACTIVE_EXPORT,
  value,
})

export const setClosedTicketsExport = (value) => ({
  type: types.SET_CLOSED_TICKETS_EXPORT,
  value,
})

export const setLookupTicketsExport = (value) => ({
  type: types.SET_LOOKUP_TICKETS_EXPORT,
  value,
})