import React, { Component, Fragment } from 'react'
import { Button, Form, Segment, Icon, Checkbox, Modal } from 'semantic-ui-react'
import SAS_AGREEMENT from '../../Confirm/SAAS_AGREEMENT'
import PLATFORM_PARTICIPATION_AGREEMENT from '../../Confirm/PLATFORM_PARTICIPATION_AGREEMENT'

class CreateUserComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      termsAndConditionsAgreed: false,
      platformParticipationAgreementModal: false,
      sasAgreementModal: false,
      userWasCreated: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.userWasCreated && this.props.user) {
      this.props.complete(this.props.user.objectId, this.props.code)
      this.setState({ userWasCreated: true })
    }
  }

  handleSubmit = () => {
    return this.props.validateUser(
      this.state.email.toLowerCase(),
      this.state.emailConfirmation.toLowerCase(),
      this.state.password,
      this.state.passwordConfirmation,
      this.state.name
    )
  }
  checkboxChangeHandler = (event, data) => {
    this.setState({ termsAndConditionsAgreed: data.checked });
  }
  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  termsClickHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const links = {
      'winit-platform-participation-agreement': 'platformParticipationAgreementModal',
      'winit-sas-agreement': 'sasAgreementModal'
    }
    const clickedId = event.target.id
    const modalToOpen = links[clickedId]
    this.setState({[modalToOpen]: true})
    return false
  };


  agreementModal = ({state, text}) => {
    return (
      <Modal
        closeIcon
        onClose={() => this.setState({[state]: false})}
        onOpen={() => this.setState({[state]: true})}
        open={this.state[state]}
        size='small'
      >
        <Modal.Content>
        <Form.TextArea
                value={text}
                onChange={() => { }}
                style={{width: "100%", height: "600px"}}

        />
        </Modal.Content>
        <Button fluid onClick={() => this.setState({[state]: false})}>Close</Button>
      </Modal>
    )
  }
  render() {
    const {
      name,
      email,
      emailConfirmation,
      password,
      passwordConfirmation,
      termsAndConditionsAgreed,
    } = this.state
    const canSubmit =
      name.trim().length >= 3 &&
      email.trim().length >= 3 &&
      emailConfirmation.trim().length >= 3 &&
      password.trim().length >= 3 &&
      passwordConfirmation.trim().length >= 3 &&
      termsAndConditionsAgreed
    return (
      <Fragment>
        <Segment attached>
          <Form size="large">
            <Form.Input
              fluid
              name="name"
              onChange={this.handleChange}
              icon="user"
              iconPosition="left"
              placeholder="Full Name"
              value={this.state.name}
            />
            <Form.Input
              fluid
              name="email"
              onChange={this.handleChange}
              icon="mail"
              iconPosition="left"
              placeholder="Email Address"
              value={this.state.email}
            />
            <Form.Input
              fluid
              name="emailConfirmation"
              onChange={this.handleChange}
              icon="mail"
              iconPosition="left"
              placeholder="Email Address Confirmation"
              value={this.state.emailConfirmation}
            />
            <Form.Input
              fluid
              name="password"
              onChange={this.handleChange}
              icon="lock"
              iconPosition="left"
              type="password"
              placeholder="Password"
              value={this.state.password}
            />
            <Form.Input
              fluid
              name="passwordConfirmation"
              onChange={this.handleChange}
              icon="lock"
              iconPosition="left"
              type="password"
              placeholder="Password Confirmation"
              value={this.state.passwordConfirmation}
            />

            <Form.Field>
              <Checkbox
                fitted
                name="termsAndConditionsAgreed"
                checked={this.state.termsAndConditionsAgreed}
                onChange={this.checkboxChangeHandler}
                label={
                  <label>
                    <p>
                    I agree with the
                    <a id="winit-platform-participation-agreement" onClick={this.termsClickHandler}> WinIt Platform Participation Agreement </a>
                    and
                    <a id="winit-sas-agreement" onClick={this.termsClickHandler}> WinIt SaaS Agreement </a>.
                    </p>
                  </label>}
              />
            </Form.Field>
            <Button
              disabled={!canSubmit || this.props.isLoading}
              fluid
              size="large"
              onClick={this.handleSubmit}
              labelPosition="right"
              icon
            >
              <Icon name="angle right" />
              Create my account
            </Button>
          </Form>
        </Segment>
        <this.agreementModal
          state="platformParticipationAgreementModal"
          text={PLATFORM_PARTICIPATION_AGREEMENT}
        />
        <this.agreementModal
          state="sasAgreementModal"
          text={SAS_AGREEMENT}
        />
      </Fragment>
    )
  }
}

export default CreateUserComponent
