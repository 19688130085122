import moment from 'moment'
import PDFurio from './PDFurio'

/**
 * Saves a PDF
 * @param {array} tickets An array of tickets to transform for PDF
 * @param {string} type Will be prepended to file name
 */
const buildPDFData = (tickets, type) => {
  const now = moment()
  // eventual return
  const pdfBuilderObject = {
    now: now.format('MM/DD/YYYY h:mm A'),
    documentTitle: type,
    fileName: `${type} - ${now.format('YYYY-MM-DD')}.pdf`
  }

  // format tickets
  const line = tickets.map(ticket => {
    return `${ticket.ticketID} \t ${ticket.violationName}`
  })
  // leads.forEach(ticket => {
  //   line.push(`${ticket.ticketID} \t ${ticket.violationName}`)
  // })

  pdfBuilderObject.lineArray = line

  return PDFurio(pdfBuilderObject)
}

export default buildPDFData
