import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Message, Form } from 'semantic-ui-react'

import { stringTools } from '../../utilities'
import { SEARCH_TYPE } from './SearchTypes'

class TicketIDSearchComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketID: '',
      error: '',
    }
    this.inputRef = React.createRef()
  }
  componentDidMount() {
    if (this.inputRef && this.inputRef.current && this.inputRef.current.focus) {
      this.inputRef.current.focus()
    }
  }
  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value })
  }
  handleSubmit = event => {
    if (event && event.preventDefault) event.preventDefault()
    const ticketID = stringTools.replaceAll(
      this.state.ticketID.trim().toUpperCase(),
      ' ',
      ''
    )
    if (ticketID.length <= 4) {
      return this.setState({
        error: 'Ticket ID must be greater than 4 alphanumeric characters.',
      })
    } else {
      this.setState({ error: '' })
    }
    return this.props.history.push(
      `/search/results?searchType=${SEARCH_TYPE.ticketId}&ticketID=${ticketID}`
    )
  }
  render() {
    return (
      <Form onSubmit={this.handleSubmit} error={!!this.state.error.length}>
        <Message error content={this.state.error} />
        <Form.Group grouped>
          <Input
            name="ticketID"
            onChange={this.handleChange}
            size="huge"
            fluid
            placeholder="ABC1234567"
            value={this.state.ticketID}
            ref={this.inputRef}
          />
        </Form.Group>
        <Form.Group>
          <Form.Button
            size="huge"
            content="Search"
            onClick={this.handleSubmit}
          />
        </Form.Group>
      </Form>
    )
  }
}

export default withRouter(TicketIDSearchComponent)
