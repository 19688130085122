import { connect } from 'react-redux'
import {regionsSelectors} from '../../regions/duck'
import { overlaysActions, overlaySelectors } from '../../overlays/duck'
import { authenticationSelectors } from '../../authentication/duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import { NOTIFICATION_TYPES, FRONTEND_TICKET_TYPES } from '../../../constants'

import SuspendedProcessingListComponent from './SuspendedProcessingListComponent'
import { processingActions, processingSelectors, processingThunks } from '../duck'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.processing.suspended

const mapStateToProps = state => {
  const currentCategory = processingSelectors.ids(TICKET_TYPE, state)
  return {
    pageType: TICKET_TYPE,
    currentCategory,
    regionFilter: processingSelectors.processingRegionFilterSelector(state),
    loading: overlaySelectors.specificOverlayLoadingStatusSelector(state, TICKET_TYPE),
    attorney: authenticationSelectors.attorneySelector(state),
    suspensions: regionsSelectors.regionsFilterBuilder(
      processingSelectors.suspendedCasesSelector,
      processingSelectors.processingRegionFilterSelector
    )(state),
    retrievedAt: processingSelectors.suspendedCasesRetrievedAt(state),
    allProcessing: state.processing,
    rtSettings: reactTableSelectors.settings(TICKET_TYPE, state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchSuspendedItems: () =>
    dispatch(processingThunks.fetchSuspendedItems()),
  setRegionFilter: region =>
    dispatch(processingActions.setProcessingRegionFilter(region)),
  clearRegionFilter: () =>
    dispatch(processingActions.setProcessingRegionFilter(null)),
  error: (title, message) =>
    dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.error,
      duration: 15,
      title,
      message
    })),
  setResized: value => 
    dispatch(reactTableActions.setResized(TICKET_TYPE, value)),
  setSorted: value => 
    dispatch(reactTableActions.setSorted(TICKET_TYPE, value)),
  setFiltered: value => 
    dispatch(reactTableActions.setFiltered(TICKET_TYPE, value))
})

const SuspendedProcessingListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuspendedProcessingListComponent)

export default SuspendedProcessingListContainer
