import { connect } from 'react-redux'
import { ticketsThunks } from './../../tickets/duck'
import { overlaysActions, overlaySelectors } from '../../overlays/duck'
import { authenticationSelectors } from '../../authentication/duck'
import { hearingsActions, hearingsSelectors } from '../duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import { NOTIFICATION_TYPES, FRONTEND_PARENT_TYPES } from '../../../constants'

import HearingsListComponent from './HearingsListComponent'

const TYPE = FRONTEND_PARENT_TYPES.hearings

const mapStateToProps = state => ({
  regionFilter: hearingsSelectors.hearingsRegionFilterSelector(state),
  isLoading: overlaySelectors.isLoadingSelector(state),
  attorney: authenticationSelectors.attorneySelector(state),
  rtSettings: reactTableSelectors.settings(TYPE, state),
})

const mapDispatchToProps = dispatch => ({
  clearRegionFilter: () =>
    dispatch(hearingsActions.setHearingsRegionFilter(null)),
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  error: message =>
    dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: message,
      })
    ),
  setResized: value => dispatch(reactTableActions.setResized(TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TYPE, value)),
  setFiltered: value => dispatch(reactTableActions.setFiltered(TYPE, value)),
})

const NoHearingDateListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HearingsListComponent)

export default NoHearingDateListContainer
