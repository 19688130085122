import { connect } from 'react-redux'
// import { closedLeadsSelector } from './duck/selectors'
// import { processingThunks } from './duck'
import CallsComponent from './CallsComponent'

const mapStateToProps = state => ({
  // activeLeads: activeLeadsSelector(state),
  // activeLeadsRetrievedAt: activeLeadsRetrievedAtSelector(state),
  // closedLeads: closedLeadsSelector(state),
  // closedLeadsRetrievedAt: closedLeadsRetrievedAtSelector(state),
  //
  // retrievedAt: activeLeadsRetrievedAtSelector(state),
  // currentlyViewing: currentlyViewingSelector(state)
})

// const mapDispatchToProps = dispatch => ({
// fetchProcessingItems: () => dispatch(processingThunks.fetchProcessingItems())
// })

const CallsContainer = connect(
  mapStateToProps,
  // mapDispatchToProps
)(CallsComponent)

export default CallsContainer
