import { connect } from 'react-redux'

import { authenticationSelectors } from '../../../authentication/duck'
import { ticketsSelectors } from '../../duck'
import ViewMagicLinkCommunicationComponent from './ViewMagicLinkCommunicationComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  isAdmin: authenticationSelectors.attorneyIsAdminSelector(state),
})

// const mapDispatchToProps = dispatch => ({})

const ViewMagicLinkCommunicationContainer = connect(mapStateToProps)(
  ViewMagicLinkCommunicationComponent
)

export default ViewMagicLinkCommunicationContainer
