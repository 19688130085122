import React, { Fragment } from 'react'
import LoadingContainer from './loading/LoadingContainer'
import ModalsContainer from './Modals/ModalsContainer'
import NotificationsListContainer from './notification/NotificationListContainer'

const OverlaysComponent = () => {
  return (
    <Fragment>
      <LoadingContainer />
      <ModalsContainer />
      <NotificationsListContainer />
    </Fragment>
  )
}

export default OverlaysComponent
