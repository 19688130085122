import React, { Component, Fragment } from 'react';
import Select from 'react-select'

import { Forms } from '../../../common'
import { objectTools } from '../../../../utilities'
import { STATES } from '../../../../constants'
import { customStyles } from '../../../common/react-select/styles'

class SelectCountyContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: undefined
    }
    if (props.value) {
      this.state.selected = {
        label: props.value.name,
        value: props.value.objectId
      }
    }
  }
  componentDidMount() {
    if (!this.props.counties[this.props.stateShortName]) {
      this.props.fetchCounties(this.props.stateShortName)
    }
  }
  updateValue = (value) => {
    this.setState({ selected: value })
  }
  proceed = () => {
    if (this.state.selected.value) {
      this.props.next(this.state.selected.value)
    }
  }
  saveAtThisStep = () => {
    if (this.state.selected.value) {
      this.props.save(this.state.selected.value)
    }
  }
  render() {
    const countyOptions = objectTools.objectToArray(this.props.counties[this.props.stateShortName])
      .map(county => ({
        value: county.objectId,
        label: county.name
      })).sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      })
    return (
      <Fragment>
        <p>Select a County in {STATES[this.props.stateShortName]}:</p>
        <Select
          placeholder="Choose a county"
          styles={customStyles}
          isMulti={false}
          onChange={this.updateValue}
          value={this.state.selected || undefined}
          options={countyOptions}
        />
        <Forms.InlineEditButtons buttons={[
          {
            onClick: this.props.previous,
            color: undefined,
            labelPosition: "left",
            icon: "arrow left",
            text: " Change State"
          },
          {
            onClick: this.saveAtThisStep,
            color: "green",
            labelPosition: "right",
            icon: "save",
            text: " Update County"
          },
        ]} />
      </Fragment>
    );
  }
}

export default SelectCountyContainer;
