import types from './types'
import {
  FRONTEND_PARENT_TYPES,
  GENERAL_SEARCH_TYPES,
  FRONTEND_TICKET_TYPES,
} from '../../../constants'

const INITIAL_STATE = {
  [FRONTEND_TICKET_TYPES.leads.all]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.leads.unassigned]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.leads.active]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.leads.closed]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.leads.closedTickets]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.processing.all]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.processing.recentlyApproved]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.processing.suspended]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_TICKET_TYPES.processing.requiresApproval]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_PARENT_TYPES.hearings]: {
    filter: [],
    sort: [],
    size: [],
  },
  [FRONTEND_PARENT_TYPES.calls]: {
    filter: [],
    sort: [],
    size: [],
  },
  [GENERAL_SEARCH_TYPES.ticket]: {
    filter: [],
    sort: [],
    size: [],
  },
  [GENERAL_SEARCH_TYPES.user]: {
    filter: [],
    sort: [],
    size: [],
  },
}

const reactTableReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SORTED: {
      const { view, sort } = action
      const newView = { ...state[view], sort }
      return {
        ...state,
        [view]: newView,
      }
    }
    case types.SET_FILTERED: {
      const { view, filter } = action
      const newView = { ...state[view], filter }
      return {
        ...state,
        [view]: newView,
      }
    }
    case types.SET_RESIZED: {
      const { view, size } = action
      const newView = { ...state[view], size }
      return {
        ...state,
        [view]: newView,
      }
    }
    case types.UNSET_TABLE_PARAMETER: {
      const { view, param } = action
      const newView = {
        ...state[view],
      }
      delete newView[param]
      return {
        ...state,
        [view]: newView,
      }
    }
    default:
      return state
  }
}

export default reactTableReducer
