import { connect } from 'react-redux'
import { ticketByIdSelector } from '../../duck/selectors'
import { authenticationSelectors } from '../../../authentication/duck'
import { overlaySelectors } from '../../../overlays/duck'
import { ticketsThunks } from '../../duck'
import ViewTicketPricePreviewComponent from './ViewTicketPricePreviewComponent'
import {
  ADDITIONAL_TICKET_FIELD,
  SPECIFIC_OVERLAYS_CATEGORY,
} from '../../../../constants'
import { specificOverlayTools } from '../../../../utilities'

const mapStateToProps = (state, props) => {
  const OVERLAY_KEY = specificOverlayTools.buildKey(
    props.objectId,
    SPECIFIC_OVERLAYS_CATEGORY.pricePreview
  )
  return {
    OVERLAY_KEY,
    attorney: authenticationSelectors.attorneySelector(state),
    ticket: ticketByIdSelector(state, props.objectId),
    pricePreview: ticketByIdSelector(state, props.objectId)[
      ADDITIONAL_TICKET_FIELD.pricePreview
    ],
    loading: overlaySelectors.specificOverlayLoadingStatusSelector(
      state,
      OVERLAY_KEY
    ),
  }
}

const mapDispatchToProps = dispatch => ({
  fetch: objectId => dispatch(ticketsThunks.fetchTicketPricePreview(objectId)),
})

const ViewTicketPricePreviewContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTicketPricePreviewComponent)

export default ViewTicketPricePreviewContainer
