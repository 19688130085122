import React, { Component } from 'react'
import { Grid, Header, Image } from 'semantic-ui-react'
import styled from 'styled-components'
import { FillHeight } from '../common'
import AttorneyLogo from '../../assets/images/navigation-bar-logo-portal.svg'

const LoginImage = styled(Image)`
  max-height: 100px;
`

class Logout extends Component {
  componentDidMount() {
    this.props.logout()
    this.props.history.push('/')
  }

  render() {
    return (
      <FillHeight justifyContent="center">
        <Grid
          style={{ height: '100%' }}
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <LoginImage src={AttorneyLogo} />
            <Header as="h2" textAlign="center">
              You Are Now Logged Out
            </Header>
          </Grid.Column>
        </Grid>
      </FillHeight>
    )
  }
}

export default Logout
