import React, { Component, Fragment } from 'react'
import { Form, Header, Grid } from 'semantic-ui-react'

import ViewAttorneyImageSectionPopupComponent from '../imageUpload/ViewAttorneyImageSectionPopupComponent'
import { StyledElements } from '../../common'
import { processFile } from '../../../utilities/imageProcessing'


class CreateAttorneyComponent extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      phone: '',
      email: '',
      state: '',
      addressLine1: '',
      addressLine2: '',
      summary: '',
      price: '',
      letterHeaderImage: "",
      bannerImage: "",
      avatarImage: "",
      errorFields: []
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const newErrors = []
    if ( 
      !this.state.name.trim() || 
      !this.state.phone.trim() || 
      !this.state.email.trim() || 
      !this.state.state.trim() || 
      !this.state.price.trim() || 
      !this.state.addressLine1.trim() || 
      !this.state.addressLine2.trim() || 
      !this.state.summary.trim()
      ) {
      Object.keys(this.state).forEach(key => {
        if (!this.state[key]) {
          newErrors.push(key)
        }
      })
      this.setState({
        errorFields: newErrors
      })
    } else {
      this.props.createAttorney({
        name: this.state.name.trim(),
        phone: this.state.phone.trim(),
        email: this.state.email.trim(),
        state: this.state.state.trim(),
        price: this.state.price.trim(),
        addressLine1: this.state.addressLine1.trim(),
        addressLine2: this.state.addressLine2.trim(),
        summary: this.state.summary.trim(),
        letterHeaderImage: this.state.letterHeaderImage,
        bannerImage: this.state.bannerImage,
        avatarImage: this.state.avatarImage,
      })
    }

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleImageDrop = async (image, name) => {
    const base64Image = await processFile(image[0])
    this.setState({
      [name]: base64Image
    })
  }

  render() {
    const IMAGE_NAMES = {letterHeaderImage: 'Letter Header Image: ', bannerImage: 'Banner Image: ', avatarImage: 'Avatar Image: '}
    return (
      <Fragment>

        <Grid centered={true}>
          <Grid.Column
            verticalAlign="middle"
            computer={10}
          >

            <Header
              style={{marginBottom: "25px", marginTop: "15px"}}
            >
              Create Attoney
            </Header>

            <Form size="large" onSubmit={this.handleSubmit}>
              <Form.Input
                fluid
                name="name"
                icon="user"
                iconPosition="left"
                placeholder="Attorney Name"
                onChange={this.handleChange}
                value={this.state.name}
                error={this.state.errorFields.includes('name')}
              />

              <Form.Input
                fluid
                name="phone"
                icon="phone"
                iconPosition="left"
                placeholder="Attorney Phone"
                onChange={this.handleChange}
                value={this.state.phone}
                error={this.state.errorFields.includes('phone')}
              />

              <Form.Input
                fluid
                name="email"
                icon="mail"
                iconPosition="left"
                placeholder="Attorney Email"
                onChange={this.handleChange}
                value={this.state.email}
                error={this.state.errorFields.includes('email')}
              />

              <Form.Input
                fluid
                name="addressLine1"
                icon="marker"
                iconPosition="left"
                placeholder="Attorney Address Line 1"
                onChange={this.handleChange}
                value={this.state.addressLine1}
                error={this.state.errorFields.includes('addressLine1')}
              />

              <Form.Input
                fluid
                name="addressLine2"
                icon="marker"
                iconPosition="left"
                placeholder="Attorney Address Line 2"
                onChange={this.handleChange}
                value={this.state.addressLine2}
                error={this.state.errorFields.includes('addressLine2')}
              />

              <Form.Input
                fluid
                name="price"
                icon="money"
                iconPosition="left"
                placeholder="Attorney Price (as integer)"
                onChange={this.handleChange}
                value={this.state.price}
                error={this.state.errorFields.includes('price')}
              />

              <Form.Input
                fluid
                name="state"
                icon="map"
                iconPosition="left"
                placeholder="State Abbreviation"
                onChange={this.handleChange}
                value={this.state.state}
                error={this.state.errorFields.includes('state')}
              />

              <Form.TextArea
                name="summary"
                placeholder="Attorney Summary"
                onChange={this.handleChange}
                value={this.state.summary}
                error={this.state.errorFields.includes('summary')}
              />

              <StyledElements.Spacer height="5px"/>

              <Grid centered>
                {Object.keys(IMAGE_NAMES).map(name => {
                  return (
                    <Grid.Row style={{padding: '10px 0 10px 0'}} key={name}>
                      <StyledElements.Bold color="black">{IMAGE_NAMES[name]}</StyledElements.Bold><StyledElements.Divider />

                      <ViewAttorneyImageSectionPopupComponent handleImageDrop={(image) => this.handleImageDrop(image, name)} attorneyImage={this.state[name]}/>
                    </Grid.Row>
                  )
                })}
              </Grid>

              <StyledElements.Spacer height="20px"/>

              <Form.Button
                style={{width:"100%", height: "45px"}}
                type="submit"
              >
                Submit
              </Form.Button>

            </Form>
          </Grid.Column>
        </Grid>
      </Fragment>
    )
  }
}

export default CreateAttorneyComponent
