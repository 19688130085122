import { connect } from 'react-redux'

import { attorneyThunks, attorneySelectors } from '../../../attorney/duck'
import { authenticationSelectors } from '../../../authentication/duck'
import { ticketsSelectors } from '../../duck'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditAttorneyComponent from './EditAttorneyComponent'
import { overlaysActions } from '../../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../../constants'

const EDIT_KEY = 'attorney'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  editTicketField: ticketsSelectors.ticketEditFieldByIdSelector(
    state,
    props.objectId,
    EDIT_KEY
  ),
  attorney: authenticationSelectors.attorneySelector(state),
  attorneys: attorneySelectors.attorneys(state),
  retrievedAt: attorneySelectors.retrievedAt(state),
})

const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch(attorneyThunks.fetchAttorneys()),
  editUpdate: (objectId, value) =>
    dispatch(ticketsActions.setEditValue(objectId, EDIT_KEY, value)),
  clearEditValue: objectId =>
    dispatch(ticketsActions.clearEditValue(objectId, EDIT_KEY)),
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  error: message =>
    dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: message,
      })
    ),
})

const EditAttorneyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAttorneyComponent)

export default EditAttorneyContainer
