import getResultsFromContactInfo from './getResultsFromContactInfo'

export const filterContactInfoByDate = (contactInfoList, date) => {
  let start = new Date(date)
  let end = new Date(date)
  start.setHours(0, 0, 0, 0)
  end.setHours(23,59,59,999)

  const filteredContactInfoList = []

  contactInfoList.forEach(contactInfo => {
    const ticketResults = getResultsFromContactInfo(contactInfo)
    for (let i = 0; i < ticketResults.length; i++) {
      const ticket = ticketResults[i]
      if (!ticket || !ticket.hearingDate) {
        continue
      }
      const ticketDate = new Date(ticket.hearingDate.iso)
      if (ticketDate >= start && ticketDate <= end) {
        filteredContactInfoList.push(contactInfo)
        break
      }
    }
  })
  return filteredContactInfoList
}