import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import {
  Button,
  Form,
  Input,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { FillHeight } from '../common'
import AttorneyLogo from '../../assets/images/navigation-bar-logo-portal.svg'
import { expiryDate } from './duck/services'

const LoginImage = styled(Image)`
  max-height: 100px;
`

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  font-size: 0.8em;
  margin: 3em 0 0 0;
`

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      token: '',
      minutes: 5,
      seconds: 0,
      clicked: false,
    }
    this.emailInput = React.createRef()
  }

  handleChange = event => {
    const target = event.target.name
    const value = event.target.value
    this.setState({ [target]: value })
  }
  handleSubmit = () => {
    if (!this.state.clicked) {
      this.props.login(this.state.email.toLowerCase(), this.state.password)
      sessionStorage.getItem('error')
      this.setState({ clicked: true })
      this.intervalId = setInterval(() => this.tick(), 1000)
    } else if (this.state.clicked) {
      this.props.twoFA(this.state.email.toLowerCase(), this.state.password, sessionStorage.getItem('secretKey'), this.state.token)
    }
  }

  handleClick = () => {
    if (localStorage.getItem('sessionToken')) {
      const sessionToken = localStorage.getItem('sessionToken')
      expiryDate(sessionToken).then(res => {
        if (res.status === 201) {
          this.props.success(res.message)
        }
      })
    }
  }

  componentDidMount() {
    this.props.autoLogin()
    this.emailInput.focus()
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  tick() {
    if (this.state.seconds > 0) {
      this.setState(prevState => ({ seconds: prevState.seconds - 1 }))
    }
    if (this.state.seconds === 0) {
      if (this.state.minutes === 0) {
        clearInterval(this.intervalId)
      } else {
        this.setState(prevState => ({ minutes: prevState.minutes - 1, seconds: 59 }))
      }
    }
  }

  render() {
    const submitDisabled = !this.state.email || !this.state.password
    return (
      <FillHeight justifyContent="center">
        <Helmet title="WinIt Attorney Login" />
        <Grid
          style={{ height: '100%', padding: '20px' }}
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450, display: 'flex' }}>
            <LoginImage src={AttorneyLogo} />
            <Header
              style={{ marginBottom: '1.5em' }}
              as="h1"
              textAlign="center"
            >
              Attorney Platform
            </Header>
            <Form size="large">
              {!this.state.clicked ? (
                <Segment>
                  <Form.Field>
                    <Input
                      ref={input => {
                        this.emailInput = input
                      }}
                      fluid
                      name="email"
                      onChange={this.handleChange}
                      icon="user"
                      iconPosition="left"
                      placeholder="E-mail address"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      name="password"
                      onChange={this.handleChange}
                      icon="lock"
                      iconPosition="left"
                      placeholder="Password"
                      type="password"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      disabled={submitDisabled || this.props.isLoading}
                      fluid
                      size="large"
                      onClick={() => { this.handleSubmit(); setTimeout(() => this.handleClick(), 5000) }}
                    >
                      Login
                    </Button>
                  </Form.Field>
                </Segment>
              ) : sessionStorage.getItem('error') ? (
                <Segment>
                  <Form.Field>
                    <Input
                      ref={input => {
                        this.emailInput = input
                      }}
                      disabled
                      fluid
                      name="email"
                      onChange={this.handleChange}
                      icon="user"
                      iconPosition="left"
                      placeholder="E-mail address"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Input
                      fluid
                      disabled
                      name="password"
                      onChange={this.handleChange}
                      icon="lock"
                      iconPosition="left"
                      placeholder="Password"
                      type="password"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      disabled
                      fluid
                      size="large"
                      onClick={() => { this.handleSubmit(); setTimeout(() => this.handleClick(), 5000) }}  
                    >
                      Login
                    </Button>
                  </Form.Field>
                </Segment>
              ) : (
                <Segment>
                  <h3>Please check your email</h3><p style={{ opacity: 0.8 }}>We've sent a secret token to <strong>{`${this.state.email}`}</strong>!</p>
                  <Form.Field>
                    <Input
                      disabled={this.state.minutes === 0 && this.state.seconds === 0}
                      fluid
                      name="token"
                      onChange={this.handleChange}
                      icon="qrcode"
                      iconPosition="left"
                      placeholder="Token"
                      type="number"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      disabled={!this.state.token || this.state.token.length !== 6 || this.props.isLoading}
                      fluid
                      size="large"
                      onClick={() => { this.handleSubmit(); setTimeout(() => this.handleClick(), 5000) }}
                    >
                      Verify
                    </Button>
                  </Form.Field>
                  <p>Token expires in <b>0{this.state.minutes}:{this.state.seconds < 10 ? `0${this.state.seconds}` : this.state.seconds}</b></p>
                  {this.state.minutes === 0 && this.state.seconds === 0 && (
                    <p>
                      Please click {' '}
                      <span
                        onClick={() => window.location.reload(true)}
                        style={{ color: 'grey', textDecoration: 'underline', cursor: 'pointer' }}>
                        this link
                      </span> {' '}
                      to refresh the page</p>
                  )}
                </Segment>
              )}
              <Centered>© {new Date().getFullYear()} WinIt LLC</Centered>
            </Form>
          </Grid.Column>
        </Grid>
      </FillHeight>
    )
  }
}

export default LoginForm
