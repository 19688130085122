import types from './types.js'

const setChats = (value) => {
  return {
    type: types.SET_CHATS,
    value,
  }
}

const setChatWindowShowing = (value) => {
  return {
    type: types.SET_CHAT_WINDOW_SHOWING,
    value,
  }
}

const setChatAttorneyId = (value) => {
  return {
    type: types.SET_CHAT_ATTORNEY_ID,
    value,
  }
}

const setChatCurrentChannelUrl = (value) => {
  return {
    type: types.SET_CHAT_CURRENT_CHANNEL_URL,
    value,
  }
}

export default {
  setChats,
  setChatWindowShowing,
  setChatAttorneyId,
  setChatCurrentChannelUrl
}
