import types from './types';

const INITIAL_STATE = {
  user: null,
  attorney: null
}
const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_USER: {
      const { value } = action;
      return {
        ...state,
        user: value
      }
    }

    case types.CLEAR_USER: {
      return {
        ...state,
        user: null
      }
    }

    case types.SET_ATTORNEY: {
      const { value } = action;
      return {
        ...state,
        attorney: value
      }
    }

    case types.CLEAR_ATTORNEY: {
      return {
        ...state,
        attorney: null
      }
    }

    default: return state;
  }
}

export default authReducer;
