export const replaceAll = (source, search, replacement = '') => {
  return source.replace(new RegExp(escapeRegExp(search), 'g'), replacement)
}

export const escapeRegExp = str => {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1') // eslint-disable-line
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeEveryFirstLetter = string => {
  const split = string.split(' ')
  return split.map(word => capitalizeEveryFirstLetter(word)).join(' ')
}

export const getWord = (string, number = 0) => {
  const splitWords = string.split(' ')
  if (number > splitWords.length) return splitWords[splitWords.length - 1]
  return splitWords[number]
}

export const getFileName = (imageUrl, includeExtension = true) => {
  const split = imageUrl.split('/')
  const fileNameWithExtension = split[split.length - 1]
  if (includeExtension) {
    return fileNameWithExtension
  }
  const extension = getFileExtension(fileNameWithExtension)
  return fileNameWithExtension.slice(0, -1 * extension.length)
}

export const getFileExtension = fileName => {
  const split = fileName.split('.')
  return '.' + split[split.length - 1]
}

export const buildFileName = (imageUrl, fileName, prepend, append) => {
  let saveAsFileName = ''
  const actualFileName = getFileName(imageUrl)
  const actualFileExtension = getFileExtension(actualFileName)
  if (prepend) saveAsFileName += prepend
  if (fileName) {
    saveAsFileName += fileName
  } else {
    const fileNameWithoutExtension = getFileName(imageUrl, false)
    saveAsFileName += fileNameWithoutExtension
  }
  if (append) saveAsFileName += append
  saveAsFileName += actualFileExtension
  return saveAsFileName
}

export const objectToURLParams = params => {
  if (
    !params ||
    typeof params !== 'object' ||
    Object.keys(params).length === 0
  ) {
    return ''
  }

  let str = '?'
  for (let key in params) {
    if (str !== '?') {
      str += '&'
    }
    str += key + '=' + encodeURIComponent(params[key])
  }
  return str
}
