import { connect } from 'react-redux'
import { stepSelector, codeSelector } from '../duck/selectors'
import { isLoadingSelector } from '../../overlays/duck/selectors'
import { activationActions } from '../duck'
import { activationThunks } from '../duck'
import EnterCodeComponent from './EnterCodeComponent'

const mapStateToProps = state => ({
  step: stepSelector(state),
  code: codeSelector(state),
  isLoading: isLoadingSelector(state)
})

const mapDispatchToProps = dispatch => ({
  setCode: (code) => dispatch(activationActions.setCode(code)),
  validateCode: (code) => dispatch(activationThunks.validateCode(code))
})

const EnterCodeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterCodeComponent)

export default EnterCodeContainer
