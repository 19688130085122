import React, { Component, Fragment } from 'react';
import { Statistic } from 'semantic-ui-react'
// import { objectTools } from '../../../utilities'
// import moment from 'moment'
// import { StyledElements } from '../../common'

class AssociatedUsersComponent extends Component {
  componentDidMount() {
    if (!this.props.attorneyStats) {
      this.props.fetchAttorneyStats()
    }
  }

  render() {
    const { attorneyStats } = this.props
    if (!attorneyStats) return <div />
    return (<Fragment>
      <Statistic.Group widths='four'>
        <Statistic>
          <Statistic.Value>{attorneyStats.guilty}</Statistic.Value>
          <Statistic.Label>Guilty</Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>{attorneyStats.dismissed}</Statistic.Value>
          <Statistic.Label>Dismissed</Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>{attorneyStats.reduced}</Statistic.Value>
          <Statistic.Label>Reduced</Statistic.Label>
        </Statistic>

        <Statistic>
          <Statistic.Value>{attorneyStats.processing}</Statistic.Value>
          <Statistic.Label>Processing</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    </Fragment>);
  }
}

export default AssociatedUsersComponent;
