import { connect } from 'react-redux'
import { associatedUsersSelector } from '../duck/selectors'
import { authenticationSelectors } from '../../authentication/duck'
import { profileThunks } from '../duck'
import AssociatedUsersComponent from './AssociatedUsersComponent'

const mapStateToProps = (state, props) => ({
  associatedUsers: associatedUsersSelector(state),
  user: authenticationSelectors.userSelector(state),
  attorney: authenticationSelectors.attorneySelector(state),
})

const mapDispatchToProps = dispatch => ({
  fetchAssociatedUsers: () => dispatch(profileThunks.fetchAssociatedUsers()),
})

const AssociatedUsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociatedUsersComponent)

export default AssociatedUsersContainer
