import actions from './actions'
import * as services from './services'
import activationSteps from '../activationSteps'
import { overlaysActions } from '../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../constants'
import { emailValidation } from '../../../utilities'

const validateCode = code => dispatch => {
  if (!code) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Missing Activation Code',
        message: 'An activation code is required to proceed.',
      })
    )
  }
  dispatch(overlaysActions.showLoader())
  services
    .CheckCode(code)
    .then(attorney => {
      dispatch(actions.setAttorney(attorney))
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Valid Activation Code Supplied`,
          duration: 5,
        })
      )
      dispatch(actions.setStep(activationSteps.user))
    })
    .catch(error => {
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Activation Code Issue',
          message: error,
        })
      )
      dispatch(overlaysActions.hideLoader())
    })
}

const validateUser = (
  email,
  emailConfirmation,
  password,
  passwordConfirmation,
  name
) => dispatch => {
  dispatch(overlaysActions.resetNotifications())
  const emailValid = emailValidation(email)
  const emailConfirmationValid = emailValidation(emailConfirmation)
  if (!emailValid) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 18,
        title: 'Email address is not valid.',
      })
    )
  }
  if (!emailConfirmationValid) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 18,
        title: 'Confirmation email address is not valid.',
      })
    )
  }
  if (!password.trim()) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 18,
        title: 'Password not entered.',
      })
    )
  }
  if (!passwordConfirmation.trim()) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 18,
        title: 'Password confirmation not entered.',
      })
    )
  }
  if (email.toLowerCase().trim() !== emailConfirmation.toLowerCase().trim()) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 18,
        title: 'Email addresses do not match.',
      })
    )
  }
  if (password !== passwordConfirmation) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 18,
        title: 'Passwords do not match.',
      })
    )
  }
  if (!name.trim()) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 18,
        title: 'Your full name is required.',
      })
    )
  }
  dispatch(createUser(email, password, name.trim()))
}

const createUser = (email, password, name) => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .CreateUser(email, password, name)
    .then(user => {
      dispatch(actions.setUser(user))
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `New User Created`,
          duration: 5,
        })
      )
    })
    .catch(err => {
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Error Creating New User',
          message: err,
        })
      )
      dispatch(overlaysActions.hideLoader())
    })
}

const validateLogin = (email, password) => dispatch => {
  if (!email.trim()) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Email Required',
      })
    )
  }
  if (!password.trim()) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Password Required',
      })
    )
  }
  dispatch(loginUser(email, password))
}

const loginUser = (email, password) => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .LoginUser(email, password)
    .then(user => {
      dispatch(actions.setUser(user))
      dispatch(actions.setStep(activationSteps.confirm))
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Logged In`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Login Issue',
          message: error,
        })
      )
      dispatch(overlaysActions.hideLoader())
    })
}

const completeActivation = (userObjectId, activationCode) => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .completeActivation(userObjectId, activationCode)
    .then(() => {
      dispatch(actions.setStep(activationSteps.complete))
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Activation Complete`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Activation Issue',
          message: error,
        })
      )
      dispatch(overlaysActions.hideLoader())
    })
}

export default {
  validateCode,
  validateUser,
  validateLogin,
  completeActivation,
}
