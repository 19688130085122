import { connect } from 'react-redux'
import { usersThunks, usersSelectors } from '../duck'
import { overlaySelectors } from '../../overlays/duck'
import { authenticationSelectors } from '../../authentication/duck'
import { SPECIFIC_OVERLAYS_ITEMS } from '../../../constants'
import { specificOverlayTools } from '../../../utilities'

import ViewUserComponent from './ViewUserComponent'

const mapStateToProps = (state, props) => {
  const objectId = usersSelectors.idFromRouter(state, props)
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_ITEMS.users
  )
  return {
    objectId,
    KEY,
    user: usersSelectors.userById(state, objectId),
    loading: overlaySelectors.specificOverlayLoadingStatusSelector(state, KEY),
    attorney: authenticationSelectors.attorneySelector(state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchUser: objectId => dispatch(usersThunks.fetchUser(objectId)),
  isUserBlocked: objectId => dispatch(usersThunks.isUserBlocked(objectId)),
})

const ViewUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUserComponent)

export default ViewUserContainer
