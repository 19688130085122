import React from 'react'
import { search } from '../../../../utilities'
import { phoneNumbers } from '../../../../utilities'
import { StyledElements } from '../..'
import { Icon } from 'semantic-ui-react'
import { colors } from '../../../../theme/colors'

export const CLIENT_COLUMNS = {
  NAME: {
    Header: 'Name',
    accessor: 'user.name',
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    width: 170,
  },
  PHONE: {
    Header: 'Phone Number',
    accessor: 'user.phoneNumber',
    filterable: true,
    filterMethod: (filter, row) =>
      search.phoneNumber(row[filter.id], filter.value),
    width: 135,
    Cell: ({ value }) =>
      value ? (
        phoneNumbers.usFormat(value)
      ) : (
        <StyledElements.Italicize>No Phone Number</StyledElements.Italicize>
      ),
  },
  HAS_PAYMENT_METHOD_ICON: {
    Header: 'Payment Method',
    accessor: 'user.hasPaymentMethod',
    width: 150,
    filterable: true,
    filterMethod: (filter, row) => {
      if (filter.value === 'all') {
        return true
      }
      if (filter.value === 'true') {
        return row[filter.id]
      }
      return !row[filter.id]
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value="all">All</option>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    ),
    Cell: ({ value }) =>
      value ? (
        <span style={{ color: colors.success.color }}>
          <Icon name="checkmark" />
          Yes
        </span>
      ) : (
        <span style={{ color: colors.error.color }}>
          <Icon name="x" />
          No
        </span>
      ),
  },
}

export const clientColumn = (...clientColumns) => {
  return {
    Header: 'Client Information',
    columns: [...clientColumns],
  }
}
