import React, { Component, Fragment } from 'react'
import styled from 'styled-components'

import { SPECIFIC_OVERLAYS_CATEGORY } from '../../../../constants'
import { specificOverlayTools } from '../../../../utilities'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import ItemHeader from '../ItemHeader'
import { StyledElements, DownloadImageLink } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import ViewImageModalComponent from '../ViewImageModal/ViewImageModalComponent'
import EvidenceDropzoneContainer from './EvidenceDropzoneContainer'

const ImageGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

const Image = styled.img`
  height: 40px;
  min-width: 40px;
  border-radius: 4px;
  ${'' /* max-width: 100px; */}
  margin: 10px 10px 0 0;
  ${'' /* border: solid 1px #dedede; */}
  cursor: pointer;
  opacity: 0.7;
  transition: 0.5s ease-in-out;
  -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
  filter: grayscale(100%);
  &:hover {
    opacity: 1;
  }
`

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
`

class ViewTicketEvidenceComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showImage: null,
    }
  }

  componentDidMount() {
    // if(!evidence)
  }

  showImage = evidenceId => {
    this.setState({ showImage: evidenceId })
  }

  hideImage = () => {
    this.setState({ showImage: null })
  }

  render() {
    const { ticket, objectId, fetchEvidence, evidence } = this.props
    const RenderEvidence = this.renderEvidence
    return (
      <StyledElements.DataContainer>
        <ItemHeader
          title="Ticket Evidence"
          isEditable={false}
          isEditing={false}
          toggleState={() => {}}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <SpecificOverlayContainer
            overlayKey={specificOverlayTools.buildKey(
              this.props.objectId,
              SPECIFIC_OVERLAYS_CATEGORY.evidence
            )}
            LoadingComponent={LoadingComponent}
            ignoreError={true}
            UpdatingComponent={UpdatingComponent}
          >
            <span>
              {ticket && evidence ? (
                <RenderEvidence />
              ) : (
                <StyledElements.FakeLink
                  onClick={fetchEvidence.bind(null, objectId)}
                >
                  Check Evidence
                </StyledElements.FakeLink>
              )}
            </span>
          </SpecificOverlayContainer>
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }

  renderEvidence = () => {
    const { ticket } = this.props
    if (!ticket.evidence.length) {
      return (
        <>
          <StyledElements.Italicize>
            User did not upload any evidence.
          </StyledElements.Italicize>
          <StyledElements.Divider />
          <EvidenceDropzoneContainer objectId={this.props.objectId} />
        </>
      )
    }
    const { showImage } = this.state
    return (
      <Fragment>
        {ticket.evidence.length < 7 ? (
            <EvidenceDropzoneContainer objectId={this.props.objectId} />
        ) : null}
        <ImageGroup>
          {ticket.evidence.map((item, index) => {
            return (
              <ImageItem key={item.objectId}>
                <Image
                  size="small"
                  src={item.file.url}
                  alt={`Evidence image ${index + 1}`}
                  onClick={() => this.showImage(item.objectId)}
                />
                <DownloadImageLink
                  imageUrl={item.file.url}
                  fileName={ticket.ticketID}
                  prepend="ticket-"
                  append={`-evidence-${item.objectId}`}
                />
              </ImageItem>
            )
          })}
        </ImageGroup>
        {ticket.evidence.map((item, index) => {
          return (
            <ViewImageModalComponent
              key={item.objectId}
              open={item.objectId === showImage}
              handleClose={this.hideImage}
              title={`Ticket #${ticket.ticketID} - Evidence #${index + 1}`}
              ticketImage={item.file}
              background={'transparent'}
            />
          )
        })}
      </Fragment>
    )
  }
}

export default ViewTicketEvidenceComponent
