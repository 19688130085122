import { connect } from 'react-redux'
import { regionsSelectors } from '../../regions/duck'
import { overlaySelectors } from '../../overlays/duck'
import { processingThunks, processingSelectors } from '../duck'
import ProcessingListContainer from './ProcessingListContainer'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.processing.all
const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(state, TICKET_TYPE),
  processing: regionsSelectors.regionsFilterBuilder(
    processingSelectors.processingSelector,
    processingSelectors.processingRegionFilterSelector
  )(state),
  retrievedAt: processingSelectors.processingRetrievedAtSelector(state),
})

const mapDispatchToProps = dispatch => ({
  fetchProcessingItems: () => dispatch(processingThunks.fetchProcessingItems()),
})

const AllProcessingListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessingListContainer)

export default AllProcessingListContainer
