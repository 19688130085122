import React, { Component } from 'react'
import { TextArea, Form } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import { LoadingComponent, UpdatingLargeComponent } from '../ticketOverlays'

class EditAttorneyNotesComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.notesInput = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.notesInput.focus()
    }
  }

  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  handleCancel = event => {
    if (event && event.preventDefault) event.preventDefault()
    const { objectId } = this.props
    this.props.clearEditValue(objectId)
    this.toggleStatus()
    return false
  }

  handleChange = event => {
    if (event && event.preventDefault) event.preventDefault()
    const { objectId } = this.props
    const { value } = event.target
    if (value === '') {
      return this.props.editUpdate(objectId, 'unset')
    }
    if (value) {
      this.props.editUpdate(objectId, value)
    }
  }

  updateAttorneyNotes = () => {
    const { ticket, editTicketField, objectId, update } = this.props
    if (
      (editTicketField &&
        editTicketField === 'unset' &&
        ticket.attorneyNotes) ||
      (!ticket.attorneyNotes &&
        editTicketField &&
        editTicketField.trim().length) ||
      (ticket.attorneyNotes &&
        editTicketField &&
        ticket.attorneyNotes.trim() !== editTicketField.trim())
    ) {
      this.setState({ isEditing: false })
      update(objectId, {
        attorneyNotes: editTicketField === 'unset' ? '' : editTicketField,
      })
    }
  }
  renderEdit = () => {
    const { ticket, editTicketField } = this.props
    const showSave =
      (ticket.attorneyNotes &&
        editTicketField &&
        editTicketField === 'unset') ||
      (ticket.attorneyNotes &&
        editTicketField &&
        editTicketField !== 'unset' &&
        ticket.attorneyNotes.trim() !== editTicketField.trim()) ||
      (!ticket.attorneyNotes && editTicketField && editTicketField !== 'unset')
    return (
      <StyledElements.EditContainer>
        <Form>
          <TextArea
            ref={input => {
              this.notesInput = input
            }}
            autoHeight
            onChange={this.handleChange}
            value={
              editTicketField
                ? editTicketField === 'unset'
                  ? ''
                  : editTicketField
                : ticket.attorneyNotes || ''
            }
            placeholder="Enter your notes for this case"
            rows={5}
          />
        </Form>
        <Forms.InlineEditButtons
          buttons={[
            {
              onClick: this.handleCancel,
              color: 'red',
              labelPosition: 'right',
              icon: 'close',
              text: 'Cancel Changes',
            },
            {
              onClick: this.updateAttorneyNotes,
              disabled: !showSave,
              color: 'green',
              labelPosition: 'right',
              icon: 'save',
              text: 'Save Notes',
              type: 'submit',
            },
          ]}
        />
      </StyledElements.EditContainer>
    )
  }
  render() {
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="Attorney Notes"
          isEditable={true}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingLargeComponent}
        >
          {this.state.isEditing ? (
            <RenderEdit />
          ) : this.props.ticket.attorneyNotes ? (
            this.props.ticket.attorneyNotes
          ) : (
            <StyledElements.Italicize>No notes</StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditAttorneyNotesComponent
