import { connect } from 'react-redux'
import { modalSelector } from '../../duck/selectors'
import { overlaysActions } from '../../duck'
import IssueModalComponent from './IssueModalComponent'

const mapStateToProps = state => ({
  modal: modalSelector(state)
})

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(overlaysActions.hideModal())
})

const IssueModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IssueModalComponent)

export default IssueModalContainer
