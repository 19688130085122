import { connect } from 'react-redux'
import { ticketsSelectors } from '../../tickets/duck'
import { ticketsThunks } from '../../tickets/duck'
import ViewTicketContainer from '../../tickets/ViewTicket/ViewTicketContainer'

const mapStateToProps = (state, props) => ({
  currentId: ticketsSelectors.idFromRouter(state, props),
  currentTicket: ticketsSelectors.ticketByIdSelector(
    state,
    ticketsSelectors.idFromRouter(state, props)
  ),
})

const mapDispatchToProps = dispatch => ({
  fetchTicket: objectId => dispatch(ticketsThunks.fetchTicket(objectId)),
  setCurrentlyViewing: ticket => {},
})

const ViewLeadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTicketContainer)

export default ViewLeadContainer
