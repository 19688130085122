import * as services from './services'
import { profileActions } from './index'
import { overlaysActions } from '../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../constants'

const fetchAssociatedUsers = () => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .getAssociatedUsers()
    .then(results => {
      dispatch(profileActions.setAssociatedUsers(results))
      dispatch(overlaysActions.hideLoader())
      // dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.success, title: `Fetched Associated Users`, duration: 5 }))
    })
    .catch(error => {
      console.error('error fetching processing items', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Associated Users',
          message: error,
        })
      )
    })
}

const fetchAttorneyStats = () => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .getAttorneyStats()
    .then(results => {
      dispatch(profileActions.SetAttorneyStats(results))
      dispatch(overlaysActions.hideLoader())
      // dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.success, title: `Fetched Statistics`, duration: 5 }))
    })
    .catch(error => {
      console.error('error fetching Statistics items', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Statistics',
          message: error,
        })
      )
    })
}

export default {
  fetchAssociatedUsers,
  fetchAttorneyStats,
}
