import * as services from "./services"
import { attorneyActions } from "./index"
import { overlaysActions } from "../../overlays/duck"
import {
  NOTIFICATION_TYPES,
  SPECIFIC_OVERLAYS_ITEMS,
  SPECIFIC_OVERLAYS_TYPE
} from "../../../constants"

const fetchAttorneys = () => dispatch => {
  const TYPE = SPECIFIC_OVERLAYS_ITEMS.attorneys
  dispatch(overlaysActions.setSpecificOverlayStatus(TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.getAllAttorneys()
    .then(result => {
      dispatch(attorneyActions.setAllAttorneys(result))
      dispatch(overlaysActions.setSpecificOverlayStatus(TYPE, SPECIFIC_OVERLAYS_TYPE.success))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: `Fetched All Attorneys`,
        duration: 5
      }))
    })
    .catch(error => {
      console.log('error fetching all attorneys', error)
      dispatch(overlaysActions.setSpecificOverlayStatus(TYPE, SPECIFIC_OVERLAYS_TYPE.error))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        title: `Issue Fetching All Attorneys`,
        duration: 15
      }))
    })
}

export default {
  fetchAttorneys
}
