import React from 'react'
import styled from 'styled-components'
import NotificationComponent from './NotificationComponent'

const StyledNotificationList = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  flex-wrap: wrap-reverse;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  pointer-events: none;
  max-height: 100vh;
  padding: ${props => (props.isLoggedIn ? '5em 2em' : '3em')};
`

const NotificationListComponent = ({
  notifications,
  closeFunc,
  isLoggedIn,
}) => {
  if (notifications.length === 0) return <div />
  return (
    <StyledNotificationList isLoggedIn={isLoggedIn}>
      {notifications.map(notification => (
        <NotificationComponent
          closeFunc={closeFunc}
          notification={notification}
          key={notification.id}
        />
      ))}
    </StyledNotificationList>
  )
}

export default NotificationListComponent
