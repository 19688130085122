import { connect } from 'react-redux'
import AuthenticationComponent from './AuthenticationComponent'
import { attorneySelector } from './duck/selectors'

const mapStateToProps = state => ({
  attorney: attorneySelector(state)
})

const mapDispatchToProps = dispatch => ({})

const AuthenticationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationComponent)

export default AuthenticationContainer
