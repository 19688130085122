const PLATFORM_PARTICIPATION_AGREEMENT = `PLATFORM SERVICES AGREEMENT
WINIT LLC (THE “COMPANY”) PROVIDES THE PLATFORM SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS PLATFORM SERVICES AGREEMENT (THE “AGREEMENT”) AND ON THE CONDITION THAT THE COUNTERPARTY HERETO (THE “ATTORNEY”) ACCEPTS AND COMPLIES WITH THEM. BY CLICKING THE “ACCEPT” BUTTON BELOW, AND/OR BY USING THE PLATFORM, ATTORNEY (A) ACCEPTS THIS AGREEMENT AND AGREES THAT HE/SHE IS LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENTS AND WARRANTS THAT ATTORNEY HAS THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND BIND HIM/HER TO ITS TERMS. IF ATTORNEY DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, THE COMPANY WILL NOT AND DOES NOT PROVISION THE PLATFORM AND SERVICES RELATED THERETO TO ATTORNEY AND ATTORNEY MUST NOT USE THE PLATFORM.
RECITALS:
WHEREAS, Company owns and operates a certain platform accessible via its website and mobile application (the “Platform”), which enables users (“Users”) to conveniently find and retain traffic attorneys to represent them in disputing traffic violation tickets, traffic violations and other matters involving motor vehicle use which they submitted to the Platform (“Tickets”); and
WHEREAS, the parties herein have decided to formalize the terms upon which the Attorney shall be entitled to use the Platform to offer legal services to the Platform’s Users in connection with their Tickets (“Legal Services”), all upon the terms and subject to the conditions of this Agreement.
NOW, THEREFORE, for good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the parties hereby agree as follows:
1.	Scope of Engagement.

1.1	Subject to the terms of this Agreement, the Company hereby grants the Attorney the limited right to use the Platform solely during the Term (as defined below) to offer and provide Legal Services to those Users which so elect to retain the Attorney.  Any User who elects to retain the Attorney via the Platform or otherwise as a result of the Attorney’s participation in the Platform shall be deemed “Attorney’s Client” or “Client” for purposes of this Agreement.

1.2	Attorney hereby grants the Company a limited, non-exclusive, non-transferable, non-sub-licensable revocable right and license, during the Term, to display Attorney’s trade names, trademarks, service marks, logos, domain names and the like for the purpose of enabling Attorney’s participation in the Platform in accordance with the terms hereof.

1.3	Attorney hereby acknowledges that Company may, from time to time, institute certain contractual provisions in respect of the Platform, to which Attorney shall be subject and adhere.  Upon such occurrence, Company will distribute such new term(s) to Attorney via email.  Unless any such term(s) is required by applicable law, Company will endeavor to provide Attorney where practicable with not less than ten (10) days to adopt and implement such requirement(s) in his/her use of the Platform.  Any and all terms of use regarding the Platform that Company mandates shall be incorporated herein by reference.  If Attorney elects not to agree to such new provisions, Attorney may terminate this Agreement without penalty no later than ten (10) days from Attorney’s receipt of the Company’s email containing such new provision.

2.	Attorney Acknowledgements.

2.1	Attorney acknowledges that:

2.1.1	Attorney is responsible for ensuring that his/her participation on the Platform complies with all applicable federal, state and municipal laws and regulations, as well as all applicable rules of professional conduct issued by the local court(s) and/or bar association(s) in each state covered by Attorney;

2.1.2	the Platform is designed to facilitate communications and, if applicable, the retention of Attorney by Clients.  However, neither the Platform nor the Company endorse or will endorse any specific attorney(s);

2.1.3	if the Attorney and a User choose to initiate a relationship via the Platform, they do so at their sole discretion;

2.1.4	Attorney shall undertake commercially reasonable efforts to either initiate or decline an engagement with a Potential Client within twenty-four (24) business hours of receiving an inquiry notice from either the Company or the User directly;

2.15	any agreement for Legal Services between Attorney and a Client is a separate direct agreement between the Attorney and the Attorney’s Client.  Accordingly, Attorney is solely responsible for conducting conflict of interest checks, complying with all applicable professional rules of conduct, maintaining the highest level of confidentiality/privilege of all the information and communications received from or about any User, whether or not the Attorney is retained by such User, and shall maintain the insurance coverage (i.e., malpractice and otherwise) that is mandated by applicable law and the rules of professional conduct;

2.1.6	Company’s provisioning of the Platform, which includes content about, and a way for Users to contact Attorney, may constitute attorney-advertising.  As such, any and all content that Attorney shall provide to Company that will be published on the Platform shall: (i) be the sole and exclusive responsibility of Attorney, (ii) not be modified by the Company without the Attorney’s express prior written approval, and (iii) similarly comply with all applicable attorney-advertising laws, regulations and professional rules;

2.1.7	to the extent necessary for Company to manage the Platform, process transactions and resolve technical/user support issues, Company may require access to the records, files and correspondence of the Clients (the “Client Materials”).  Accordingly, Attorney grants to Company the right to access Client Materials notwithstanding the confidentiality and privilege of the foregoing solely for the limited purpose described in this Section 2.1.7; and

2.18	during the period in which Attorney makes use of the Platform in accordance with the terms of this Agreement, Attorney shall use the software and services supplied by Company to manage his/her/its Client Materials (i.e., the Company’s proprietary SaaS solution), or such other file management system that is pre-approved by Company.

3.	Warrants; Representations and Covenants.  Attorney represents, warrants and covenants that:

3.1	Attorney is a licensed attorney in good standing with the State Bar of New York, and has all necessary power and authority to execute, deliver, and perform this Agreement and provide the Legal services to those Users who engage the Attorney’s legal services.

3.2	Attorney has not been and is not subject to any disciplinary actions or complaints filed with the State Bar of New York, nor has there been any civil or criminal actions initiated against the Attorney.

3.3	Attorney possesses the capabilities, skills and experience to render the Legal Services and shall conduct his/her/its business in a way that conforms to the highest business and professional standards.

3.4	Attorney is familiar with and does not provide any legal services in violation of any of the provisions of the New York State Unified Court System- Part 1200 Rules of Professional Conduct, including but not limited to the Rule 1.5(a) provision regarding contingency payments.

3.5	Attorney shall not engage in any act or conduct and shall not make any statement which can reasonably be expected to have a material adverse effect on the business, financial condition or reputation of the Company or the Platform.

3.6	If Attorney becomes subject to any actual or a threatened disciplinary action in any jurisdiction or otherwise fails to meet any of the foregoing representations and covenants at any time, it must immediately notify the Company in writing and immediately cease any further use of the Platform without the Company’s prior written approval.  Additionally, in the event of the suspension or termination of the license to practice law in any State of a member or associate of the Attorney’s Firm, Attorney shall immediately notify the Company and the Company reserves the right to remove such professional’s information from the Platform. Upon the occurrence of any of the foregoing, the Company reserves the right to have its legal counsel discuss the management of the Attorney’s firm and make a determination that the Company’s business reputation is not harmed by the suspension or termination of the license to practice law in any State of a member or associate of Attorney’s Firm.

3.7	Attorney undertakes, subject to the applicable laws and attorney-client privilege, to promptly notify the Company in writing of any complaint by the Attorney’s Client regarding the Legal Services that Attorney has rendered for such party, which may result in a dispute between the parties.

3.8	Throughout the Term of this Agreement, the Company shall monitor the applicable state laws and regulations pertaining to attorney advertising, and to the extent deemed necessary by the Company and its counsel, will modify the Company’s Platform, marketing materials as well as this Agreement.

4.	Attorney Fee; Payment Processing.

4.1	The fee(s) which Clients shall pay to receive Legal Services from Attorney (the “Legal Fees”) shall be determined by Attorney, in his/her/its sole discretion, after consultation with Company.  Set forth in Exhibit A attached hereto is the Legal Fee schedule that Clients will pay commencing from the Effective Date (as defined below) hereof, subject to the changes that Attorney desires to make from time to time during the Term hereof.  Attorney acknowledges that it shall provide Company with not less than thirty (30) days prior written notice of any changes that it desires to make to the Legal Fees.  As between Attorney and Company, Attorney shall be solely responsible for ensuring the Legal Fees charged to Attorney’s Clients complies with all applicable laws and regulations.  Nothing herein shall be constructed as limiting Attorney’s right and flexibility to determine the Legal Fees, extend discounts, charge additional fees based on new information obtained or complexity of the matter not disclosed at the initial time of retention via the Platform, as the same shall be at the sole discretion of Attorney.

4.2	Promptly following a User’s retention of Attorney via the Platform, the Company shall process a payment of the Legal Fees made by the Attorney’s Client on behalf of the Attorney (the “Client Payment”). The full amount of the Client Payment will thereafter be transferred by the Company to the account designated by the Attorney at the time of registration (or any other account that may be substituted by the Attorney from time to time).  Depending upon the Company’s payment processing practices and/or as mutually agreed by the parties, Client Payments will be processed on a daily or weekly basis by the Company without deduction of costs of expenses.

4.3	Attorney hereby acknowledges and understands that although the transactions with the Attorney’s Clients are processed through the Platform, the Company is simply an intermediary party in such transaction, and has no liability, either primarily or secondarily, for paying the Attorney or any third party in the event of any failure to process and collect the Client Fee or any other payment due in connection with the Legal Services.

5.	Remuneration.

5.1	In consideration for the Attorney’s use of the Platform, the Attorney shall pay the Company the applicable platform usage fee (“Subscription Fee”) and, if applicable, premium services fee (the “Premium Fee” and together with the Subscription Fee, collectively, the “Remuneration”), both of which are set forth in Exhibit B attached hereto (as may be amended from time to time by the Company in its sole discretion on ten (10) days prior written notice Attorney).  The Remuneration shall be paid in accordance with the payment terms set forth in Exhibit B.  Each of Attorney and Company acknowledges and agrees the Remuneration constitutes an independent, arm’s length negotiated and mutually agreed fee that Attorney shall pay in connection with his/her/its use of the Platform, and under no circumstances shall such fee be deemed, whether directly or indirectly, a referral fee or the like from the Legal Fees which a Client pays to the Attorney in connection with the Attorney’s provisioning of Legal Services.

6.	Term & Termination:

6.1	This Agreement shall commence as of the Effective Date and thereafter continue in full force and effect until terminated by either party upon ten (10) days’ prior written notice (the “Term”).  In addition, each party herein may terminate this Agreement at any time upon prior written notice to the other party if the other party breaches this Agreement and fails to cure such breach within five (5) days after receipt of a written notice of the breach.

6.2	Notwithstanding the foregoing or anything else to the contrary herein, the Company may also terminate this Agreement, effective immediately, in the event the Company reasonably determines, in its sole discretion, that providing advance notice is not practicable under the circumstances and avoiding immediate termination may bring the Company into disrepute or otherwise negatively impacts its business.

6.3	Upon termination of this Agreement, the Attorney must immediately cease all use of the Platform in his/her/its capacity as a participating attorney and all references to the Attorney on the Platform shall be promptly removed by the Company. Any termination hereof shall not affect: (i) any accrued Remuneration due the Company on the effective date of termination; and (ii) the Attorney’s obligation to perform the ongoing Legal Services.

6.4	The above post-termination obligations as well as Sections 2, 5, 6, 7, 8, 9, 10 and 11 hereof shall survive the termination or expiration of the Term for any reason.

7.	Non-Solicitation; Non-Circumvention ‬. ‬‬‬‬‬‬
7.1	Attorney hereby expressly agrees that without the Company’s prior written consent, it shall not:‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬‬ (i) during the Term, engage, directly or indirectly, as a principal, agent, employee, employer, consultant, investor or service provider (including in its capacity as an attorney), in any directly competing business (i.e., an online platform designed to assist users to find attorneys to challenge parking and traffic violations tickets); or (ii) during the Term and for a period of one (1) year thereafter, solicit or induce any employee, consultant, investor, service provider, affiliate or customer of the Company, including Users (each and collectively “Restrictive Person”) to discontinue or reduce the scope of his/her/its relationship with the Company in any capacity, or otherwise contact such Restrictive Person in a manner which results in or ,is likely to result in circumventing or obviating the restrictions set forth in this Section 7.‬
7.2	Attorney acknowledges and agrees that all User demographic information (“User Information”) is the sole and exclusive property of the Company.  Accordingly, except for rendering Legal Services to any User, Attorney may not sell, lease, license, disclose or otherwise commercialize any User information without the Company’s express prior written consent.
8.	Disclaimers.
THE PLATFORM AND THE SERVICES PROVIDED TO THE ATTORNEY ARE ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE. NEITHER THE COMPANY NOR ITS THIRD PARTY PROVIDERS REPRESENTS OR WARRANTS THAT THE PLATFORM AND ITS CONTENT WILL BE ERROR-FREE OR MEET ATTORNEY’S EXPECTATIONS. THE COMPANY DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR GUARANTEES CONCERNING ANY USERS’ TRUSTWORTHINESS, PAYMENT ABILITY OR ELIGIBILITY TO USE THIS PLATFORM, OR THE SUITABILITY, COMPLIANCE WITH PROFESSIONAL RULES, OR QUALITY OF THE LEGAL SERVICES, WHICH ARE THE ATTORNEY’S SOLE RESPONSIBILITY.
9.	Limitation on Liability.
IN NO EVENT WILL THE COMPANY, ITS MANAGERS, OFFICERS, MEMBERS, EMPLOYEES, SERVICE PROVIDERS, REPRESENTATIVES OR AGENTS, BE LIABLE FOR DAMAGES OF ANY KIND, WHETHER DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE, INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, UNDER ANY LEGAL THEORY, WHETHER CAUSED BY BREACH OF CONTRACT, TORT, NEGLIGENCE, OR OTHERWISE, EVEN IF FORESEEABLE, ARISING OUT OF OR IN CONNECTION WITH: (i) ATTORNEY’S USE OF (OR INABILITY TO USE) THE PLATFORM OR SERVICES PROVIDED THROUGH THE PLATFORM; (ii) ANY CONTENT SUBMITTED TO OR ACCESSED THROUGH THE PLATFORM; (iii) ANY SERVICES PROVIDED ON OR OBTAINED THROUGH THE PLATFORM INCLUDING ANY LEGAL REPRESENTATION; OR (iv) ANY COMMUNICATIONS OR DEALINGS WITH, OR CONDUCT OF USERS AND ATTORNEYS. IN THE EVENT THAT NOTWITHSTANDING THE FOREGOING, THE COMPANY SHALL BE HELD LEGALLY LIABLE FOR LOSS OR DAMAGE, TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF THE COMPANY, (INCLUDING ITS MANAGERS, OFFICERS, MEMBERS, EMPLOYEES, SERVICE PROVIDERS, REPRESENTATIVES OR AGENTS), FOR ANY CLAIM ARISING OUT OF OR RELATING TO THE USE OF THE PLATFORM, IS LIMITED TO THE AMOUNT OF SUBSCRIPTION FEES PAID BY THE ATTORNEY IN THE 12 MONTH PERIOD PRECEDING THE FILING OF SUCH CLAIM.

10.	Indemnification:

10.1	Attorney shall indemnify, defend and save harmless the Company, its affiliates and service providers, and its and their respective officers, managers, members, employees, contractors, agents, licensors, suppliers, successors and assigns from all losses, liabilities, damages claims costs (including reasonable legal costs) and demands which may be brought against the Company in respect of (i) any breach by the Attorney of any term of this Agreement, any applicable laws or rights of any third party; (ii) Attorney’s performance of the Legal Services or any other aspect of the communication and relationship between Attorney and Users (including the Attorney’s Clients).

10.2	The Company shall defend, indemnify and hold harmless Attorney from and against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards, penalties, fines, costs or expenses of whatever kind, including reasonable attorneys’ fees, the cost of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers, arising out of or resulting from any third-party claim against Attorney arising out of or resulting from the Company’s failure to comply with any of its obligations hereunder.

11.	Confidentiality and Data Security.	The Company’s obligations related to confidentiality and data security are contained herein below:
11.1	“Highly-Sensitive Personal Information” means an (i) individual’s government-issued identification number (including social security number, driver’s license number or state-issued identified number); (ii) financial account number, credit card number, debit card number, credit report information, with or without any required security code, access code, personal identification number or password, that would permit access to an individual’s financial account; or (iii) biometric or health data.

11.2	“Personal Information” means information provided to the Company by or at the direction of Customer, or to which access was provided to The Company by or at the direction of Customer, in the course of the Company’s performance under this Agreement that: (i) identifies or can be used to identify an individual (including, without limitation, names, signatures, addresses, telephone numbers, e-mail addresses and other unique identifiers); or (ii) can be used to authenticate an individual (including, without limitation, employee identification numbers, government-issued identification numbers, passwords or PINs, financial account numbers, credit report information, biometric or health data, answers to security questions and other personal identifiers), in case of both sub-clauses (i) and (ii), including, without limitation, all Highly-Sensitive Personal Information. A User’s business contact information is not by itself deemed to be Personal Information.

11.3	“Security Breach” means (i) any act or omission that compromises either the security, confidentiality or integrity of Personal Information or the physical, technical, administrative or organizational safeguards put in place by the Company or any authorized persons that relate to the protection of the security, confidentiality or integrity of Personal Information, or (ii) receipt of a complaint in relation to the privacy practices of the Company or any authorized persons or a breach or alleged breach of this Agreement relating to such privacy practices.

11.4	The Company acknowledges and agrees that, in the course of its engagement by Attorney, the Company may receive or have access to Personal Information of the Potential Clients who use the Company’s web site to engage Legal Services.

11.4.1	The Company shall comply with the terms and conditions set forth in this Agreement in its collection, receipt, transmission, storage, disposal, use and disclosure of such Personal Information and be responsible for the unauthorized collection, receipt, transmission, access, storage, disposal, use and disclosure of Personal Information under its control or in its possession.

11.5	In recognition of the foregoing, the Company agrees and covenants that it shall: (i) keep and maintain all Personal Information in strict confidence, using such degree of care as is appropriate to avoid unauthorized access, use or disclosure; (ii) use and disclose Personal Information solely and exclusively for the purposes for which the Personal Information, or access to it, is provided pursuant to the terms and conditions of this Agreement, and not use, sell, rent, transfer, distribute, or otherwise disclose or make available Personal Information for the Company’s own purposes or for the benefit of anyone other than User, in each case, without User’s prior written consent; and (iii) not, directly or indirectly, disclose Personal Information to any person other than its authorized employees/authorized persons, including any auditors or retained outside vendors.

11.5.1	In the event of breach or access by unauthorized person(s) of a User’s Personal Information, the Company will be responsible for and remain liable to User for the actions and omissions of such unauthorized third party concerning the treatment of such Personal Information as if they were the Company’s own actions and omissions.

11.6	If, in the course of its engagement by Attorney, the Company has access to or will collect, access, use, store, process, dispose of or disclose credit, debit or other payment cardholder information, the Company shall at all times remain in compliance with the Payment Card Industry Data Security Standard (“PCI DSS”) requirements, including remaining aware at all times of changes to the PCI DSS and promptly implementing all procedures and practices as may be necessary to remain in compliance with the PCI Security Counsel Standards, at the Company’s sole cost and expense.

11.7	At a minimum, the Company’s safeguards for the protection of Personal Information shall include: (i) limiting access of Personal Information to authorized employees/authorized persons; and (ii) securing business facilities, data centers, paper files, servers, back-up systems and computing equipment, including, but not limited compliance with IT management industry standard practices which comprise (a) implementing network, device application, database and platform security; (b) securing information transmission, storage and disposal; (c) implementing authentication and access controls within media, applications, operating systems and equipment; (d) encrypting Highly-Sensitive Personal Information stored on any mobile media; (e) encrypting Highly-Sensitive Personal Information transmitted over public or wireless networks; (e) strictly segregating Personal Information from information of the Company or its other customers so that Personal Information is not commingled with any other types of information; (f) implementing appropriate personnel security and integrity procedures and practices, including, but not limited to, conducting background checks consistent with applicable law; and (g) providing appropriate privacy and information security training to the Company’s employees.

12.	Miscellaneous
12.1	Notices.  All notices and approvals hereunder shall be in writing and deemed duly given if personally delivered or sent by facsimile or email, to the other party at the address set forth below.  Notices shall be deemed given when personally delivered or one (1) business day after successful transmission when sent by facsimile or email.
12.2	Relationship. Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint venture, or other form of joint enterprise, employment, or fiduciary relationship between the parties.
12.3	Severability.  If any provision herein is unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of this Agreement will continue in full force and effect.
12.4	Governing Law; Jurisdiction. This Agreement shall be governed by and construed in accordance with the laws of the State of New York, and the parties hereby irrevocably consent to the venue of and the personal jurisdiction of the New York State Courts located in the State of New York, Borough of Manhattan (New York County) for any suit or action arising from or related to this Agreement. EACH PARTY HEREIN VOLUNTARILY WAIVES (IN CONNECTION WITH ANY DISPUTE ARISING OUT OF THESE TERMS OR THE SERVICES), ANY RIGHT TO A TRIAL BY JURY IN ANY COURT.
12.5	Entire Agreement; Amendment.  The Attorney acknowledges that he/she/it has read the Term of Use of the Platform (as may be amended from time to time, the “TOU”), which are fully incorporated herein by reference, provided that in the event of any inconsistency between the terms of this Agreement and the TOU, the terms of this Agreement shall prevail.  This Agreement constitutes the entire understanding between the parties hereto and supersedes all prior written or oral communications, relating to the subject matter covered in this Agreement.  This Agreement shall not be amended except by a writing executed by the parties hereto.
12.6	Counterparts. This Agreement may be executed in separate counterparts, each of which will be deemed an original and all of which, when taken together, will constitute one agreement.  Electronic copies/faxed/scanned transmitted documents shall be valid as originals.
12.7	Exhibits.	The exhibits referenced in this Agreement shall be supplied to Attorney under separate cover, but all of which shall be deemed incorporated herein by reference.
12.8	Click-Wrap.	Attorney’s click of the “I accept” button below shall have the same legal validity and enforceability as a manually executed signature or use of a paper-based recordkeeping system to the fullest extent permitted by applicable law, and the parties hereby waive any objection to the contrary.


By clicking “I agree”, I hereby agree to the terms and conditions set forth in this Agreement. 
Exhibit A to Winit Platform Participation Agreement


CLIENT LEGAL FEES


All fees are to be set by the Attorney in his/her/its sole discretion and shall be communicated to the Company via email.  In addition, the Attorney shall have the right to amend the price(s) previously communicated to the Company by separate email notice.
 

Exhibit B to Winit Platform Participation Agreement
REMUNERATION
A. Subscription Fee: Attorney shall pay the applicable monthly Subscription Fees set forth in the Company’s then current rate card below based on the number of active client files in effect at the end of each month.
B. Premium Fee:  If Attorney shall commission the provisioning of the Premium Services described in the below notes, Attorney shall pay the applicable Premium Fee pursuant to the Company’s then-current rate card based on the number of active client files in effect at the end of each month, which fee shall be paid monthly and combined with the applicable Subscription Fee.

Notes:
•	The Company’s current rate cards are available on your monthly statements.
•	Attorney acknowledges that each Client’s payment via the Platform shall be processed as follows -- upon receipt of the Client payment: (i) Company shall retain the Platform User Fee (as defined in the Platform Terms of Use); (ii) Company shall immediately transfer the balance of such Client payment to an account designated and managed by the Attorney (the “Attorney Designated Account”); (iii) thereafter, the Company shall transfer the balance (less the Attorney’s Legal Fees which are retained by Attorney) from the Attorney Designated Account to another designated bank account (the “Company Designated Account”) and all authorized transfers of monies to Company for the settling of the Remuneration and other fees due Company shall be made from the Company Designated Account in accordance with the Remuneration terms set forth in this Agreement.  A detailed reconciliation of such payments will be sent to Attorney each month by Company.
•	The Premium Fee shall cover the following premium services: (i) access to the Company’s BI analytics group, (ii) live agent support sessions, and (iii) impression delivery preferences for unique Users.
•	So long as Attorney continues to manage new Client files which are facilitated via the Company, the Company may apply monies on balance in the Company Designated Account to the Remuneration.   If, upon any termination of this Agreement, there is a balance remaining in the Company Designated Account following the transfer of all monies due and owing to Company, such remaining proceeds will be transferred to Attorney.
•	The applicable Subscription Fee and Premium Fee shall be transferred the Company in full on a monthly basis in arrears for the provisioning of the Platform by the Company during the immediately preceding month.
•	Attorney shall not be required to pay any Subscription Fees and/or Premium Fees to the Company until such time as Attorney has onboarded his/her/its fifth (5th) active client file on the Platform.
•	The applicable Subscription Fee and Premium Fee shall be applied to all of the Attorney’s usage of the Platform during the immediately preceding month based upon the Attorney’s designated Subscription Level in effect at the end of the month.
•	In the event of a dispute between the Company’s records and those of the Attorney in order to calculate the Subscription Fee, the Company’s records shall govern.
•	Company will endeavor to provide Attorney with at least forty-five (45) days’ prior written notice of changes to its Rate Cards before such changes shall go into effect and be binding on Attorney.

`

export default PLATFORM_PARTICIPATION_AGREEMENT
