import types from './types.js'

export const setContactInfo = (value) => ({
  type: types.SET_ALL_CONTACT_INFO,
  value,
})

export const setUpdatedContactInfo = (value) => ({
  type: types.SET_UPDATED_CONTACT_INFO,
  value,
})

export const setSingleContactInfo = (value) => ({
  type: types.SET_SINGLE_CONTACT_INFO,
  value,
})

export const setHearingDateContactInfo = (value) => ({
  type: types.SET_HEARING_DATE_CONTACT_INFO,
  value,
})

export default {
  setContactInfo
}
