import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { Dropdown, Segment } from 'semantic-ui-react'
import { ListToolbarComponent, RTTools, StyledElements } from '../../common'
import resolvedDataForProcessing from '../../common/csv/resolvedDataForProcessing'
import { CSVLink } from 'react-csv'
import ReactTable from 'react-table'
import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import Error from '../../Error'

class SuspendedProcessingListComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
      CSVDataToDownload: []
    }
  }
  componentDidMount() {
    if (!this.props.retrievedAt && !this.props.loading) {
      this.props.fetchSuspendedItems()
    }
  }
  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }
  getCSVData = () => {
    const currentRecords = this.reactTable.getResolvedState().data
    const currentRecordsFiltered = resolvedDataForProcessing(currentRecords)
    this.setState({ CSVDataToDownload: currentRecordsFiltered }, () => {
      this.csvLink.link.click()
    })
  }
  getRegionText = (prepend = 'in ') => {
    if (!this.props.regionFilter) return ''
    return `${prepend}${this.props.regionFilter.name}`
  }
  renderToolBars = () => {
    const toolbarItems = [
      {
        icon: 'law',
        text: `${this.props.suspensions.length} Cases Suspended ${this.getRegionText()}`,
      },
    ]
    return (
      <Fragment>
        {this.props.suspensions.length ? (
          <ListToolbarComponent
            items={toolbarItems}
            update={this.props.fetchSuspendedItems.bind(
              null,
              this.props.currentCategory
            )}
            refreshedAt={this.props.retrievedAt}
            getCSVData={this.getCSVData}
          />
        ) : null}
      </Fragment>
    )
  }
  render() {
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        centerFillStatus={true}
        overlayKey={this.props.pageType}
        showSpinner={false}
        errorText={`Issue fetching suspensions`}
        loadingText={`Loading Suspensions`}
      >
        <RenderList />
      </SpecificOverlay>
    )
  }

  renderList = () => {
    const regionText = this.getRegionText()
    const Toolbars = this.renderToolBars
    if (!this.props.suspensions.length) {
      return (
        <Fragment>
          <Toolbars />
          <StyledElements.Centered>
            <Helmet
              title={`No Suspended Cases ${regionText}`}
            />
            <h3>
              {`No Suspended Cases ${regionText}`}
            </h3>
            <ListToolbarComponent
              update={this.props.fetchSuspendedItems.bind(
                null,
                this.props.currentCategory
              )}
              isLoading={this.props.isLoading}
              refreshedAt={this.props.retrievedAt}
            />
            {this.props.regionFilter ? (
              <StyledElements.FakeLink onClick={this.props.clearRegionFilter}>
                Try clearing the region filter...
              </StyledElements.FakeLink>
            ) : null}
          </StyledElements.Centered>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Helmet title="Suspended Cases" />
        <Toolbars />
        <Segment>
          <CSVLink
            data={this.state.CSVDataToDownload}
            ref={r => (this.csvLink = r)}
            filename={`Processing (Suspensions) - ${moment().format('YYYY-MM-DD')}`}
          />
          <Error.Boundary>
            <ReactTable
              data={this.props.suspensions}
              noDataText={`No Cases Found`}
              columns={RTTools.columns.buildProcessingRecentlyApproved(
                  this.props.attorney && this.props.attorney.globalAdmin,
                  this.props.attorney && !this.props.attorney.globalAdmin && !this.props.attorney.disableAttorneyPriceVisibility,
                  {
                    history: this.props.history,
                    baseUrl: '/processing/view',
                  }
              )}
              pageSize={this.state.pageSize}
              showPageSizeOptions={false}
              minRows={RTTools.preferences.minRows}
              className={RTTools.preferences.RowClassName}
              sorted={this.props.rtSettings.sort}
              filtered={this.props.rtSettings.filter}
              resized={this.props.rtSettings.size}
              onSortedChange={(newSorted, column, shiftKey) =>
                this.props.setSorted(newSorted)
              }
              onFilteredChange={(filtered, column) =>
                this.props.setFiltered(filtered)
              }
              onResizedChange={(newResized, event) =>
                this.props.setResized(newResized)
              }
              ref={r => this.reactTable = r}
            />
          </Error.Boundary>
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default SuspendedProcessingListComponent
