import searchReducer from './reducers'
import * as searchActions from './actions'
import * as searchSelectors from './selectors'
import * as searchServices from './services'
import * as searchThunks from './thunks'
import * as searchTypes from './types'

export {
  searchActions,
  searchSelectors,
  searchServices,
  searchThunks,
  searchTypes,
}
export default searchReducer
