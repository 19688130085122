export const rowOnClick = (baseUrl, history) => {
  return (state, rowInfo) => {
    return {
      onClick: (props) => {
        if (rowInfo && rowInfo.original) {
          history.push(`${baseUrl}${rowInfo.original.objectId}`)
        }
      }
    };
  }
}
