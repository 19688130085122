import React, { Component } from 'react'
import { Loader, Button, Icon, Dimmer, Message } from 'semantic-ui-react'
// import PropTypes from 'prop-types'
import { BraintreeDropIn } from '../../common'

class BasicAddFormComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disableSubmit: false,
      dropinRef: null,
    }
  }
  setDropinRef = dropin =>
    this.setState({
      ...this.state,
      dropinRef: dropin,
    })
  // setDisableSubmit = (bool) => this.setState({
  //   ...this.state,
  //   disableSubmit: bool
  // })
  componentDidMount() {
    const { loading, clientToken, fetchToken, user } = this.props
    if (!clientToken && !loading && user && user.objectId) {
      fetchToken(user.objectId)
    }
  }
  componentWillUnmount() {
    const { clearNonceAndToken } = this.props
    if (clearNonceAndToken) clearNonceAndToken()
  }
  savePayment = () => {
    const {
      user,
      addPaymentMethod,
      loading,
      ready,
      unsetReady,
      setError,
      setNonce,
    } = this.props
    if (!loading && ready) {
      unsetReady()
      this.state.dropinRef
        .requestPaymentMethod()
        .then(btResponse => {
          setNonce(btResponse.nonce)
          addPaymentMethod(user.objectId)
        })
        .catch(error => {
          console.log('error getting token', error)
          let errorString = 'Could not generate a payment token'
          if (error && error.message && typeof error.message === 'string') {
            errorString = error.message
          }
          setError(errorString)
        })
    }
  }
  render() {
    const {
      loading,
      clientToken,
      success,
      ready,
      setReady,
      unsetReady,
      error,
      hideModal,
    } = this.props
    const braintreeDropInOptions = {
      authorization: clientToken,
      card: {
        cardholderName: {
          required: true,
        },
      },
    }
    if (success && hideModal) {
      hideModal()
    }
    return (
      <div>
        {loading ? (
          <Dimmer active>
            <Loader size="massive" inverted />{' '}
          </Dimmer>
        ) : null}
        {error ? (
          <Message negative>
            <Message.Header>Payment method not added</Message.Header>
            <p>{error}</p>
          </Message>
        ) : null}
        {clientToken ? (
          <BraintreeDropIn
            onPaymentMethodRequestable={setReady}
            onNoPaymentMethodRequestable={unsetReady}
            // shouldReset={!!general.error}
            options={braintreeDropInOptions}
            instanceRef={this.setDropinRef}
          />
        ) : (
          'Fetching Payment Token'
        )}
        <div style={{ textAlign: 'right', paddingTop: 20 }}>
          <Button negative onClick={this.props.hideModal}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button positive disabled={!ready} onClick={this.savePayment}>
            <Icon name="checkmark" /> Save
          </Button>
        </div>
      </div>
    )
  }
}

BasicAddFormComponent.propTypes = {}

export default BasicAddFormComponent
