/* eslint no-unused-expressions: "off" */

const isRollbarAvailable = !!process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN

export const rollbarConfig = {
  enabled: isRollbarAvailable,
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ENV,
  server: {
    root: 'http://appwinit.com/',
  },
};
