import React from 'react';
import { Input } from 'semantic-ui-react'


const NameComponent = ({name, value, title, handleChange}) => {
  return (
    <Input name={name} size="mini" onChange={handleChange} label={title} defaultValue={value}/>
  )
}

export default NameComponent;
