import React from 'react'
import { MODAL_TYPES } from '../../../constants'

import IssueModal from './IssueModal/IssueModalContainer'

const ModalComponent = ({ modal }) => {
  switch (modal) {
    default:
      return <div />
    case MODAL_TYPES.issue:
      return <IssueModal />
  }
}

export default ModalComponent
