import types from './types'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const INITIAL_STATE = {
  [FRONTEND_TICKET_TYPES.leads.all]: {
    items: [],
    retrievedAt: null,
  },
  [FRONTEND_TICKET_TYPES.leads.active]: {
    items: [],
    retrievedAt: null,
  },
  [FRONTEND_TICKET_TYPES.leads.closed]: {
    items: [],
    retrievedAt: null,
  },
  [FRONTEND_TICKET_TYPES.leads.closedTickets]: {
    items: [],
    retrievedAt: null,
  },
  [FRONTEND_TICKET_TYPES.leads.unassigned]: {
    items: [],
    retrievedAt: null,
  },
  regionFilter: null,
  currentlyViewing: null,
  closedTicketsHearingDate: new Date(),
}
const leadsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_CLOSED_TICKET_HEARING_DATE: {
      return {
        ...state,
        closedTicketsHearingDate: action.hearingDate,
      }
    }

    case types.SET_LEADS_REGION_FILTER: {
      return {
        ...state,
        regionFilter: action.value,
      }
    }

    case types.SET_CURRENTLY_VIEWING: {
      const { value } = action
      return {
        ...state,
        currentlyViewing: value,
      }
    }

    case types.SET_LEADS_RETRIEVED_AT: {
      const { leadType } = action
      const newLeadType = { ...state[leadType] }
      newLeadType.retrievedAt = new Date()
      return {
        ...state,
        [leadType]: newLeadType,
      }
    }

    case types.SET_LEADS: {
      const { leadType, value } = action
      const newLeadType = { ...state[leadType] }
      newLeadType.items = value
      return {
        ...state,
        [leadType]: newLeadType,
      }
    }

    default:
      return state
  }
}

export default leadsReducer
