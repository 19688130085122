import React, { Component } from 'react'
import CreateTrafficTicket from '../../users/ViewUser/DetailSection/CreateTrafficTicket'

class LookupQuotesColumn extends Component {
  getDataFromQuotes() {
    let quotes = this.props.result.quotes
    let quotesData = []
    // If an error occurred, quotes will be an error object
    if (quotes instanceof Array) {
      quotes = quotes.filter(Boolean) // Remove empty quotes
      quotesData = quotes.map(quote => {
        return {
          text: `${quote.companyName}: ${quote.pricing.winItPrice}$`,
          key: quote.objectId,
          value: quote.objectId,
        }
      })
    }
    return quotesData
  }
  render() {
    if (this.props.result.quotes === undefined) {
      return <div>Loading quotes ...</div>
    }
    return (
      <CreateTrafficTicket
        options={this.getDataFromQuotes()}
        searchResult={this.props.result}
        contactInfo={this.props.contactInfo}
        history={this.props.history}
        ticketType="searchResult"
      />
    )
  }
}

export default LookupQuotesColumn
