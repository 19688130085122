import types from './types.js'

const addManyCounties = (stateShortName, value) => ({
  type: types.ADD_MANY_COUNTIES,
  stateShortName,
  value
})

export default {
  addManyCounties
}
