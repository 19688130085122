
const amountOfLookupTickets = (source, target) => {
  const totalTickets = source.searchCriteria.reduce((acc, cur) => {
    acc += cur.results.length
    return acc
  }, 0)
  return Number(totalTickets) === Number(target)
}

export default amountOfLookupTickets
