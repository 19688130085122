import { connect } from 'react-redux'
import { ticketByIdSelector } from '../../duck/selectors'
import { ticketsThunks } from '../../duck'
import EditTrafficBondComponent from './EditTrafficBondComponent'
import { overlaysActions } from "../../../overlays/duck"
import { NOTIFICATION_TYPES } from "../../../../constants"
import { attorneyIsAdminSelector } from '../../../../app/authentication/duck/selectors'

const mapStateToProps = (state, props) => ({
  isAdmin: attorneyIsAdminSelector(state),
  ticket: ticketByIdSelector(state, props.objectId)
})

const mapDispatchToProps = dispatch => ({
  createTrafficBond: (ticket) => dispatch(ticketsThunks.createTrafficBond(ticket)),
  error: (message) => dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.error, duration: 15, title: message }))
})

const EditTrafficBondContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTrafficBondComponent)

export default EditTrafficBondContainer
