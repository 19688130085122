import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Segment, Dropdown } from 'semantic-ui-react'
import ReactTable from 'react-table'

import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import {
  // buildPDF,
  // ListToolbarComponent,
  RTTools,
  StyledElements,
} from '../../common'
import '../../common/react-table/style.css'
import Error from '../../Error'
import getResultsFromContactInfo from '../../../utilities/getResultsFromContactInfo'
// import { CSVLink} from "react-csv";
// import resolvedDataFilterCSVLeads from '../../common/csv/resolvedDataFilterCSV'


class ViewLookupComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
      // CSVDataToDownload: [],
      currentPage: null,
      totalPages: null
    }
  }

  componentDidMount() {
    if (this.props.allContactInfo.length && this.props.match.params.id) {
      const selectedContactInfo = this.props.allContactInfo.filter(val => val.objectId === this.props.match.params.id)
      this.props.setSingleContactInfo(selectedContactInfo[0])
      this.props.fetchSingleContactInfo(this.props.match.params.id, false) // Call again in background with no loading screen to get additional data (like quotes)
    } else if (!this.props.allContactInfo || !this.props.allContactInfo.length) {
      this.props.fetchSingleContactInfo(this.props.match.params.id)
    }
    // if (!this.props.retrievedAt && !this.props.loading) {
    //   this.props.fetchSingleContactInfo(this.props.match.params.id)
    // }
  }

  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }

  // downloadPDF = () => {
  //   buildPDF(this.props.leads, this.getLeadType() + ' Leads')
  // }

  // handleRowClick = (state, rowInfo) => {
  //   return {
  //     onClick: () => {
  //       if (rowInfo && rowInfo.original) {
  //         this.props.history.push(`/leads/view/${rowInfo.original.objectId}`)
  //       }
  //     },
  //   }
  // }

  // getCSVData = () => {
  //   const currentRecords = this.reactTable.getResolvedState().data
  //   const currentRecordsFiltered = resolvedDataFilterCSVLeads(currentRecords)
  //   this.setState({ CSVDataToDownload: currentRecordsFiltered }, () => {
  //     this.csvLink.link.click()
  //   })
  // }
  
  handlePageChange = (page) => {
    const totalPages = this.reactTable.getResolvedState().pages
    this.setState({
      currentPage: page + 1,
      totalPages: totalPages
    })
  }

  render() {
    // if (!this.props.results || this.props.results.length === 0) {
    //   this.props.history.push('/lookup')
    // }
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        // centerFillStatus={true}
        overlayKey={'lookupSingle'}
        // showSpinner={false}
        // errorText={`Issue fetching`}
      >
        <RenderList />
      </SpecificOverlay>
    )
  }

  renderList = () => {
    if (!this.props.selectedContactInfo.hasOwnProperty('searchCriteria')) {
      return (
        <StyledElements.Centered>
          <Helmet />
          <h3>Lookup Tickets</h3>

          {/* <ListToolbarComponent
            update={this.props.fetchAllContactInfo}
            isLoading={this.props.isLoading}
            refreshedAt={this.props.retrievedAt}
          /> */}

          {/* {this.props.regionFilter ? (
            <StyledElements.FakeLink onClick={this.props.clearRegionFilter}>
              Try clearing the region filter...
            </StyledElements.FakeLink>
          ) : null} */}
        </StyledElements.Centered>
      )
    }
    // const toolbarItems = [
    //   {
    //     icon: 'binoculars',
    //     text: `${this.props.allContactInfo.length}`,
    //   },
    // ]
    const buildColumns = RTTools.columns.buildLookupTickets
    return (
      <Fragment>
        <Helmet
          // title={`${leadType} Leads ${regionText ? `(${regionText})` : ''}`}
        />
        {/* <ListToolbarComponent
          items={toolbarItems}
          update={this.props.fetchAllContactInfo}
          refreshedAt={this.props.retrievedAt}
          download={this.downloadPDF} 
          getCSVData={this.getCSVData}
          pageSize={this.state.pageSize}
          totalItems = {this.props.allContactInfo.length}
          // currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
          lookup={true}
        /> */}
        <Segment>
          <Error.Boundary>
            {/* <CSVLink data={this.state.CSVDataToDownload} ref={(r) => this.csvLink = r} filename={this.props.pageType}/> */}
            <ReactTable
              data={getResultsFromContactInfo(this.props.selectedContactInfo)}
              noDataText={`No Tickets Found`}
              columns={buildColumns(
                this.props.attorney && this.props.attorney.globalAdmin,
                {
                  history: this.props.history,
                  baseUrl: '/lookup',
                },
                this.props.selectedContactInfo,
              )}
              pageSize={this.state.pageSize}
              showPageSizeOptions={false}
              minRows={RTTools.preferences.minRows}
              className={RTTools.preferences.RowClassName}
              // sorted={this.props.rtSettings.sort}
              // filtered={this.props.rtSettings.filter}
              // resized={this.props.rtSettings.size}
              // onSortedChange={(newSorted, column, shiftKey) =>
              //   this.props.setSorted(newSorted)
              // }
              // onFilteredChange={(filtered, column) =>
              //   this.props.setFiltered(filtered)
              // }
              // onResizedChange={(newResized, event) =>
              //   this.props.setResized(newResized)
              // }
              onPageChange={this.handlePageChange}
              ref={(r)=>this.reactTable=r}
            />
          </Error.Boundary>
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default ViewLookupComponent
