import { connect } from 'react-redux'
import { isLoggedIn } from './authentication/duck/selectors'
import AppComponent from './AppComponent'

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
})

const AppContainer = connect(mapStateToProps)(AppComponent)

export default AppContainer
