const SET_SPECIFIC_OVERLAY_STATUS = 'SET_SPECIFIC_OVERLAY_STATUS'

const SHOW_LOADER = 'SHOW_LOADER'
const HIDE_LOADER = 'HIDE_LOADER'

const SET_MODAL = 'SET_MODAL'
const HIDE_MODAL = 'HIDE_MODAL'

const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export default {
  SET_SPECIFIC_OVERLAY_STATUS,
  SHOW_LOADER,
  HIDE_LOADER,
  SET_MODAL,
  HIDE_MODAL,
  RESET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
}
