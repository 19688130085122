import * as types from './types'

export const setUserPaymentClientToken = clientToken => ({
  type: types.SET_USER_PAYMENT_CLIENT_TOKEN,
  clientToken,
})

export const clearUserPaymentClientToken = () => ({
  type: types.CLEAR_USER_PAYMENT_CLIENT_TOKEN,
})

export const setUserPaymentNonce = nonce => ({
  type: types.SET_USER_PAYMENT_NONCE,
  nonce,
})

export const clearUserPaymentNonce = () => ({
  type: types.CLEAR_USER_PAYMENT_NONCE,
})

export const setUserPaymentReady = () => ({
  type: types.SET_USER_PAYMENT_READY,
})

export const clearUserPaymentReady = () => ({
  type: types.CLEAR_USER_PAYMENT_READY,
})

export const setUserPaymentSuccess = () => ({
  type: types.SET_USER_PAYMENT_SUCCESS,
})

export const clearUserPaymentSuccess = () => ({
  type: types.CLEAR_USER_PAYMENT_SUCCESS,
})

export const setUserPaymentError = error => ({
  type: types.SET_USER_PAYMENT_ERROR,
  error,
})

export const clearUserPaymentError = () => ({
  type: types.CLEAR_USER_PAYMENT_ERROR,
})
