import moment from 'moment'

export const mapTicketToObjectIds = ticket => ticket.objectId

export const getTicketChildType = (parentType, ticket) => {
  if (!ticket) return null
  if (!ticket.classifications) return null
  const found = ticket.classifications.filter(classification => {
    const keys = classification.split('/')
    return keys[0] === parentType
  })
  return !found ? null : found[0]
}

/**
 * Gets a date from a ticket.
 * @param {string} dateKey Date key on ticket we are seeking
 * @param {object} ticket Ticket object
 * @param {moment} [min] Moment date for minimum acceptable date
 * @param {moment} [max] Moment date for maximum acceptable date
 */
export const getDate = (dateKey, ticket, min, max) => {
  if (ticket[dateKey]) {
    let date
    if (typeof ticket[dateKey] === "string") {
      date = moment(ticket[dateKey])
    }
    if (ticket[dateKey] && ticket[dateKey].iso) {
      date = moment(ticket[dateKey].iso)
    }
    if (min && max) {
      if (date.isBetween(min, max)) {
        return date.toDate()
      }
    }
    return date.toDate()
  }
  return null
}

/**
 * Get Approved Date from classification array
 */
export const getApprovalDateFromClassification = (classifications) => {
  const prefix = "APPROVED:"
  const result = classifications.filter(item => {
    return item.includes(prefix)
  })
  if (!result.length) return null
  return result[0].slice(prefix.length)
}

/**
 * Create approval classification string
 */
export const makeApprovalDateForClassification = (category) => {
  const prefix = "APPROVED:"
  return prefix + category
}
