import React, { Fragment } from 'react'
import { StyledElements } from '../../common'
import Dropzone from 'react-dropzone'

class ImageDropComponent extends React.Component {

  render() {
    return (
      <Fragment>
        <StyledElements.Divider />
        <Dropzone multiple={false} onDrop={this.props.handleImageDrop} accept="image/*">
          {({ getRootProps, getInputProps }) => {
            return (
              <Fragment>
                <input {...getInputProps()} />
                <StyledElements.FakeLink
                  {...getRootProps({ onClick: evt => evt.preventDefault() })}
                >
                  Upload
                </StyledElements.FakeLink>
              </Fragment>
            )
          }}
        </Dropzone>
      </Fragment>
    )
  }
}

export default ImageDropComponent
