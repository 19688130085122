import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import HearingsRoutes from './HearingsRoutes'
import RegionFilterMenu from '../regions/FilterMenu/FilterMenuContainer'
import { CountLabel, DescribeTab } from '../common'
import { TAB_DESCRIPTIONS } from '../../constants'

class LeadsComponent extends Component {
  // componentWillUnmount() {
  // this.props.setRegionFilter(null)
  // }

  render() {
    const currentLocation = this.props.location.pathname
    const secondLevel = currentLocation.split('/').filter(v => v !== '')[1]
    const { currentlyViewing } = this.props
    const currentlyViewingStatus = currentlyViewing
      ? currentlyViewing.hearingType
      : null
    return (
      <Fragment>
        <Menu stackable={true} tabular>
          {/* <Menu.Item><Menu.Header>Hearings</Menu.Header></Menu.Item> */}
          <Menu.Item
            as={Link}
            name="Recent Hearings"
            active={
              secondLevel === 'recent' || currentlyViewingStatus === 'recent'
            }
            to="/hearings/recent"
          >
            Recent Hearings
            <CountLabel
              key={`recenthearing-count-${this.props.recentHearings.length}`}
              in={!!this.props.recentHearingsRetrievedAt}
              value={this.props.recentHearings.length}
              loading={this.props.recentHearingsLoading}
            />
          </Menu.Item>
          <Menu.Item
            as={Link}
            name="Needs Hearing Date"
            active={
              secondLevel === 'needs-date' ||
              currentlyViewingStatus === 'needs-date'
            }
            to="/hearings/needs-date"
          >
            Needs Hearing Date
            <CountLabel
              key={`nodatehearing-count-${this.props.noDateHearings.length}`}
              in={!!this.props.noDateHearingsRetrievedAt}
              value={this.props.noDateHearings.length}
              loading={this.props.noDateHearingsLoading}
            />
          </Menu.Item>
        </Menu>
        {['recent', 'needs-date'].includes(secondLevel) ? (
          <DescribeTab title={secondLevel}>
            {TAB_DESCRIPTIONS.hearings[secondLevel]}
          </DescribeTab>
        ) : null}
        {!currentlyViewing ? (
          <RegionFilterMenu
            vertical={false}
            active={this.props.regionFilter}
            setFilter={this.props.setRegionFilter}
          />
        ) : null}

        <HearingsRoutes />
      </Fragment>
    )
  }
}

export default LeadsComponent
