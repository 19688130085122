import * as services from "./services"
import { setContactInfo, setUpdatedContactInfo, setSingleContactInfo } from './actions'
import {
  NOTIFICATION_TYPES,
  FRONTEND_TICKET_TYPES,
  SPECIFIC_OVERLAYS_TYPE
} from "../../../constants"
import { overlaysActions } from "../../overlays/duck"
import { formatSearchCriteriaOnTickets } from '../../../utilities/formatContactInfo'


const fetchAllContactInfo = (created_at_date) => (dispatch, getState) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.lookupAll
  dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.getAllContactInfo(created_at_date)
    .then(results => {
      results = results.map(result => formatSearchCriteriaOnTickets(result))    
      dispatch(setContactInfo(results))
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: `Fetched Lookup Leads`,
        duration: 5
      }))
    })
    .catch(error => {
      console.error('error fetching contact info: ', error)
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue Fetching Lookup Leads',
        message: error
      }))
    })
}

const fetchSingleContactInfo = (contactInfoObjectId, loading = true) => (dispatch, getState) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.lookupSingle
  if (loading) {
    dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  }
  services.getSingleContactInfo(contactInfoObjectId)
    .then(result => {
      result = formatSearchCriteriaOnTickets(result)
      dispatch(setSingleContactInfo(result))
      if (loading) {
        dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
      }
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: `Fetched Contact Info: ${result.objectId}`,
        duration: 5
      }))
    })
    .catch(error => {
      console.error('error fetching contact info: ', error)
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue Fetching Indivdual Contact Info',
        message: error
      }))
    })
}

const updateSingleContactInfo = (contactInfoObjectId, updateObject) => (dispatch, getState) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.lookupSingle
  dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.updateSingleContactInfo(contactInfoObjectId, updateObject)
    .then(result => {
      const formattedResult = formatSearchCriteriaOnTickets(result)
      dispatch(setUpdatedContactInfo(formattedResult))
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: `Updated Contact Info: ${result.objectId}`,
        duration: 5
      }))
    })
    .catch(error => {
      console.error('error updating contact info: ', error)
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue Updating Indivdual Contact Info',
        message: error
      }))
    })
}

export default {
  fetchAllContactInfo,
  fetchSingleContactInfo,
  updateSingleContactInfo
}
