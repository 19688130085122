import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  attorneySelector,
  userSelector,
} from '../authentication/duck/selectors'
import ProfileComponent from './ProfileComponent'

const mapStateToProps = (state, props) => ({
  attorney: attorneySelector(state),
  user: userSelector(state),
})

// const mapDispatchToProps = dispatch => ({
//   fetchTicket: (objectId) => dispatch(ticketsThunks.fetchTicket(objectId)),
//   setCurrentlyViewing: (ticket) => dispatch(processingActions.setCurrentlyViewing(ticket))
// })

const ProfileContainer = connect(
  mapStateToProps
  // mapDispatchToProps
)(ProfileComponent)

export default withRouter(ProfileContainer)
