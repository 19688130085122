import * as types from './types'
import { SEARCH_TYPE } from '../SearchTypes'

const INITIAL_STATE = {
  searchType: SEARCH_TYPE.ticketId,
  loading: false,
  error: '',
  results: [],
  retrievedAt: null,
  description: '',
}

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SEARCH_TYPE:
      return {
        ...state,
        searchType: action.searchType,
      }
    case types.SET_SEARCH_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case types.SET_SEARCH_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case types.SET_SEARCH_RESULTS:
      return {
        ...state,
        results: action.results,
        retrievedAt: new Date(),
      }
    case types.SET_SEARCH_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      }
    default:
      return state
  }
}

export default searchReducer
