import React from 'react'
import PropTypes from 'prop-types'
import { FakeLink } from './StyledElements'
import { fileTools } from '../../utilities'

const DownloadImageLink = ({ imageUrl, fileName, prepend, append }) => {
  return (
    <FakeLink
      onClick={fileTools.downloadImage.bind(
        null,
        imageUrl,
        fileName,
        prepend,
        append
      )}
    >
      Download
    </FakeLink>
  )
}

DownloadImageLink.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string,
  prepend: PropTypes.string,
  append: PropTypes.string,
}

export default DownloadImageLink
