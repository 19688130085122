import { connect } from 'react-redux'
import { stepSelector, codeSelector } from '../duck/selectors'
import { isLoadingSelector } from '../../overlays/duck/selectors'
// import { activationActions } from '../duck'
// import { activationThunks } from '../duck'
import AccountComponent from './AccountComponent'

const mapStateToProps = state => ({
  step: stepSelector(state),
  code: codeSelector(state),
  isLoading: isLoadingSelector(state)
})

// const mapDispatchToProps = dispatch => ({ })

const AccountContainer = connect(
  mapStateToProps,
  // mapDispatchToProps
)(AccountComponent)

export default AccountContainer
