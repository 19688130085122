import React, { PureComponent } from 'react';
import { Transition } from 'react-transition-group'
import { Label, Loader } from 'semantic-ui-react'

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  // opacity: 1,
  marginLeft: "10px"
}

const transitionStyles = {
  entering: { opacity: 0 },
  exiting: { opacity: 1 },
  entered: { opacity: 1 },
  exited: { opacity: 0 }
};

class CountLabelComponent extends PureComponent {
  render() {
    if (this.props.loading) return <Loader
      style={{ marginLeft: "10px" }}
      size="small"
      active
      inline
    />
    return (
      <Transition
        appear={true}
        in={this.props.in}
        timeout={duration}
        unmountOnExit={false}
        mountOnEnter={false}
      >
        {state => {
          return (<div style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <Label>{this.props.value}</Label>
          </div>)
        }}
      </Transition>
    );
  }
}

export default CountLabelComponent;
