import { usersSelectors, usersThunks, usersActions } from '../../duck'
import { specificOverlayTools } from '../../../../utilities'
import { SPECIFIC_OVERLAYS_ITEMS } from '../../../../constants'

const connectFuncs = EDIT_KEY => {
  const mapStateToProps = (state, props) => ({
    overlayKey: specificOverlayTools.buildKey(
      props.objectId,
      SPECIFIC_OVERLAYS_ITEMS.users
    ),
    isEditing: usersSelectors.isEditingField(state, props.objectId, EDIT_KEY),
    user: usersSelectors.userById(state, props.objectId),
    editUserField: usersSelectors.editFieldById(state, props.objectId, EDIT_KEY),
  })

  const mapDispatchToProps = dispatch => ({
    setIsEditing: (objectId, value) =>
      dispatch(usersActions.setIsEditingUserField(objectId, EDIT_KEY, value)),
    editUpdate: (objectId, value) =>
      dispatch(usersActions.setEditValue(objectId, EDIT_KEY, value)),
    clearEdit: objectId =>
      dispatch(usersActions.clearEditValue(objectId, EDIT_KEY)),
    update: (objectId, updateObject) =>
      dispatch(usersThunks.updateUser(objectId, updateObject)),
    setIsBlockedUser: (objectId, isUserBlocked) =>
        dispatch(usersActions.setIsBlockedUser(objectId, isUserBlocked))
  })

  return { mapStateToProps, mapDispatchToProps }
}

export default connectFuncs
