import * as types from './types'

const INITIAL_STATE = {
  items: {}, // userObjectId are keys: value is userObject
  editItems: {},
  isEditing: {},// key of userid: { fieldNameKey: true/false/undefined}
  isUserBlocked: {}, // key of userid: { fieldNameKey: true/false/undefined}
}

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_USER: {
      const items = { ...state.items }
      items[action.user.objectId] = action.user
      return {
        ...state,
        items,
      }
    }

    case types.SET_EDIT_USER_VALUE: {
      const { objectId, key, value } = action
      const editItems = { ...state.editItems }
      let target = editItems[objectId] || {}
      target[key] = value
      editItems[objectId] = target
      return {
        ...state,
        editItems,
      }
    }

    case types.CLEAR_EDIT_USER_VALUE: {
      const { objectId, key } = action
      const editItems = { ...state.editItems }
      let target = editItems[objectId]
      if (target && target[key]) {
        target[key] = null
      }
      editItems[objectId] = target
      return {
        ...state,
        editItems,
      }
    }

    case types.SET_IS_EDITING_USER_FIELD: {
      const { objectId, key, isEditing } = action
      const newIsEditing = { ...state.isEditing }
      if (!newIsEditing[objectId]) {
        newIsEditing[objectId] = {}
      }
      newIsEditing[objectId][key] = isEditing
      return {
        ...state,
        isEditing: newIsEditing,
      }
    }

    case types.IS_BLOCKED_USER: {
      const { objectId, isUserBlocked } = action
      const newIsUserBlocked = { ...state.isUserBlocked }
      if (!newIsUserBlocked[objectId]) {
        newIsUserBlocked[objectId] = {}
      }
      newIsUserBlocked[objectId]= isUserBlocked
      return {
        ...state,
        isUserBlocked: newIsUserBlocked,
      }
    }

    case types.RESET:
      return INITIAL_STATE

    default:
      return state
  }
}

export default usersReducer
