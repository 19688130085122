// types.js
const SET_USER = 'SET_USER';
const CLEAR_USER = 'CLEAR_USER';
const SET_ATTORNEY = 'SET_ATTORNEY';
const CLEAR_ATTORNEY = 'CLEAR_ATTORNEY';
const RESET = 'RESET';

export default {
  SET_USER,
  CLEAR_USER,
  SET_ATTORNEY,
  CLEAR_ATTORNEY,
  RESET,
}
