import { ticketColumn, TICKET_COLUMNS } from './ticket'
import { clientColumn, CLIENT_COLUMNS } from './client'
import { attorneyColumn, ATTORNEY_COLUMNS } from './attorney'

const buildProcessingRecentlyApproved = (
  showAttorney = false,
  showAttorneyPrice = true,
  {
    history, // this.props.history
    baseUrl, // '/hearings/view'
  }
) => {
  const ADMIN_OR_ATTORNEY_ID = showAttorney ? TICKET_COLUMNS.ID_ADMIN() : TICKET_COLUMNS.ID
  const config = []
  const ticketInfo = showAttorneyPrice ? ticketColumn(
    TICKET_COLUMNS.CLICK_TO_VIEW(history, baseUrl),
    ADMIN_OR_ATTORNEY_ID,
    TICKET_COLUMNS.VIOLATION_NAME,
    TICKET_COLUMNS.HEARING_DATE_EDITABLE,
    TICKET_COLUMNS.ATTORNEY_PRICE,
    TICKET_COLUMNS.LOCATION_COURT
  ) : ticketColumn(
      TICKET_COLUMNS.CLICK_TO_VIEW(history, baseUrl),
      ADMIN_OR_ATTORNEY_ID,
      TICKET_COLUMNS.VIOLATION_NAME,
      TICKET_COLUMNS.HEARING_DATE_EDITABLE,
      TICKET_COLUMNS.LOCATION_COURT
  )
  config.push(ticketInfo)
  const clientInfo = clientColumn(CLIENT_COLUMNS.NAME, CLIENT_COLUMNS.PHONE)
  config.push(clientInfo)
  if (showAttorney) {
    const attorneyInfo = attorneyColumn(
      ATTORNEY_COLUMNS.NAME,
      ATTORNEY_COLUMNS.COMPANY_NAME
    )
    config.push(attorneyInfo)
  }
  return config
}

export default buildProcessingRecentlyApproved
