
const string = (source, target) => {
  if (!source || !target) {
    return false
  }
  const lowerCaseSource = source.toLowerCase().trim()
  const lowerCaseTarget = target.toLowerCase().trim()
  const result = lowerCaseSource.indexOf(lowerCaseTarget)
  return result >= 0
}

export default string
