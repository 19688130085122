import React, { Component, Fragment } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import { getIsSupervisor } from '../partially/duck/services'

import AdminRoutes from './AdminRoutes'


class AdminComponent extends Component {
  constructor() {
    super()
    this.state = {
      isSupervisor: false
    }
  }
  render() {
    const { attorney } = this.props
    const currentLocation = this.props.location.pathname
    let secondLevel = currentLocation.split('/').filter(v => v !== '')[1]

    getIsSupervisor(attorney.attorneyObjectId).then(res => {
      if (res.status == 200) {
        this.state.isSupervisor = res.isSupervisor
      } else if (res.status === 400) {
        console.log(res.error)
      }
    })
    return (
      <Fragment>
        <Menu stackable={true} tabular>
          <Menu.Item
            as={Link}
            name="ticket"
            active={
              secondLevel === 'ticket' || !secondLevel
            }
            to="/admin/ticket"
          >
            Ticket
          </Menu.Item>

          <Menu.Item
            as={Link}
            name="attorney"
            active={
              secondLevel === 'attorney'
            }
            to="/admin/attorney"
          >
            Attorney
          </Menu.Item>

          <Menu.Item
            as={Link}
            name="exports"
            active={
              secondLevel === 'exports'
            }
            to="/admin/exports"
          >
            CSV Exports
          </Menu.Item>

          <Menu.Item
            as={Link}
            name="linkGenerator"
            active={
              secondLevel === 'linkGenerator'
            }
            to="/admin/linkGenerator"
          >
            Link Generator
          </Menu.Item>

          <Menu.Item
            as={Link}
            name="allowCC"
            active={
              secondLevel === 'allowCC'
            }
            to={this.state.isSupervisor === true ? "/admin/allowCC" : "/admin/ticket"}
          >
            Allow Credit Card
          </Menu.Item>
        </Menu>
        
        <AdminRoutes />
      </Fragment>
    )
  }
}

export default AdminComponent
