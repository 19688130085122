import { stringTools } from '../index'

const phoneNumber = (source, target) => {
  if (!source || !target) {
    return false
  }
  let lowerCaseSource = source.toLowerCase()
  lowerCaseSource = stringTools.replaceAll(lowerCaseSource, ' ')
  lowerCaseSource = stringTools.replaceAll(lowerCaseSource, '.')
  lowerCaseSource = stringTools.replaceAll(lowerCaseSource, '-')
  lowerCaseSource = stringTools.replaceAll(lowerCaseSource, '(')
  lowerCaseSource = stringTools.replaceAll(lowerCaseSource, ')')
  let lowerCaseTarget = target.toLowerCase()
  lowerCaseTarget = stringTools.replaceAll(lowerCaseTarget, ' ')
  lowerCaseTarget = stringTools.replaceAll(lowerCaseTarget, '.')
  lowerCaseTarget = stringTools.replaceAll(lowerCaseTarget, '-')
  lowerCaseTarget = stringTools.replaceAll(lowerCaseTarget, '(')
  lowerCaseTarget = stringTools.replaceAll(lowerCaseTarget, ')')
  const result = lowerCaseSource.indexOf(lowerCaseTarget)
  return result >= 0
}

export default phoneNumber
