export const colors = {
  error: {
    color: '#ca3b33',
  },
  success: {
    color: '#59b655',
  },
  borders: {
    main: '#d4d4d5',
    seperator: '#cececf',
  },
  link: '#5083bf',
  darkText: '#5d5d5d',
  subtleGray: '#616162',
  disabled: '#f1f1f2',
  label: {
    background: '#e8e8e8',
  },
}
