import { connect } from 'react-redux'
import { regionsSelectors } from '../../regions/duck'
import { overlaySelectors } from '../../overlays/duck'
import { leadsThunks, leadsSelectors, leadsActions } from '../duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import LeadsListContainer from './LeadsListContainer'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.leads.closedTickets
const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    TICKET_TYPE
  ),
  leads: regionsSelectors.regionsFilterBuilder(
    leadsSelectors.buildLeadsSelectorByType(TICKET_TYPE),
    leadsSelectors.leadsRegionFilterSelector
  )(state),
  retrievedAt: leadsSelectors.closedTicketsRetrievedAtSelector(state),
  rtSettings: reactTableSelectors.settings(TICKET_TYPE, state),
  closedTicketsHearingDate: leadsSelectors.closedTicketsHearingDateSelector(
    state
  ),
})

const mapDispatchToProps = dispatch => ({
  setHearingDate: hearingDate => {
    dispatch(leadsActions.setClosedTicketsHearingDate(hearingDate))
    dispatch(leadsThunks.fetchClosedTickets())
  },
  fetchLeads: () => dispatch(leadsThunks.fetchClosedTickets()),
  setResized: value =>
    dispatch(reactTableActions.setResized(TICKET_TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TICKET_TYPE, value)),
  setFiltered: value =>
    dispatch(reactTableActions.setFiltered(TICKET_TYPE, value)),
})

const ClosedTicketsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsListContainer)

export default ClosedTicketsContainer
