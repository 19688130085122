import React, { Component, Fragment } from 'react'
import { Message, Icon } from 'semantic-ui-react'

class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: '',
      info: '',
    }
  }
  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info })
    console.log('encountered err caught info', info)
    console.log('encountered err caught', error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Fragment>
          <Message negative icon>
            <Icon name="bug" />
            <Message.Content>
              <Message.Header>
                Oops.. Something unexpected happened.
              </Message.Header>
              <p>
                {this.state.error && this.state.error.message
                  ? this.state.error.message
                  : JSON.stringify(this.state.error)}
              </p>
              <p>
                If this error happens again, please let us know what you were
                doing when this error happened.
              </p>
            </Message.Content>
          </Message>
        </Fragment>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundaryComponent
