import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { StyledElements } from '../../common'
import { DATE_FORMATS } from '../../../constants'

const RequestHistoryToolbarComponent = ({
  retrievedAt,
  fetch,
  requestCount,
}) => {
  return (
    <div>
      <StyledElements.Italicize>
        {requestCount}{' '}
        {requestCount > 1 || requestCount === 0 ? 'Requests ' : 'Request '}
        {retrievedAt
          ? `as of ${moment(retrievedAt).format(DATE_FORMATS.DATE_TIME)}`
          : null}
      </StyledElements.Italicize>
      <StyledElements.Divider />
      <StyledElements.FakeLink onClick={fetch}>Refresh</StyledElements.FakeLink>
    </div>
  )
}

RequestHistoryToolbarComponent.defaultProps = {
  fetch: () => {},
  retrievedAt: null,
  requestCount: 0,
}

RequestHistoryToolbarComponent.propTypes = {
  retrievedAt: PropTypes.any,
  fetch: PropTypes.func.isRequired,
  requestCount: PropTypes.number,
}

export default RequestHistoryToolbarComponent
