export const clickableRowClassName = "-striped -highlight -clickable"
export const RowClassName = "-striped -highlight"

// export const pageSizeOptions = [10, 20, 30, 50, 100]
export const options = [
  { key: 1, text: '10 Items Per Page', value: 10 },
  { key: 2, text: '20 Items Per Page', value: 20 },
  { key: 3, text: '30 Items Per Page', value: 30 },
  { key: 4, text: '50 Items Per Page', value: 50 },
  { key: 5, text: '100 Items Per Page', value: 100 }
]

export const defaultSorted = {
  submitted: {
    recent: {
      id: "SUBMITTED",
      desc: false
    }
  },
  created: {
    recent: {
      id: "CREATED",
      desc: false,
    }
  }
}

export const minRows = 10

export const getDefaultLength = () => {
  let storedValue = localStorage.getItem('wi-rt-default-size')
  if (typeof storedValue === "string") {
    storedValue = parseInt(storedValue)
  }
  return storedValue || 30
}

export const setDefaultLength = (size) => {
  return localStorage.setItem('wi-rt-default-size', size)
}
