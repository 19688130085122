import React from 'react'
import { List } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import RequestItem from './RequestItem'

const RequestListComponent = ({ history }) => {
  return (
    <List divided relaxed>
      {history
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
        .map((item, index) => (
          <RequestItem key={item.objectId} number={index + 1} {...item} />
        ))}
    </List>
  )
}

RequestListComponent.defaultProps = {
  history: [],
}

RequestListComponent.propTypes = {
  history: PropTypes.array,
}

export default RequestListComponent
