import React, { Component, Fragment } from 'react';
import { Step } from 'semantic-ui-react'
import CreateUser from './CreateUser/CreateUserContainer';

class AccountComponent extends Component {
  renderAccountType = () => {
    return <CreateUser />
  }
  render() {
    const RenderAccountType = this.renderAccountType
    return (
      <Fragment>
        <Step.Group ordered fluid vertical size='small'>
          <Step completed>
            <Step.Content style={{ textAlign: 'left' }}>
              <Step.Title>Activation Code</Step.Title>
              <Step.Description>{this.props.code}</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <RenderAccountType />
      </Fragment>
    );
  }
}

export default AccountComponent;
