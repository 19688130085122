import React, { Component, Fragment } from 'react'
// import { Redirect } from "react-router-dom"
import { Helmet } from 'react-helmet'
import { Header, Grid } from 'semantic-ui-react'
import moment from 'moment'

import { PROCESSING_PIPELINE_STATUS } from '../../../constants'
// import { routerTools } from '../../../utilities'

// General Use Components
import ErrorBoundaryComponent from '../../Error/Boundary'
import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'

// Edit/View Components for individual keys
// import EditProcessingPipelineDropdown from '../DetailSection/EditProcessingPipelineDropdown/EditProcessingPipelineDropdownContainer'
import EditUserComment from '../DetailSection/EditUserComment/EditUserCommentContainer'
import ViewLocationCourt from '../DetailSection/ViewLocationCourt/ViewLocationCourtComponent'
import UserInfo from '../DetailSection/UserInfo/UserInfoContainer'
import EditStatus from '../DetailSection/EditStatus/EditStatusContainer'
import EditStatusReason from '../DetailSection/EditStatusReason/EditStatusReasonContainer'
import EditProcessingPipelineButton from '../DetailSection/EditProcessingPipelineButton/EditProcessingPipelineButtonContainer'
import EditAttorney from '../DetailSection/EditAttorney/EditAttorneyContainer'
import EditTicketCallStatus from '../DetailSection/EditCallStatus/EditCallStatusContainer'
import EditTicketID from '../DetailSection/EditTicketID/EditTicketIDContainer'
import ViewPaymentStatus from '../DetailSection/ViewPaymentStatus/ViewPaymentStatusContainer'
import EditViolationName from '../DetailSection/EditViolationName/EditViolationNameContainer'
import EditAttorneyNotes from '../DetailSection/EditAttorneyNotes/EditAttorneyNotesContainer'
import EditSupportNotes from '../DetailSection/EditSupportNotes/EditSupportNotesContainer'
import EditPrice from '../DetailSection/EditPrice/EditPriceContainer'
import EditFineAmount from '../DetailSection/EditFineAmount/EditFineAmountContainer'
import EditTicketDate from '../DetailSection/EditDate/EditTicketDateContainer'
import EditFineDueDate from '../DetailSection/EditDate/EditTicketDateContainer'
import EditCaseProtectionFee from '../DetailSection/EditCaseProtectionFee/EditCaseProtectionFeeContainer'
import EditCaseProtectionActivation from '../DetailSection/EditCaseProtectionActivation/EditCaseProtectionActivationContainer'
import EditTicketDateTime from '../DetailSection/EditDateTime/EditDateTimeContainer'
import EditTicketPoints from '../DetailSection/EditPoints/EditTicketPointsContainer'
import EditPointsReduced from '../DetailSection/EditPointsReduced/EditPointsReducedContainer'
import EditCourt from '../../courts/edit/EditCourtContainer'
import EditCounty from '../../counties/EditCounty/EditCountyContainer'
import ViewTicketImagePopup from '../DetailSection/ViewTicketImagePopup/ViewTicketImageSectionPopupComponent'
import ViewTicketCourtScreenshotPopup from '../DetailSection/ViewTicketCourtScreenshotPopup/ViewTicketCourtScreenshotSectionPopupComponent'
import ViewTicketEvidence from '../DetailSection/ViewTicketEvidence/ViewTicketEvidenceContainer'
import ViewCallRequestHistory from '../DetailSection/ViewCallRequestHistory/ViewCallRequestHistoryComponent'
import ViewTicketLoadingComponent from './ViewTicketLoadingComponent'
import EditTicketLeadStatus from '../DetailSection/EditLeadStatus/EditLeadStatusContainer'
import ViewTicketPricePreview from '../DetailSection/ViewTicketPricePreview/ViewTicketPricePreviewContainer'
import ViewPortalEditHistory from '../DetailSection/ViewPortalEditHistory/ViewPortalEditHistoryContainer'
import ViewRetentionLetter from '../DetailSection/ViewRetentionLetter/ViewRetentionLetterContainer'
import ViewAssignedTo from '../DetailSection/ViewAssignedTo/ViewAssignedToComponent'
import EditNewFineAmount from '../DetailSection/EditNewFineAmount/EditNewFineAmountContainer'
import ViewMagicLinkCommunication from '../DetailSection/ViewMagicLinkCommunication/ViewMagicLinkCommunicationContainer'
import ViewChatAction from '../DetailSection/ViewChatAction/ViewChatActionContainer'
import EditTrafficBond from '../DetailSection/EditTrafficBond/EditTrafficBondContainer'
import PartiallyInfo from '../../partially/Partially/PartiallyContainer'

class ViewTicketComponent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.currentId && !this.props.currentTicket) {
      this.props.setCurrentlyViewing(this.props.currentId)
      this.props.fetchTicket(this.props.currentId)
    }
    if (this.props.currentTicket) {
      this.props.setCurrentlyViewing(this.props.currentTicket)
    }
  }

  componentWillUnmount() {
    this.props.setCurrentlyViewing(null)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentTicket !== this.props.currentTicket) {
      this.props.setCurrentlyViewing(nextProps.currentTicket)
    }
  }

  render() {
    const ticket = this.props.currentTicket
    if (!ticket) return <ViewTicketLoadingComponent />
    const { attorney } = this.props
    return (
      <ErrorBoundaryComponent>
        <Helmet title={`Ticket #${ticket.ticketID}`} />
        <Header as="h2">Ticket #{ticket.ticketID}</Header>
        <UserInfo objectId={ticket.objectId} />
        {attorney.globalAdmin ?
          (<>
          <Header as="h3" dividing>
            Partially Payment Plan
          </Header>
          <PartiallyInfo objectId={ticket.objectId} />
          </>) : null}
        <Header as="h3" dividing>
          Ticket Details
        </Header>
        <SpecificOverlay
          centerFillStatus={true}
          ignoreLoading={true}
          ignoreUpdating={true}
          ignoreError={true}
          overlayKey={ticket.objectId}
          // errorText="Issue fetching ticket"
        >
          <Fragment>
            <EditTicketCallStatus objectId={ticket.objectId} />
            <Grid stackable stretched columns={4}>
              {ticket.processingPipeline ===
              PROCESSING_PIPELINE_STATUS.requiresApproval && attorney && attorney.globalAdmin && attorney.isSupervisor ? (
                <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                  <EditProcessingPipelineButton objectId={ticket.objectId} />
                </Grid.Column>
              ) : null}
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                {ticket.processingPipeline !==
                PROCESSING_PIPELINE_STATUS.requiresApproval ? (
                  <EditProcessingPipelineButton objectId={ticket.objectId} />
                ) : null}
                {attorney && attorney.globalAdmin ? (
                  <EditTicketLeadStatus objectId={ticket.objectId} />
                ) : null}
                <EditStatusReason objectId={ticket.objectId} />
                <EditStatus objectId={ticket.objectId} />
                {attorney && attorney.globalAdmin && ticket.assignedTo ? (
                  <ViewAssignedTo assignee={ticket.assignedTo.name} />
                ) : null}
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                <ViewPaymentStatus objectId={ticket.objectId} />
                <EditPrice objectId={ticket.objectId} />
                <ViewTicketPricePreview objectId={ticket.objectId} />
                <EditCaseProtectionActivation objectId={ticket.objectId} />
                <EditCaseProtectionFee objectId={ticket.objectId} />
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                <EditSupportNotes objectId={ticket.objectId} />
                <EditAttorneyNotes objectId={ticket.objectId} />
                <EditTicketDate
                  isEditable={attorney && attorney.globalAdmin ? true : false}
                  objectId={ticket.objectId}
                  title="Issue Date"
                  dateKey="issueDate"
                />
                <EditTicketDateTime
                  isEditable={true}
                  objectId={ticket.objectId}
                  title="Hearing Date"
                  dateKey="hearingDate"
                  includeTime={true}
                  minValue={moment().startOf('day')}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                <EditTicketPoints objectId={ticket.objectId} />
                <EditPointsReduced objectId={ticket.objectId} />
                <EditViolationName objectId={ticket.objectId} />
                <EditFineAmount objectId={ticket.objectId} />
                <EditNewFineAmount objectId={ticket.objectId} />
                <EditFineDueDate
                  isEditable={true}
                  objectId={ticket.objectId}
                  title="Fine Due Date"
                  dateKey="fineDueDate"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                <ViewRetentionLetter objectId={ticket.objectId} />
                <EditUserComment objectId={ticket.objectId} />
                <ViewTicketImagePopup
                  objectId={ticket.objectId}
                  ticketID={ticket.ticketID}
                  ticketImage={ticket.ticketImage}
                  showIconOnly={false}
                />
                <ViewTicketCourtScreenshotPopup
                    objectId={ticket.objectId}
                    ticketID={ticket.ticketID}
                    ticketImage={ticket.ticketCourtViewImage}
                    showIconOnly={false}
                />
                <ViewTicketEvidence objectId={ticket.objectId} />
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                <ViewLocationCourt ticket={ticket} />
                <EditCounty objectId={ticket.objectId} />
                <EditCourt objectId={ticket.objectId} />
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                <EditTicketDate
                  isEditable={false}
                  objectId={ticket.objectId}
                  title="Approval Date"
                  dateKey="dateApproved"
                  includeTime={true}
                />
                <EditTicketDate
                  isEditable={false}
                  objectId={ticket.objectId}
                  title="Submission Date"
                  dateKey="dateSubmitted"
                  includeTime={true}
                />
                <EditTicketDate
                  isEditable={false}
                  objectId={ticket.objectId}
                  title="Creation Date"
                  dateKey="createdAt"
                  includeTime={true}
                />
                <EditTicketDate
                  isEditable={false}
                  objectId={ticket.objectId}
                  title="Last Update"
                  dateKey="updatedAt"
                  includeTime={true}
                />
                <EditTicketDate
                  isEditable={true}
                  objectId={ticket.objectId}
                  title="Follow Up On Date"
                  dateKey="followUpOnDate"
                  includeTime={true}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={8} verticalAlign={'top'}>
                <EditAttorney objectId={ticket.objectId} />
                <EditTicketID objectId={ticket.objectId} />
                <EditTrafficBond
                  objectId={ticket.objectId}
                  fetchTicket={this.props.fetchTicket}
                  setCurrentlyViewing={this.props.setCurrentlyViewing}
                />
              </Grid.Column>
            </Grid>
            <Grid stackable stretched columns={2}>
              <Grid.Column computer={8} tablet={8} verticalAlign="top">
                <ViewCallRequestHistory objectId={ticket.objectId} />
                <ViewMagicLinkCommunication objectId={ticket.objectId} />
                <ViewChatAction ticket={ticket}/>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} verticalAlign="top">
                <ViewPortalEditHistory objectId={ticket.objectId} />
              </Grid.Column>
            </Grid>
          </Fragment>
        </SpecificOverlay>
      </ErrorBoundaryComponent>
    )
  }
}

export default ViewTicketComponent
