import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const AttorneyAgreementsComponent = () => {
    return (
        <Wrapper>
            <Link className="link" to="/profile/agreements/saas">SaaS Services Agreement</Link>
            <Link className="link" to="/profile/agreements/platform">Platform Participation Agreement</Link>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;

    .link {
        margin: 10px;
        font-size: 15px;
        font-weight: 500;
    }
`

export default AttorneyAgreementsComponent