import { connect } from 'react-redux'
import { regionsThunks, regionsSelectors } from '../duck'
import { overlaySelectors } from '../../overlays/duck'
import FilterMenuComponent from './FilterMenuComponent'
import { SPECIFIC_OVERLAYS_ITEMS } from '../../../constants'
import { attorneyIsAdminSelector } from '../../../app/authentication/duck/selectors'


const mapStateToProps = state => ({
  isAdmin: attorneyIsAdminSelector(state),
  regions: regionsSelectors.regionsSelector(state),
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(state, SPECIFIC_OVERLAYS_ITEMS.trafficRegions),
})

const mapDispatchToProps = dispatch => ({
  fetchRegions: () => dispatch(regionsThunks.fetchRegions())
})

const FilterMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterMenuComponent)

export default FilterMenuContainer
