import { axios } from '../../../utilities'

export const sendSubmissionMagicLink = (ticketObjectId, header, message) => {
  return axios.secure
    .post(`ticket-actions/sendMagicLink`, {
      trafficTicketObjectId: ticketObjectId,
      header,
      message,
    })
    .then(res => res.data)
}
