import { connect } from 'react-redux'
import { usersSelectors, usersThunks, usersActions } from '../../duck'
import { overlaysActions } from '../../../overlays/duck'
import { specificOverlayTools } from '../../../../utilities'
import {
  SPECIFIC_OVERLAYS_ITEMS,
  NOTIFICATION_TYPES,
} from '../../../../constants'

import EditLicenseComponent from './EditLicenseComponent'

const LIC_ID_EDIT_KEY = 'licenseID'
const LIC_STATE_EDIT_KEY = 'licenseState'

const mapStateToProps = (state, props) => ({
  overlayKey: specificOverlayTools.buildKey(
    props.objectId,
    SPECIFIC_OVERLAYS_ITEMS.users
  ),
  isEditing: usersSelectors.isEditingField(
    state,
    props.objectId,
    LIC_ID_EDIT_KEY
  ),
  user: usersSelectors.userById(state, props.objectId),
  editLicenseId: usersSelectors.editFieldById(
    state,
    props.objectId,
    LIC_ID_EDIT_KEY
  ),
  editLicenseState: usersSelectors.editFieldById(
    state,
    props.objectId,
    LIC_STATE_EDIT_KEY
  ),
})

const mapDispatchToProps = dispatch => ({
  setIsEditing: (objectId, value) =>
    dispatch(
      usersActions.setIsEditingUserField(objectId, LIC_ID_EDIT_KEY, value)
    ),
  editLicenseIdUpdate: (objectId, value) =>
    dispatch(usersActions.setEditValue(objectId, LIC_ID_EDIT_KEY, value)),
  editLicenseStateUpdate: (objectId, value) =>
    dispatch(usersActions.setEditValue(objectId, LIC_STATE_EDIT_KEY, value)),
  clearEdit: objectId => {
    dispatch(usersActions.clearEditValue(objectId, LIC_ID_EDIT_KEY))
    dispatch(usersActions.clearEditValue(objectId, LIC_STATE_EDIT_KEY))
  },
  update: (objectId, updateObject) =>
    dispatch(usersThunks.updateUser(objectId, updateObject)),
  error: (title, message) =>
    dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title,
        message,
      })
    ),
})

const EditLicenseContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLicenseComponent)

export default EditLicenseContainer
