import * as types from './types'

const INITIAL_STATE = {
  clientToken: null,
  nonce: null,
  ready: false,
  success: false,
  error: null,
}
const paymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER_PAYMENT_CLIENT_TOKEN:
      return {
        ...state,
        clientToken: action.clientToken,
      }
    case types.CLEAR_USER_PAYMENT_CLIENT_TOKEN:
      return {
        ...state,
        clientToken: null,
      }
    case types.SET_USER_PAYMENT_NONCE:
      return {
        ...state,
        nonce: action.nonce,
      }
    case types.CLEAR_USER_PAYMENT_NONCE:
      return {
        ...state,
        nonce: null,
      }
    case types.SET_USER_PAYMENT_READY:
      return {
        ...state,
        ready: true,
      }
    case types.CLEAR_USER_PAYMENT_READY:
      return {
        ...state,
        ready: false,
      }
    case types.SET_USER_PAYMENT_SUCCESS:
      return {
        ...state,
        success: true,
      }
    case types.CLEAR_USER_PAYMENT_SUCCESS:
      return {
        ...state,
        success: false,
      }
    case types.SET_USER_PAYMENT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case types.CLEAR_USER_PAYMENT_ERROR:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}

export default paymentsReducer
