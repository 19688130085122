import React, { useEffect, useRef } from 'react'
import { Input, Form } from 'semantic-ui-react'

import ItemHeader from '../../../tickets/DetailSection/ItemHeader'
import { StyledElements, Forms } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import {
  LoadingComponent,
  UpdatingComponent,
} from '../../../tickets/DetailSection/ticketOverlays'
import { updateUser } from '../../duck/services'

const EditEmailComponent = ({
  user,
  objectId,
  editUserField,
  editUpdate,
  wasPreviouslyEditing,
  isEditing,
  setIsEditing,
  update,
  clearEdit,
  overlayKey,
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (isEditing && !wasPreviouslyEditing) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const onChangeHandler = ev => {
    const email = ev.target.value
    editUpdate(objectId, email)
  }

  const toggleEditStatus = () => {
    setIsEditing(objectId, !isEditing)
  }

  const onSubmitHandler = ev => {
    ev.preventDefault()
    const email = user.email

    updateUser(objectId, {
      email,
    })

    update(objectId, { email: editUserField })
    setIsEditing(objectId, false)
    clearEdit(objectId)
  }

  return (
    <StyledElements.DataContainer isEditing={isEditing}>
      <ItemHeader
        title="Email"
        isEditable={true}
        isEditing={!!isEditing}
        toggleState={toggleEditStatus}
      />
      <SpecificOverlayContainer
        overlayKey={overlayKey}
        LoadingComponent={LoadingComponent}
        ignoreError={true}
        UpdatingComponent={UpdatingComponent}
      >
        {isEditing ? (
          <StyledElements.EditContainer>
            <Form onSubmit={onSubmitHandler}>
              <Input
                fluid
                ref={inputRef}
                onChange={onChangeHandler}
                value={editUserField === null ? user.email : editUserField}
                placeholder="youremail@here.com"
              />
              <Forms.InlineEditButtons
                buttons={[
                  {
                    color: 'green',
                    text: 'Update Email',
                    icon: 'save',
                    labelPosition: 'right',
                    type: 'submit',
                    onClick: onSubmitHandler,
                  },
                ]}
              />
            </Form>
          </StyledElements.EditContainer>
        ) : user.email ? (
          user.email
        ) : (
          <StyledElements.Italicize>No Email Set</StyledElements.Italicize>
        )}
      </SpecificOverlayContainer>
    </StyledElements.DataContainer>
  )
}

export default EditEmailComponent
