import types from './types'

const INITIAL_STATE = {
  items: {},
}
const countiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.ADD_MANY_COUNTIES: {
      const { stateShortName } = action
      const incomingCounties = action.value
      const updatedState = {}
      incomingCounties.forEach(county => {
        updatedState[county.objectId] = county
      })
      const items = { ...state.items }
      items[stateShortName] = updatedState
      return {
        ...state,
        items
      }
    }

    default:
      return state
  }
}

export default countiesReducer
