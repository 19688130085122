import { connect } from 'react-redux'
import { specificOverlayStatusSelector } from '../duck/selectors'
import SpecificOverlayComponent from './SpecificOverlayComponent'

const mapStateToProps = (state, props) => ({
  status: specificOverlayStatusSelector(state, props),
})

const SpecificOverlayContainer = connect(mapStateToProps)(
  SpecificOverlayComponent
)

export default SpecificOverlayContainer
