import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { StyledElements } from '../../../common'
import { DATE_FORMATS } from '../../../../constants'

const RequestHistoryToolbarComponent = ({ retrievedAt, fetch }) => {
  return (
    <div>
      {retrievedAt ? (
        <Fragment>
          <StyledElements.Italicize>
            {retrievedAt
              ? `as of ${moment(retrievedAt).format(DATE_FORMATS.DATE_TIME)}`
              : null}
          </StyledElements.Italicize>
          <StyledElements.Divider />
        </Fragment>
      ) : null}

      <StyledElements.FakeLink onClick={fetch}>
        {retrievedAt ? 'Refresh' : 'Re-fetch Edit History'}
      </StyledElements.FakeLink>
    </div>
  )
}

RequestHistoryToolbarComponent.defaultProps = {
  retrievedAt: null,
}

RequestHistoryToolbarComponent.propTypes = {
  retrievedAt: PropTypes.any,
  fetch: PropTypes.func.isRequired,
}

export default RequestHistoryToolbarComponent
