import React from 'react'
import PropTypes from 'prop-types'
import { Feed, List } from 'semantic-ui-react'
import moment from 'moment'
import EditValue from './EditValueEvent'
import { DATE_FORMATS } from '../../../../constants'

const EditHistoryEventItemComponent = ({ edits, user, createdAt }) => {
  return (
    <Feed.Event>
      <Feed.Content>
        <Feed.Date
          content={moment(createdAt).format(DATE_FORMATS.FULL_DATE_TIME)}
        />
        <Feed.Summary as="div">
          {user.name} ({user.objectId}) changed {edits.length}
          {edits.length === 0 || edits.length >= 2 ? ' fields' : ' field'}
        </Feed.Summary>
        <Feed.Extra style={{ paddingBottom: '10px' }}>
          <List bulleted>
            {edits.map(item => (
              <EditValue
                key={item.key}
                editKey={item.key}
                editValue={item.value}
              />
            ))}
          </List>
        </Feed.Extra>
        {/* <List.Header>
          {' '}
          <Label size="tiny">{user.objectId}</Label>
        </List.Header>
        <List.Description>
          <List bulleted>
            {edits.map(item => (
              <EditValue
                key={item.key}
                editKey={item.key}
                editValue={item.value}
              />
            ))}
          </List>
        </List.Description> */}
      </Feed.Content>
    </Feed.Event>
  )
}

EditHistoryEventItemComponent.defaultProps = {
  edits: [],
}

EditHistoryEventItemComponent.propTypes = {
  edits: PropTypes.array,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
  }),
  createdAt: PropTypes.string,
}

export default EditHistoryEventItemComponent
