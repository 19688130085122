import React from 'react'
import moment from 'moment'
import { DateInput } from '@blueprintjs/datetime'
import { Position } from '@blueprintjs/core'

const BaseDateInput = props => {
  let newProps = { ...props }
  if (!props.minDate) {
    newProps.minDate = moment()
      .subtract(40, 'years')
      .toDate()
  }
  if (!props.maxDate) {
    newProps.maxDate = moment()
      .add(40, 'years')
      .toDate()
  }
  return (
    <DateInput
      popoverProps={{ position: Position.RIGHT }}
      formatDate={date => (date == null ? '' : date.toLocaleDateString())}
      parseDate={str => new Date(Date.parse(str))}
      placeholder="MM/DD/YYYY"
      closeOnSelection={true}
      disabled={false}
      showActionsBar={true}
      todayButtonText="Today"
      canClearSelection={true}
      clearButtonText="Clear"
      {...newProps}
    />
  )
}

export default BaseDateInput
