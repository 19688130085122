const ADD_PROCESSING = "ADD_PROCESSING"
const RESET = "RESET"
const SET_CURRENTLY_VIEWING = "SET_CURRENTLY_VIEWING"
const SET_PROCESSING = "SET_PROCESSING"
const SET_PROCESSING_RETRIEVED_AT = "SET_PROCESSING_RETRIEVED_AT"
const SET_PROCESSING_REGION_FILTER = "SET_PROCESSING_REGION_FILTER"
const SET_RECENTLY_APPROVED_CATEGORY = "SET_RECENTLY_APPROVED_CATEGORY"
const SET_SUSPENDED_CASES = "SET_SUSPENDED_CASES"
const SET_REQUIRES_APPROVAL_CASES = "SET_REQUIRES_APPROVAL_CASES"

export default {
  ADD_PROCESSING,
  SET_RECENTLY_APPROVED_CATEGORY,
  RESET,
  SET_CURRENTLY_VIEWING,
  SET_PROCESSING,
  SET_PROCESSING_RETRIEVED_AT,
  SET_PROCESSING_REGION_FILTER,
  SET_SUSPENDED_CASES,
  SET_REQUIRES_APPROVAL_CASES,
}
