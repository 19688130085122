import React from 'react'

import { StyledElements } from '../../../common'
import ItemHeader from '../ItemHeader'

const ViewTicketPriceComponent = ({ ticket }) => {
  if (ticket.trafficCourt) return null
  return (
    <StyledElements.DataContainer>
      <ItemHeader
        title={'User Entered Location Court'}
        isEditable={false}
        isEditing={false}
        toggleState={() => {}}
      />
      {ticket.locationCourt || (
        <StyledElements.Italicize>
          No Location Court Supplied
        </StyledElements.Italicize>
      )}
    </StyledElements.DataContainer>
  )
}

export default ViewTicketPriceComponent
