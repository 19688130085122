import React, { Component, Fragment } from 'react';
import Select from 'react-select'

import { customStyles } from '../../../common/react-select/styles'
import { objectTools } from '../../../../utilities'
import { STATES } from '../../../../constants'
import { Forms } from '../../../common'

const REVERSED_STATES = objectTools.reverseObject(STATES)
const options = objectTools.objectToArray(STATES).map(state => {
  return {
    value: REVERSED_STATES[state],
    label: state
  }
})

class SelectStateComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.value ? { value: props.value, label: STATES[props.value] } : undefined
    }
  }
  updateValue = (value) => {
    this.setState({ selected: value })
  }
  proceed = () => {
    if (this.state.selected) {
      this.props.next(this.state.selected.value)
    }
  }
  render() {
    return (
      <Fragment>
        <p>Select a State</p>
        <form onSubmit={this.proceed}>
          <Select
            placeholder="State"
            styles={customStyles}
            isMulti={false}
            onChange={this.updateValue}
            value={this.state.selected}
            options={options}
          />
          <Forms.InlineEditButtons buttons={[
            {
              onClick: this.proceed,
              color: "green",
              labelPosition: "right",
              icon: "arrow right",
              text: "Choose County",
              disabled: !this.state.selected,
              type: "submit"
            }
          ]} />
        </form>

      </Fragment>
    );
  }
}
export default SelectStateComponent;
