import { connect } from 'react-redux'
import { overlaySelectors } from '../../overlays/duck'
import { authenticationSelectors } from '../../authentication/duck'
import { callsThunks, callsSelectors } from '../duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import {
  FRONTEND_PARENT_TYPES,
  FRONTEND_TICKET_TYPES,
} from '../../../constants'
import CallsListComponent from './CallsListComponent'

const TYPE = FRONTEND_PARENT_TYPES.calls
const TICKET_TYPE = FRONTEND_TICKET_TYPES.calls
const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    TICKET_TYPE
  ),
  calls: callsSelectors.callsSelector(state),
  retrievedAt: callsSelectors.callsRetrievedAtSelector(state),
  isLoading: overlaySelectors.isLoadingSelector(state),
  attorney: authenticationSelectors.attorneySelector(state),
  rtSettings: reactTableSelectors.settings(TYPE, state),
})

const mapDispatchToProps = dispatch => ({
  fetchCallsItems: () => dispatch(callsThunks.fetchCallsItems()),
  setResized: value => dispatch(reactTableActions.setResized(TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TYPE, value)),
  setFiltered: value => dispatch(reactTableActions.setFiltered(TYPE, value)),
})

const CallsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallsListComponent)

export default CallsListContainer
