import React, { Component, Fragment } from 'react'
import { Input } from 'semantic-ui-react'

import { TICKET_STATUS } from '../../../../constants'
import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'

class EditTicketPointsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.pointsInput = React.createRef()
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.pointsInput.inputRef.focus()
    }
  }
  toggleStatus = () => {
    this.props.clearEditValue(this.props.objectId, 'reducedPoints')
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = event => {
    const { objectId } = this.props
    if (event.target.value === '')
      return this.props.editUpdate(objectId, 'reducedPoints', '')
    const reducedPoints = parseInt(event.target.value)
    if (reducedPoints || reducedPoints === 0) {
      this.props.editUpdate(objectId, 'reducedPoints', reducedPoints)
    }
  }
  updatePoints = () => {
    const { objectId, editTicketField, ticket } = this.props
    this.setState({ isEditing: false })
    const value = parseInt(editTicketField, 10)
    if (
      editTicketField === '' ||
      (ticket.reducedPoints !== value && Number.isInteger(value))
    ) {
      this.props.clearEditValue(objectId, 'reducedPoints')
      this.props.update(objectId, {
        reducedPoints: editTicketField === '' ? 'unset' : value,
      })
    }
  }
  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    const value =
      editTicketField === null || editTicketField === undefined
        ? !ticket.reducedPoints && ticket.reducedPoints !== 0
          ? ''
          : ticket.reducedPoints
        : editTicketField
    return (
      <StyledElements.EditContainer>
        <form>
          <Input
            ref={input => {
              this.pointsInput = input
            }}
            onChange={this.handleChange}
            value={value}
            placeholder="New Point Amount"
            fluid
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                disabled:
                  !editTicketField &&
                  editTicketField !== '' &&
                  editTicketField !== 0,
                color: 'green',
                text: 'Update Reduced Points',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updatePoints,
              },
            ]}
          />
        </form>
      </StyledElements.EditContainer>
    )
  }
  render() {
    const { ticket } = this.props
    const { isEditing } = this.state
    const canSetReduced = [
      TICKET_STATUS.processing,
      TICKET_STATUS.guilty,
      TICKET_STATUS.reduced,
      TICKET_STATUS.dismissed,
    ]
    if (!ticket || !canSetReduced.includes(ticket.status)) return null
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Reduced Points"
          isEditable={true}
          isEditing={isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <Fragment>
            {isEditing ? (
              <RenderEdit />
            ) : ticket.reducedPoints || ticket.reducedPoints === 0 ? (
              ticket.reducedPoints
            ) : (
              <StyledElements.Italicize>
                No Point Reduction Set
              </StyledElements.Italicize>
            )}
          </Fragment>
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditTicketPointsComponent
