import React, { Component } from 'react'
import { Dropdown, Header } from 'semantic-ui-react'

import ItemHeader from '../ItemHeader'
import { StyledElements, Forms } from '../../../common'
import { TICKET_STATUS } from '../../../../constants'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditStatusReasonComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
  }

  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  buildOptionsArray = (arrayOfKeys, currentStatus) => {
    const result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: key,
        content: (
          <Header
            content={key}
            // subheader={TICKET_STATUS_DESCRIPTION_MAP[key]}
          />
        ),
      }
    })
    return result
  }

  getPossibleStatuses = () => {
    const {status, locationState} = this.props.ticket
    let possibleKeys = ['generic', 'parking', 'type']
    const result = this.buildOptionsArray(possibleKeys, status)
    return result
  }

  handleChange = (event, data) => {
    const { objectId } = this.props
    if (data.value) {
      this.props.editUpdate(objectId, data.value)
    }
  }

  updateStatus = e => {
    if (e && e.preventDefault) e.preventDefault()
    this.setState({ isEditing: false })
    const { objectId, editTicketField, ticket } = this.props
    if (editTicketField !== ticket.statusReason) {
      this.props.update(objectId, { statusReason: editTicketField })
    }
    return false
  }

  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    const { statusReason } = ticket
    return (
      <StyledElements.EditContainer>
        <form>
          <Dropdown
            fluid
            selection
            options={this.getPossibleStatuses()}
            value={editTicketField !== null ? editTicketField : statusReason}
            onChange={this.handleChange}
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                disabled: !editTicketField,
                color: 'green',
                text:  'Update Status Reason and Close Ticket',
                icon: 'save',
                labelPosition: 'right',
                onClick: this.updateStatus,
                type: 'submit',
              },
            ]}
          />
        </form>
      </StyledElements.EditContainer>
    )
  }

  render() {
    const { objectId, ticket } = this.props
    const { isEditing } = this.state
    const { statusReason } = ticket
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Status Reason"
          isEditable={ticket.status !== TICKET_STATUS.closed}
          isEditing={isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {isEditing ? (
            <RenderEdit />
          ) : statusReason ? (
            statusReason
          ) : (
            <StyledElements.Italicize>
              Open Ticket & No Status Reason
            </StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditStatusReasonComponent
