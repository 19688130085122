import { combineReducers } from 'redux'

import activationReducer from './app/activation/duck'
import adminReducer from './app/admin/duck'
import attorneysReducer from './app/attorney/duck'
import authenticationReducer from './app/authentication/duck'
import callsReducer from './app/calls/duck'
import countiesReducer from './app/counties/duck'
import courtsReducer from './app/courts/duck'
import hearingsReducer from './app/hearings/duck'
import leadsReducer from './app/leads/duck'
import lookupReducer from './app/lookup/duck'
import overlaysReducer from './app/overlays/duck'
import paymentsReducer from './app/payments/duck'
import processingReducer from './app/processing/duck'
import profileReducer from './app/profile/duck'
import reactTableReducer from './app/react-table/duck'
import regionsReducer from './app/regions/duck'
import searchReducer from './app/search/duck'
import ticketsReducer from './app/tickets/duck'
import usersReducer from './app/users/duck'
import chatsReducer from './app/chats/duck'

const rootReducer = combineReducers({
  activation: activationReducer,
  admin: adminReducer,
  attorneys: attorneysReducer,
  authentication: authenticationReducer,
  counties: countiesReducer,
  courts: courtsReducer,
  calls: callsReducer,
  hearings: hearingsReducer,
  leads: leadsReducer,
  lookup: lookupReducer,
  overlays: overlaysReducer,
  payments: paymentsReducer,
  processing: processingReducer,
  profile: profileReducer,
  reactTable: reactTableReducer,
  regions: regionsReducer,
  search: searchReducer,
  tickets: ticketsReducer,
  users: usersReducer,
  chats: chatsReducer,
})

export default rootReducer
