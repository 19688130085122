import { connect } from 'react-redux'
import { ticketsThunks } from '../../duck'
import ImageDropzone from './ImageDropzone'

const mapStateToProps = null
const ticketType = 'Ticket_Court_Screenshot'

const mapDispatchToProps = dispatch => ({
  setImage: (ticketObjectId, ticketImage) => {
    dispatch(ticketsThunks.addTicketImage(ticketObjectId, ticketImage, ticketType))
  },
})

const ImageDropzoneContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageDropzone)

export default ImageDropzoneContainer
