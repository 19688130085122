import { axios } from '../../../utilities'
import { processFile } from '../../../utilities/imageProcessing'
import { default as axiosModule } from "axios"

export const getTicket = objectId => {
  return axios.secure.delayed
    .get(`/ticket-data/id/${objectId}`)
    .then(res => res.data)
}

export const getTicketEvidence = objectId => {
  return axios.secure.delayed
    .get(`/ticket-data/evidence/${objectId}`)
    .then(res => res.data)
}
export const getTicketPortalEditHistory = objectId => {
  return axios.secure.delayed
    .get(`/ticket-data/portalEditHistory/${objectId}`)
    .then(res => res.data)
}

export const getTicketPricePreview = objectId => {
  return axios.secure.delayed
    .get(`/ticket-data/price/${objectId}`)
    .then(res => res.data)
}

export const updateTicket = (objectId, updatesObject) => {
  return axios.secure.delayed
    .put(`/ticket-data/id/${objectId}`, updatesObject)
    .then(res => res.data)
}

export const createTicketFromDOTTicket = data => {
  return axios.secure.delayed
    .post(`/ticket-data/createFromDOTTicket`, data)
    .then(res => res.data)
}

export const createTicketFromSearchResult = data => {
  return axios.secure.delayed
    .post(`/ticket-data/createFromSearchResult`, data)
    .then(res => res.data)
}

export const chargeTicket = ticketObjectId => {
  return axios.secure.delayed
    .post(`/ticket-actions/charge`, {
      ticket: ticketObjectId,
    })
    .then(res => res.data)
}

export const getTicketUserPaymentMethod = userObjectId => {
  return axios.secure.delayed
    .get(`/user-data/payment/${userObjectId}`)
    .then(res => res.data)
}

export const setTicketImage = (ticketObjectId, ticketImage, ticketType) => {
  return processFile(ticketImage)
    .then(base64Image => {
      return axios.secure.delayed
      .post(
        `/ticket-data/ticketImage/${ticketObjectId}`,
        {ticketImage: base64Image, ticketType}
      )
      .then(res => res.data)
    })
    .catch(console.log)
}

export const addTicketEvidence = (ticketObjectId, ticketImage) => {
  return processFile(ticketImage)
    .then(base64Image => {
      return axios.secure.delayed
        .post(
          `/ticket-data/ticketEvidence/${ticketObjectId}`,
          {evidenceImage: base64Image}
        )
        .then(res => res.data)
    })
    .catch(console.log)
}

export const createTrafficBond = (ticket) => {
  return axios.secure.delayed
    .post(`/bond-data`, ticket)
    .then(res => res.data)
}

export const sendSlackMessageForTicketStatusSwitchedBack = (data) => {
    return axios.secure.post('/ticket-data/ticket-status-switch/slackMessage', data).then(res => res.data)
}

export const generateRetentionLetter = async (ticket) => {
  return axiosModule.post(
    `${process.env.REACT_APP_WINIT_MAIN_BACKEND_URL}/api/v1/attorney/generateRetentionLetter`,
    { ticket },
    {
      headers: {
        "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      }
    }
  ).then((res) => {
    if (res.status === 201) return true
    return false
  })
}
