import React from "react"
import styled from "styled-components"

const SharedCloseBtn = ({ type, className, onClick, name }) => {
  return (
    <CloseButton type={type} className={className} onClick={onClick}>{name}</CloseButton>
  )
}

const CloseButton = styled.button`
  background-color: #e70000;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  margin-right: 10px;

  &:hover {
    filter: brightness(90%);
  }
`

export default SharedCloseBtn