import { connect } from 'react-redux'
import {authenticationSelectors} from '../authentication/duck'
import ChatsComponent from './ChatsComponent'
import { chatsSelectors, chatsThunks} from './duck'

const mapStateToProps = state => ({
  chatWindowShowing: chatsSelectors.chatWindowShowing(state),
  attorneyChatObjectId: chatsSelectors.attorneyChatObjectId(state),
  attorney: authenticationSelectors.attorneySelector(state),
})

const mapDispatchToProps = dispatch => ({
  attorneyChatEnabledCheck: (attorney) => dispatch(chatsThunks.attorneyChatEnabledCheck(attorney)),
})

const ChatsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatsComponent)

export default ChatsContainer