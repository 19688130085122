import React, { Component, Fragment } from 'react'
import { Icon } from 'semantic-ui-react'

import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import ItemHeader from '../ItemHeader'
import { StyledElements, DownloadImageLink } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import ViewImageModalComponent from '../ViewImageModal/ViewImageModalComponent'
import ImageDropzone from './ImageDropzoneContainer'

class ViewTicketImageSectionPopupComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showImage: false
    }
  }

  showImage = () => {
    this.setState({ showImage: true })
  }

  hideImage = () => {
    this.setState({ showImage: false })
  }

  render() {
    const { ticketImage, ticketID, showIconOnly } = this.props
    return (
      <StyledElements.DataContainer>
        {!showIconOnly ?
          <ItemHeader
            title="Ticket Image"
            isEditable={false}
            isEditing={false}
            toggleState={() => {}}
          /> : null
        }
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <span>
            {ticketImage && ticketImage.url ? (
              <Fragment>
                <StyledElements.FakeLink onClick={this.showImage}>
                  <Icon name="picture" /> {!showIconOnly ? <span>View Image</span> : null}
                </StyledElements.FakeLink>
                {!showIconOnly ?
                  <Fragment>
                    <StyledElements.Divider />
                    <DownloadImageLink
                      imageUrl={ticketImage.url}
                      fileName={ticketID}
                      prepend="ticket-"
                    />
                  </Fragment> : null}
                <ViewImageModalComponent
                  open={this.state.showImage}
                  handleClose={this.hideImage}
                  ticketImage={ticketImage}
                  title={`Ticket #${ticketID} Image`}
                  background={'transparent'}
                />
              </Fragment>
            ) : (
              <Fragment>
                <StyledElements.Italicize>
                  No Image Available
                </StyledElements.Italicize>
              </Fragment>
            )}
            <ImageDropzone objectId={this.props.objectId} />
          </span>
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default ViewTicketImageSectionPopupComponent
