import paymentsReducer from './reducers'
import * as paymentsActions from './actions'
import * as paymentsSelectors from './selectors'
import * as paymentsServices from './services'
import * as paymentsThunks from './thunks'
import * as paymentsTypes from './types'

export {
  paymentsActions,
  paymentsSelectors,
  paymentsServices,
  paymentsThunks,
  paymentsTypes,
}
export default paymentsReducer
