import { connect } from 'react-redux'
import { overlaySelectors } from '../../overlays/duck'
import { chatsSelectors, chatsThunks } from '../duck'

import { authenticationSelectors } from '../../authentication/duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import {
  FRONTEND_PARENT_TYPES,
  FRONTEND_TICKET_TYPES,
} from '../../../constants'
import ChatsListComponent from './ChatsListComponent'
import {attorneyIsAdminSelector} from '../../authentication/duck/selectors'

const TYPE = FRONTEND_PARENT_TYPES.calls
const TICKET_TYPE = FRONTEND_TICKET_TYPES.calls
const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    TICKET_TYPE
  ),
  isLoading: overlaySelectors.isLoadingSelector(state),
  rtSettings: reactTableSelectors.settings(TYPE, state),
  attorney: authenticationSelectors.attorneySelector(state),
  allChats: chatsSelectors.allChats(state),
  isAdmin: attorneyIsAdminSelector(state)
})

const mapDispatchToProps = dispatch => ({
  fetchChatsItems: (attorneyObjectId) => dispatch(chatsThunks.fetchChatsItems(attorneyObjectId)),
  setChatWindowShowing: (bool) => dispatch(chatsThunks.setChatWindowShowing(bool)),
  setChatCurrentChannelUrl: (currentChannelUrl) => dispatch(chatsThunks.setChatCurrentChannelUrl(currentChannelUrl)),
  selectAttorneyFromChannelMembers: (channelMembers) => dispatch(chatsThunks.selectAttorneyFromChannelMembers(channelMembers)),
  setResized: value => dispatch(reactTableActions.setResized(TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TYPE, value)),
  setFiltered: value => dispatch(reactTableActions.setFiltered(TYPE, value)),
})

const ChatsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatsListComponent)

export default ChatsListContainer
