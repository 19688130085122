import React from 'react'
import { Input, Form } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import {
  LoadingComponent,
  UpdatingComponent
} from '../../../tickets/DetailSection/ticketOverlays'
import ItemHeader from '../../../tickets/DetailSection/ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'


class EditDOBComponent extends React.Component {
  constructor(props) {
    super(props)
    this.dobInput = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const wasPreviouslyEditing = prevProps.isEditing
    const { isEditing } = this.props
    if (isEditing && !wasPreviouslyEditing) {
      this.dobInput.current.focus()
    }
  }

  handleChange = event => {
    const { objectId, editUpdate } = this.props
    const dateInput = event.target.value
    editUpdate(objectId, dateInput)
  }

  toggleEditStatus = () => {
    const { isEditing, setIsEditing, objectId } = this.props
    setIsEditing(objectId, !isEditing)
  }

  updateDOB = () => {
    const {
      objectId,
      update,
      editUserField,
      setIsEditing,
      clearEdit,
    } = this.props

    update(objectId, { dob: editUserField })
    setIsEditing(objectId, false)
    clearEdit(objectId)
  }

  renderEdit = () => {
    const { user, editUserField } = this.props
    return (
      <StyledElements.EditContainer>
        <Form>
          <Input 
            fluid
            ref={this.dobInput}
            onChange={this.handleChange}
            value={editUserField === null ? user.dob : editUserField}
            placeholder="01/01/2000"
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                color: 'green',
                text: 'Update Date of Birth',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updateDOB,
              },
            ]}
          />
        </Form>
      </StyledElements.EditContainer>
    )
  }

  render() {
    const { isEditing, user, overlayKey } = this.props
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader 
          title="Date of Birth"
          isEditable={true}
          isEditing={!!isEditing}
          toggleState={this.toggleEditStatus}
        />
        <SpecificOverlayContainer
          overlayKey={overlayKey}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {isEditing ? (
            <RenderEdit />
          ) : user.dob ? (
            user.dob
          ) : (
            <StyledElements.Italicize>No DOB Set</StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditDOBComponent
