import React from 'react'
import PropTypes from 'prop-types'
import Styled from 'styled-components'

const ScrollArea = Styled.div`
  max-height: ${props => props.height};
  padding: ${props => props.padding};
  overflow-y: scroll;
`

const ScrollOverflowContent = props => {
  return <ScrollArea {...props}>{props.children}</ScrollArea>
}

ScrollOverflowContent.defaultProps = {
  height: '200px',
  padding: '10px 0 0 0',
}

ScrollOverflowContent.propTypes = {
  height: PropTypes.string,
  padding: PropTypes.string,
}

export default ScrollOverflowContent
