import { connect } from 'react-redux'
import LogoutComponent from './LogoutComponent'
import { authenticationThunks } from './duck'

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authenticationThunks.logout())
})

const LogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutComponent)

export default LogoutContainer
