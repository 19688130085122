import React, { Component } from 'react'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import ItemHeader from '../ItemHeader'
import { StyledElements } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import PricePreviewList from './PricePreviewList'
import { FRONTEND_TICKET_TYPES } from '../../../../constants'

class ViewTicketPricePreviewComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }
  toggleExpansion = () => {
    const { objectId, fetch, pricePreview, loading } = this.props
    const { expanded } = this.state
    if (!expanded && (!pricePreview || !pricePreview.length) && !loading) {
      fetch(objectId)
    }
    this.setState({ expanded: !expanded })
  }
  render() {
    const { ticket, OVERLAY_KEY, pricePreview, attorney } = this.props
    const { expanded } = this.state
    if (
        (!attorney.globalAdmin &&
        !ticket.classifications.includes(FRONTEND_TICKET_TYPES.leads.all)) || (!attorney.globalAdmin && attorney.disableAttorneyPriceVisibility)
    ) {
      return null
    }
    return (
      <StyledElements.DataContainer isEditing={expanded}>
        <ItemHeader
          title="Price Preview"
          isEditable
          toggleState={this.toggleExpansion}
          expandText="show"
          collapseText="hide"
          isEditing={expanded}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <SpecificOverlayContainer
            overlayKey={OVERLAY_KEY}
            centerFillStatus={true}
            ignoreError={true}
          >
            {expanded ? (
              ticket && pricePreview && pricePreview.length ? (
                <PricePreviewList
                  pricePreview={pricePreview}
                  attorney={attorney}
                />
              ) : (
                <StyledElements.Italicize>
                  Pricing not available.
                </StyledElements.Italicize>
              )
            ) : (
              <StyledElements.Italicize>
                View the user quoted price
              </StyledElements.Italicize>
            )}
          </SpecificOverlayContainer>
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default ViewTicketPricePreviewComponent
