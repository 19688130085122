import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import CallsList from './CallsList/CallsListContainer'
import NotFoundPage from '../404NotFound'
import ViewCall from './ViewCallTicket/ViewCallTicketContainer'

class LeadsRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/calls/" component={CallsList} />
        <Route path="/calls/view/:id" component={ViewCall} />
        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    )
  }
}

export default LeadsRoutes
