import { connect } from 'react-redux'
import { ticketsSelectors } from '../../duck'
import { authenticationSelectors } from '../../../authentication/duck'
import { ticketsThunks } from '../../duck'
import EditSupportNotesComponent from './EditSupportNotesComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  attorney: authenticationSelectors.attorneySelector(state)
})

const mapDispatchToProps = dispatch => ({
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject))
})

const EditSupportNotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSupportNotesComponent)

export default EditSupportNotesContainer
