import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
// import { Container } from 'semantic-ui-react'
import styled from 'styled-components'

import { FillHeight } from '../common'
import HeaderContainer from './HeaderContainer'
import FooterContainer from './FooterContainer'

const PaddedContainer = styled.div`
  &&& {
  padding: 1em 2em;
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  }
`

class Template extends Component {
  render() {
    return (
      <Fragment>
        <Helmet
          defaultTitle="WinIt Attorney Platform"
          titleTemplate="%s | WinIt Attorney Platform"
        />
        <HeaderContainer />
        <FillHeight basic>
          <PaddedContainer>{this.props.children}</PaddedContainer>
        </FillHeight>
        <FooterContainer />
      </Fragment>
    )
  }
}

export default Template
