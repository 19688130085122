import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
// import moment from 'moment'
import { Dropdown, Icon } from 'semantic-ui-react'
import { stringTools } from '../../utilities'
// import styled from 'styled-components'
// import AttorneyLogo from '../../assets/images/navigation-bar-logo-portal.svg'

// const UserImage = styled(Image)`
//   &&& {
//     margin: 0 10px 0 10px;
//     opacity: 0.8;
//     transition: 1s;
//     max-height: 40px;
//     &:hover {
//       opacity: 1;
//     }
//   }
// `

const AttorneyImagePopup = ({ attorney, user, history }) => {
  return (
    <UserDropdown
      history={history}
      user={user}
      attorney={attorney}
      trigger={
        <Fragment>
          {/* <UserImage
            inline
            rounded
            spaced="right"
            // ui={false}
            size="mini"
            // fitted
            style={{ maxHeight: 27 }}
            src={
              attorney && attorney.avatar && attorney.avatar.url
                ? attorney.avatar.url
                : AttorneyLogo
            }
            avatar
          /> */}
          <Icon name="user circle" />
          {user && user.name
            ? stringTools.capitalizeFirstLetter(stringTools.getWord(user.name))
            : ''}
        </Fragment>
      }
    />
  )
}

const UserDropdown = ({ history, trigger, attorney, user }) => (
  <Dropdown
    style={{ zIndex: 10000 }}
    trigger={trigger}
    direction="left"
    pointing="top"
    // inline
    // fluid
    // basic
    item
    // button
    // style={{ marginRight: 10 }}
    // header={attorney && attorney.name ? attorney.name : 'Please Add Your Name'}
    // text={user && user.name ? `${user.name.split(' ')[0]}` : ''}
  >
    <Dropdown.Menu>
      <Dropdown.Header
        disabled
        // icon="user circle"
        content={
          <Fragment>
            {/* <UserImage
              inline
              // rounded
              spaced="right"
              // ui={false}
              // size="mini"
              // fitted
              // style={{ maxHeight: 27 }}
              src={
                attorney && attorney.avatar && attorney.avatar.url
                  ? attorney.avatar.url
                  : AttorneyLogo
              }
              avatar
            /> */}
            {attorney && attorney.name ? attorney.name : 'Please Add Your Name'}
          </Fragment>
        }
      />
      {/* <Dropdown.Divider /> */}
      <Dropdown.Item
        active={history.location.pathname === '/profile'}
        text="Account Summary"
        icon="address card"
        onClick={() => history.push('/profile')}
      />
      <Dropdown.Item
        icon="chart bar"
        active={history.location.pathname === '/profile/stats'}
        onClick={() => history.push('/profile/stats')}
        text="Statistics"
      />
      <Dropdown.Item
        icon="users"
        active={history.location.pathname === '/profile/users'}
        onClick={() => history.push('/profile/users')}
        text="Associated Users"
      />
      <Dropdown.Item
        icon="law"
        active={history.location.pathname === '/profile/agreements'}
        onClick={() => history.push('/profile/agreements')}
        text="Legal Docs"
      />
      <Dropdown.Divider />
      <Dropdown.Item
        icon="sign out"
        onClick={() => history.push('/logout')}
        text="Sign Out"
      />
    </Dropdown.Menu>
  </Dropdown>
)

export default withRouter(AttorneyImagePopup)
