import * as types from './types'

export const setSearchType = searchType => ({
  type: types.SET_SEARCH_TYPE,
  searchType,
})

export const clearSearch = () => ({
  type: types.CLEAR_SEARCH,
})

export const setSearchLoading = loading => ({
  type: types.SET_SEARCH_LOADING,
  loading,
})

export const setSearchError = error => ({
  type: types.SET_SEARCH_ERROR,
  error,
})

export const setSearchResults = results => ({
  type: types.SET_SEARCH_RESULTS,
  results,
})

export const setSearchDescription = description => ({
  type: types.SET_SEARCH_DESCRIPTION,
  description,
})
