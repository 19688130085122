import React, { Component } from 'react'
import styled from 'styled-components'
import ViewChat from '../../../chats/ViewChat/ViewChatContainer'
import { StyledElements } from '../../../common'


const Title = styled.span`
  font-weight: bold;
  font-size: 1em;
  margin: 0 0.5em 0 0;
`

class ViewChatActionComponent extends Component {
  openChat = async () => {
    const SBuser = await this.props.createChatUser(this.props.ticket)
    if (SBuser) {
      const { channelUrl } = await this.props.autoJoinSendbirdUserToGroupChannel(this.props.ticket)
      this.props.setChatCurrentChannelUrl(channelUrl)
      this.props.setChatWindowShowing(true)
    }
  }

  render() {
    return (
      <>
        <StyledElements.DataContainer>
          <div>
            <Title>Chat&nbsp;</Title>
            <StyledElements.FakeLink 
              size="0.9em" 
              onClick={this.openChat}
            >
              show
            </StyledElements.FakeLink>
          </div>
          <StyledElements.Italicize>
            Initiate or view chat for this ticket.
          </StyledElements.Italicize>
        </StyledElements.DataContainer>
        {this.props.chatWindowShowing ? (
          <ViewChat userId={this.props.ticket.attorney?.objectId} /> 
        ) : null}
      </>
    )
  }
}

export default ViewChatActionComponent
