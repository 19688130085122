import { axios } from "../../../utilities"


export const getAllContactInfo = (created_at_date) => {
  let url = false 
  if (created_at_date) {
    url = `/lookup/contactInfo?createdAt=${created_at_date}`
  }else{
    url = "/lookup/contactInfo"
  }
  return axios.secure.delayed.get(url)
    .then(res => res.data)
}

export const getSingleContactInfo = (contactInfoObjectId) => {
  return axios.secure.delayed.get(`/lookup/contactInfo/${contactInfoObjectId}`)
    .then(res => res.data)
}

export const updateSingleContactInfo = (contactInfoObjectId, updateObject) => {
  return axios.secure.delayed.put(`/lookup/contactInfo/${contactInfoObjectId}`, updateObject)
    .then(res => res.data)
}
