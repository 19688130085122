import Creators from './actions'
import * as services from './services'
import authenticationActions from './actions'
import { overlaysActions } from '../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../constants'

const clearUser = Creators.clearUser
const setUser = Creators.setUser

const validateLogin = (email, password) => dispatch => {
  if (!email) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Missing Email',
        message: 'An email is required to login.',
      })
    )
  }
  if (!password) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Missing Password',
        message: 'A password is required to login.',
      })
    )
  }
  dispatch(login(email, password))
}

const login = (email, password) => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .login(email, password)
    .then(result => {
      if (result.attorney.globalAdmin) {
        dispatch(overlaysActions.resetNotifications())
        if (result.attorney.disable2FA) {
          dispatch(
            overlaysActions.addNotification({
              type: NOTIFICATION_TYPES.success,
              title: `Welcome Back ${result.user.name}!`,
              duration: 5,
            })
          )
          dispatch(overlaysActions.hideLoader())
          dispatch(authenticationActions.setUser(result.user))
          dispatch(authenticationActions.setAttorney(result.attorney))
        } else {
          dispatch(
            overlaysActions.addNotification({
              type: NOTIFICATION_TYPES.success,
              title: `Please check your inbox at ${result.user.email}!`,
              duration: 5,
            })
          )
          dispatch(overlaysActions.hideLoader())
        }
      } else {
        if (result.attorney.disable2FA) {
          dispatch(
            overlaysActions.addNotification({
              type: NOTIFICATION_TYPES.success,
              title: `Welcome Back ${result.user.name}!`,
              duration: 5,
            })
          )
          dispatch(overlaysActions.hideLoader())
          dispatch(authenticationActions.setUser(result.user))
          dispatch(authenticationActions.setAttorney(result.attorney))
        } else {
          dispatch(
            overlaysActions.addNotification({
              type: NOTIFICATION_TYPES.success,
              title: `Please check your inbox at ${result.user.email}!`,
              duration: 5,
            })
          )
          dispatch(overlaysActions.hideLoader())
        }
      }
    })
    .catch(error => {
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Login Issue',
          message: error,
        })
      )
      dispatch(overlaysActions.hideLoader())
      setTimeout(() => window.location.reload(), 4000)
      console.error('error in attempt login', error)
    })
}

const twoFA = (email, password, secret, token) => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .twoFA(email, password, secret, token)
    .then(result => {
      dispatch(overlaysActions.resetNotifications())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Welcome Back ${result.user.name}!`,
          duration: 5,
        })
      )
      dispatch(overlaysActions.hideLoader())
      dispatch(authenticationActions.setUser(result.user))
      dispatch(authenticationActions.setAttorney(result.attorney))
    })
    .catch(error => {
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Login Issue',
          message: error
        })
      )
      dispatch(overlaysActions.hideLoader())
    })
}

const autoLogin = () => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .autoLogin()
    .then(result => {
      dispatch(overlaysActions.resetNotifications())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Welcome Back ${result.user.name}!`,
          duration: 5,
        })
      )
      dispatch(overlaysActions.hideLoader())
      dispatch(authenticationActions.setUser(result.user))
      dispatch(authenticationActions.setAttorney(result.attorney))
    })
    .catch(err => {
      dispatch(overlaysActions.hideLoader())
      if (err === 'No Token Supplied') {
        return undefined
        // dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.error, duration: 5, title: 'No Token' }))
      }
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          title: 'Login Issue',
          message: err,
        })
      )
      console.error('auto login failed', err)
    })
}

export const logout = () => dispatch => {
  services.logout()
  dispatch(authenticationActions.reset())
  dispatch(overlaysActions.resetNotifications())
  dispatch(
    overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.success,
      duration: 10,
      title: 'Logged Out',
      message: 'You were successfully logged out.',
    })
  )
}

export default {
  clearUser,
  setUser,
  login,
  twoFA,
  autoLogin,
  logout,
  validateLogin,
}
