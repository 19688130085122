import types from './types'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const INITIAL_STATE = {
  [FRONTEND_TICKET_TYPES.calls]: {
    items: [],
    retrievedAt: null,
  },
  requestHistory: {},
  currentlyViewing: null,
}
const callsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_CALLS: {
      const { callType, value } = action
      const newType = { ...state[callType] }
      newType.items = value
      return {
        ...state,
        [callType]: newType,
      }
    }

    case types.SET_CALLS_RETRIEVED_AT: {
      const { callType } = action
      const newType = { ...state[callType] }
      newType.retrievedAt = new Date()
      return {
        ...state,
        [callType]: newType,
      }
    }

    case types.SET_CURRENTLY_VIEWING: {
      const { value } = action
      return {
        ...state,
        currentlyViewing: value,
      }
    }

    case types.SET_CALL_REQUEST_HISTORY: {
      const requestHistory = { ...state.requestHistory }
      const { ticketObjectId, callHistory } = action
      requestHistory[ticketObjectId] = {
        items: callHistory,
        retrievedAt: new Date(),
      }
      return {
        ...state,
        requestHistory,
      }
    }

    default:
      return state
  }
}

export default callsReducer
