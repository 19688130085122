import { FRONTEND_TICKET_TYPES } from '../../../constants'
import { ticketsByIdsSelector } from '../../tickets/duck/selectors'

// get current region filter
export const processingRegionFilterSelector = state =>
  state.processing.regionFilter

export const currentlyViewingSelector = state =>
  state.processing.currentlyViewing

export const requiresApprovalCasesSelector = state => 
  state.processing[FRONTEND_TICKET_TYPES.processing.requiresApproval].items

export const requiresApprovalCasesRetrievedAt = state => 
  state.processing[FRONTEND_TICKET_TYPES.processing.requiresApproval].retrievedAt

export const suspendedCasesSelector = state => 
  state.processing[FRONTEND_TICKET_TYPES.processing.suspended].items

export const suspendedCasesRetrievedAt = state =>
  state.processing[FRONTEND_TICKET_TYPES.processing.suspended].retrievedAt

// New Dynamic Selectors
export const ids = (ticketType, state) => {
  return state.processing[ticketType].items
}

export const retrievedAt = (state, ticketType) => {
  return state.processing[ticketType].retrievedAt
}

export const recentlyApprovedRetrievedAt = state => {
  const currentCategory = ids(
    FRONTEND_TICKET_TYPES.processing.recentlyApproved,
    state
  )
  return retrievedAt(state, currentCategory)
}

export const recentlyApprovedSelector = state => {
  const currentCategory = ids(
    FRONTEND_TICKET_TYPES.processing.recentlyApproved,
    state
  )
  return byType(state, currentCategory)
}

export const byType = (state, ticketType) => {
  const processingIds = ids(ticketType, state)
  return ticketsByIdsSelector(state, processingIds)
}

export const buildProcessingSelectorByType = ticketType => state => {
  const processingIds = ids(ticketType, state)
  return ticketsByIdsSelector(state, processingIds)
}

// Old Specific Selectors
export const processingIdsSelector = state =>
  state.processing[FRONTEND_TICKET_TYPES.processing.all].items

export const processingRetrievedAtSelector = state =>
  state.processing[FRONTEND_TICKET_TYPES.processing.all].retrievedAt

export const processingSelector = state =>
  ticketsByIdsSelector(state, processingIdsSelector(state))

export const idFromRouterSelector = (state, props) => props.match.params.id
