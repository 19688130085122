import axios from 'axios'
import { axios as axiosUtil, localStorageUtils } from "../../../utilities"

const sendbirdApiBaseUrl = `https://api-${process.env.REACT_APP_SENDBIRD_API_KEY}.sendbird.com/v3`

const axiosConfig = () => ({
  headers: {
    "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
    "X-Parse-Master-Key": process.env.REACT_APP_PARSE_MASTER_KEY,
    "sessionToken": localStorageUtils.get("sessionToken")
  }
})

export const getChatsItems = async (attorneyUser) => {
  const attorneyObjectId = attorneyUser.objectId || attorneyUser.attorney.objectId
  let result = null
  try {
    result = await axios.get(
      sendbirdApiBaseUrl + `/users/${attorneyObjectId}/my_group_channels?show_member=true&limit=100&order=latest_last_message`, 
      { headers: {"Api-Token": process.env.REACT_APP_SENDBIRD_API_TOKEN} }
    )
  } catch (error) {
    console.error(error)
  }
  return result.data.channels
}

export const selectAttorneyFromChannelMembers = async (channelMembers) => {
  let result = null
  try {
    result = await axiosUtil.secure.post(
      `${process.env.REACT_APP_API_URL}/attorney-data/attorney-chat-select`,
      {channelMembers}
    )
  } catch (error) {
    console.error("Error in network request", error)
    return ""
  }
  return result.data.objectId
}

export const getSendbirdAttorneyUser = async (attorney) => {
  let result = null
  try {
    result = await axios.get(
      sendbirdApiBaseUrl + `/users/${attorney.objectId}?include_unread_count=true`, 
      { headers: {"Api-Token": process.env.REACT_APP_SENDBIRD_API_TOKEN} }
    )
  } catch (error) {
    console.error(error)
  }
  return result
}

export const createChatUser = async (ticket) => {
  const body = {
    attorneyId: ticket.attorney.objectId,
    ticketId: ticket.objectId,
    ticketUser: ticket.user,
  }

  let result = null
  try {
    result = await axios.post(
      process.env.REACT_APP_WINIT_MAIN_BACKEND_URL + "/api/v1/sendbird_chat/createSendbirdChatUser",
      body,
      axiosConfig()
    )
  } catch (error) {
    console.error(error)
  }
  return result.data
}

export const autoJoinSendbirdUserToGroupChannel = async (ticket) => {
  const body = {
    attorneyId: ticket.attorney.objectId,
    ticketId: ticket.objectId,
    ticketUser: ticket.user,
    supressAdminMessage: true,
  }

  let result = null
  try {
    result = await axios.put(
      process.env.REACT_APP_WINIT_MAIN_BACKEND_URL + "/api/v1/sendbird_chat/autoJoinSendbirdUserToHisGroupChannel",
      body,
      axiosConfig()
    )
  } catch (error) {
    console.error(error)
  }
  return result.data
}
