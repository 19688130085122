import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import NotFoundPage from '../404NotFound'
import AllLeadsList from './LeadsList/AllLeadsListContainer'
import ActiveLeadsList from './LeadsList/ActiveLeadsListContainer'
import ClosedLeadsList from './LeadsList/ClosedLeadsListContainer'
import ClosedTicketsList from './LeadsList/ClosedTicketsContainer'
import UnassignedLeadsList from './LeadsList/UnassignedLeadsListContainer'
import ViewLead from './ViewLead/ViewLeadContainer'
import { attorneySelector } from '../authentication/duck/selectors'


class LeadsRoutes extends Component {
  render() {
    const globalAdmin = this.props.attorney && this.props.attorney.globalAdmin
    return (
      <>
        { globalAdmin ?
          <Switch>
            <Route exact path="/leads/all" component={AllLeadsList} />
            <Route exact path="/leads/active" component={ActiveLeadsList} />
            <Route exact path="/leads/unassigned" component={UnassignedLeadsList} />
            <Route exact path="/leads/closed" component={ClosedLeadsList} />
            <Route exact path="/leads/closed-tickets" component={ClosedTicketsList} />
            <Route path="/leads/view/:id" component={ViewLead} />
            <Route path="*" component={NotFoundPage} status={404} />
          </Switch>
          : 
          <Switch>
            <Route exact path="/leads/closed" component={ClosedLeadsList} />
            <Route path="*" component={NotFoundPage} status={404} />
          </Switch>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  attorney: attorneySelector(state)
})

export default connect(
  mapStateToProps
)(LeadsRoutes)
