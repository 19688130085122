import React, { Component } from 'react';
import { Popup, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import Styled from 'styled-components'

const Padded = Styled.div`
  margin: 0 8px 0 8px;
  display: inline-block;
  opacity: 0.3;
`

class HelpIconTooltip extends Component {
  render() {
    const {
      icon,
      circular,
      invertedPopup,
      popupSize,
      iconSize,
      content,
      fittedIcon,
      children
    } = this.props
    if (!content) return <div />
    return (
      <Popup
        size={popupSize}
        inverted={invertedPopup}
        trigger={<span>{children || <Padded><Icon fitted={fittedIcon} size={iconSize} circular={circular} name={icon} /></Padded>}</span>}
      >
        <Popup.Content>
          {content}
        </Popup.Content>
      </Popup>
    );
  }
}

HelpIconTooltip.defaultProps = {
  icon: 'help circle',
  circular: false,
  content: '',
  invertedPopup: true,
  popupSize: undefined,
  iconSize: undefined,
  fittedIcon: true,
  children: undefined
}

HelpIconTooltip.propTypes = {
  icon: PropTypes.string,
  circular: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  invertedPopup: PropTypes.bool,
  fittedIcon: PropTypes.bool,
  popupSize: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'huge']),
  iconSize: PropTypes.oneOf(["mini", "tiny", "small", "large", "big", "huge", "massive"])

}

export default HelpIconTooltip;
