import * as searchServices from './duck/services'
import TicketIdSearchComponent from './TicketIDSearchComponent'
import LicenseIDSearchComponent from './LicenseIDSearchComponent'
import PhoneNumberSearchComponent from './PhoneNumberSearchComponent'
import EmailSearchComponent from './EmailSearchComponent'

export const SEARCH_TYPE = {
  ticketId: 'ticketId',
  licenseId: 'licenseId',
  phoneNumber: 'phoneNumber',
  email: 'email',
}

const SEARCH_DESCRIBER = {
  [SEARCH_TYPE.ticketId]: {
    searchType: SEARCH_TYPE.ticketId,
    name: 'Ticket ID',
    service: searchServices.ticketIDSearch,
    Component: TicketIdSearchComponent,
    URLKeys: ['ticketID'],
  },
  [SEARCH_TYPE.licenseId]: {
    searchType: SEARCH_TYPE.licenseId,
    name: 'License ID',
    service: searchServices.licenseIDSearch,
    Component: LicenseIDSearchComponent,
    URLKeys: ['licenseID'],
  },
  [SEARCH_TYPE.phoneNumber]: {
    searchType: SEARCH_TYPE.phoneNumber,
    name: 'Phone Number',
    service: searchServices.phoneNumberSearch,
    Component: PhoneNumberSearchComponent,
    URLKeys: ['phoneNumber'],
  },
  [SEARCH_TYPE.email]: {
    searchType: SEARCH_TYPE.email,
    name: 'Email',
    service: searchServices.emailSearch,
    Component: EmailSearchComponent,
    URLKeys: ['email']
  },
}

export default SEARCH_DESCRIBER
