import types from './types.js';

const setUser = (value) => ({
  type: types.SET_USER,
  value: value
})

const clearUser = () => ({
  type: types.CLEAR_USER
})

const setAttorney = (value) => ({
  type: types.SET_ATTORNEY,
  value: value
})

const clearAttorney = () => ({
  type: types.CLEAR_ATTORNEY
})

const reset = () => ({
  type: types.RESET
})

export default {
  setUser,
  clearUser,
  setAttorney,
  clearAttorney,
  reset
}
