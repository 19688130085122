import React, { Component } from 'react'
import { Button, Popup, Confirm } from 'semantic-ui-react'

class CreateTrafficTicketButton extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  CreateButton = () => {
    const {
      ticketType,
      attorneySelected,
      loading,
      success,
      hasUserToAssociate,
    } = this.props
    let court = this.props[ticketType].trafficCourt
    return (
      <div style={{ height: '100%' }}>
        <Button
          style={{ width: '170px', height: '100%' }}
          primary
          loading={loading}
          disabled={
            (!attorneySelected && court) ||
            success ||
            loading ||
            !hasUserToAssociate
          }
          onClick={this.show}
        >
          {success ? 'Success !' : `Create Traffic Ticket`}
        </Button>
      </div>
    )
  }

  show = () => this.setState({ open: true })

  handleCancel = () => this.setState({ open: false })

  handleConfirm = () => {
    this.setState({ open: false })
    this.props.createTrafficTicket()
  }

  popupContent = () => {
    if (!this.props.hasUserToAssociate) {
      return 'Please create a user in order to create a traffic ticket'
    } else {
      return 'Please select a quote to create a traffic ticket'
    }
  }

  confirmText = () => {
    let { attorneySelected, ticketType } = this.props
    if (!attorneySelected) {
      return `There is no court on the ticket. You can still create a Traffic Ticket,
        but please remember to add the court manually on the next step, as well as to select an attorney.`
    }
    let ticketID = this.props[ticketType].ticketID
    if (ticketType === 'DOTTicket') {
      return `Are you sure to create a traffic ticket for ticket ${ticketID}? This DOT ticket will be moved to traffic tickets.`
    } else if (ticketType === 'searchResult') {
      return `Are you sure to create a traffic ticket for ticket ${ticketID}?`
    }
  }

  render() {
    let { attorneySelected, ticketType, hasUserToAssociate } = this.props
    let court = this.props[ticketType].trafficCourt

    // We want to show a tooltip when the button is disabled (with no court)
    // or if we do not have a user to associate to the future traffic ticket
    if ((!attorneySelected && court) || !hasUserToAssociate) {
      return (
        <Popup
          content={this.popupContent()}
          // We have to wrap the button into a div in order to work on a disabled button
          trigger={
            <div>
              <this.CreateButton />
            </div>
          }
        />
      )
    } else {
      return (
        <div>
          <this.CreateButton />
          <Confirm
            open={this.state.open}
            content={this.confirmText()}
            header="Please confirm"
            cancelButton="No"
            confirmButton="Yes"
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
            size="mini"
          />
        </div>
      )
    }
  }
}

export default CreateTrafficTicketButton
