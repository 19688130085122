import React, { Component, Fragment } from "react"
import { Form, Header, Grid, Dropdown } from "semantic-ui-react"
import { AES } from "crypto-js"

import { STATES, TRAFFIC_STATES_URL_SLUGS } from "../../../constants"

class LinkGeneratorComponent extends Component {
  constructor() {
    super()
    this.state = {
      generatedLink: "", 
      attorney: "",
      trafficState: "",
      trafficCounty: "",
      trafficCourt: "",
      price: "",
      recentlyCopied: false,
      inputFieldError: "",
    }
  }

  componentDidMount() {
    const { retrievedAt, loading, fetchAttorneys } = this.props
    if (!loading && !retrievedAt) {
      fetchAttorneys()
    }
  }

  buildAttorneyListItems = () => {
    const { attorneys } = this.props
    if (attorneys && attorneys.length) {
      return attorneys.map(attorney => ({
        key: attorney.objectId,
        value: attorney.objectId,
        text: attorney.name,
      }))
    }
    return []
  }

  buildTrafficStateListItems = () => Object.keys(STATES).map((key) => ({
    key: key,
    value: key,
    text: STATES[key],
  }))

  validatePrice = (price) => {
    if (!price) return "Price is required."
    if (!Number(price)) return "Price must be strictly a number."
    return ""
  }


  generateSubmissionLink = () => {
    const inputFieldError = this.validatePrice(this.state.price)

    if (inputFieldError) {
      this.setState({inputFieldError})
    } else {
      const formattedStateName = TRAFFIC_STATES_URL_SLUGS[this.state.trafficState]
      let generatedLink = `${process.env.REACT_APP_WINIT_WEBAPP_URL}/${formattedStateName}-traffic-tickets`

      let queryParams = {
        attorney: this.state.attorney,
        locationState: this.state.trafficState,
        trafficCounty: this.state.trafficCounty,
        trafficCourt: this.state.trafficCourt,
        winItPrice: this.state.price,
      }

      const oneStepHash = AES.encrypt(JSON.stringify(queryParams), process.env.REACT_APP_ENCRYPTION_KEY).toString()
      generatedLink += `?oneStepCheckout=${encodeURIComponent(oneStepHash)}`
      this.setState({ generatedLink, inputFieldError })
    }

  }

  clipboardSubmissionLink = () => {
    navigator.clipboard.writeText(this.state.generatedLink)
    this.setState({recentlyCopied:true})
    setTimeout(() => {
      this.setState({recentlyCopied:false})
    }, 2000)
  }

  handleInputChange = (event) => {
    this.setState({[event.target.name]: event.target.value.trim(), generatedLink: ""})
  }

  handleAttorneyChange = (event, { value }) => {
    this.setState({attorney: value, generatedLink: ""})
  }

  handleTrafficStateChange = (event, { value }) => {
    if (this.state.trafficState !== value) {
      this.setState({trafficState: value, trafficCounty: "", trafficCourt: "", generatedLink: ""})
      this.props.fetchCounties(value)
    }
  }

  handleTrafficCountyChange = (event, { value }) => {
    if (this.state.trafficCounty !== value) {
      this.setState({trafficCounty: value, generatedLink: ""})
      this.props.fetchCourts(value)
    }
  }

  handleTrafficCourtChange = (event, { value }) => {
    this.setState({trafficCourt: value, generatedLink: ""})
  }

  render() {
    const existingCounties = this.props.counties[this.state.trafficState] || []
    let trafficCountyOptions = [] 
    Object.keys(existingCounties).forEach((existingCounty) => {
      if (existingCounties[existingCounty]) {
        trafficCountyOptions.push({
          key: existingCounty,
          value: existingCounty,
          text: existingCounties[existingCounty].name,
        })
      }
    })

    const existingCourts = this.props.courts[this.state.trafficCounty] || []
    const trafficCourtOptions = []
    Object.keys(existingCourts).forEach((existingCourt) => {
      if (existingCourts[existingCourt]) {
        trafficCourtOptions.push({
          key: existingCourt,
          value: existingCourt,
          text: existingCourts[existingCourt].courtName,
        })
      }
    })

    const allowSubmit = (
      !!this.state.attorney &&
      !!this.state.trafficState &&
      !!this.state.trafficCounty &&
      !!this.state.trafficCourt &&
      !!this.state.price
    )

    const allowCopy = !!this.state.generatedLink

    return (
      <Fragment>
        <Grid centered={true}>
          <Grid.Column verticalAlign="middle" computer={10} >
            <Header style={{marginBottom: "25px", marginTop: "15px"}} >
              Submission Link Generator
            </Header>

            <Form size="large">
              <Dropdown style={{marginBottom:"20px"}}
                placeholder="Attorney"
                fluid
                search
                selection
                options={this.buildAttorneyListItems()}
                value={this.state.attorney}
                onChange={this.handleAttorneyChange}
              />

              <Dropdown style={{marginBottom:"20px"}}
                placeholder="State"
                fluid
                search
                selection
                options={this.buildTrafficStateListItems()}
                value={this.state.trafficState}
                onChange={this.handleTrafficStateChange}
              />

              <Dropdown style={{marginBottom:"20px"}}
                placeholder="County"
                noResultsMessage={
                  Object.keys(existingCounties).length && !trafficCountyOptions.length ? 
                  "No counties available." : 
                  "Select a state to see available counties."
                  }
                fluid
                search
                selection
                options={trafficCountyOptions}
                value={this.state.trafficCounty}
                onChange={this.handleTrafficCountyChange}
              />

              <Dropdown style={{marginBottom:"20px"}}
                placeholder="Court"
                noResultsMessage={
                  Object.keys(existingCourts).length && !trafficCourtOptions.length ? 
                  "No courts available" : 
                  "Select a state to see available counties."
                  }
                fluid
                search
                selection
                options={trafficCourtOptions}
                value={this.state.trafficCourt}
                onChange={this.handleTrafficCourtChange}
              />

              <Form.Input
                fluid
                name="price"
                placeholder="Price"
                onChange={this.handleInputChange}
                value={this.state.price}
              />

              <Form.Button
                disabled={!allowSubmit}
                style={{width:"150px", height: "45px"}}
                onClick={this.generateSubmissionLink}
              >
                Generate Link
              </Form.Button>

              <Form.Input
                fluid
                name="generatedLink"
                value={this.state.generatedLink}
              />

              <Form.Button
                style={{width:"150px", height: "45px"}}
                onClick={this.clipboardSubmissionLink}
                disabled={!allowCopy || this.state.recentlyCopied}
              >
                {this.state.recentlyCopied ? "Copied ✅" : "Copy Link"}
              </Form.Button>

              <div style={{color:"red", minHeight: "21px"}}>
                {this.state.inputFieldError}
              </div>

            </Form>
          </Grid.Column>
        </Grid>
      </Fragment>
    )
  }
}

export default LinkGeneratorComponent
