import React, { Component } from 'react'
import styled from 'styled-components'
import SendBirdProvider from "@sendbird/uikit-react/SendbirdProvider"
import withSendbird from "@sendbird/uikit-react/withSendbird"
import "sendbird-uikit/dist/index.css"
import CustomizedSB from "../CustomizedSB/CustomizedSB"
import sendbirdSelectors from "@sendbird/uikit-react/sendbirdSelectors"
import { getTicket } from "../../tickets/duck/services"
import { phoneNumbers } from '../../../utilities'

const SendBirdWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0px;
  width: 50%;
  right: 0px;
  height: calc(100% - 120px);
  @media (max-width: 768px) {
    width: 100vw;
  }
`

const SendBirdBackgroundMask = styled.div`
  position: fixed;
  background: rgb(0,0,0,0.35);
  z-index: 9;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`

const SendBirdMobileCloseButton = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 5px;
  right: 5px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
`

const InfoBox = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0px;
  right: 0px;
  width: 50%;
  height: 120px;
  border-top: solid 1px rgba(0, 0, 0, 0.38);
  display: flex;
  background: #FFF;
  padding: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 5px 0px;
`

const FieldTitle = styled.span`
  font-weight: bold;
`

class DisconnectorComponent extends React.Component {
  componentWillUnmount = () => {
    this.props.disconnect() // No need to await 
  }

  render() {
    return <></>
  }
}

const Disconnector = withSendbird(DisconnectorComponent, (state) => {
  const disconnect = sendbirdSelectors.getDisconnect(state)
  const connect = sendbirdSelectors.getConnect(state)
  return ({ connect, disconnect })
})

class ViewChatComponent extends Component {
  constructor(props){
    super(props)
    this.state = {ticket: null, message: "Loading..."}
  }

  handleKeyDown = (event) => {
    if (event.key === "Escape") {
      this.props.setChatWindowShowing(false)
    }
  }

  componentDidMount = async () => {
    window.addEventListener("keydown", this.handleKeyDown)

    // Get the ticket's objectId from the group-channel name:
    let str = this.props.currentChannelUrl
    let match = str.match(/_([^_]*)_/)
    let ticketObjectId
    if (match) {
      ticketObjectId = match[1]
    } else {
      // Unlikely to enter this block
      console.error('No ticket objectID match found in channel URL')
      this.setState({message: "Error"})
      return
    }
    
    const ticket = await getTicket(ticketObjectId)
    if (ticket && ticket.objectId === ticketObjectId) {
      this.setState({ticket})
    }
  }
  
  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleKeyDown)
  }

  render() {
    if (!this.props.currentChannelUrl || !this.props.userId) {
      return <></>
    }
    return (
      <>
        <SendBirdWrapper>
          <SendBirdProvider appId={process.env.REACT_APP_SENDBIRD_API_KEY} userId={this.props.userId}>
            <CustomizedSB 
              currentChannelUrl={this.props.currentChannelUrl} 
              setCurrentChannelUrl={this.props.setCurrentChannelUrl}
            />
            <Disconnector />
          </SendBirdProvider>
          <SendBirdMobileCloseButton onClick={() => {this.props.setChatWindowShowing(false)}}>
            X
          </SendBirdMobileCloseButton>
        </SendBirdWrapper>
        <InfoBox>
          {this.state.ticket?.objectId ? (
            <>
              <InfoColumn>
                <div><FieldTitle>Violation: </FieldTitle>{this.state.ticket.violationName}</div>
                <div><FieldTitle>Ticket ID: </FieldTitle>{this.state.ticket.ticketID}</div>
                <div><FieldTitle>Court: </FieldTitle>{this.state.ticket.locationCourt} ({this.state.ticket.locationState})</div>
                <div><FieldTitle>Points: </FieldTitle>{this.state.ticket.points}</div>
              </InfoColumn>
              <InfoColumn>
                <div><FieldTitle>Name: </FieldTitle>{this.state.ticket.user.name}</div>
                <div><FieldTitle>Email: </FieldTitle>{this.state.ticket.user.email}</div>
                <div><FieldTitle>Phone: </FieldTitle>{phoneNumbers.usFormat(this.state.ticket.user.phoneNumber)}</div>
              </InfoColumn>
            </>
          ) : (
            <>{this.state.message}</>
          )}
        </InfoBox>
        <SendBirdBackgroundMask onClick={() => {this.props.setChatWindowShowing(false)}}/>
      </>
    )
  }
}

export default ViewChatComponent
