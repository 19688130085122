import React from 'react'

export default function withDate(Component, dateType) {
  return class WithDate extends React.Component {
    state = { dateType:  dateType}

    handleChange = (date) => {
      this.props.handleChange(this.state.dateType, date)
    }

    render() {
      return (
        <Component 
          defaultValue={this.props.date}
          onChange={this.handleChange}
        />
      );
    }
  }
}