import React from 'react'
import { Loader } from 'semantic-ui-react'
import styled from 'styled-components'

const Text = styled.span`
  margin: 0 0 0 10px;
  opacity: 0.7;
  font-size: 1.1em;
  display: inline;
`

const LoadingAttorneys = () => {
  return (
    <span>
      <Loader size="tiny" active inline />
      <Text>Loading Attorneys</Text>
    </span>
  )
}

export default LoadingAttorneys
