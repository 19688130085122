import { connect } from 'react-redux'
import HomeComponent from './HomeComponent'
// import { homeOperations } from './duck';

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent)

export default HomeContainer
