import { ticketColumn, TICKET_COLUMNS } from './ticket'
import { clientColumn, CLIENT_COLUMNS } from './client'
import { attorneyColumn, ATTORNEY_COLUMNS } from './attorney'

const buildCallsColumns = (
  showAttorney = false,
  {
    history, // this.props.history
    baseUrl, // '/calls/view'
  }
) => {
  const ADMIN_OR_ATTORNEY_ID = showAttorney ? TICKET_COLUMNS.ID_ADMIN() : TICKET_COLUMNS.ID
  const config = []
  const ticketInfo = ticketColumn(
    TICKET_COLUMNS.CLICK_TO_VIEW(history, baseUrl),
    ADMIN_OR_ATTORNEY_ID,
    TICKET_COLUMNS.CALL_REQUEST_DATE,
    TICKET_COLUMNS.CALL_STATUS_EDITABLE,
    TICKET_COLUMNS.ATTORNEY_NOTES_EDITABLE,
    TICKET_COLUMNS.HEARING_DATE
  )
  config.push(ticketInfo)

  if (showAttorney) {
    const clientInfo = clientColumn(CLIENT_COLUMNS.NAME, CLIENT_COLUMNS.PHONE)
    const attorneyInfo = attorneyColumn(
      ATTORNEY_COLUMNS.NAME,
      ATTORNEY_COLUMNS.COMPANY_NAME
    )
    config.push(clientInfo, attorneyInfo)
  }

  return config
}

export default buildCallsColumns
