import React, { Component, Fragment } from 'react';
import { Header, Icon, Segment, Form, Button, Step } from 'semantic-ui-react'

class AttorneyInfoComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      companyName: ''
    }
  }
  render() {
    return (
      <Fragment>
        <Step.Group ordered fluid vertical size='small'>
          <Step completed>
            <Step.Content style={{ textAlign: 'left' }}>
              <Step.Title>Activation Code</Step.Title>
              <Step.Description>{this.props.code}</Step.Description>
            </Step.Content>
          </Step>
          <Step completed>
            <Step.Content style={{ textAlign: 'left' }}>
              <Step.Title>User Account</Step.Title>
              <Step.Description>{this.props.user ? this.props.user.email : ''}</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>

        <Header as="h4" textAlign="center" attached='top' inverted>
          Update Your Info
        </Header>
        <Segment attached>
          <Form size="large">
            <Form.Input
              fluid
              name="email"
              onChange={this.handleChange}
              icon="mail"
              iconPosition="left"
              label="Attorney Name"
              value={this.state.name}
            />
            <Form.Input
              fluid
              name="password"
              onChange={this.handleChange}
              icon="lock"
              iconPosition="left"
              label="Company Name"
              value={this.state.companyName}
            />
            <Button
              disabled={this.props.isLoading}
              fluid
              size="large"
              onClick={this.handleSubmit}
              labelPosition="right"
              icon
            >
              <Icon name="angle right" />
              Login
            </Button>
          </Form>
        </Segment>
      </Fragment>
    );
  }
}

export default AttorneyInfoComponent;
