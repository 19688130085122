import * as services from './services'
import chatsActions from "./actions"
import { overlaysActions } from '../../overlays/duck'
import { NOTIFICATION_TYPES } from "../../../constants"

const fetchChatsItems = (attorneyUser) => dispatch => {
  return services
    .getChatsItems(attorneyUser)
    .then(result => {
      dispatch(chatsActions.setChats(result))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: `Fetched Chats`,
        duration: 5,
      }))

      return result
    })
    .catch(error => {
      console.error('error fetching chats items', error)
    })
}

const setChatWindowShowing = (bool) => dispatch => {
  dispatch(chatsActions.setChatWindowShowing(bool))
}

const selectAttorneyFromChannelMembers = (channelMembers) => dispatch => {
  services
  .selectAttorneyFromChannelMembers(channelMembers)
  .then((objectId) => {
    dispatch(chatsActions.setChatAttorneyId(objectId))
  })
  .catch(error => {
    console.error('error selecting attorney from channel members', error)
  })
}

const setChatCurrentChannelUrl = (channelUrl) => dispatch => {
  dispatch(chatsActions.setChatCurrentChannelUrl(channelUrl))
}

const attorneyChatEnabledCheck = (attorney) => dispatch => {
  if (attorney.globalAdmin) return
  
  if (!attorney.chatEnabled) {
    dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.error,
      title: "New chats are currently disabled for this account.",
      message: "Existing chats will still appear on this page",
      duration: 15,
    }))
  }
}

const getSendbirdAttorneyUser = (attorney) => dispatch => {
  return services
    .getSendbirdAttorneyUser(attorney)
    .then((result) => {
      return result.data
    })
    .catch(error => {
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        title: "Error fetching Sendbird attorney user",
        duration: 15,
      }))
      console.error('error fetching sendbird attorney user.', error)
      return null
    })
}

const createChatUser = (ticket) => dispatch => {
  if (!ticket.attorney) {
    dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.error,
      title: "Attorney must be assigned before initiating chat.",
      duration: 15,
    }))
    return null
  }

  return services
    .createChatUser(ticket)
    .then((result) => {
      return result
    })
    .catch(error => {
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        title: "Error fetching Sendbird attorney user",
        duration: 15,
      }))
      console.error('error fetching sendbird attorney user.', error)
      return null
    })
}

const autoJoinSendbirdUserToGroupChannel = (ticket) => dispatch => {
  return services
    .autoJoinSendbirdUserToGroupChannel(ticket)
    .then((result) => {
      return result
    })
    .catch(error => {
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        title: "Error joining SB chat channel",
        duration: 15,
      }))
      console.error('error joining SB chat channel.', error)
      return null
    })
}

export default {
  fetchChatsItems,
  setChatWindowShowing,
  selectAttorneyFromChannelMembers,
  setChatCurrentChannelUrl,
  attorneyChatEnabledCheck,
  getSendbirdAttorneyUser,
  createChatUser,
  autoJoinSendbirdUserToGroupChannel,
}
