import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import {
  LEAD_STATUS_TEXT,
  // FRONTEND_TICKET_TYPES,
  LEAD_STATUS,
} from '../../../../constants'
import { StyledElements, Forms } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import ItemHeader from '../ItemHeader'

class EditLeadStatusInlineComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
  }
  toggleEdit = () => this.setState({ isEditing: !this.state.isEditing })

  buildOptionsArray = (arrayOfKeys, currentStatus) => {
    const result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: LEAD_STATUS_TEXT[key],
      }
    })
    if (currentStatus) {
      result.push({
        key: 'RESET LEAD STATUS',
        value: 'unset',
        text: 'RESET LEAD STATUS',
      })
    }
    return result
  }

  getPossibleStatuses = () => {
    const { leadStatus } = this.props
    const possibleKeys = Object.keys(LEAD_STATUS)
    return this.buildOptionsArray(possibleKeys, leadStatus)
  }

  handleChange = (event, data) => {
    const { objectId } = this.props
    if (data.value) {
      this.props.editUpdate(objectId, data.value)
    }
  }

  updateStatus = e => {
    if (e && e.preventDefault) e.preventDefault()
    const { objectId, editTicketField, leadStatus, EDIT_KEY } = this.props
    if (editTicketField !== leadStatus) {
      this.toggleEdit()
      this.props.update(objectId, { leadStatus: editTicketField })
      this.props.clearEditValue(objectId, EDIT_KEY)
    }
    return false
  }

  render() {
    const { objectId } = this.props
    const { isEditing } = this.state
    // if (!ticket.classifications.includes(FRONTEND_TICKET_TYPES.leads.active)) {
    //   return null
    // }
    const RenderOptions = this.renderOptions
    const RenderStatus = this.renderStatus
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Lead Status"
          isEditable
          isEditing={isEditing}
          toggleState={this.toggleEdit}
        />
        <SpecificOverlayContainer
          overlayKey={objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {isEditing ? <RenderOptions /> : <RenderStatus />}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }

  renderStatus = () => {
    const { leadStatus } = this.props
    if (leadStatus) {
      const text = LEAD_STATUS_TEXT[leadStatus]
      return text || leadStatus
    }
    return (
      <StyledElements.Italicize>No Lead Status Set</StyledElements.Italicize>
    )
  }

  renderOptions = () => {
    const { leadStatus, editTicketField } = this.props
    return (
      <StyledElements.EditContainer>
        <form>
          <Dropdown
            fluid
            selection
            options={this.getPossibleStatuses()}
            value={editTicketField !== null ? editTicketField : leadStatus}
            onChange={this.handleChange}
            defaultValue={leadStatus}
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                disabled: !editTicketField && editTicketField !== leadStatus,
                color: 'green',
                text: 'Update Lead Status',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updateStatus,
              },
            ]}
          />
        </form>
      </StyledElements.EditContainer>
    )
  }
}

export default EditLeadStatusInlineComponent
