import React, { Component } from 'react'

class GetTicketsComponent extends Component {
  render() {
    const { tickets } = this.props
    if (!tickets || tickets.length === 0) return <div />
    let toReturn = tickets.map((ticket) => {
      let href = `/new-window/${ticket.objectId}`
      let hearingDateDay = ''
      let hearingDateTime = ''
      if (ticket.hearingDate && ticket.hearingDate.iso) {
        let hearingDate = new Date(ticket.hearingDate.iso)
        hearingDateDay = hearingDate.toISOString().slice(0,10)
        hearingDateTime = hearingDate.toISOString().split('T')[1].slice(0,8)
      }
      return (
          <div key={ticket.objectId}>
            <span><b>Ticket ID:</b>
              <a href={href} target="_blank" rel="noopener noreferrer" key={ticket.objectId}>{ticket.ticketID}</a>
            </span>
            <br/>
            <span><b>Status:</b> {ticket.status}</span>
            <br/>
            <span><b>Violation Name:</b>{ticket.violationName}</span>
            <br/>
            <span><b>Hearing Date:</b>{hearingDateDay + ' ' + hearingDateTime}</span>
            <br/>
            <span><b>Points:</b>{ticket.points}</span>
            <br/>
            <span><b>Court:</b>{ticket.locationCourt}</span>
            <br/>
            <br/>
          </div>
      )
    })
    return (
      <div>
        <h2>Traffic Tickets</h2>
        <div>{toReturn}</div>
      </div>
    )
  }
}

export default GetTicketsComponent
