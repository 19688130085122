import { connect } from 'react-redux'

import { authenticationSelectors } from '../../../authentication/duck'
import { chatsSelectors, chatsThunks } from '../../../chats/duck'

import ViewChatActionComponent from './ViewChatActionComponent'

const mapStateToProps = (state, props) => ({
  isAdmin: authenticationSelectors.attorneyIsAdminSelector(state),
  attorney: authenticationSelectors.attorneySelector(state),
  chatWindowShowing: chatsSelectors.chatWindowShowing(state),
})

const mapDispatchToProps = dispatch => ({
  setChatWindowShowing: (bool) => dispatch(chatsThunks.setChatWindowShowing(bool)),
  createChatUser: (ticket) => dispatch(chatsThunks.createChatUser(ticket)),
  autoJoinSendbirdUserToGroupChannel: (ticket) => dispatch(chatsThunks.autoJoinSendbirdUserToGroupChannel(ticket)),
  setChatCurrentChannelUrl: (url) => dispatch(chatsThunks.setChatCurrentChannelUrl(url)),
})

const ViewMagicLinkCommunicationContainer = connect(mapStateToProps, mapDispatchToProps)(
  ViewChatActionComponent
)

export default ViewMagicLinkCommunicationContainer
