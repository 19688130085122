import React from 'react'
import moment from 'moment'
import { List } from 'semantic-ui-react'
import { DATE_FORMATS } from '../../../../constants'

const BondItemComponent = ({
  ticketID,
  createdAt,
  paymentStatus
}) => {
  const createdAtMoment = moment(createdAt).format(DATE_FORMATS.DATE_TIME)
  return (
    <List.Item>
      <List.Icon
        size="large"
        verticalAlign="middle"
      />
      <List.Content>
        <List.Description>
          <div>
            <em>TicketID: </em>
            {ticketID}
          </div>
          <div>
            <em>Created At: </em>
            {createdAtMoment}
          </div>
          <div>
            <em>Payment Status: </em>
            {paymentStatus}
          </div>
        </List.Description>
      </List.Content>
    </List.Item>
  )
}


export default BondItemComponent
