import React, { Component } from 'react'
import moment from 'moment'
import BaseDateInput from '../BaseDateInput'


class ReactTableDatePicker extends Component {
  constructor(props) {
    super(props)
    this.MAX_DATE = moment().add(40, "years").toDate()
    this.MIN_DATE = moment().subtract(40, "years").toDate()
  }

  handleDateChange = event => {
    if (!event) return this.props.onChange(event)
    const input = moment(event)
    if (
      input.isValid() &&
      input.isBetween(this.MIN_DATE, this.MAX_DATE)
    ) {
      this.props.onChange(input)
    }
  }

  render() {
    const { filter } = this.props
    return <BaseDateInput
      minDate={this.MIN_DATE}
      maxDate={this.MAX_DATE}
      value={filter && filter.value ? new Date(filter.value) : null}
      onChange={this.handleDateChange}
    />
  }
}

export default ReactTableDatePicker
