import { connect } from 'react-redux'
import { overlaysActions } from '../../../overlays/duck'
import { ticketsSelectors } from '../../duck'
import { ticketsThunks, ticketsActions } from '../../duck'
import { NOTIFICATION_TYPES } from '../../../../constants'
import EditDateTimeInlineComponent from './EditDateTimeInlineComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  editTicketField: ticketsSelectors.ticketEditFieldByIdSelector(state, props.objectId, props.dateKey)
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, key, value) => dispatch(ticketsActions.setEditValue(objectId, key, value)),
  clearEditValue: (objectId, key) => dispatch(ticketsActions.clearEditValue(objectId, key)),
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  error: (title, message) => dispatch(overlaysActions.addNotification({
    type: NOTIFICATION_TYPES.error,
    duration: 15,
    title,
    message
  }))
})

const EditDateTimeInlineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDateTimeInlineComponent)

export default EditDateTimeInlineContainer
