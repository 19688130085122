import React, { Component } from 'react';
// import PropTypes from 'prop-types'
import moment from 'moment'

import { DATE_FORMATS } from '../../../../constants'
import { StyledElements, Forms, BaseDateInput } from '../../../common'
import ItemHeader from '../ItemHeader'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';

class EditTicketDateComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      date: this.getDate(props)
    }
  }
  componentWillReceiveProps(nextProps) {
    const nextDate = this.getDate(nextProps)
    if (nextDate !== this.state.date) {
      this.setState({ date: nextDate })
    }
  }
  getDate = (props) => {
    if (props.ticket) {
      if (typeof props.ticket[props.dateKey] === "string") {
        return new Date(props.ticket[props.dateKey])
      }
      if (props.ticket[props.dateKey] && props.ticket[props.dateKey].iso) {
        const date = new Date(props.ticket[props.dateKey].iso)
        return date
      }
    }
    return null;
  }
  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = (event) => {
    if (event) {
      this.setState({ date: event })
    } else {
      this.setState({ date: null })
    }
  }
  updateDate = () => {
    this.setState({ isEditing: false })
    const existingDate = this.getDate(this.props)
    const newDate = new Date(this.state.date)
    if (!existingDate || newDate.getTime() !== existingDate.getTime()) {
      const updateObject = {}
      updateObject[this.props.dateKey] = newDate
      this.props.update(this.props.objectId, updateObject)
    }
  }
  renderEdit = () => {
    return <StyledElements.EditContainer>
      <BaseDateInput
        defaultValue={this.state.date}
        onChange={this.handleChange}
      />
      <Forms.InlineEditButtons buttons={[
        {
          icon: "save",
          labelPosition: "right",
          text: `Update ${this.props.title} Date`,
          color: "green",
          onClick: this.updateDate
        }
      ]} />
    </StyledElements.EditContainer>
  }
  render() {
    const currentDate = this.getDate(this.props)
    const includeTime = this.props.includeTime || false
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title={this.props.title}
          isEditable={this.props.isEditable}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {this.state.isEditing ?
            <RenderEdit />
            : currentDate ? moment(currentDate).format(includeTime ? DATE_FORMATS.FULL_DATE_TIME : DATE_FORMATS.FULL_DATE) : <StyledElements.Italicize>No {this.props.title} Set</StyledElements.Italicize>
          }
        </SpecificOverlayContainer>

      </StyledElements.DataContainer>
    );
  }
}

export default EditTicketDateComponent;
