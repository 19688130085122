import { connect } from 'react-redux'
import { courtsSelector } from '../duck/selectors'
import { courtsThunks } from '../duck'
import SelectCourtComponent from './SelectCourtComponent'

const mapStateToProps = (state, props) => ({
  courts: courtsSelector(state),
})

const mapDispatchToProps = dispatch => ({
  fetchCourts: (countyId) => dispatch(courtsThunks.fetchCourtsByCountyId(countyId)),
})

const SelectCourtContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCourtComponent)

export default SelectCourtContainer
