import React, { Component, Fragment } from 'react'
import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import RequestHistoryToolbar from './Toolbar'
import RequestList from './RequestList'
import { ScrollOverflowContent, StyledElements } from '../../common'

class RequestHistoryComponent extends Component {
  componentDidMount() {
    const { retrievedAt, loading, fetch, objectId } = this.props
    if (!retrievedAt && !loading) {
      return fetch(objectId)
    }
  }
  render() {
    const { retrievedAt, history, OVERLAY_KEY, fetch, objectId } = this.props
    return (
      <SpecificOverlay
        overlayKey={OVERLAY_KEY}
        centerFillStatus={true}
        ignoreError={true}
      >
        <Fragment>
          <RequestHistoryToolbar
            fetch={fetch.bind(null, objectId)}
            retrievedAt={retrievedAt}
            requestCount={history ? history.length : 0}
          />
          {history && history.length ? (
            <ScrollOverflowContent>
              <RequestList history={history} />
            </ScrollOverflowContent>
          ) : (
            <StyledElements.Italicize>
              No call history available.
            </StyledElements.Italicize>
          )}
        </Fragment>
      </SpecificOverlay>
    )
  }
}

export default RequestHistoryComponent
