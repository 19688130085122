import React, { Component } from 'react'
import { Grid, Modal, Header, Icon, Message, Button } from 'semantic-ui-react'

import { STATES } from '../../../constants'
import { StyledElements } from '../../common'
import SelectStateComponent from './select/SelectStateComponent'
import SelectCounty from './select/SelectCountyContainer'
import {
  LoadingComponent,
  UpdatingComponent,
} from '../../tickets/DetailSection/ticketOverlays'
import ItemHeader from '../../tickets/DetailSection/ItemHeader'
import SpecificOverlayContainer from '../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditCountyComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      step: props.ticket.trafficCounty ? 'county' : 'state',
      state: this.getState(props),
      county: props.ticket.trafficCounty,
    }
  }
  getState(source) {
    if (
      source.ticket &&
      source.ticket.trafficCounty &&
      source.ticket.trafficCounty.stateShortName
    ) {
      return source.ticket.trafficCounty.stateShortName
    }
    if (source.ticket && source.ticket.locationState) {
      return source.ticket.locationState
    }
    return undefined
  }
  componentWillReceiveProps(nextProps) {
    const newState = this.getState(nextProps)
    if (!this.state.state && newState) {
      this.setState({ state: newState })
    }
  }
  stateSelected = stateShortName => {
    this.setState({ state: stateShortName, step: 'county' })
  }
  countySelected = countyId => {
    this.setState({
      county: this.props.counties[this.state.state][countyId],
      step: 'confirm',
    })
  }
  toggleStatus = () => {
    if (this.state.isEditing) {
      this.resetState()
    }
    this.setState({ isEditing: !this.state.isEditing })
  }
  updateCounty = () => {
    this.props.update(this.props.objectId, {
      trafficCounty: this.state.county.objectId,
    })
    this.resetState()
  }
  resetState = () => {
    const originalState = this.getState(this.props)
    this.setState({
      isEditing: false,
      state: originalState,
      step: this.props.ticket.trafficCounty ? 'county' : 'state',
    })
  }
  renderStep = () => {
    switch (this.state.step) {
      case 'county':
        return (
          <StyledElements.EditContainer>
            <SelectCounty
              stateShortName={this.state.state}
              value={
                this.state.state === this.getState(this.props)
                  ? this.props.ticket.trafficCounty
                  : undefined
              }
              save={this.countySelected}
              previous={() => this.setState({ step: 'state' })}
            />
          </StyledElements.EditContainer>
        )
      case 'confirm':
        return (
          <Modal open={true} basic size="small">
            <Header icon="edit outline" content="Confirm County Change" />
            <Modal.Content>
              {this.props.ticket.trafficCourt &&
              this.props.ticket.trafficCourt.trafficCounty &&
              this.props.ticket.trafficCourt.trafficCounty.objectId !==
                this.state.county.objectId ? (
                <Message
                  icon="warning sign"
                  warning
                  header="Traffic Court will be unset."
                  list={[
                    `The currently assigned court : ${
                      this.props.ticket.trafficCourt.courtName
                    }, is outside the county you are selecting.`,
                    'This will unset the current court.',
                    'You can update to a different court after this county update.',
                  ]}
                />
              ) : (
                ''
              )}
              <Grid columns="equal">
                <Grid.Row>
                  {this.props.ticket.trafficCounty ? (
                    <Grid.Column>
                      <h5>Current State</h5>
                      {this.props.ticket.trafficCounty.stateShortName
                        ? STATES[this.props.ticket.trafficCounty.stateShortName]
                        : ''}
                    </Grid.Column>
                  ) : (
                    ''
                  )}
                  <Grid.Column>
                    <h5>New State</h5>
                    {STATES[this.state.state]}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {this.props.ticket.trafficCounty ? (
                    <Grid.Column>
                      <h5>Current County</h5>
                      {this.props.ticket.trafficCounty.name}
                    </Grid.Column>
                  ) : (
                    ''
                  )}
                  <Grid.Column>
                    <h5>New County</h5>
                    {this.state.county.name}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.resetState} basic color="red">
                <Icon name="remove" /> Cancel
              </Button>
              <Button onClick={this.updateCounty} color="green" inverted>
                <Icon name="checkmark" /> Confirm Changes
              </Button>
            </Modal.Actions>
          </Modal>
        )
      case 'state':
      default:
        return (
          <StyledElements.EditContainer>
            <SelectStateComponent
              value={this.state.state}
              next={this.stateSelected}
              cancel={this.toggleStatus}
            />
          </StyledElements.EditContainer>
        )
    }
  }
  render() {
    const RenderStep = this.renderStep
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="County"
          isEditable={true}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {this.state.isEditing ? (
            <RenderStep />
          ) : this.props.ticket.trafficCounty ? (
            `${this.props.ticket.trafficCounty.name}, ${
              this.props.ticket.trafficCounty.stateShortName
            }`
          ) : (
            <StyledElements.Italicize>No County Set</StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditCountyComponent
