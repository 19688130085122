import React, { Fragment, Component } from 'react'
// import UserMenu from './UserMenu'
import UserRoutes from './UserRoutes'

class UserHome extends Component {
  getUserPage = () => {
    if (this.props.location.pathname === '/users') return ''
    const secondary = this.props.location.pathname.replace('/users/', '')
    return secondary
  }
  render() {
    return (
      <Fragment>
        {/* <UserMenu active={this.getUserPage()} /> */}
        <UserRoutes />
      </Fragment>
    )
  }
}

export default UserHome
