import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { StyledElements } from '../../common'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1em;
  margin: 0 0.5em 0 0;
`

const ItemHeader = ({
  title,
  isEditable,
  isEditing,
  toggleState,
  expandText,
  collapseText,
}) => {
  return (
    <Row>
      <Title>{title}</Title>
      {isEditable ? (
        <Fragment>
          <StyledElements.FakeLink
            onClick={toggleState}
            italicize={isEditing}
            size="0.9em"
          >
            {isEditing ? collapseText : expandText}
          </StyledElements.FakeLink>
        </Fragment>
      ) : (
        <div />
      )}
    </Row>
  )
}

ItemHeader.defaultProps = {
  expandText: 'edit',
  collapseText: 'cancel changes',
  toggleState: () => {},
}

ItemHeader.propTypes = {
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
  title: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleState: PropTypes.func,
}

export default ItemHeader
