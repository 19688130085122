import { connect } from 'react-redux'
import connectFuncs from './connectFunctions'

import EditNameComponent from './EditNameComponent'

const EDIT_KEY = 'name'

const { mapStateToProps, mapDispatchToProps } = connectFuncs(EDIT_KEY)

const EditNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNameComponent)

export default EditNameContainer
