import React from 'react'
import { List } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import TrafficBondItem from './TrafficBondItem'

const TrafficBondList = ({ bonds }) => {
  return (
    <List divided relaxed>
      {bonds
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
        .map((item) => (
          <TrafficBondItem 
            key={item.objectId} 
            ticketID={item.ticket.ticketID} 
            paymentStatus={item.paymentStatus} 
            createdAt={item.createdAt}
          />
        ))}
    </List>
  )
}

TrafficBondList.defaultProps = {
  bonds: [],
}

TrafficBondList.propTypes = {
  bonds: PropTypes.array,
}

export default TrafficBondList
