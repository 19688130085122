import React, { Component, Fragment } from 'react';
import {Confirm, Dropdown, Header} from 'semantic-ui-react'

import ItemHeader from '../ItemHeader'
import { StyledElements, HelpIconTooltip, Forms } from '../../../common'
import {
  TICKET_STATUS_MAP,
  TICKET_STATUS_DESCRIPTION_MAP,
  TICKET_STATUS_ICON_MAP,
  POSSIBLE_STATUS_CHANGES, TICKET_STATUS, CONFLICTED_STATUS_CHANGES
} from '../../../../constants'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';
import {sendSlackMessage} from "../../../partially/duck/services";
import {sendSlackMessageForTicketStatusSwitchedBack} from "../../duck/services";

class EditStatusComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      showWarningModal: false,

    }
  }

  showWarningModal = () => this.setWarningModal(true)
  setWarningModal = showWarningModal => this.setState({ ...this.state, showWarningModal })
  handleCancel = () => this.setState({ ...this.state, showWarningModal: false })

  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  buildOptionsArray = (arrayOfKeys, currentStatus, locationState) => {
    let result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: TICKET_STATUS_MAP[key],
        content: <Header
          icon={TICKET_STATUS_ICON_MAP[key]}
          content={TICKET_STATUS_MAP[key]}
          subheader={TICKET_STATUS_DESCRIPTION_MAP[key]}
        />
      }
    })
    if (locationState !== "CA") {
      result = result.filter(status => status.key !== TICKET_STATUS.reduced_ca_traffic_school)
    }
    if (currentStatus) {
      result.push({
        key: currentStatus,
        value: currentStatus,
        text: TICKET_STATUS_MAP[currentStatus],
        content: <Header
          icon={TICKET_STATUS_ICON_MAP[currentStatus]}
          content={TICKET_STATUS_MAP[currentStatus]}
          subheader={TICKET_STATUS_DESCRIPTION_MAP[currentStatus]}
        />
      })
    }
    return result
  }

  getPossibleStatuses = () => {
    const { attorney } = this.props
    const { status, locationState } = this.props.ticket
    if (attorney.globalAdmin) {
      const allValues = Object.keys(TICKET_STATUS_MAP)
      return this.buildOptionsArray(allValues, undefined, locationState)
    }
    const possibleKeys = POSSIBLE_STATUS_CHANGES[status]
    if (!possibleKeys) return []
    const result = this.buildOptionsArray(possibleKeys, status, locationState)
    return result
  }

  handleChange = (event, data) => {
    const { objectId } = this.props
    if (data.value) {
      this.props.editUpdate(objectId, data.value)
    }
  }
  warningModal = () => {
    return (
        <Confirm
            open={this.state.showWarningModal}
            content={`Are you sure you want to change the status of this ticket?`}
            header="Please confirm"
            cancelButton="No"
            confirmButton="Yes"
            onCancel={this.handleCancel}
            onConfirm={this.updateStatus}
            size="mini"
        />
    )
  }


  updateStatus = (e) => {
    e.preventDefault()
    this.setState({ isEditing: false, showWarningModal: false})
    const { objectId, editTicketField, ticket, attorney } = this.props
    if (editTicketField !== ticket.status) {
      if (CONFLICTED_STATUS_CHANGES[ticket.status] && CONFLICTED_STATUS_CHANGES[ticket.status].includes(editTicketField)) {
        sendSlackMessageForTicketStatusSwitchedBack({
          attorney: attorney.name,
          userName: ticket.user.name,
          userEmail: ticket.user.email,
          ticketID: ticket.ticketID,
          prevStatus: ticket.status,
          newStatus: editTicketField
        })
      }
      this.props.update(objectId, {status: editTicketField})
    }
    return false
  }

  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    const { status } = ticket
    return <StyledElements.EditContainer>
      <form>
        <Dropdown
          fluid
          selection
          options={this.getPossibleStatuses()}
          value={editTicketField !== null ? editTicketField : status}
          onChange={this.handleChange}
        />
        <Forms.InlineEditButtons buttons={[
          {
            disabled: !editTicketField,
            color: "green",
            text: "Update Status",
            icon: "save",
            labelPosition: "right",
            onClick: this.showWarningModal,
            //type: "submit"
          }
        ]} />
      </form>
    </StyledElements.EditContainer>
  }

  render() {
    const { isEditing } = this.state
    const { objectId, ticket, attorney } = this.props
    const { status } = ticket
    const possibleChanges = POSSIBLE_STATUS_CHANGES[status] || attorney.globalAdmin
    const RenderEdit = this.renderEdit
    const RenderModal =  this.state.showWarningModal
        ? this.warningModal : () => null
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Status"
          isEditable={!!possibleChanges}
          isEditing={isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {
            isEditing ? <RenderEdit /> :
              status ?
                <Fragment>
                  <span>
                    {TICKET_STATUS_MAP[status]}
                    <HelpIconTooltip content={TICKET_STATUS_DESCRIPTION_MAP[status]} />
                  </span>
                </Fragment>
                :
                <StyledElements.Italicize>
                  No Status
                </StyledElements.Italicize>
          }
        </SpecificOverlayContainer>
        <RenderModal />
      </StyledElements.DataContainer>
    );
  }
}


export default EditStatusComponent;
