import React from 'react'
import { Button } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TopMargin = styled.div`
  margin-top: 3px;
`

const InlineTableEditButtons = ({ updateFunc, cancelFunc, updateText }) => {
  return (
    <TopMargin>
      <Button.Group size="mini" fluid>
        <Button type="submit" size="mini" color="green" onClick={updateFunc}>
          {updateText}
        </Button>
        <Button type="button" size="mini" color="red" onClick={cancelFunc}>
          Cancel
        </Button>
      </Button.Group>
    </TopMargin>
  )
}

InlineTableEditButtons.defaultProps = {
  updateText: 'Update',
}

InlineTableEditButtons.propTypes = {
  updateFunc: PropTypes.func.isRequired,
  cancelFunc: PropTypes.func.isRequired,
  updateText: PropTypes.string,
}

export default InlineTableEditButtons
