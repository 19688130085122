import React, { Component } from 'react'
import { Button, Checkbox } from 'semantic-ui-react'
import { CSVLink} from 'react-csv'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { BaseDateInput, StyledElements } from '../../../common'
import withDate from './withDate'
import PointsComponent from './PointsComponent'
import NameComponent from './NameComponent'
import exportHeaderOrderCSV from '../../../common/csv/exportHeaderOrderCSV'
import exportLookupHeaderOrderCSV from '../../../common/csv/exportLookupHeaderOrderCSV'


const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`

class ExportComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hearingStartDate: new Date(2020, 0, 1),
      hearingEndDate: new Date(),
      createdAtStartDate: new Date(2020, 0, 1),
      createdAtEndDate: new Date(),
      startPoints: 1,
      endPoints: 12,
      hearingDate: true,
      createdAtDate: true,
      points: true,
      loading: false,
      name: ''
    }
    this.exportRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.dataExport.length && this.props.dataExport.length) {
      this.exportRef.current.link.click()
      this.setState({
        loading: false
      })
    }
  }

  handleDateChange = (dateType, newDate) => {
    let dateToSet = newDate
    if (!dateToSet) {
      dateToSet = new Date()
    }
    this.setState({
      [dateType]: dateToSet
    })
  }

  handleIncludeField = (event, data) => {
    const curVal = this.state[data.name]
    this.setState({
      [data.name]: !curVal
    })
  }

  handlePointChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleNameChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleExport = async () => {
    await this.setState({
      loading: true
    })
    const queryObject = {}
    queryObject.exportType = this.props.name
    if (this.state.hearingDate) {
      queryObject.hearingStartDate = this.state.hearingStartDate || ""
      queryObject.hearingEndDate = this.state.hearingEndDate || ""
    }
    if (this.state.createdAtDate) {
      queryObject.createdAtStartDate = this.state.createdAtStartDate || ""
      queryObject.createdAtEndDate = this.state.createdAtEndDate || ""
    }
    if (this.state.points) {
      queryObject.startPoints = this.state.startPoints || ""
      queryObject.endPoints = this.state.endPoints || ""
    }
    if (this.state.name) {
      queryObject.name = this.state.name || ""
    }
    this.props.getExport(queryObject)
  }

  render() {
    const CreatedAtStartComponent = withDate(BaseDateInput, 'createdAtStartDate')
    const CreatedAtEndComponent = withDate(BaseDateInput, 'createdAtEndDate')
    const HearingStartComponent = withDate(BaseDateInput, 'hearingStartDate')
    const HearingEndComponent = withDate(BaseDateInput, 'hearingEndDate')
    const CSVHeaders = this.props.name === "Lookup_Tickets" ? exportLookupHeaderOrderCSV : exportHeaderOrderCSV
    return (
      <>
        <CSVLink headers={CSVHeaders} data={this.props.dataExport} ref={this.exportRef} filename={`${this.props.name}.csv`} />
        <div>
          <Checkbox
            name="points"
            checked={this.state.points}
            onChange={this.handleIncludeField}
            label='Include Point Filter'
          />
           <span style={{padding: '20px'}}/>
          <Checkbox
            name="hearingDate"
            checked={this.state.hearingDate}
            onChange={this.handleIncludeField}
            label='Include Hearing Date Filter'
          />
          <span style={{padding: '20px'}}/>
          <Checkbox
            name="createdAtDate" 
            checked={this.state.createdAtDate}
            onChange={this.handleIncludeField}
            label='Include Created At Date Filter' 
          />
        </div>

        <StyledElements.Spacer height="15px"/>

        <RowContainer>

          <InputContainer>
            <PointsComponent handleChange={this.handlePointChange} name="startPoints" points={this.state.startPoints} title="Points - Greater than or Equal to"/>
            <StyledElements.Spacer height="25px"/>
            <PointsComponent handleChange={this.handlePointChange} name="endPoints" points={this.state.endPoints} title="Points - Less than or Equal to"/>
          </InputContainer>


          <InputContainer>
            <span style={{padding: '6px'}}>From Hearing Date</span>
              <HearingStartComponent handleChange={this.handleDateChange} date={this.state.hearingStartDate}/>
            <span style={{padding: '6px'}}/>
            <span style={{padding: '6px'}}>To Hearing Date</span>
            <HearingEndComponent handleChange={this.handleDateChange} date={this.state.hearingEndDate}/>
          </InputContainer>


          <InputContainer>
            <span style={{padding: '6px'}}>From Created At Date</span>
              <CreatedAtStartComponent handleChange={this.handleDateChange} date={this.state.createdAtStartDate}/>
            <span style={{padding: '6px'}}/>
            <span style={{padding: '6px'}}>To Created At Date</span>
            <CreatedAtEndComponent handleChange={this.handleDateChange} date={this.state.createdAtEndDate}/>
          </InputContainer>


        </RowContainer>
        <RowContainer>

          <InputContainer>
            <StyledElements.Spacer height="25px"/>
            <NameComponent handleChange={this.handleNameChange} name="name" value={this.state.name} title="Agent"/>
            <StyledElements.Spacer height="25px"/>
          </InputContainer>

        </RowContainer>
        <br />

        <Button
          loading={this.state.loading}
          fluid
          onClick={this.handleExport}
        >
          {`Export ${this.props.name}`}
        </Button>
      </>
    )
  }
}

ExportComponent.propTypes = {
  name: PropTypes.string,
  dataExport: PropTypes.array,
  getExport: PropTypes.func
}

export default ExportComponent
