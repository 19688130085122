import React, { Component } from 'react'

import {
  LEAD_STATUS_TEXT,
  FRONTEND_TICKET_TYPES,
  LEAD_STATUS,
} from '../../../../constants'
import { StyledElements } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import InlineTableEditButtons from '../../../common/react-table/InlineTableEditButtons'

class EditLeadStatusInlineComponent extends Component {
  buildOptionsArray = (arrayOfKeys, currentStatus) => {
    const result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: LEAD_STATUS_TEXT[key],
      }
    })
    if (currentStatus) {
      result.push({
        key: 'RESET LEAD STATUS',
        value: 'unset',
        text: 'RESET LEAD STATUS',
      })
    } else {
      result.push({
        key: 'placeHolder',
        value: 'Select Status',
        text: 'Select Status',
      })
    }
    return result
  }

  getPossibleStatuses = () => {
    const { leadStatus } = this.props
    const possibleKeys = Object.keys(LEAD_STATUS)
    return this.buildOptionsArray(possibleKeys, leadStatus)
  }

  handleChange = event => {
    const { objectId } = this.props
    const { value } = event.target
    if (value && value !== 'Select Status') {
      this.props.editUpdate(objectId, value)
    }
  }

  handleCancel = event => {
    event.preventDefault()
    const { objectId } = this.props
    this.props.clearEditValue(objectId)
    return false
  }

  updateStatus = e => {
    if (e && e.preventDefault) e.preventDefault()
    const { objectId, editTicketField, leadStatus, EDIT_KEY } = this.props
    if (editTicketField !== leadStatus) {
      this.props.update(objectId, { leadStatus: editTicketField })
      this.props.clearEditValue(objectId, EDIT_KEY)
    }
    return false
  }

  render() {
    const { ticket, objectId, attorney } = this.props
    const isAdmin = attorney && attorney.globalAdmin
    const RenderOptions = this.renderOptions
    if (ticket.classifications.includes(FRONTEND_TICKET_TYPES.leads.closed)) {
      return (
        <StyledElements.Italicize>No longer a lead</StyledElements.Italicize>
      )
    }
    // if (
    //   ticket.classifications.includes(FRONTEND_TICKET_TYPES.leads.unassigned)
    // ) {
    //   return (
    //     <StyledElements.Italicize>Not assigned to you</StyledElements.Italicize>
    //   )
    // }
    if (ticket.classifications.includes(FRONTEND_TICKET_TYPES.leads.unassigned) && !isAdmin) {
      return (
        <StyledElements.Italicize>Not assigned to you</StyledElements.Italicize>
      )
    }
    return (
      <SpecificOverlayContainer
        overlayKey={objectId}
        ignoreError={true}
        LoadingComponent={() => (
          <StyledElements.Italicize>Loading...</StyledElements.Italicize>
        )}
        UpdatingComponent={() => (
          <StyledElements.Italicize>Updating...</StyledElements.Italicize>
        )}
      >
      <RenderOptions />
      </SpecificOverlayContainer>
    )
  }

  renderOptions = () => {
    const { leadStatus, editTicketField, ticket } = this.props
    return (
      <StyledElements.FillForm>
        <StyledElements.FillSelect
          onChange={this.handleChange}
          value={
            !editTicketField && !leadStatus
              ? 'Select Status'
              : editTicketField || leadStatus
          }
        >
          {this.getPossibleStatuses().map(statusOption => {
            return (
              <option key={statusOption.value} value={statusOption.value}>
                {statusOption.text}
              </option>
            )
          })}
        </StyledElements.FillSelect>
        {editTicketField && ticket.leadStatus !== editTicketField ? (
          <InlineTableEditButtons
            cancelFunc={this.handleCancel}
            updateFunc={this.updateStatus}
          />
        ) : null}
      </StyledElements.FillForm>
    )
  }
}

export default EditLeadStatusInlineComponent
