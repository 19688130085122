import React, { Component } from 'react'
import { TextArea } from 'semantic-ui-react'

import { StyledElements } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import InlineTableEditButtons from '../../../common/react-table/InlineTableEditButtons'

class EditAttorneyNotesInlineComponent extends Component {
  handleCancel = event => {
    if (event && event.preventDefault) event.preventDefault()
    const { objectId } = this.props
    this.props.clearEditValue(objectId)
    return false
  }

  handleChange = event => {
    if (event && event.preventDefault) event.preventDefault()
    const { objectId } = this.props
    const { value } = event.target
    if (value === '') {
      return this.props.editUpdate(objectId, 'unset')
    }
    if (value) {
      this.props.editUpdate(objectId, value)
    }
  }

  updateAttorneyNotes = () => {
    const { ticket, editTicketField, objectId, update } = this.props
    if (
      (editTicketField &&
        editTicketField === 'unset' &&
        ticket.attorneyNotes) ||
      (!ticket.attorneyNotes &&
        editTicketField &&
        editTicketField.trim().length) ||
      (ticket.attorneyNotes &&
        editTicketField &&
        ticket.attorneyNotes.trim() !== editTicketField.trim())
    ) {
      update(objectId, {
        attorneyNotes: editTicketField === 'unset' ? '' : editTicketField,
      })
    }
  }

  renderEdit = () => {
    const { ticket, editTicketField } = this.props
    const showSave =
      (ticket.attorneyNotes &&
        editTicketField &&
        editTicketField === 'unset') ||
      (ticket.attorneyNotes &&
        editTicketField &&
        editTicketField !== 'unset' &&
        ticket.attorneyNotes.trim() !== editTicketField.trim()) ||
      (!ticket.attorneyNotes && editTicketField && editTicketField !== 'unset')
    return (
      <StyledElements.FillForm>
        <TextArea
          // autoHeight
          onChange={this.handleChange}
          value={
            editTicketField
              ? editTicketField === 'unset'
                ? ''
                : editTicketField
              : ticket.attorneyNotes || ''
          }
          placeholder="Enter your notes for this case"
          rows={!ticket.attorneyNotes ? 1 : 3}
        />
        {showSave ? (
          <InlineTableEditButtons
            cancelFunc={this.handleCancel}
            updateFunc={this.updateAttorneyNotes}
          />
        ) : null}
      </StyledElements.FillForm>
    )
  }
  render() {
    const RenderEdit = this.renderEdit
    return (
      <SpecificOverlayContainer
        overlayKey={this.props.objectId}
        LoadingComponent={() => (
          <StyledElements.Italicize>Loading...</StyledElements.Italicize>
        )}
        ignoreError={true}
        UpdatingComponent={() => (
          <StyledElements.Italicize>Updating...</StyledElements.Italicize>
        )}
      >
        <RenderEdit />
      </SpecificOverlayContainer>
    )
  }
}

export default EditAttorneyNotesInlineComponent
