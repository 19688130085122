import { ticketColumn, TICKET_COLUMNS } from './ticket'
import { clientColumn, CLIENT_COLUMNS } from './client'
import { userColumn, USER_COLUMNS } from './user'
import { attorneyColumn, ATTORNEY_COLUMNS } from './attorney'

export const buildTicketSearchColumns = (showAttorney = false, results) => {
  const ADMIN_OR_ATTORNEY_ID = showAttorney ? TICKET_COLUMNS.ID_ADMIN() : TICKET_COLUMNS.ID
  const config = []
  const ticketInfo = ticketColumn(
    ADMIN_OR_ATTORNEY_ID,
    TICKET_COLUMNS.STATUS_FILTERABLE(results),
    TICKET_COLUMNS.VIOLATION_NAME,
    TICKET_COLUMNS.APPROVAL_DATE,
    TICKET_COLUMNS.HEARING_DATE,
    TICKET_COLUMNS.ASSIGNED,
    TICKET_COLUMNS.LEAD_STATUS
  )
  config.push(ticketInfo)
  const clientInfo = clientColumn(CLIENT_COLUMNS.NAME, CLIENT_COLUMNS.PHONE)
  config.push(clientInfo)
  if (showAttorney) {
    const attorneyInfo = attorneyColumn(
      ATTORNEY_COLUMNS.NAME,
      ATTORNEY_COLUMNS.COMPANY_NAME
    )
    config.push(attorneyInfo)
  }
  return config
}

export const buildUserSearchColumns = results => {
  const config = []
  const userInfo = userColumn(
    USER_COLUMNS.NAME,
    USER_COLUMNS.EMAIL,
    USER_COLUMNS.PHONE_NUMBER,
    USER_COLUMNS.LICENSE_ID,
    USER_COLUMNS.CITY
  )
  config.push(userInfo)
  return config
}

const licenseId = buildUserSearchColumns
const phoneNumber = buildUserSearchColumns
const ticketId = buildTicketSearchColumns
const email = buildUserSearchColumns

const buildSearchTable = (showAttorney, results, searchType) => {
  const buildFuncs = { licenseId, ticketId, phoneNumber, email }
  return buildFuncs[searchType](showAttorney, results)
}
export default buildSearchTable
