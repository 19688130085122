import React, { Component, Fragment } from 'react'
import { List, Label } from 'semantic-ui-react'
// import moment from 'moment'
// import { StyledElements } from '../../common'

class AssociatedUsersComponent extends Component {
  componentDidMount() {
    if (!this.props.associatedUsers.length) {
      this.props.fetchAssociatedUsers()
    }
  }
  render() {
    return (
      <Fragment>
        <List divided relaxed="very">
          {this.props.associatedUsers.length
            ? this.props.associatedUsers.map((join, index) => {
                if (!join.user) return <div key={index + 'userJoin'} />
                return (
                  <List.Item key={join.user.email}>
                    <List.Content>
                      <List.Header>
                        {join.user.name}{' '}
                        {join.user.objectId === this.props.user.objectId ? (
                          <Label color="blue" size="tiny" pointing="left">
                            ME
                          </Label>
                        ) : (
                          ''
                        )}
                      </List.Header>
                      <List.Description>
                        {this.props.attorney &&
                        this.props.attorney.globalAdmin ? (
                          <Label size="tiny">{join.user.objectId}</Label>
                        ) : null}{' '}
                        {join.user.email}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                )
              })
            : ''}
        </List>
      </Fragment>
    )
  }
}

export default AssociatedUsersComponent
