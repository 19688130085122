import React, { useState, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Grid, Header } from 'semantic-ui-react'
import { updateIsAllowedCC } from '../../partially/duck/services'

const AllowCCComponent = ({ error, success }) => {
  const [agentObjectId, setAgentObjectId] = useState('')
  let [isAllowed, setIsAllowed] = useState('')
  const [disabled, setDisabled] = useState(false)

  const history = useHistory()

  const handleObjectIdChange = ev => {
      setAgentObjectId(ev.target.value)
  }

  const handleIsAllowed = (ev) => {
    setIsAllowed(ev.target.value)
  }

  const handleSubmit = ev => {
    ev.preventDefault()

    updateIsAllowedCC(agentObjectId, {
      allowCC: JSON.parse(isAllowed.toLowerCase())
    }).then(res => {
      if (res.status === 200) {
        if (res.attorneyUser.allowCC === JSON.parse(isAllowed.toLowerCase())) {
          success(`Allow CC Status has been updated to ${JSON.parse(isAllowed.toLowerCase())}`)
          setDisabled(true)
          setTimeout(() => history.push('/leads/active'), 2500)
          setTimeout(() => history.go(0), 3500)
        }
      } else if (res.status === 400) {
        error(`Failed to update status to ${JSON.parse(isAllowed.toLowerCase())}`, res.error)
      }
    })
  }

  return (
    <Fragment>
      <Grid centered={true}>
        <Grid.Column verticalAlign="middle" computer={10}>
          <Header style={{ marginBottom: '25px', marginTop: '15px' }}>
            Allow Credit Card Use for Partial.ly
          </Header>

          <Form size="large" onSubmit={handleSubmit}>
            <Form.Input
              fluid
              name="agentObjectId"
              icon="user"
              iconPosition="left"
              placeholder="Agent ObjectId"
              disabled={disabled}
              onChange={handleObjectIdChange}
              value={agentObjectId}
            />

            <Form.Input
              fluid
              name="setAllowCC"
              icon="ticket"
              iconPosition="left"
              placeholder="True or False"
              disabled={disabled}
              onChange={handleIsAllowed}
              value={isAllowed}
            />

            <Form.Button
              style={{ width: '100%', height: '45px' }}
              type="submit"
              disabled={!agentObjectId || agentObjectId.length !== 10 || isAllowed.length < 4 || isAllowed.length > 5 || disabled}
            >
              Submit
            </Form.Button>
          </Form>
        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

export default AllowCCComponent