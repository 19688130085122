import { connect } from "react-redux"
import AttorneyAgreementsComponent from "./AttorneyAgreementsComponent"

const mapStateToProps = (state, props) => ({
  })
  
  const mapDispatchToProps = dispatch => ({
  })
  
  const AttorneyAgreementsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
  )(AttorneyAgreementsComponent)
  
  export default AttorneyAgreementsContainer