import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FooterComponent from './FooterComponent'
import { authenticationThunks } from '../authentication/duck'
import { overlaysActions } from '../overlays/duck'
import { MODAL_TYPES } from '../../constants'
import {
  userSelector,
  attorneySelector
} from '../authentication/duck/selectors'

const mapStateToProps = state => ({
  user: userSelector(state),
  attorney: attorneySelector(state)
})

const mapDispatchToProps = dispatch => ({
  showIssueModal: () => dispatch(overlaysActions.setModal(MODAL_TYPES.issue)),
  showLoader: () => dispatch(overlaysActions.showLoader()),
  logout: () => dispatch(authenticationThunks.logout())
})

const FooterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterComponent)

export default withRouter(FooterContainer)
