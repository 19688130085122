import React from 'react'
import { ticketColumn, TICKET_COLUMNS } from './ticket'
import{ LOOKUP_COLUMNS } from './lookup'
import { usFormat } from '../../../../utilities/phoneNumbers'
import { Loader, Button, Icon } from 'semantic-ui-react'
import CreateNewUser from './CreateNewUser'

const ViewUserButton = (props) => {
  const { data, history } = props
  const userOnClick = () => history.push(`/users/view/${data.user.objectId}`)
  return <Button color="blue" size="mini" onClick={userOnClick} style={{marginLeft: '10px'}}>
    <Button.Content visible>View user</Button.Content>
  </Button>
}

const UserNotFound = (props) => {
  const { contactInfo, history } = props
  return (
    <div>
    No matching user found <Icon name="cancel" />
      <CreateNewUser contactInfo={contactInfo} history={history} />
    </div>
)
}

const NameLine = (props) => <div className='contactInfoData'><Icon name='user'/> <b>Full name</b>: {props.data.fullName}</div>
const EmailLine = (props) => <div className='contactInfoData'><Icon name='mail'/> <b>Email</b>: {props.data.email}</div>
const PhoneLine = (props) => <div className='contactInfoData'><Icon name='phone'/> <b>Phone number</b>: {usFormat(props.data.phoneNumber)}</div>

const LookupTicketsHeader = (props) => {
  const { data, history } = props
  return <>
    <div>Contact Information</div>
    <NameLine data={data}/>
    <EmailLine data={data}/>
    <PhoneLine data={data}/>
    <div>
      {/*data.user === undefined means it's still searching for a user*/}
      {data.user === undefined && <div><Loader size='tiny' inline active /> Searching for a matching user </div>}

      {/* when a user was found */}
      {
        (data.user && data.user.objectId && data.user.foundWith) &&
        <div className="contactInfoData">
          Found a user with matching { data.user.foundWith === 'email' ? 'email' : 'phone number'} (User ObjectID: { data.user.objectId })<ViewUserButton history={history} data={data} />
        </div>
      }

      {/* when a user was not found (but the search finished)*/}
      {(data.user && Object.keys(data.user).length === 0) && <UserNotFound contactInfo={data} history={history}/>}
    </div>
  </>
}

const buildLeadsColumns = (
  showAttorney = false,
  {
    history, // this.props.history
    baseUrl, // '/hearings/view'
  },
  data
) => {
  const config = []
  const ticketInfo = ticketColumn(
    // TICKET_COLUMNS.CLICK_TO_VIEW(history, baseUrl),
    TICKET_COLUMNS.ID,
    TICKET_COLUMNS.POINTS,
    TICKET_COLUMNS.ISSUE_DATE,
    TICKET_COLUMNS.HEARING_DATE,
    TICKET_COLUMNS.VIOLATION_NAME,
    TICKET_COLUMNS.TICKET_COURT_SCREENSHOT,
    TICKET_COLUMNS.CHARGES,
    TICKET_COLUMNS.LOCATION_COURT,
    TICKET_COLUMNS.CREATED,
    LOOKUP_COLUMNS.QUOTES(data, history),
    TICKET_COLUMNS.MODIFIED,
    LOOKUP_COLUMNS.GENDER,
    LOOKUP_COLUMNS.DATE_OF_BIRTH,
    LOOKUP_COLUMNS.LICENSE_ID,
    LOOKUP_COLUMNS.LAST_4,
    {
      Header: 'Phone Number',
      width: 200,
      Cell: () => {
        return (
          <div>
            {data.phoneNumber}
          </div>
        )
      }
    },
  )
  ticketInfo.Header = <LookupTicketsHeader history={history} data={data}/>
  config.push(ticketInfo)
  return config
}

export default buildLeadsColumns
