import React, { Fragment, Component } from 'react'
import SearchRoutes from './SearchRoutes'

class SearchHome extends Component {
  render() {
    return (
      <Fragment>
        <SearchRoutes />
      </Fragment>
    )
  }
}

export default SearchHome
