const SET_STEP = 'SET_ACTIVATION_STEP'
const SET_CODE = 'SET_ACTIVATION_CODE';
const SET_USER = 'SET_ACTIVATION_USER';
const CLEAR_USER = 'CLEAR_ACTIVATION_USER';
const SET_ATTORNEY = 'SET_ACTIVATION_ATTORNEY';
const CLEAR_ATTORNEY = 'CLEAR_ACTIVATION_ATTORNEY';

export default {
  SET_STEP,
  SET_CODE,
  SET_USER,
  CLEAR_USER,
  SET_ATTORNEY,
  CLEAR_ATTORNEY,
}
