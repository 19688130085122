import types from './types.js'

const SetAttorneyStats = value => ({
  type: types.SET_ATTORNEY_STATS,
  value,
})

const setAssociatedUsers = value => ({
  type: types.SET_ASSOCIATED_USERS,
  value,
})

export default {
  SetAttorneyStats,
  setAssociatedUsers
}
