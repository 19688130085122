import React, { Component } from 'react'
import CreateTrafficTicket from './CreateTrafficTicket'

class GetDOTTicketsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tickets: [],
    }
  }
  render() {
    const { tickets } = this.props
    if (!tickets || tickets.length === 0) return <div />
    for (let ticket of tickets) {
      this.state.tickets.push({
        ticketObjectId: ticket.objectId,
      })
    }
    let toReturn = tickets.map(ticket => {
      let quotes = ticket.quotes
      let quotesData = []
      // If an error occurred, quotes will be an error object
      if (quotes instanceof Array) {
        quotes = quotes.filter(Boolean) // Remove empty quotes
        quotesData = quotes.map(quote => {
          return {
            text: `${quote.companyName}: $${quote.pricing.winItPrice}`,
            key: quote.objectId,
            value: quote.objectId,
          }
        })
      }
      let href = `/new-window/${ticket.objectId}`
      let hearingDateDay = ''
      let hearingDateTime = ''
      if (ticket.hearingDate && ticket.hearingDate.iso) {
        let hearingDate = new Date(ticket.hearingDate.iso)
        hearingDateDay = hearingDate.toISOString().slice(0, 10)
        hearingDateTime = hearingDate
          .toISOString()
          .split('T')[1]
          .slice(0, 8)
      }
      return (
        <div key={ticket.objectId}>
          <span>
            <b>Ticket ID:</b>
            <a href={href} target="_blank" rel="noopener noreferrer"key={ticket.objectId}>
              {ticket.ticketID}
            </a>
          </span>
          <br />
          <span>
            <b>Violation Name:</b>
            {ticket.violationName}
          </span>
          <br />
          <span>
            <b>Hearing Date:</b>
            {hearingDateDay + ' ' + hearingDateTime}
          </span>
          <br />
          <span>
            <b>Points:</b>
            {ticket.points}
          </span>
          <br />
          <span>
            <b>Court:</b>
            {ticket.locationCourt}
          </span>
          <br />
          <span>
            <b>Quotes:</b>
            <CreateTrafficTicket
              options={quotesData}
              DOTTicket={ticket}
              history={this.props.history}
              ticketType="DOTTicket"
            />
          </span>
          <br />
        </div>
      )
    })
    return (
      <div>
        <h2>DOT Tickets</h2>
        <div>{toReturn}</div>
      </div>
    )
  }
}

export default GetDOTTicketsComponent
