import * as services from "./services"
import { courtsActions } from "./index"
import { overlaysActions } from "../../overlays/duck"
import { NOTIFICATION_TYPES } from "../../../constants"

const fetchCourtsByCountyId = (countyId) => (dispatch) => {
  dispatch(overlaysActions.showLoader())
  services.getCourtsByCountyId(countyId).then(result => {
    dispatch(courtsActions.addManyCourts(countyId, result))
    dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.success, title: `Fetched Courts for County ID #${countyId}`, duration: 5 }))
  }).catch(error => {
    console.error('error fetching courts by county id', error)
    dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.error, duration: 15, title: 'Issue Fetching Courts by County ID', message: error }))
  })
}

export default {
  fetchCourtsByCountyId
}
