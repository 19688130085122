import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import ChatsList from './ChatsList/ChatsListContainer'
import NotFoundPage from '../404NotFound'

class ChatsRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/chats/" component={ChatsList} />
        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    )
  }
}

export default ChatsRoutes
