import React, { Component } from 'react'
import { Input } from 'semantic-ui-react'

import InlineTableEditButtons from '../../../common/react-table/InlineTableEditButtons'
import Error from '../../../Error'
import { StyledElements } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditTicketPointsReducedInlineComponent extends Component {
  getValue = () => {
    const { ticket } = this.props
    if (ticket.reducedPoints) return ticket.reducedPoints
    return ''
  }
  getCurrentEditValue = () => {
    const { editTicketField } = this.props
    if (editTicketField) return editTicketField
    return ''
  }
  handleChange = event => {
    const { objectId } = this.props
    if (event.target.value === '')
      return this.props.editUpdate(objectId, 'reducedPoints', '')
    const reducedPoints = parseInt(event.target.value)
    if (reducedPoints || reducedPoints === 0) {
      this.props.editUpdate(objectId, 'reducedPoints', reducedPoints)
    }
  }

  handleCancel = event => {
    if (event) event.preventDefault()
    const { objectId } = this.props
    this.props.clearEditValue(objectId, 'reducedPoints')
    return false
  }

  updatePoints = event => {
    if (event) event.preventDefault()
    const { objectId, editTicketField, ticket } = this.props
    const value = parseInt(editTicketField, 10)
    if (
      editTicketField === '' ||
      (ticket.reducedPoints !== value && Number.isInteger(value))
    ) {
      this.props.clearEditValue(objectId, 'reducedPoints')
      this.props.update(objectId, {
        reducedPoints: editTicketField === '' ? 'unset' : value,
      })
    }
  }

  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    const value =
      editTicketField === null || editTicketField === undefined
        ? !ticket.reducedPoints && ticket.reducedPoints !== 0
          ? ''
          : ticket.reducedPoints
        : editTicketField
    return (
      <StyledElements.FillForm>
        <Input
          onChange={this.handleChange}
          value={value}
          placeholder="New Point Amount"
          fluid
        />
        {((editTicketField ||
          editTicketField === 0 ||
          editTicketField === '') &&
          ticket.reducedPoints !== null &&
          ticket.reducedPoints >= 0) ||
        ((editTicketField || editTicketField === 0 || editTicketField === '') &&
          editTicketField !== ticket.reducedPoints) ? (
          <InlineTableEditButtons
            cancelFunc={this.handleCancel}
            updateFunc={this.updatePoints}
          />
        ) : null}
      </StyledElements.FillForm>
    )
  }
  render() {
    const { objectId } = this.props
    const RenderEdit = this.renderEdit
    return (
      <Error.Boundary>
        <SpecificOverlayContainer
          overlayKey={objectId}
          LoadingComponent={() => (
            <StyledElements.Italicize>Loading...</StyledElements.Italicize>
          )}
          ignoreError={() => (
            <StyledElements.Italicize>
              Issue Updating Ticket...
            </StyledElements.Italicize>
          )}
          UpdatingComponent={() => (
            <StyledElements.Italicize>Updating...</StyledElements.Italicize>
          )}
        >
          <RenderEdit />
        </SpecificOverlayContainer>
      </Error.Boundary>
    )
  }
}

export default EditTicketPointsReducedInlineComponent
