import { connect } from 'react-redux'
import { regionsSelectors } from '../../regions/duck'
import { overlaySelectors, overlaysActions } from '../../overlays/duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import {
  processingThunks,
  processingActions,
  processingSelectors,
} from '../duck'
import { authenticationSelectors } from '../../authentication/duck'
import { FRONTEND_TICKET_TYPES, NOTIFICATION_TYPES } from '../../../constants'
import ProcessingListComponent from './RecentlyApprovedProcessingListComponent'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.processing.recentlyApproved

const mapStateToProps = state => {
  const currentCategory = processingSelectors.ids(TICKET_TYPE, state)
  return {
    pageType: TICKET_TYPE,
    currentCategory,
    regionFilter: processingSelectors.processingRegionFilterSelector(state),
    attorney: authenticationSelectors.attorneySelector(state),
    loading: overlaySelectors.specificOverlayLoadingStatusSelector(
      state,
      TICKET_TYPE + '/' + currentCategory
    ),
    processing: regionsSelectors.regionsFilterBuilder(
      processingSelectors.recentlyApprovedSelector,
      processingSelectors.processingRegionFilterSelector
    )(state),
    retrievedAt: processingSelectors.retrievedAt(state, currentCategory),
    allProcessing: state.processing,
    rtSettings: reactTableSelectors.settings(TICKET_TYPE, state),
  }
}

const mapDispatchToProps = dispatch => ({
  fetchProcessingItems: dateString =>
    dispatch(processingThunks.fetchRecentlyApprovedItems(dateString)),
  setRegionFilter: region =>
    dispatch(processingActions.setProcessingRegionFilter(region)),
  clearRegionFilter: () =>
    dispatch(processingActions.setProcessingRegionFilter(null)),
  setRecentlyApprovedDate: category =>
    dispatch(processingActions.setRecentlyApprovedCategory(category)),
  error: (title, message) =>
    dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title,
        message,
      })
    ),
  setResized: value =>
    dispatch(reactTableActions.setResized(TICKET_TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TICKET_TYPE, value)),
  setFiltered: value =>
    dispatch(reactTableActions.setFiltered(TICKET_TYPE, value)),
})

const ProcessingListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessingListComponent)

export default ProcessingListContainer
