import * as services from "./services"
import { countiesActions } from "./index"
import { overlaysActions } from "../../overlays/duck"
import { NOTIFICATION_TYPES } from "../../../constants"

const fetchCountiesByStateShortName = (stateShortName) => (dispatch) => {
  dispatch(overlaysActions.showLoader())
  services.getCountiesByStateShortName(stateShortName).then(result => {
    dispatch(countiesActions.addManyCounties(stateShortName, result))
    dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.success, title: `Fetched Counties for ${stateShortName}`, duration: 5 }))
  }).catch(error => {
    console.error('error fetching counties by state short name', error)
    dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.error, duration: 15, title: 'Issue Fetching Courts by County ID', message: error }))
  })
}

export default {
  fetchCountiesByStateShortName
}
