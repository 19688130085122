import React, { Component } from 'react'
import { Input, Form, Dropdown, Grid } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import { STATES } from '../../../../constants'
import {
  LoadingComponent,
  UpdatingComponent,
} from '../../../tickets/DetailSection/ticketOverlays'
import ItemHeader from '../../../tickets/DetailSection/ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditLicenseComponent extends Component {
  constructor(props) {
    super(props)
    this.idInput = React.createRef()
  }
  componentDidUpdate(prevProps) {
    const wasPreviouslyEditing = prevProps.isEditing
    const { isEditing } = this.props
    if (isEditing && !wasPreviouslyEditing) {
      this.idInput.current.focus()
    }
  }
  handleIDChange = event => {
    const { objectId, editLicenseIdUpdate } = this.props
    editLicenseIdUpdate(objectId, event.target.value)
  }
  handleStateChange = (event, data) => {
    const { objectId, editLicenseStateUpdate } = this.props
    if (data.value) {
      editLicenseStateUpdate(objectId, data.value)
    }
  }
  toggleEditStatus = () => {
    const {
      isEditing,
      setIsEditing,
      objectId,
      user,
      editLicenseIdUpdate,
      editLicenseStateUpdate,
    } = this.props
    if (!isEditing) {
      editLicenseIdUpdate(objectId, user.licenseID)
      editLicenseStateUpdate(objectId, user.licenseState)
    }
    setIsEditing(objectId, !isEditing)
  }
  updateLicense = event => {
    if (event && event.preventDefault) event.preventDefault()
    const {
      objectId,
      update,
      editLicenseId,
      editLicenseState,
      setIsEditing,
      clearEdit,
      user,
      error,
    } = this.props

    if (!editLicenseId && !user.licenseID) {
      return error(
        'License ID Required',
        'In order to make a change, you must set a license ID'
      )
    }
    if (!editLicenseState && !user.licenseState) {
      return error(
        'License State Required',
        'In order to make a change, you must set a license state'
      )
    }

    const updateObject = {}
    if (editLicenseId) {
      updateObject.licenseID = editLicenseId
    }
    if (editLicenseState) {
      updateObject.licenseState = editLicenseState
    }
    update(objectId, updateObject)
    setIsEditing(objectId, false)
    clearEdit(objectId)
  }
  renderEdit = () => {
    const { user, editLicenseId, editLicenseState } = this.props
    const IDValue = editLicenseId === null ? user.licenseID : editLicenseId
    const stateValue =
      editLicenseState === null ? user.licenseState : editLicenseState
    return (
      <StyledElements.EditContainer>
        <Form>
          <Grid stackable stretched columns={2}>
            <Grid.Column
              computer={10}
              tablet={8}
              mobile={16}
              verticalAlign={'top'}
            >
              <Input
                fluid
                ref={this.idInput}
                onChange={this.handleIDChange}
                value={IDValue}
                placeholder="A012345678901"
              />
            </Grid.Column>
            <Grid.Column
              computer={6}
              tablet={8}
              mobile={16}
              verticalAlign={'top'}
            >
              <Dropdown
                fluid
                selection
                options={Object.keys(STATES).map(shortName => ({
                  key: shortName,
                  value: shortName,
                  text: STATES[shortName],
                }))}
                value={stateValue}
                onChange={this.handleStateChange}
              />
            </Grid.Column>
          </Grid>

          <Forms.InlineEditButtons
            buttons={[
              {
                color: 'green',
                disabled:
                  (!editLicenseId && !user.licenseID) ||
                  (!editLicenseState && !user.licenseState),
                text: 'Update Drivers License Info',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updateLicense,
              },
            ]}
          />
        </Form>
      </StyledElements.EditContainer>
    )
  }
  render() {
    const { isEditing, user, overlayKey } = this.props
    const validState = !!user.licenseState && !!STATES[user.licenseState]
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Driver's License"
          isEditable={true}
          isEditing={!!isEditing}
          toggleState={this.toggleEditStatus}
        />
        <SpecificOverlayContainer
          overlayKey={overlayKey}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {isEditing ? (
            <RenderEdit />
          ) : user.licenseID ? (
            `${user.licenseID} (${
              validState ? (
                STATES[user.licenseState]
              ) : (
                "Missing State or invalid state"
              )
            })`
          ) : (
            <StyledElements.Italicize>No License ID</StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditLicenseComponent
