import React, { Component, Fragment } from 'react';
import Select from 'react-select'

import { objectTools } from '../../../utilities'
import { STATES } from '../../../constants'
import { customStyles } from '../../common/react-select/styles'
import { Forms } from '../../common'

class SelectCourtComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: undefined
    }
    if (props.value) {
      this.state.selected = { label: props.value.courtName, value: props.value.objectId }
    }
  }
  componentDidMount() {
    if (!this.props.courts[this.props.county.objectId]) {
      this.props.fetchCourts(this.props.county.objectId)
    }
  }
  updateValue = (value) => {
    this.setState({ selected: value })
  }
  proceed = () => {
    if (this.state.selected.value) {
      this.props.save(this.state.selected.value)
    }
  }
  render() {
    const courtOptions = objectTools.objectToArray(this.props.courts[this.props.county.objectId])
      .map(county => ({
        value: county.objectId,
        label: county.courtName
      })).sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      })
    return (
      <Fragment>
        <p>Select a Court in {this.props.county.name} County ({STATES[this.props.county.stateShortName]}):</p>
        <Select
          placeholder="Choose a Court"
          styles={customStyles}
          isMulti={false}
          onChange={this.updateValue}
          value={this.state.selected}
          options={courtOptions}
        />
        <Forms.InlineEditButtons
          buttons={[
            {
              labelPosition: "right",
              onClick: this.proceed,
              color: "green",
              icon: "save",
              text: "Updated Court"
            }
          ]}
        />
      </Fragment>
    );
  }
}

export default SelectCourtComponent;
