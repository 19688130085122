import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { openPaymentPlan } from "../duck/services"
import styled from "styled-components"
import SharedCloseBtn from "../shared/SharedCloseBtn"
import SharedSubmitBtn from "../shared/SharedSubmitBtn"
import { Fragment } from "react"

const PlanActions = ({
  details,
  schedule,
  allowCC,
  paymentMethods,
  setShowPlanModal,
  setShowDetailedPlan,
  setShowActionBtns,
  success,
  error 
}) => {
  const [signature, setSignature] = useState('')
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  
  const history = useHistory()
  
  useEffect(() => {
    if (!allowCC) {
      if (paymentMethods.length >= 1) {
        for (let i = 0; i < paymentMethods.length; i++) {
          if (paymentMethods[i].type === 'bank_account') {
            setPaymentMethodId(paymentMethods[i].id)
            break
          } else if (i === paymentMethods.length - 1) {
            setErrorMsg('⚠️ User has no bank account linked to his account. Will not work without an ACH account, please contact the user. ⚠️')
          }
        }
      } else if (paymentMethods.length === 0) {
        setErrorMsg('⚠️ Please Be Advised: User has no payment method linked to his account. ⚠️')
      }
    } else if (allowCC) {
      if (paymentMethods.length === 0) {
        setErrorMsg('⚠️ Please Be Advised: User has no payment method linked to his account. ⚠️')
      }
    }
  }, [paymentMethods])

  const selectValueHandler = (ev) => {
    if (allowCC) {
      if (paymentMethods.length >= 1) {
        setPaymentMethodId(ev.target.value)
      }
    }
  }

  const openPlanHandler = (ev) => {
    ev.preventDefault()
    
    openPaymentPlan(details.id, {
      contract_signature: signature,
      id: paymentMethodId    
    }).then(res => {
      if (res.status === 200) {
        setShowActionBtns(false)
        setShowPlanModal(false)
        setShowDetailedPlan(false)
        success('Plan opened!', res.message)
        setTimeout(() => history.go(0), 3000)
      } else if (res.status === 400) {
        setShowActionBtns(false)
        setShowPlanModal(false)
        setShowDetailedPlan(false)
        error('Failed to open plan', res.error)
      }
    })
  }

  const payMethodElement = allowCC && (details.status === 'pending' || details.status === 'canceled') &&
    <PaymentMethodDiv>
      <select name="payment_type" onChange={selectValueHandler} required>
        <option>Select Payment Method</option>
        {paymentMethods.map(paymentMethod => {
          return (
            <Fragment key={paymentMethod.id}>
              <option value={paymentMethod.id}>{paymentMethod.type.toUpperCase()} {paymentMethod.integration_details.last4}</option>
            </Fragment>
          )
        })}
      </select>
  </PaymentMethodDiv>
  
  const contractBody = schedule.contract_body !== null
    ? <p style={{ whiteSpace: 'pre-line', fontSize: '15px' }}>{schedule.contract_body}</p>
    : <h3 style={{ textAlign: 'center' }}>No contract returned from Partial.ly</h3>
  
  return (
    <Wrapper>
      {errorMsg && <WarningDiv><p>{errorMsg}</p></WarningDiv>}
      <div style={{ marginBottom: '30px' }}>{contractBody}</div>
      <form onSubmit={openPlanHandler}>
        <label htmlFor="signature">Signature</label>
        <FormDiv>
          <input type="text" className="signature" onChange={(ev) => setSignature(ev.target.value)} />
          {payMethodElement}
          <SharedCloseBtn type="button" onClick={() => setShowActionBtns(false)} name='Cancel' />
          <SharedSubmitBtn className='submit' type='submit' value='Confirm' disabled={signature.length < 2 || paymentMethods.length === 0} />
        </FormDiv>
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  form {    
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: baseline;

    label {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 20px;
    }

    input {
        &.signature {
          outline: none;
          border: 1px solid black;
          height: 35px;
          font-family: cursive;
          width: 150px;
          font-size: 16px;
          padding: 0 10px;
          border-radius: 10px;
          margin-right: 10px;
          background-color: #d5d5d5;;
        }
    }
  }
`

const WarningDiv = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
  padding: 20px 10px;
  background-color: #ffdede;
  border-radius: 10px;
  margin-bottom: 30px;

  p {
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #9f0000;
  }
`

const FormDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.actions {
    justify-content: flex-end;
    align-items: center;
  }
`

const PaymentMethodDiv = styled.div`
  margin: 5px;

  select {
    padding: 5px;
    border-radius: 10px;
  }
`

export default PlanActions