import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Segment, Dropdown } from 'semantic-ui-react'
import ReactTable from 'react-table'

import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import {
  ListToolbarComponent,
  buildPDF,
  StyledElements,
  RTTools,
} from '../../common'
import '../../common/react-table/style.css'
import Error from '../../Error'

class CallsListComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
    }
  }
  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }
  componentDidMount() {
    if (!this.props.retrievedAt && !this.props.loading) {
      this.props.fetchCallsItems()
    }
  }
  downloadPDF = () => {
    buildPDF(this.props.calls, 'Calls')
  }

  render() {
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        centerFillStatus={true}
        overlayKey={this.props.pageType}
        errorText={`Issue fetching`}
        spinnerSize={'massive'}
      >
        <RenderList />
      </SpecificOverlay>
    )
  }

  renderList = () => {
    if (!this.props.calls.length) {
      return (
        <StyledElements.Centered>
          <Helmet title={`No Calls`} />
          <h3>{`No Calls`}</h3>
          <ListToolbarComponent
            update={this.props.fetchCallsItems}
            isLoading={this.props.isLoading}
            refreshedAt={this.props.retrievedAt}
          />
        </StyledElements.Centered>
      )
    }
    const toolbarItems = [
      {
        icon: 'call',
        text: this.props.retrievedAt ? `${this.props.calls.length} Calls` : '',
      },
    ]
    return (
      <Fragment>
        <Helmet title="Calls" />
        <ListToolbarComponent
          items={toolbarItems}
          update={this.props.fetchCallsItems}
          isLoading={this.props.isLoading}
          refreshedAt={this.props.retrievedAt}
          download={this.downloadPDF}
        />
        <Segment>
          <Error.Boundary>
            <ReactTable
              data={this.props.calls}
              noDataText={`No Call Requests Found`}
              columns={RTTools.columns.buildCalls(
                this.props.attorney && this.props.attorney.globalAdmin,
                {
                  history: this.props.history,
                  baseUrl: '/calls/view',
                }
              )}
              pageSize={this.state.pageSize}
              showPageSizeOptions={false}
              minRows={RTTools.preferences.minRows}
              className={RTTools.preferences.RowClassName}
              sorted={this.props.rtSettings.sort}
              filtered={this.props.rtSettings.filter}
              resized={this.props.rtSettings.size}
              onSortedChange={(newSorted, column, shiftKey) =>
                this.props.setSorted(newSorted)
              }
              onFilteredChange={(filtered, column) =>
                this.props.setFiltered(filtered)
              }
              onResizedChange={(newResized, event) =>
                this.props.setResized(newResized)
              }
            />
          </Error.Boundary>
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default CallsListComponent
