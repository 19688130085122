import types from './types.js';

const setStep = (value) => ({
  type: types.SET_STEP,
  value
})

const setCode = (value) => ({
  type: types.SET_CODE,
  value
})

const setUser = (value) => ({
  type: types.SET_USER,
  value: value
})

const clearUser = () => ({
  type: types.CLEAR_USER
})

const setAttorney = (value) => ({
  type: types.SET_ATTORNEY,
  value: value
})

const clearAttorney = () => ({
  type: types.CLEAR_ATTORNEY
})

export default {
  setStep,
  setCode,
  setUser,
  clearUser,
  setAttorney,
  clearAttorney,
}
