import React from "react"
import styled from "styled-components"
import SharedActionWords from "../shared/SharedActionWords"

const Disputes = ({ disputes }) => {
  if (disputes.length === 0) return <SharedActionWords name='disputes' />

  return (
    <DetailsWrap>
      <PlanDetails>
        <p>Date {disputes.map(dispute => {
          const date = new Date(dispute.inserted_at)
          const options = { month: 'long', day: 'numeric', year: 'numeric' }
          let formattedDate = date.toLocaleDateString('en-us', options)
          return <span>{formattedDate}</span>
        })}
        </p>
        <p>Amount {disputes.map(dispute => <span>{dispute.amount}</span>)}</p>
        <p>Status {disputes.map(dispute => <span>{dispute.status}</span>)}</p>
        <p>Reason {disputes.map(dispute => <span>{dispute.reason}</span>)}</p>
      </PlanDetails>
    </DetailsWrap>
  )
}

const DetailsWrap = styled.div`
  display: flex;
  justify-content: space-evenly;

  span {
    font-weight: 400;
  }

  .status {
    font-weight: 600;
  }

  p {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
  }
`

const PlanDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
  border: 2px solid lightgray;
  width: 60%;
  border-radius: 5px;

  p {
    display: flex;
    flex-direction: column;
  }

  span {
    padding: 4px 0;
  }
`

export default Disputes