import types from './types'

const addProcessing = (processingType, value) => ({
  type: types.ADD_PROCESSING,
  processingType,
  value,
})

const setProcessingRegionFilter = value => ({
  type: types.SET_PROCESSING_REGION_FILTER,
  value,
})

const setRecentlyApprovedCategory = value => ({
  type: types.SET_RECENTLY_APPROVED_CATEGORY,
  value,
})

const setProcessing = (processingType, value) => ({
  type: types.SET_PROCESSING,
  processingType,
  value,
})

const setProcessingRetrievedAt = processingType => ({
  type: types.SET_PROCESSING_RETRIEVED_AT,
  processingType,
})

const setSuspendedCases = (processingType, value) => ({
  type: types.SET_SUSPENDED_CASES,
  processingType,
  value
})

const setRequiresApprovalCases = (processingType, value) => ({
  type: types.SET_REQUIRES_APPROVAL_CASES,
  processingType,
  value
})

const setCurrentlyViewing = value => ({
  type: types.SET_CURRENTLY_VIEWING,
  value,
})

export default {
  addProcessing,
  setCurrentlyViewing,
  setProcessing,
  setProcessingRetrievedAt,
  setProcessingRegionFilter,
  setRecentlyApprovedCategory,
  setSuspendedCases,
  setRequiresApprovalCases,
}
