import React, { useState, useRef } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { Form, Input } from 'semantic-ui-react'
import styled from 'styled-components'
import { SEARCH_TYPE } from './SearchTypes'

const EmailSearchComponent = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const inputRef = useRef(null)
  const history = useHistory()

  if (inputRef && inputRef.current && inputRef.current.focus) {
    inputRef.current.focus()
  }

  const onChangeHandler = ev => {
    setEmail(ev.target.value)
  }

  const onSubmitHandler = ev => {
    if (ev && ev.preventDefault) ev.preventDefault()

    if (email.length < 5 || !email.includes('@')) {
      return setError(
        'Email must be minimum 5 chars and include the "@" symbol.'
      )
    } else {
      setError('')
    }

    return history.push(
      `/search/results?searchType=${SEARCH_TYPE.email}&email=${email}`
    )
  }

  return (
    <Form onSubmit={onSubmitHandler}>
      {error && (
        <Message>
          <p>{error}</p>
        </Message>
      )}
      <Form.Group grouped>
        <Input
          name="email"
          onChange={onChangeHandler}
          size="huge"
          fluid
          placeholder="enter@email.com"
          value={email}
          ref={inputRef}
        />
      </Form.Group>
      <Form.Group>
        <Form.Button size="huge" content="Search" onClick={onSubmitHandler} />
      </Form.Group>
    </Form>
  )
}

const Message = styled.div`
  display: block;
  margin-top: 0;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
  background-color: #fff6f6;
  min-height: 1em;
  margin: 1em 0;
  background: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  border-radius: 0.28571429rem;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue', 'Icons16',
    sans-serif;
  
  p {
    opacity: .85;
    line-height: 1.4285em;
    color: #9f3a38;
    font-size: 1em;
  }
`

export default withRouter(EmailSearchComponent)
