import axios from 'axios'
import { API_URL } from '../constants'
import { axiosErrorResponse, localStorageUtils, delayedResponse } from './index'
import moment from 'moment'

const tokenCheck = () => {
  const target = localStorageUtils.get('sessionTokenDate')
  if (!target) {
    localStorageUtils.remove('sessionToken')
  }
  const tokenDate = moment(new Date(target))

  const now = moment()
  if (tokenDate.add(3, 'days').isAfter(now)) {
    localStorageUtils.remove('sessionToken')
    localStorageUtils.remove('sessionTokenDate')
  }
}

const generalConfig = {
  baseURL: API_URL,
}

const basicInstance = axios.create({ ...generalConfig })

const basicPost = (route, data) => {
  const func = basicInstance.post.bind(null, route, data)
  return delayedResponse(func, 1200)
}

export const basic = {
  post: basicPost,
}

const secureInstanceGenerator = token =>
  axios.create({
    ...generalConfig,
    headers: { Authorization: token },
  })

export const get = route => {
  tokenCheck()
  const token = localStorageUtils.get('sessionToken')
  if (token) {
    return secureInstanceGenerator(token)
      .get(route)
      .catch(error => {
        return Promise.reject(axiosErrorResponse(error))
      })
  }
  return Promise.reject('No Token Supplied')
}
export const post = (route, data) => {
  tokenCheck()
  const token = localStorageUtils.get('sessionToken')
  if (token) {
    return secureInstanceGenerator(token)
      .post(route, data)
      .catch(error => {
        return Promise.reject(axiosErrorResponse(error))
      })
  }
  return Promise.reject('No Token Supplied')
}
export const put = (route, data) => {
  tokenCheck()
  const token = localStorageUtils.get('sessionToken')
  if (token) {
    return secureInstanceGenerator(token)
      .put(route, data)
      .catch(error => {
        return Promise.reject(axiosErrorResponse(error))
      })
  }
  return Promise.reject('No Token Supplied')
}

const delayed = {
  get: (route, minimumTime = 1200) => {
    const func = get.bind(null, route)
    return delayedResponse(func, minimumTime)
  },
  post: (route, data, minimumTime = 1200) => {
    const func = post.bind(null, route, data)
    return delayedResponse(func, minimumTime)
  },
  put: (route, data, minimumTime = 1200) => {
    const func = put.bind(null, route, data)
    return delayedResponse(func, minimumTime)
  },
}

export const secure = {
  get,
  post,
  put,
  delayed,
}
