import SEARCH_DESCRIBER from '../SearchTypes'
import { searchActions } from './index'
import { overlaysActions } from '../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../constants'

export const fetchSearchResults = searchCriteria => dispatch => {
  const searchType = SEARCH_DESCRIBER[searchCriteria.searchType]
  if (!searchType) {
    return dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 20,
        title: 'Invalid search type provided.',
        message: 'Please use the search form and try again.',
      })
    )
  }
  dispatch(searchActions.setSearchLoading(true))
  const searchObject = { ...searchCriteria }
  delete searchObject.searchType

  return searchType
    .service(searchObject)
    .then(results => {
      // console.log('results', results)
      dispatch(searchActions.setSearchError(''))
      dispatch(searchActions.setSearchLoading(false))
      dispatch(searchActions.setSearchResults(results))
    })
    .catch(error => {
      // console.log('error', error)
      dispatch(searchActions.setSearchError(error))
      dispatch(searchActions.setSearchLoading(false))
      dispatch(searchActions.setSearchResults([]))
    })
}
