import React from 'react'
// import moment from 'moment'
// import { Button } from 'semantic-ui-react'

// import { search, dateFilter } from '../../../../utilities'
import { StyledElements } from '../..'
// import * as utils from '../utilities'
// import { FakeLink } from '../../StyledElements'

export const USER_COLUMNS = {
  NAME: {
    Header: 'Name',
    accessor: 'name',
    filterable: false,
    sortable: false,
    width: 150,
    Cell: ({ value }) =>
      value || <StyledElements.Italicize>No Name Set</StyledElements.Italicize>,
  },
  EMAIL: {
    Header: 'Email',
    accessor: 'email',
    filterable: false,
    sortable: false,
    width: 150,
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>No Email Set</StyledElements.Italicize>
      ),
  },
  PHONE_NUMBER: {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    filterable: false,
    sortable: false,
    width: 130,
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>No Phone Number Set</StyledElements.Italicize>
      ),
  },
  LICENSE_ID: {
    Header: 'License ID',
    accessor: 'licenseID',
    filterable: false,
    sortable: false,
    width: 130,
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>No License ID Set</StyledElements.Italicize>
      ),
  },
  CITY: {
    Header: 'City',
    accessor: 'city',
    filterable: false,
    sortable: false,
    width: 150,
    Cell: ({ value }) =>
      value || <StyledElements.Italicize>No City Set</StyledElements.Italicize>,
  },
}

export const userColumn = (...userColumns) => {
  return {
    Header: 'User Information',
    columns: [...userColumns],
  }
}
