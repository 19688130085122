import React from 'react'
import PropTypes from 'prop-types'
import PricePreview from './PricePreview'
import { List } from 'semantic-ui-react'

const PricePreviewListComponent = ({ pricePreview }) => {
  return (
    <List divided relaxed>
      {pricePreview.map(item => (
        <PricePreview key={item.objectId} {...item} />
      ))}
    </List>
  )
}

PricePreviewListComponent.propTypes = {
  pricePreview: PropTypes.array.isRequired,
}

export default PricePreviewListComponent
