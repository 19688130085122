import React from 'react'
import { Placeholder, Grid } from 'semantic-ui-react'

const ViewTicketLoadingComponent = () => {
  return (
    <div>
      <h2 style={{ color: 'lightgray' }}>Loading Ticket</h2>
      <Grid stackable stretched columns={4}>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
      </Grid>
      <br />
      <br />
      <br />
      <Grid stackable stretched columns={4}>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={4} tablet={8}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default ViewTicketLoadingComponent
