import React, { Component } from 'react';
import { Input, Form } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';

class EditCaseProtectionFee extends Component {
  constructor(props) {
    super(props)
    this.DEFAULT_START_FEE = ""
    this.state = {
      isEditing: false,
      caseProtectionFee: props.ticket.caseProtectionFee || this.DEFAULT_START_FEE
    }
    this.caseProtectionFeeInput = React.createRef();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.caseProtectionFeeInput.inputRef.focus()
    }
  }
  toggleStatus = () => {
    if (this.state.isEditing) {
      this.setState({
        caseProtectionFee: this.props.ticket.caseProtectionFee || this.DEFAULT_START_FEE
      })
    }
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = (event) => {
    // allow to set empty value
    if (event.target.value === "") {
      return this.setState({ caseProtectionFee: "" })
    }
    // allow entering number followed by a decimal point
    if (
      event.target.value.indexOf(".") === (event.target.value.length - 1) &&
      event.target.value.slice(-1) === "." &&
      Number(event.target.value.slice(0, -1))
    ) {
      return this.setState({ caseProtectionFee: event.target.value })
    }
    // normal conditions
    const caseProtectionFee = Number(event.target.value)
    if (caseProtectionFee) {
      this.setState({ caseProtectionFee })
    }
  }
  updateCaseProtectionFee = () => {
    if (this.state.caseProtectionFee <= 0) {
      return this.props.error('case protection fee must be greater than $1')
    }
    this.setState({ isEditing: false })
    if (this.state.caseProtectionFee !== this.props.ticket.caseProtectionFee) {
      this.props.update(this.props.objectId, { caseProtectionFee: this.state.caseProtectionFee })
    }
  }
  renderEdit = () => {
    return <StyledElements.EditContainer>
      <Form>

        <Input
          ref={(input) => { this.caseProtectionFeeInput = input; }}
          onChange={this.handleChange}
          value={this.state.caseProtectionFee}
          placeholder="Case Protection Fee"
          labelPosition="left"
          label="$"
          fluid
        />

        <Forms.InlineEditButtons buttons={[
          {
            disabled: this.state.caseProtectionFee <= 0,
            color: "green",
            text: "Update Case Protection Fee",
            icon: "save",
            labelPosition: "right",
            onClick: this.updateCaseProtectionFee,
            type: "submit"
          }
        ]} />
      </Form>
    </StyledElements.EditContainer>
  }
  render() {
    if (!this.props.isAdmin) {return <div />}
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="Case Protection Fee"
          isEditable={true}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {this.state.isEditing ?
            <RenderEdit />
            :
            this.props.ticket.caseProtectionFee ?
              `$${this.props.ticket.caseProtectionFee.toFixed(2)}`
              :
              <StyledElements.Italicize>
                No Case Protection Fee Set
            </StyledElements.Italicize>
          }
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    );
  }
}

export default EditCaseProtectionFee;
