import { axios } from '../../../utilities'

export const getClientToken = userObjectId => {
  return axios.secure
    .get(`/payments/token/${userObjectId}`)
    .then(res => res.data)
}

export const addPaymentMethod = (userObjectId, paymentMethodNonce) => {
  const payload = { userObjectId, paymentMethodNonce }
  payload.makeDefault = true
  return axios.secure
    .post('/payments/paymentMethods', payload)
    .then(res => res.data)
}
