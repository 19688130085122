import React from 'react'
import { Placeholder } from 'semantic-ui-react'
import { StyledElements } from '../../common'

export const LoadingComponent = () => {
  return (
    <Placeholder>
      <Placeholder.Line length={randomLength()} />
    </Placeholder>
  )
}

export const UpdatingLargeComponent = () => {
  return (
    <Placeholder>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  )
}

export const UpdatingComponent = () => {
  return (
    <Placeholder>
      <Placeholder.Line length={randomLength()} />
    </Placeholder>
  )
}

export const ErrorComponent = () => {
  return (
    <StyledElements.Italicize>Error fetching data</StyledElements.Italicize>
  )
}

const randomLength = () => {
  const arr = ['very short', 'short', 'medium', 'long']
  const random = Math.floor(Math.random() * arr.length)
  return arr[random]
}
