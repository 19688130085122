import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { Modal, Icon, Popup } from 'semantic-ui-react'

import { createPaymentPlan } from '../duck/services'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import SharedHeader from '../shared/SharedHeader'
import SharedCloseBtn from '../shared/SharedCloseBtn'
import SharedSubmitBtn from '../shared/SharedSubmitBtn'
import EditTicketPrice from '../paymentPlans/EditTicketPrice'
import DownPaymentException from '../paymentPlans/DownPaymentException'

const CreateNewPlan = ({ user, attorney, customerAccount, userBlocked, paymentPlans, update, error, success }) => {
  const [showNewPlanModal, setShowNewPlanModal] = useState(false)
  const [showPriceModal, setShowPriceModal] = useState(false)
  const [showDPModal, setShowDPModal] = useState(false)

  let [ticketArray, setTicketArray] = useState([])
  let [amount, setAmount] = useState(0)
  let [checkoutFee, setCheckoutFee] = useState(0)
  const [downPayment, setDownPayment] = useState(0)
  const [downPaymentType, setDownPaymentType] = useState("fixed")
  const [frequency, setFrequency] = useState(30)
  const [frequencyUnits, setFrequencyUnits] = useState("days")
  const [term, setTerm] = useState(12)
  const [termDate, setTermDate] = useState("")
  const [termUnits, setTermUnits] = useState("weeks")

  const history = useHistory()
  const tickets = user.tickets

  useEffect(() => {
    const dates = []
    tickets.forEach((ticket) => {
      if (ticket.hearingDate && ticketArray.includes(ticket.ticketID)) {
        dates.push(ticket.hearingDate.iso.substring(0, 10))
        const earliestDate = dates.sort()[0]
        setTermDate(earliestDate)
      }
    })
    setDownPayment(20 * ticketArray.length)
  }, [ticketArray])

  const onSubmitHandler = ev => {
    ev.preventDefault()

    createPaymentPlan({
      amount: amount + Number(checkoutFee),
      customer_id: customerAccount.id,
      status: "pending",
      send_plan_request: "true",
      description: ticketArray.join(" - "),
      down_payment: downPayment,
      down_payment_type: downPaymentType,
      frequency,
      frequency_units: frequencyUnits,
      term,
      term_date: termDate,
      term_units: termUnits
    }).then(res => {
      if (res.status === 201 && res.plan.message) {
        setShowNewPlanModal(false)
        success(res.plan.message)
      } else if (res.status === 201) {
        setShowNewPlanModal(false)
        success(res.message)
        setTimeout(() => history.go(0), 3000)
      } else if (res.status === 400) {
        setShowNewPlanModal(false)
        error(res.error)
      }
    })
  }

  const addTicketsHandler = (ticket) => {
    if (!ticketArray.includes(ticket.ticketID) ) {
      setTicketArray(current => [...current, ticket.ticketID])
      setAmount(total => total + ticket.winItPrice)
    }
  }
  const removeTicketsHandler = (ticket) => {
    if (ticketArray.includes(ticket.ticketID)) {
      setTicketArray(current => current.filter(item => item !== ticket.ticketID))
      setAmount(total => total - ticket.winItPrice)
    }
  }

  if (!customerAccount || !paymentPlans) return <p></p>

  const planDescription = paymentPlans.map(plan => {
    if (plan.payment_schedule.description) {
      return plan.payment_schedule.description
    }
    if (plan.meta.description) {
      return plan.meta.description
    }
  })

  let foundMatchingTix = false
  const renderTickets = tickets.map(ticket => {
    const findMatchingTickets = planDescription.find(matchingId => {
      if (matchingId && matchingId.includes(ticket.ticketID)) {
        foundMatchingTix = true
        return ticket.ticketID
      }
    })

    return (
      <Fragment key={ticket.ticketID}>
        {(ticket.status === "temp" || ticket.status === "pendingConf") && !findMatchingTickets &&
          <>
            <FineWrap onClick={() => { addTicketsHandler(ticket); removeTicketsHandler(ticket); }} >
              <Ticket>{`${ticket.ticketID} ($${ticket.winItPrice})`}</Ticket>
              {!ticket.winItPrice && <EditTicketButton type="button" className='editPrice' onClick={() => setShowPriceModal(true)}>Edit</EditTicketButton>}
            </FineWrap>
          </>}
      </Fragment>
    )
  })

  const downPaymentException = () => {
    return (
      <>
        <SharedSubmitBtn type="button" onClick={() => setShowDPModal(true)} className="submit" value='Submit'
          disabled={amount + Number(checkoutFee) < 60 || checkoutFee < 0 || !downPaymentType || !frequency
          || !frequencyUnits || !termUnits || userBlocked || !ticketArray.length} />
        
        <Modal open={showDPModal}>
          <DownPaymentException user={user} attorney={attorney} paymentPlans={paymentPlans} downPayment={downPayment} ticketArray={ticketArray}
            submitPlan={onSubmitHandler} setShowDPModal={setShowDPModal} amount={amount + Number(checkoutFee)} />
        </Modal>
      </>
    )
  }

  const DPException = downPaymentException

  return (
    <>
      <SharedHeader onClick={() => setShowNewPlanModal(true)} name={'Create Payment Plan'} />
      <Modal
        open={showNewPlanModal}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>Create a New Payment Plan</Modal.Header>
        <Modal.Content>
          <FormWrap>
            <form onSubmit={onSubmitHandler}>
              <h3>Customer Details</h3>
              <Wrap>
                <p>Name <span>{customerAccount.first_name} {customerAccount.last_name}</span></p>
                <p>Email <span>{customerAccount.email}</span></p>
              </Wrap>

              {foundMatchingTix ?
                <h3>
                Tickets {' '}
                <Popup
                  basic={true}
                  position='right center'
                  style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                  header='Not Seeing Any Tickets?'
                  content='Please make sure new tickets have a status of temp or pendingConf, and are not already in a Payment Plan'
                  trigger={<Icon name='question circle outline' />} />
                </h3> :
                <h3>Tickets</h3>
              }
              <AmountWrap>
                {renderTickets}
              </AmountWrap>

              <Modal open={showPriceModal}>
                <EditTicketPrice tickets={tickets} update={update} error={error} setShowPriceModal={setShowPriceModal} />
              </Modal>

              <h3>Payment Schedule</h3>
              <ScheduleWrap>
              <label htmlFor="down_payment">Down Payment</label>
              <input required type="number" onChange={(ev) => setDownPayment(ev.target.value)} value={downPayment} />
              <select required name="down_payment_units" onChange={(ev) => setDownPaymentType(ev.target.value)} value={downPaymentType}>
                <option>Select an option</option>
                <option value="fixed">Fixed ($)</option>
                <option value="percent">Percent</option>
              </select>
              <label htmlFor="frequency">Frequency</label>
              <input required type="number" min={1} onChange={(ev) => setFrequency(ev.target.value)} value={frequency} />
              <select required name="frequency_units" onChange={(ev) => setFrequencyUnits(ev.target.value)} value={frequencyUnits}>
                <option>Select an option</option>
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
              </select>
              <label htmlFor="term">Term</label>
                {termUnits === "date" ? <b>{termDate}</b>
                : <input type='number' min={1} onChange={(ev) => setTerm(ev.target.value)} value={term} />}
              <select required name="term_units" onChange={(ev) => setTermUnits(ev.target.value)} value={termUnits}>
                <option>Select an option</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
                <option value="payments">Payments</option>
                <option value="date">Date</option>
              </select>
              </ScheduleWrap>

              <h3>Total</h3>
              <TotalCost className="amount">
                <p>Selected Tickets: {ticketArray.join(" - ")}</p>
                <p className="fine">Legal Fee: <span className="price">{`$${amount}`}</span></p>
                <p className="fee">Payment Plan Fee: <span className="price">{`$${parseFloat(checkoutFee).toFixed(2)}`}</span>
                  <input type="number" min={0} onChange={(ev) => setCheckoutFee(ev.target.value)} step=".01" value={checkoutFee} /></p>
                <div className="separate" />
                <p className="total">Total Plan Price: <span className="price">{`$${amount + Number(checkoutFee)}`}</span></p>
              </TotalCost>
              <BtnWrap>
                <SharedCloseBtn type="button" className="close" onClick={() => setShowNewPlanModal(false)} name='Close' />
                {downPayment < (20 * ticketArray.length)
                  ? <DPException />
                  : <SharedSubmitBtn type="submit" className="submit" value='Submit'
                    disabled={amount + Number(checkoutFee) < 60 || checkoutFee < 0 || !downPaymentType || !frequency
                    || !frequencyUnits || !termUnits || userBlocked || !ticketArray.length} />
                }
              </BtnWrap>
            </form>
          </FormWrap>
        </Modal.Content>
      </Modal>
    </>
  )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 200px;
    height: 30px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0 10px;
    margin: 10px 0;

    &.radio {
      width: 50px;
      height: 10px;
      align-items: center;
    }
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  
  span {
    font-size: 15px;
    color: #007ae5;
    margin-left: 30px;
    font-weight: 600;
  }
`

const AmountWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: center;
`

const EditTicketButton = styled.button`
  height: 20px;
  width: 55px;
  border: none;
  outline: none;
  border-radius: 10px;
  background: #a9a9a9;
  color: white;
  font-weight: 500;
  cursor: pointer;
  
  &:hover {
    filter: brightness(90%);
  }
`

const FineWrap = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 10px;
  height: 50px;
  background-color: #d3d3d3;
  margin: 10px 3px;
  border-radius: 30px;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  .selected {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    color: #494cef;
  }
`

const Ticket = styled.p`
  font-weight: 600;
`

const ScheduleWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;

  select {
    width: 130px;
    height: 30px;
  }
`

const TotalCost = styled.div`
  display: flex;
  flex-direction: column;

  .separate {
    width: 200px;
    height: 2px;
    background-color: #b3b3b3;
    margin: 10px 0;
  }

  .price {
    font-weight: bold;
  }

  input {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    outline: none;
    width: 50px;
    max-width: max-content;
    font-weight: bold;
    margin-left: 10px;
    text-align: center;
  }
`

const BtnWrap = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
  align-items: center;
`

export default CreateNewPlan