import React, { Component } from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
// import { StyledElements } from '../../common';

const StyledMenu = styled(Menu)`
  &&&&& {
    opacity: 0.9;
    transition: 0.5s;
    margin: 0 -0.5em 0em -0.5em;
    &:hover {
      opacity: 1;
    }
  }
`
class FilterMenuComponent extends Component {
  componentDidMount() {
    if (!this.props.regions.length && !this.props.loading) {
      this.props.fetchRegions()
    }
  }
  render() {
    if (!this.props.regions.length) {
      return <div />
    }
    const { active, setFilter, isAdmin } = this.props
    let filterByRegions = this.props.regions
    if (isAdmin) {
      filterByRegions = filterByRegions.filter(region => {
        return region.state === 'NY'
      })
    }
    return (
      <StyledMenu stackable={true} text vertical={this.props.vertical}>
        <Menu.Item header>
          {/* {active ? (
            <Icon
              style={{ cursor: 'pointer' }}
              name="x"
              onClick={() => setFilter(null)}
            />
          ) : null} */}
          Filter By Region
        </Menu.Item>
        {filterByRegions.map(region => {
          const isActive = active && active.name === region.name
          return (
            <Menu.Item
              key={region.name}
              name={region.name}
              active={isActive}
              onClick={() => {
                if (isActive) {
                  setFilter(null)
                } else {
                  setFilter(region)
                  window.scrollTo(0, 0)
                }
              }}
            >
              {isActive ? <Icon name="check circle" /> : null}
              {region.name}
            </Menu.Item>
          )
        })}
      </StyledMenu>
    )
  }
}

FilterMenuComponent.defaultProps = {
  vertical: true,
}

export default FilterMenuComponent
