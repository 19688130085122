import { connect } from 'react-redux'
import { ticketsSelectors, ticketsThunks, ticketsActions } from '../../duck'
import { overlaysActions } from '../../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../../constants'

import EditDateTimeComponent from './EditDateTimeComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  editTicketField: ticketsSelectors.ticketEditFieldByIdSelector(state, props.objectId, props.dateKey)
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, key, value) => dispatch(ticketsActions.setEditValue(objectId, key, value)),
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  clearEditValue: (objectId, key) => dispatch(ticketsActions.clearEditValue(objectId, key)),
  error: (title, message) => dispatch(overlaysActions.addNotification({
    type: NOTIFICATION_TYPES.error,
    duration: 15,
    title,
    message
  }))
})

const EditDateTimeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDateTimeComponent)

export default EditDateTimeContainer
