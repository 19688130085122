import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import AttorneyStats from './AttorneyStats/AttorneyStatsContainer'
import AssociatedUsers from './AssociatedUsers/AssociatedUsersContainer'
import AttorneyAgreements from './AttorneyAgreements/AttorneyAgreementsContainer'
import PlatformAgreements from './AttorneyAgreements/PlatformAgreementsContainer'
import SaasAgreements from './AttorneyAgreements/SaasAgreementsContainer'
import ViewProfile from './ViewProfile/ViewProfileContainer'
import NotFoundPage from '../404NotFound'

class ProfileRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/profile/" component={ViewProfile} />
        <Route exact path="/profile/users" component={AssociatedUsers} />
        <Route exact path="/profile/stats" component={AttorneyStats} />
        <Route exact path="/profile/agreements" component={AttorneyAgreements} />
        <Route exact path="/profile/agreements/saas" component={SaasAgreements} />
        <Route exact path="/profile/agreements/platform" component={PlatformAgreements} />
        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    )
  }
}

export default ProfileRoutes
