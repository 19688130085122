import React, { Component, Fragment } from 'react'
import { Header, Image } from 'semantic-ui-react'
import ProfileRoutes from './ProfileRoutes'
import ProfileMenu from './ProfileMenu'
import Logo from '../../assets/images/navigation-bar-logo-portal.svg'
class ProfileComponent extends Component {
  getProfilePage = () => {
    if (this.props.location.pathname === '/profile') return ''
    const secondary = this.props.location.pathname.replace('/profile/', '')
    return secondary
  }
  getAttorneyImage = () => {
    if (
      !this.props.attorney ||
      !this.props.attorney.avatar ||
      !this.props.attorney.avatar.url
    )
      return Logo
    return this.props.attorney.avatar.url
  }
  getAttorneyName = () => {
    if (!this.props.attorney || !this.props.attorney.name)
      return 'Vinny Gambini'
    return this.props.attorney.name
  }
  getUser = () => {
    if (!this.props.user || !this.props.user.name || !this.props.user.email)
      return ''
    let userString = 'Signed in as '
    userString += this.props.user.name
    userString += ` (${this.props.user.email})`
    return userString
  }
  render() {
    return (
      <Fragment>
        {/* <Header as='h3'>Attorney Profile</Header> */}
        <Header as="h2">
          <Image circular src={this.getAttorneyImage()} />
          <Header.Content>
            {this.getAttorneyName()}
            <Header.Subheader>{this.getUser()}</Header.Subheader>
          </Header.Content>
        </Header>
        <ProfileMenu active={this.getProfilePage()} />
        <ProfileRoutes />
      </Fragment>
    )
  }
}

export default ProfileComponent
