import React from 'react'
import { Placeholder, Grid } from 'semantic-ui-react'

const LoadingComponent = () => {
  return (
    <div>
      <h2 style={{ color: 'lightgray' }}>Loading User</h2>
      <Grid stackable stretched columns={2}>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default LoadingComponent
