import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Segment, Dropdown } from 'semantic-ui-react'
import ReactTable from 'react-table'

import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import Error from '../../Error'
import {
  ListToolbarComponent,
  buildPDF,
  StyledElements,
  RTTools,
} from '../../common'
import '../../common/react-table/style.css'

class HearingsListComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
    }
  }
  componentDidMount() {
    if (!this.props.retrievedAt && !this.props.loading) {
      this.props.fetchHearings()
    }
  }
  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }
  getHearingType = () => {
    let hearingType = this.props.location.pathname
      .split('/')[2]
      .replace('-', ' ')
    hearingType = hearingType
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(' ')
    return hearingType
  }
  getRegionText = (prepend = 'in ') => {
    if (!this.props.regionFilter) return ''
    return `${prepend}${this.props.regionFilter.name}`
  }
  downloadPDF = () => {
    buildPDF(
      this.props.hearings,
      this.getHearingType() + ' Hearings ' + this.getRegionText()
    )
  }

  render() {
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        centerFillStatus={true}
        overlayKey={this.props.pageType}
        errorText={`Issue fetching`}
        // spinnerSize={"massive"}
        showSpinner={false}
      >
        <RenderList />
      </SpecificOverlay>
    )
  }

  renderList = () => {
    const hearingType = this.getHearingType()
    const regionText = this.getRegionText()
    if (!this.props.hearings.length) {
      return (
        <StyledElements.Centered>
          <Helmet title={`No ${hearingType} Hearings ${regionText}`} />
          <h3>{`No ${hearingType} Hearings ${regionText}`}</h3>
          <ListToolbarComponent
            update={this.props.fetchHearings}
            isLoading={this.props.isLoading}
            refreshedAt={this.props.retrievedAt}
          />
          {this.props.regionFilter ? (
            <StyledElements.FakeLink onClick={this.props.clearRegionFilter}>
              Try clearing the region filter...
            </StyledElements.FakeLink>
          ) : null}
        </StyledElements.Centered>
      )
    }
    const toolbarItems = [
      {
        icon: 'gavel',
        text: `${
          this.props.hearings.length
        } ${hearingType} Hearings ${regionText}`,
      },
    ]
    return (
      <Fragment>
        <Helmet title={`${hearingType} Hearings ${regionText}`} />
        <ListToolbarComponent
          items={toolbarItems}
          update={this.props.fetchHearings}
          isLoading={this.props.isLoading}
          refreshedAt={this.props.retrievedAt}
          download={this.downloadPDF}
        />
        <Segment>
          <Error.Boundary>
            <ReactTable
              data={this.props.hearings}
              noDataText={`No ${hearingType} Hearings ${this.getRegionText()} Found`}
              columns={RTTools.columns.buildHearing(
                this.props.attorney && this.props.attorney.globalAdmin,
                {
                  history: this.props.history,
                  baseUrl: '/hearings/view',
                }
              )}
              pageSize={this.state.pageSize}
              showPageSizeOptions={false}
              minRows={RTTools.preferences.minRows}
              className={RTTools.preferences.RowClassName}
              sorted={this.props.rtSettings.sort}
              filtered={this.props.rtSettings.filter}
              resized={this.props.rtSettings.size}
              onSortedChange={(newSorted, column, shiftKey) =>
                this.props.setSorted(newSorted)
              }
              onFilteredChange={(filtered, column) =>
                this.props.setFiltered(filtered)
              }
              onResizedChange={(newResized, event) =>
                this.props.setResized(newResized)
              }
            />
          </Error.Boundary>
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default HearingsListComponent
