import { createSelector } from 'reselect'

export const regionsSelector = state => state.regions.items

export const regionsFilterBuilder = (ticketSelector, regionFilterSelector) => createSelector(
  ticketSelector,
  regionFilterSelector,
  (tickets, filterRegion) => {
    if (!filterRegion || !filterRegion.trafficCounties || !filterRegion.trafficCourts) return tickets
    const regionCountyIds = filterRegion.trafficCounties.map(county => county.objectId)
    const regionCourtIds = filterRegion.trafficCourts.map(court => court.objectId)
    const result = tickets.filter(hearing => {
      if (hearing.trafficCounty && regionCountyIds.includes(hearing.trafficCounty.objectId)) return true
      if (hearing.trafficCourt && regionCourtIds.includes(hearing.trafficCourt.objectId)) return true
      return false
    })
    return result
  }
)
