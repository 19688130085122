import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { List } from 'semantic-ui-react'
import { CALLS_STATUS_TEXT, DATE_FORMATS } from '../../../constants'
import { StyledElements } from '../../common'

const RequestItemComponent = ({
  number,
  createdAt,
  objectId,
  status,
  trafficTicket,
  updatedAt,
}) => {
  const requested = moment(createdAt).format(DATE_FORMATS.DATE_TIME)
  const closed = moment(updatedAt).format(DATE_FORMATS.DATE_TIME)
  const humanStatus = CALLS_STATUS_TEXT[status]
  return (
    <List.Item>
      <List.Icon
        name={!status ? 'wait' : 'check'}
        size="large"
        verticalAlign="middle"
      />
      <List.Content>
        <List.Header>
          {status ? (
            humanStatus
          ) : (
            <StyledElements.Italicize>
              Awaiting Response
            </StyledElements.Italicize>
          )}
        </List.Header>
        <List.Description>
          <div>
            <em>Requested: </em>
            {requested}
          </div>
          {status ? (
            <div>
              <em>Answered: </em>
              {closed}
            </div>
          ) : null}
        </List.Description>
      </List.Content>
    </List.Item>
  )
}

RequestItemComponent.propTypes = {
  number: PropTypes.number,
  createdAt: PropTypes.string,
  objectId: PropTypes.string.isRequired,
  status: PropTypes.string,
  trafficTicket: PropTypes.object,
  updatedAt: PropTypes.string,
}

export default RequestItemComponent
