import { ticketColumn, TICKET_COLUMNS } from './ticket'
import { clientColumn, CLIENT_COLUMNS } from './client'
import { attorneyColumn, ATTORNEY_COLUMNS } from './attorney'

const buildTicketsClosedColumns = (
  showAttorney = false,
  {
    history, // this.props.history
    baseUrl, // '/hearings/view'
  },
  data
) => {
  const ADMIN_OR_ATTORNEY_ID = showAttorney ? TICKET_COLUMNS.ID_ADMIN() : TICKET_COLUMNS.ID
  const config = []
  const ticketInfo = ticketColumn(
    TICKET_COLUMNS.CLICK_TO_VIEW(history, baseUrl),
    TICKET_COLUMNS.TICKET_IMAGE,
    ADMIN_OR_ATTORNEY_ID,
    TICKET_COLUMNS.VIOLATION_NAME,
    TICKET_COLUMNS.POINTS,
    TICKET_COLUMNS.STATUS_FILTERABLE(data),
    TICKET_COLUMNS.STATUS_UPDATED,
    TICKET_COLUMNS.LEAD_STATUS_EDITABLE(data),
    TICKET_COLUMNS.CREATED,    
    TICKET_COLUMNS.HEARING_DATE,
    TICKET_COLUMNS.FOLLOW_UP_ON_DATE_EDITABLE,
    TICKET_COLUMNS.ASSIGNED
  )
  config.push(ticketInfo)

  const clientColumns = []
  if (showAttorney) {
    clientColumns.push(
      CLIENT_COLUMNS.NAME, 
      CLIENT_COLUMNS.HAS_PAYMENT_METHOD_ICON, 
      CLIENT_COLUMNS.PHONE
    )
  }

  const clientInfo = clientColumn(...clientColumns)
  config.push(clientInfo)
  if (showAttorney) {
    const attorneyInfo = attorneyColumn(
      ATTORNEY_COLUMNS.NAME,
      ATTORNEY_COLUMNS.COMPANY_NAME
    )
    config.push(attorneyInfo)
  }
  config.push(
    TICKET_COLUMNS.STATUS_REASON_EDITABLE(data),
    TICKET_COLUMNS.LOCATION_COURT,
    TICKET_COLUMNS.CX_NOTES,
  )
  return config
}

export default buildTicketsClosedColumns
