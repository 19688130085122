import { connect } from 'react-redux'

import EditGenderComponent from './EditGenderComponent'
import connectFuncs from './connectFunctions'

const EDIT_KEY = 'gender'

const { mapStateToProps, mapDispatchToProps } = connectFuncs(EDIT_KEY)

const EditGenderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGenderComponent)

export default EditGenderContainer
