import React from 'react'
import { Container, Icon } from 'semantic-ui-react'
import { StyledElements } from './common'

const NotFoundPage = () => {
  return (
    <StyledElements.CenteredSegment vertical textAlign="center">
      <Icon fitted name="server" size="huge" />
      <h1>404</h1>
      <h3>Page Not Found</h3>
      <Container>
        <p>If you expected something to be here, please let us know here.</p>
      </Container>
    </StyledElements.CenteredSegment>
  )
}

export default NotFoundPage
