const SET_TICKET = "SET_TICKET";
const SET_CREATED_ATTORNEY = "SET_CREATED_ATTORNEY";
const SET_UNASSIGNED_ACTIVE_EXPORT = "SET_UNASSIGNED_ACTIVE_EXPORT";
const SET_CLOSED_TICKETS_EXPORT = "SET_CLOSED_TICKETS_EXPORT";
const SET_LOOKUP_TICKETS_EXPORT = "SET_LOOKUP_TICKETS_EXPORT";

export default {
  SET_TICKET,
  SET_CREATED_ATTORNEY,
  SET_UNASSIGNED_ACTIVE_EXPORT,
  SET_CLOSED_TICKETS_EXPORT,
  SET_LOOKUP_TICKETS_EXPORT
}
