import React, { Component } from 'react'
import { Dropdown, Form } from 'semantic-ui-react'

import SEARCH_DESCRIBER from './SearchTypes'
const SEARCH_DESCRIBERS_ARR = Object.keys(SEARCH_DESCRIBER).map(
  key => SEARCH_DESCRIBER[key]
)
class SearchTypeDropdown extends Component {
  handleChange = (event, data) => {
    // console.log('data', data)
    this.props.onChange(data.value)
  }
  render() {
    return (
      <Form>
        <Form.Field>
          <label>Search By</label>
          <Dropdown
            onChange={this.handleChange}
            selection
            value={this.props.active}
            options={SEARCH_DESCRIBERS_ARR.map(search => ({
              text: search.name,
              value: search.searchType,
            }))}
          />
        </Form.Field>
      </Form>
    )
  }
}

export default SearchTypeDropdown
