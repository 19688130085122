import { connect } from 'react-redux'
import LoginComponent from './LoginComponent'
import { authenticationThunks } from './duck'
import { isLoadingSelector } from '../overlays/duck/selectors'
import { attorneySelector } from './duck/selectors'
import { overlaysActions } from '../overlays/duck'
import { NOTIFICATION_TYPES } from '../../constants'

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  attorney: attorneySelector(state)
})

const mapDispatchToProps = dispatch => ({
  login: (email, password) =>
    dispatch(authenticationThunks.validateLogin(email, password)),
  autoLogin: () => dispatch(authenticationThunks.autoLogin()),
  twoFA: (email, password, secret, token) =>
    dispatch(authenticationThunks.twoFA(email, password, secret, token)),
  success: (title, message) => {
    dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        duration: 10,
        title,
        message
      })
    )
  }
})

const AuthenticationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent)

export default AuthenticationContainer
