import React, { Component } from 'react'

import { StyledElements } from '../../../common'
import {
  POSSIBLE_INTERNAL_STATUS,
  CALLS_STATUS,
  CALLS_STATUS_TEXT,
} from '../../../../constants'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import InlineTableEditButtons from '../../../common/react-table/InlineTableEditButtons'

class EditCallStatusInlineComponent extends Component {
  buildOptionsArray = (arrayOfKeys, currentStatus) => {
    const result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: CALLS_STATUS_TEXT[key],
      }
    })
    if (currentStatus) {
      result.push({
        key: currentStatus,
        value: currentStatus,
        text: CALLS_STATUS_TEXT[currentStatus],
      })
    }
    return result
  }

  getPossibleStatuses = () => {
    const { attorney } = this.props
    const { internalReason } = this.props.ticket
    if (attorney.globalAdmin) {
      const allValues = Object.keys(CALLS_STATUS)
      return this.buildOptionsArray(allValues)
    }
    const possibleKeys = POSSIBLE_INTERNAL_STATUS[internalReason]
    if (!possibleKeys) return []
    const result = this.buildOptionsArray(possibleKeys, internalReason)
    return result
  }

  handleCancel = event => {
    event.preventDefault()
    const { objectId } = this.props
    this.props.clearEditValue(objectId)
    return false
  }

  handleChange = event => {
    const { objectId } = this.props
    const { value } = event.target
    if (value) {
      this.props.editUpdate(objectId, value)
    }
  }

  updateStatus = e => {
    e.preventDefault()
    this.setState({ isEditing: false })
    const { objectId, editTicketField, ticket } = this.props
    if (editTicketField !== ticket.internalReason) {
      this.props.update(objectId, { internalReason: editTicketField })
    }
    return false
  }

  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    return (
      <StyledElements.FillForm>
        <StyledElements.FillSelect
          onChange={this.handleChange}
          value={editTicketField || ticket.internalReason}
        >
          {this.getPossibleStatuses().map(statusOption => {
            return (
              <option key={statusOption.value} value={statusOption.value}>
                {statusOption.text}
              </option>
            )
          })}
        </StyledElements.FillSelect>
        {editTicketField && ticket.internalReason !== editTicketField ? (
          <InlineTableEditButtons
            cancelFunc={this.handleCancel}
            updateFunc={this.updateStatus}
          />
        ) : null}
      </StyledElements.FillForm>
    )
  }

  render() {
    const { objectId, ticket, attorney } = this.props
    const { internalReason } = ticket
    const possibleChanges =
      POSSIBLE_INTERNAL_STATUS[internalReason] || attorney.globalAdmin
    const RenderEdit = this.renderEdit
    return (
      <SpecificOverlayContainer
        overlayKey={objectId}
        ignoreError={true}
        LoadingComponent={() => (
          <StyledElements.Italicize>Loading...</StyledElements.Italicize>
        )}
        UpdatingComponent={() => (
          <StyledElements.Italicize>Updating...</StyledElements.Italicize>
        )}
      >
        {possibleChanges ? (
          <RenderEdit />
        ) : internalReason ? (
          CALLS_STATUS[internalReason]
        ) : (
          <StyledElements.Italicize>No Call Status</StyledElements.Italicize>
        )}
      </SpecificOverlayContainer>
    )
  }
}

export default EditCallStatusInlineComponent
