const SAAS_AGREEMENT = `SAAS SERVICES AGREEMENT
WINIT LLC (THE “COMPANY”) PROVIDES THE PLATFORM SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS SERVICES AGREEMENT (THE “AGREEMENT”) AND ON THE CONDITION THAT THE COUNTERPARTY HERETO (THE “ATTORNEY”) ACCEPTS AND COMPLIES WITH THEM. BY CLICKING THE “ACCEPT” BUTTON BELOW, AND/OR BY USING THE PLATFORM-RELATED SERVICES, CUSTOMER (A) ACCEPTS THIS AGREEMENT AND AGREES THAT HE/SHE IS LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENTS AND WARRANTS THAT CUSTOMER HAS THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT AND BIND HIM/HER TO ITS TERMS. IF CUSTOMER DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, COMPANY WILL NOT AND DOES NOT PROVISION THE PLATFORM AND SERVICES RELATED THERETO TO CUSTOMER AND CUSTOMER MUST NOT USE THE PLATFORM.
1.	Definitions
“Customer Data” means any of Customer’s information, documents, or electronic files that are uploaded to the Platform by Customer and/or any of its Users.
“Error” means any reproducible material failure of the Platform to function in accordance with its Documentation.
“Maintenance Windows” means collectively, standard maintenance and emergency maintenance. Standard maintenance windows will be published in advance on Winit’s website at least five (5) hours in advance of the start of the standard maintenance window. Emergency maintenance will occur as needed. Winit will make reasonable efforts to publish emergency maintenance windows on Winit’s website in advance of the emergency maintenance window, but it is possible that advanced notification of an emergency window may not occur.
“Platform” means the proprietary software service for which Customer has paid to, including any Updates relating thereto that may be provided hereunder by Winit from time to time, and any derivative works of the foregoing.
“Purpose” means the management and organization of Customer’s client files, and specifically the management of traffic ticket cases handled by Customer.
“Services” means, collectively, the Platform-related services and all Support-related services provisioned by Winit during the Term.
“Subscription” means Customer’s subscription to the Platform, pursuant to the terms hereof.
“Support” means the ongoing support and maintenance services carried out by Winit, as described in Section 3 hereof.
“Update” means any patch, bug fix, release, version, modification or successor to the Platform.
“User” means a named individual to whom Customer has granted access to use the Platform on Customer’s behalf, regardless of whether or not the User actually accesses the Software. Users may be Customer’s employees, consultants, contractors or agents.
2.	Use Rights
(a)	License and Use Rights. During the Term (as defined below) and subject to the terms and conditions set forth in this Agreement, Winit hereby grants to Customer a non-exclusive, non-transferable, non-sublicensable right to use and permit Users to use the Platform for the Purpose contemplated hereunder. The use right in the preceding sentence is limited to use by the number of Users for which Customer has paid. All rights in and to the Platform not expressly granted herein are reserved to Winit.
(b)	Use Restrictions. Customer shall not, directly, indirectly, alone, or with another party, (i) copy, disassemble, reverse engineer, or decompile the Platform; (ii) modify, create derivative works based upon, or translate the Platform; or (iii) license, sell, rent, lease, transfer, grant any rights in or otherwise commercially exploit the Platform in any form to any other party, nor shall Customer attempt to do any of the foregoing or cause or permit any third party to do or attempt to do any of the foregoing, except as expressly permitted hereunder. Customer acknowledges and agrees that Winit shall own all right, title and interest in and to all intellectual property rights (including all derivatives or improvements thereof) in the Platform and any suggestions, enhancement requests, feedback, recommendations or other information provided by Customer or any of the Users relating to the Platform.
(c)	Platform Administrator; User Access. Customer shall designate one or more Platform administrators (“Platform Administrators”) who shall be responsible for managing User access, including adding and deleting Users. The Platform Administrator shall ensure that multiple Users do not share a password or user name. Customer acknowledges and agrees that it is prohibited from sharing passwords and/or user names with unauthorized users.
(d)	Customer Data. Customer owns or has the right to use the Customer Data. Customer hereby grants to Winit a non-exclusive, non-transferable (except as set forth in Section 8(c)), non-sublicensable right and license to use, copy, transmit and modify the Customer Data solely for purposes of Winit’s Support of the Platform.
(e)	No Sensitive Data; Customer Responsibilities. Customer hereby assumes all risk arising from its upload and use of Customer Data via the Platform, including the risk of any inadvertent disclosure or unauthorized access thereto. Customer is responsible for ensuring that Customer and its Users’ use of the Platform is in compliance with all applicable laws and governmental regulations and Customer acknowledges that Customer assumes all risk arising from any such use that is not compliant with applicable laws and regulations.
(f)	Security. Customer is solely responsible for maintaining the security of all user names and passwords granted to it, for the security of its information systems used to access the Platform, and for its Users’ compliance with the terms hereof. Winit will act as though any electronic communications it receives under User’s user names have been sent by Customer. Customer will immediately notify Winit if it becomes aware of any loss or theft or unauthorized use of any of Customer’s passwords or user names. Winit has the right at any time to terminate or suspend access to any User or to Customer if Winit believes in good faith that such termination or suspension is necessary to preserve the security, integrity, or accessibility of the Platform or Winit’s network.
(g) Compliance with Applicable Laws. Customer is reponsible for ensuring that his/her use of the Services complies with all applicable federal, state and municipal laws and regulations, as well as all applicable rules of professional conduct issued by the local court(s) and/or bar association(s) in each state where Attorney carries out the Attorney's professional services.
3.	Support
(a)	Services Generally. Subject to the terms and conditions of this Agreement, Winit shall use commercially reasonable efforts to make the Platform and Services available to Customer, and will provide general support and maintenance in respect thereof.
(b)	Updates. Winit shall deliver Updates to the Platform that apply to the Customer’s current Subscription at no additional charge.
(c)	Error Correction. Winit shall use commercially reasonable efforts to correct all Errors or to provide a reasonable workaround as soon as is possible using its commercially reasonable efforts during Winit’s normal business hours. Customer shall provide such access, information, and support as Winit may reasonably require in the process of resolving any Error. This paragraph is Customer’s sole and exclusive remedy for Errors.
(d)	Support Exclusions. Winit is not obligated to correct any Errors or provide any other support to the extent such Errors or need for support was created in whole or in part by: (i) the acts, omissions, negligence or willful misconduct of Customer, including any unauthorized modifications of the Platform or its operating environment; (ii) any failure or defect of Customer’s or a third party’s equipment, software, facilities, third party applications, or internet connectivity (or other causes outside of Winit’s firewall); (iii) Customer’s use of the Platform other than in accordance with the Platform’s Documentation; or (iv) a Force Majeure Event (as defined below).
(e)	Limitation of Remedies. Correction of Errors as defined in this Agreement are Customer’s sole remedies for any Errors in the Platform.
4.	Financial Terms
(a)	Fees. In consideration for the Services and use rights provided by Winit to Customer hereunder, Customer shall pay to Winit a monthly service fee in the amount set forth in Exhibit A attached hereto (the “Service Fee”).
(b)	Payment Terms. All Service Fees shall be paid in accordance with the payment terms set forth in Exhibit A attached hereto.  If Customer is delinquent in the payment of any portion of any invoice, Winit may, in addition to any other remedies it may have pursuant this Agreement or under applicable law, suspend Customer’s (and therefore its Users’) access to the Platform and/or provision of the Services to Customer. Customer agrees to pay interest on delinquent amounts at the rate of one and one-half percent (1.5%) per month (or, if lower, the maximum amount permitted by law) that a payment is overdue.
(c)	Taxes. Customer shall pay or shall reimburse Winit for all sales taxes and other taxes, however characterized by the taxing authority, based upon the license fees or other charges hereunder or otherwise incurred on account of Customer’s use of the Platform, except for any taxes based upon Winit’s net income or gross receipts or for any franchise or excise taxes owed by Winit.
(d)	Pricing Changes. Customers will receive notice of changes in pricing/fees at least forty-five (45) days’ before the month in which the change in pricing will take effect.
5.	Term and Termination
(a)	Term. The term of this Agreement commences on the Effective Date and will continue until either party notifies the other party at least thirty (30) days in advance of its intention to terminate this Agreement, or until otherwise terminated pursuant to the terms hereof (the “Term”).
(b)	Termination for Cause. Either party may terminate this Agreement for cause upon written notice to the other party: (i) if the other party has committed any other material breach of its obligations hereunder and has failed to cure such breach within ten (10) days of written notice by the non-breaching party specifying in reasonable detail the nature of the breach (or, if such breach is not reasonably curable within ten (10) days, has failed to begin and continue to work diligently and in good faith to cure such breach); or (ii) upon the institution of bankruptcy or state law insolvency proceedings against the other party, if such proceedings are not dismissed within thirty (30) days of commencement.
(c)	Obligations Upon Termination. Upon termination of this Agreement, provided that Customer has paid all amounts owed to Winit hereunder, Winit shall, upon written request received within thirty (30) days of termination, provide to Customer access to the Platform for a period of seventy-two (72) hours for the limited purpose of exporting its Customer Data.  Thereafter, Winit shall immediately terminate Customer’s and its Users’ access to the Platform.  Notwithstanding anything hereunder to the contrary, Customer shall immediately pay Winit any accrued and unpaid amounts due and owing to Winit as of the effective date of termination of this Agreement.
(d)	Survival. Each party hereto covenants and agrees that the provisions in Sections 1, 2(b), 5(c), 5(d), 6, 7 and 8 in addition to any other provision that, by its terms, is intended to survive the expiration or termination of this Agreement, shall survive the expiration or termination of this Agreement.
CUSTOMER ACKNOWLEDGES THAT UPON TERMINATION OF THIS AGREEMENT, SUBJECT TO THE LIMITED ACCESS RIGHTS UNDER SECTION 5(C)(I), WINIT IS UNDER NO OBLIGATION TO EITHER MAINTAIN CUSTOMER DATA OR TO PROVIDE CUSTOMER WITH ACCESS TO OR A COPY OF THE CUSTOMER DATA.
6.	Confidentiality
(a)	Confidential Information. “Confidential Information” means any and all tangible and intangible information (whether written or otherwise recorded or oral) of a party that: (A) derives independent economic value, actual or potential, from not being generally known to, and not being readily ascertainable by proper means by, other persons who can obtain economic value from its disclosure or use and is the subject of efforts that are reasonable under the circumstances to maintain its secrecy; or (B) the disclosing party designates as confidential or, given the nature of the information or the circumstances surrounding its disclosure, reasonably should be considered as confidential. “Confidential Information” also includes, without limitation: (i) nonpublic information relating to a party’s technology, customers, business plans, promotional and marketing activities, finances and other business affairs; (ii) third-party information that Customer or Winit is obligated to keep confidential; (iii) the material terms and conditions of this Agreement; (iv) any nonpublic information relating to any activities conducted hereunder; and (v) Customer’s financial information (e.g., Customer’s banking information, credit card information and names, addresses and similar data). For avoidance of doubt, (A) the Platform and all designs, engineering details, and other technical, financial, marketing, commercial and other information pertaining to the Platform shall be considered Winit’s Confidential Information; and (B) all proprietary and/or pre-existing information and other materials of Customer (including Customer Data) shall be considered Customer’s Confidential Information.
(b)	Exclusions. Notwithstanding the above, the term “Confidential Information” does not include any information that: (i) is public knowledge at the time of disclosure by the disclosing party; (ii) becomes public knowledge or known to the receiving party after disclosure by the disclosing party other than by breach of the receiving party's obligations under this Section or by breach of a third party's confidentiality obligations; (iii) was known by the receiving party prior to disclosure by the disclosing party other than by breach of a third party's confidentiality obligations; (iv) is independently developed by the receiving party; or (v)  approved for disclosure by prior written permission of an executive officer of the disclosing party.
(c)	Use of Confidential Information. Each party shall only use Confidential Information furnished to it hereunder in furtherance of the activities contemplated by this Agreement, and, except as authorized in this Agreement, it shall not disclose the Confidential Information to any other persons without the disclosing party’s express written authorization.
(d)	Required Disclosures. A receiving party may disclose Confidential Information of the disclosing party as required to comply with binding orders of governmental entities that have jurisdiction over it or as otherwise required by law, provided that the receiving party (i) gives the disclosing party reasonable written notice to allow it to seek a protective order or other appropriate remedy (except to the extent compliance with the foregoing would cause the receiving party to violate a court order or other legal requirement), (ii) discloses only such information as is required by the governmental entity or otherwise required by law, and (iii) and uses its best efforts to obtain confidential treatment for any Confidential Information so disclosed.
(e)	Return of Information. Except as set forth otherwise in the specific provisions concerning Customer Data hereunder, if a disclosing party so requests at any time, the receiving party shall return promptly all copies, extracts, or other reproductions in whole or in part of the Confidential Information in its possession.
7.	Service Level Commitments, Disclaimers and Limitations
(a)	Service Level Commitments; Credits. Subject to the occurrence of a force majeure event, Winit guarantees that the Platform and all Services will be accessible to Customer’s Users 99.5% of the time in any given calendar month, excluding Maintenance Windows. Notwithstanding the foregoing, Winit does not guarantee network availability between Customer and the Winit hosting servers, as such availability can involve numerous third parties and is beyond the control of Winit. Winit will not be liable for nor provide any Service credits hereunder for any downtime caused in whole or part by a third-party data center provider nor for any downtime that Customer experiences as a result of Customer or Users’ own network connectivity issues. If Customer experiences a Platform or Service outage and is unable to access the Platform or any Service, Customer must immediately contact Winit’s help desk, providing any/all necessary information that may assist Winit in determining the cause of the outage. Winit will determine in good faith whether the outage was within Winit’s reasonable control. If Winit determines that a timely reported outage was attributable to Winit (e.g., and not to a force majeure event), then Winit will credit Customer 1-day of Service Fees for every four (4) hours of downtime Customer experienced, up to a maximum of twenty-five (25%) percent of that month’s applicable Service Fees. This shall be Customer’s sole remedy, and Winit’s sole liability, for Winit’s failure to provide the guaranteed availability set forth in this Section 7(a).
(b)	Representations and Warranties.	Winit represents that it is the sole owner of the proprietary software concerned herein, and that it was developed and maintained without infringement of intellectual property rights owned or licensed by others without proper licenses/agreements as might be the case. Winit will hold Customer harmless and indemnify Customer for all claims, if any, made against Customer related to the matters stated in the previous sentence, including but not limited to assuming the defense of the claim(s), legal fees, costs, judgments and expenses.
(c)	Disclaimer of Warranties. EXCEPT FOR THE LIMITED SERVICE LEVEL COMMITMENTS SET FORTH IN SECTION 7(a) AND THE REPRESENTATIONS AND WARRANTIES SET FORTH IN SECTION 7(b) HEREOF, WINIT MAKES NO, AND HEREBY DISCLAIMS ANY AND ALL, REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE PLATFORM, THE SERVICES OR THE AVAILABILITY, FUNCTIONALITY, PERFORMANCE OR RESULTS OF USE OF THE PLATFORM. WITHOUT LIMITING THE FOREGOING, EXCEPT AS SPECIFICALLY SET FORTH IN THE LIMITED SERVICE LEVEL COMMITMENTS PROVIDED IN SECTION 7(A), WINIT DISCLAIMS ANY WARRANTY THAT THE PLATFORM, THE SERVICES, OR THE OPERATION OF THE PLATFORM ARE OR WILL BE ACCURATE, ERROR-FREE OR UNINTERRUPTED. WINIT MAKES NO, AND HEREBY DISCLAIMS ANY, IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF NON-INFRINGEMENT, MERCHANTABILITY, OF FITNESS FOR ANY PARTICULAR PURPOSE OR ARISING BY USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE.
(d)	Disclaimer of Consequential Damages. WINIT HAS NO LIABILITY WITH RESPECT TO THE PLATFORM, SERVICES, OR ITS OTHER OBLIGATIONS HEREUNDER OR OTHERWISE FOR CONSEQUENTIAL, EXEMPLARY, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES (INCLUDING WITHOUT LIMITATION LOSS OF PROFITS AND THE COST OF COVER) EVEN IF WINIT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
(e)	Limitations of Remedies and Liability. WINIT’S TOTAL AGGREGATE LIABILITY TO CUSTOMER FOR ANY REASON AND UPON ANY CAUSE OF ACTION INCLUDING WITHOUT LIMITATION, BREACH OF CONTRACT, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATIONS, AND OTHER TORTS, IS LIMITED TO ALL FEES PAID TO WINIT BY THE CUSTOMER IN RESPECT OF USE RIGHTS/USER LICENSES FOR THE PLATFORM DURING THE THREE (3) MONTHS IMMEDIATELY PRECEDING THE EVENTS GIVING RISE TO THE LIABILITY.
8.	General
(a)	Notices. Any notice, consent, or acknowledgment provided for or permitted to be given hereunder will be in writing addressed to each party at the address set forth in the preamble above, or as such party may otherwise advise in writing from time to time. All notices hereunder will be given in writing via (a) messenger, (b) overnight courier, (c) first class mail, certified, return receipt requested, or (d) confirmed facsimile transmission with follow-up via one of the methods in (a), (b) or (c), in each case to the addresses set forth above, or such other address as either party may substitute by notice hereunder.  All notices given in accordance with this Section will be deemed given on the first business day following the date of receipt.
(b)	Force Majeure. “Force Majeure Event” means any act or event that (a) prevents a party (the “Nonperforming Party”) from performing its obligations or satisfying a condition to the other party’s (the “Performing Party”) obligations hereunder, (b) is beyond the reasonable control of and not the fault of the Nonperforming Party, and (c) the Nonperforming Party has not, through commercially reasonable efforts, been able to avoid or overcome. “Force Majeure Event” does not include economic hardship, changes in market conditions, and insufficiency of funds. If a Force Majeure Event occurs, the Nonperforming Party is excused from the performance thereby prevented and from satisfying any conditions precedent to the other party’s performance that cannot be satisfied, in each case to the extent limited or prevented by the Force Majeure Event. When the Nonperforming Party is able to resume its performance or satisfy the conditions precedent to the other party’s obligations, the Nonperforming Party shall immediately resume performance hereunder. The relief offered by this paragraph is the exclusive remedy available to the Performing Party with respect to a Force Majeure Event.
(c)	Assignment. Winit may assign any of its rights or obligations hereunder at any time; provided, however, that Winit shall not assign the rights granted to Customer Data in Section 2(d) except in connection with the sale (whether by merger, asset sale, equity sale or otherwise) of (i) Winit; (ii) the Platform; or (iii) a portion of Winit or the Platform that would reasonably require the acquirer of said portion to be assigned such rights to the Customer Data. Customer shall not assign any of its rights hereunder, except with the prior written approval of Winit, which shall not be unreasonably withheld; provided, that, no approval shall be required in connection with any assignment by Customer to any successor-in-interest. The preceding sentence applies to all assignments of rights, except in the event of a voluntary transfer of substantially all assets by Customer to a transferee which executes Winit’s form of agreement agreeing to be bound all of the terms and conditions of this Agreement. Any change of control transaction is deemed an assignment hereunder. Any purported assignment of rights in violation of this Section is void.
(d)	Governing Law; Venue. The laws of the State of New York (without giving effect to its conflict of laws principles) govern all matters arising out of or relating to this Agreement and the transactions it contemplates, including, without limitation, its interpretation, construction, performance, and enforcement. Any claims or actions regarding or arising out of this Agreement must be brought exclusively in a court of competent jurisdiction sitting in New York County, New York, and each party to this Agreement submits to the jurisdiction of such courts for the purposes of all legal actions and proceedings arising out of or relating to this Agreement. Each party waives, to the fullest extent permitted by law, any objection that it may now or later have to (i) the laying of venue of any legal action or proceeding arising out of or relating to this Agreement brought in any state or federal court sitting in New York County, New York; and (ii) any claim that any action or proceeding brought in any such court has been brought in an inconvenient forum.
(e)	Entire Agreement. This Agreement constitutes the final agreement between the parties. It is the complete and exclusive expression of the parties’ agreement on the matters contained in this Agreement. All prior and contemporaneous negotiations and agreements between the parties on the matters contained in this Agreement are expressly merged into and superseded by this Agreement. The provisions of this Agreement cannot be explained, supplemented or qualified through evidence of trade usage or a prior course of dealings. In entering into this Agreement, neither party has relied upon any statement, representation, warranty or agreement of any other party except for those expressly contained in this Agreement. There are no conditions precedent to the effectiveness of this Agreement, other than any that are expressly stated in this Agreement.
(f)	Amendments. The parties can amend this Agreement only by a written agreement of the parties that identifies itself as an amendment to this Agreement.
(g)	Exhibits.	The exhibits referenced in this Agreement shall be supplied to Customer under separate cover, but all of which shall be deemed incorporated herein by reference.
(h)	Click-Wrap.	Customer’s click of the “I accept” button below shall have the same legal validity and enforceability as a manually executed signature or use of a paper-based recordkeeping system to the fullest extent permitted by applicable law, and the parties hereby waive any objection to the contrary.

By clicking “I agree”, I hereby agree to the terms and conditions set forth in this Agreement.



 
EXHIBIT A TO SAAS CUSTOMER AGREEMENT
Service Fees
Fees: Customer agrees to pay the applicable fee(s) set forth in the Company’s then current rate card, which comprise either the per-case one-time service fee option or the per-case monthly subscription fee option.  Customer must make his/her/its selection at the start of each case, and if Customer shall fail to make an election, Customer will be deemed to have selected the one-time service fee.  The Company’s current rate cards are available on your monthly statements.

Payment Terms:  At Winit’s election, Winit may either invoice Customer for the  applicable Service Fee(s) which are due and owing to Winit, whereupon Customer will pay such invoice on Net 10 day terms, or if Winit shall have drawn certain monies from Customer in connection with other fees due Winit, then Winit shall apply such drawn fees in accordance with the terms of this Agreement.  Winit will endeavor to provide Customer with at least forty-five (45) days’ prior written notice of changes to the fees set forth in this Exhibit A before such changes shall go into effect and be binding on Customer.

`

export default SAAS_AGREEMENT
