import React, { Fragment } from 'react'
import { StyledElements } from '../../../common'
import Dropzone from 'react-dropzone'

class ImageDropzone extends React.Component {
  handleTicket = ticketImage => {
    this.props.setImage(this.props.objectId, ticketImage[0])
  }

  render() {
    return (
      <Fragment>
        <StyledElements.Divider />
        <Dropzone multiple={false} onDrop={this.handleTicket} accept="image/*">
          {({ getRootProps, getInputProps }) => {
            return (
              <Fragment>
                <input {...getInputProps()} />
                <StyledElements.FakeLink
                  {...getRootProps({ onClick: evt => evt.preventDefault() })}
                >
                  Set
                </StyledElements.FakeLink>
              </Fragment>
            )
          }}
        </Dropzone>
      </Fragment>
    )
  }
}

export default ImageDropzone
