import jsPDF from 'jspdf'
// import logo from '../../assets/images/navigation-bar-logo-portal.svg'

const array_chunks = (array, chunk_size) => Array(Math.ceil(array.length / chunk_size)).fill().map((_, index) => index * chunk_size).map(begin => array.slice(begin, begin + chunk_size));

const pdfBuilder = ({ fileName, lineArray, now, documentTitle }) => {
  const doc = new jsPDF()

  // doc.addSvgAsImage(logo, 10, 10, 20, 20)

  // add retrieved date
  doc.setTextColor("#6e6e6e")
  doc.setFontSize(9)
  doc.text(`As of ${now}`, 200, 10, { align: "right" })
  // document title
  doc.text(documentTitle, 10, 10, { align: "left" })


  const itemsPerPage = 9
  const chunks = array_chunks(lineArray, itemsPerPage)

  //logic for each page
  chunks.forEach((chunk, chunkIndex) => {
    if (chunkIndex >= 1) doc.addPage()

    // Add x Items to a page
    const startXPos = 25
    const start = 20
    const spacing = 30
    chunk.forEach((text, index) => {
      const y = start + (spacing * index)
      //add text line
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(13)
      doc.text(text, startXPos, y)
      // add item # to text title
      doc.setTextColor("#6e6e6e")
      doc.setFontSize(10)
      doc.text(`${(chunkIndex * itemsPerPage) + (index + 1)}`, startXPos - 10, y, { align: 'right' })
      doc.setDrawColor("#dededf")
      doc.line(startXPos - 5, y + 2, startXPos - 5, y - 4)
      // add writing lines
      doc.setDrawColor("#dededf")
      doc.line(startXPos, y + 9, startXPos + 170, y + 9)
      doc.line(startXPos, y + 18, startXPos + 170, y + 18)
    })

    // Add Page number to bottom of each page
    doc.setTextColor("#909090")
    doc.setFontSize(8)
    doc.text(`Page ${chunkIndex + 1} of ${chunks.length}`, 100, 290, { align: 'center' })
  });

  doc.save(fileName)
}

export default pdfBuilder
