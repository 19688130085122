import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Menu, Modal, Popup } from 'semantic-ui-react'

import { getPaymentMethod, getPaymentPlan } from '../duck/services'
import SharedHeader from '../shared/SharedHeader'
import SharedCloseBtn from '../shared/SharedCloseBtn'
import Details from '../paymentPlans/Details'
import Schedule from '../paymentPlans/Schedule'
import Payments from '../paymentPlans/Payments'
import Refunds from '../paymentPlans/Refunds'
import Disputes from '../paymentPlans/Disputes'
import ManualPayment from '../paymentPlans/ManualPayment'

const SeePlan = ({ user, attorney, paymentPlans, customerAccount, allowCC, error, success }) => {
  const [showPlanModal, setShowPlanModal] = useState(false)
  const [showDetailedPlan, setShowDetailedPlan] = useState(false)

  const [activeItem, setActiveItem] = useState('Details')

  const [planId, setPlanId] = useState("")
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [paymentPlan, setPaymentPlan] = useState(null)

  if (!paymentPlans?.length || !customerAccount) return <p></p>

  const getPartiallyData = () => {
    getPaymentMethod(customerAccount.id).then(res => {
      setPaymentMethods(res.payment_methods)
    })
  }

  return (
    <>
      <SharedHeader onClick={() => setShowPlanModal(true)} name={'See Payment Plan'} />
      <Modal
        open={showPlanModal}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >  
        <Modal.Header>See Payment Plans for {user.name}</Modal.Header>
        <Modal.Content>
          <ModalWrap>
            <form>{paymentPlans.map(plan => {
              const getPaymentPlanHandler = () => {
                getPaymentPlan(plan.id).then(res => {
                  setPaymentPlan(res)
                })
              }
              let planNumber = plan.number
              let metaDescription = plan.meta.description && plan.meta.description.length > 10
                ? plan.meta.description.slice(0, 10) + '...' : plan.meta.description
              
              let scheduleDescription = plan.payment_schedule.description && plan.payment_schedule.description.length > 10
                ? plan.payment_schedule.description.slice(0, 10) + '...' : plan.payment_schedule.description
              
              const content = planNumber
                ? `Payment Plan #${plan.number}`
                : metaDescription || scheduleDescription || 'Ticket(s) not defined'
                
              const popup =
                <Popup content={plan.meta.description || plan.payment_schedule.description || plan.id}
                  key={plan.id} trigger={<p className='planDescription'>{content}</p>} />
              return (
                <Fragment key={plan.id}>
                  <Wrapper style={{ backgroundColor: plan.status === "paid" && "limegreen" }}
                    onClick={() => { setPlanId(plan.id); setShowDetailedPlan(true); getPartiallyData(); getPaymentPlanHandler() }}>
                    {popup}
                  </Wrapper>
                  {plan.id === planId &&
                    <Modal open={showDetailedPlan}>
                      <Modal.Header>{plan.meta.description || plan.id}</Modal.Header>
                      <Modal.Content>
                        <Menu pointing secondary>
                          <Menu.Item name='Details' active={activeItem === 'Details'} onClick={() => setActiveItem('Details')} />
                          <Menu.Item name='Schedule' active={activeItem === 'Schedule'} onClick={() => setActiveItem('Schedule')} />
                          <Menu.Item name='Payments' active={activeItem === 'Payments'} onClick={() => setActiveItem('Payments')} />
                          <Menu.Item name='Refunds' active={activeItem === 'Refunds'} onClick={() => setActiveItem('Refunds')} />
                          <Menu.Item name='Disputes' active={activeItem === 'Disputes'} onClick={() => setActiveItem('Disputes')} />
                          {paymentPlan && paymentPlan.payment_plan.status === 'open' &&
                            <Menu.Item name='Make Payment' active={activeItem === 'Make Payment'}
                              onClick={() => setActiveItem('Make Payment')} />}
                        </Menu>
                        <Modal.Description>
                          {activeItem === 'Details' && paymentPlan && <Details details={paymentPlan.payment_plan} schedule={paymentPlan.payment_schedule} />}
                          {activeItem === 'Schedule' && paymentPlan &&
                            <Schedule details={paymentPlan.payment_plan} user={user} schedule={paymentPlan.payment_schedule} installments={paymentPlan.installments}
                              allowCC={allowCC} paymentMethods={paymentMethods} setShowPlanModal={setShowPlanModal} setShowDetailedPlan={setShowDetailedPlan} success={success} error={error} />
                          }
                          {activeItem === 'Payments' && paymentPlan &&
                            <Payments attorney={attorney} payments={paymentPlan.payments} setShowPlanModal={setShowPlanModal}
                              setShowDetailedPlan={setShowDetailedPlan} success={success} error={error} />
                          }
                          {activeItem === 'Refunds' && paymentPlan && <Refunds refunds={paymentPlan.refunds} />}
                          {activeItem === 'Disputes' && paymentPlan && <Disputes disputes={paymentPlan.disputes} />}
                          {activeItem === 'Make Payment' && paymentPlan &&
                            <ManualPayment details={paymentPlan.payment_plan} schedule={paymentPlan.payment_schedule} setShowPlanModal={setShowPlanModal}
                              setShowDetailedPlan={setShowDetailedPlan} success={success} error={error} />
                          }
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <SharedCloseBtn type="button" className="close" onClick={() => setShowDetailedPlan(false)} name='Close' />
                      </Modal.Actions>
                    </Modal>
                  }
                </Fragment>
              )
            })}
            </form>
          </ModalWrap>
        </Modal.Content>
        <Modal.Actions>
          <SharedCloseBtn type="button" className="close" onClick={() => setShowPlanModal(false)} name='Close' />
        </Modal.Actions>
      </Modal>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: max-content;
  flex-wrap: wrap;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  justify-content: center;
  background-color: #e1e1e1;
  cursor: pointer;

  &:hover {
    filter: brightness(90%);
  }

  span {
    margin-left: 5px;
    color: green;
  }
`

const ModalWrap = styled.div`
  form {
    display: grid;
    grid-template-columns: repeat(4, auto);
    align-items: center;
  }

  button {
    width: 60px;
    margin-top: 20px;
  }

  .planDescription {
    font-weight: 500;
  }
`

export default SeePlan