import { connect } from 'react-redux'

import ExportsComponent from './ExportsComponent'
import thunks from '../duck/thunks'
import { closedTicketsExportSelector, lookupTicketsExportSelector, unassignedActiveLeadsExportSelector } from '../duck/selectors'


const mapStateToProps = state => ({
  closedTicketsExport: closedTicketsExportSelector(state),
  lookupTicketsExport: lookupTicketsExportSelector(state),
  unassignedActiveLeadsExport: unassignedActiveLeadsExportSelector(state)
})

const mapDispatchToProps = dispatch => ({
  getTrafficTicketsExport: (queryObject) => dispatch(thunks.getTrafficTicketsExport(queryObject)),
  getLookupTicketsExport: (queryObject) => dispatch(thunks.getLookupTicketsExport(queryObject))
})

const ExportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportsComponent)


export default ExportsContainer
