import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import ReactTable from 'react-table'
import Error from '../../Error'
import { RTTools } from '../../common'
import '../../common/react-table/style.css'
import styled from 'styled-components'
import { colors } from '../../../theme/colors'

const Refresh = styled.div`
  font-weight: bold;
  display: flex;
  border: 1 px solid red;
  margin-left: auto;
`

const RefreshFakeLink = styled.div`
  font-weight: bold;
  color: ${colors.link};
  cursor: pointer;
`

class ChatsListComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
      chatDataResults: [],
      isLoading: true,
      lastRetrieved: 0
    }
    this.interval = setInterval(() => {
      this.setState({lastRetrieved: this.state.lastRetrieved + 1})
    }, 60000)
  }

  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }

  componentDidMount = async () => {
    this.setState({isLoading: false, chatDataResults: await this.props.fetchChatsItems(this.props.attorney)})

    const queryParams = {}
    if (this.props.location.search) {
      const queryParameterStrings = this.props.location.search.slice(1).split("&")
      queryParameterStrings.forEach((string) => {
        const keyValues = string.split("=")
        queryParams[keyValues[0]] = keyValues[1]
      })
    }

    if (queryParams.channelUrl) {
      this.props.setChatWindowShowing(true)
      this.props.setChatCurrentChannelUrl(queryParams.channelUrl)
      if (this.props.attorney.globalAdmin) {
          const channel = this.state.chatDataResults.find(result => result.channel_url === queryParams.channelUrl)
          if (channel) {
            this.props.selectAttorneyFromChannelMembers(channel.members)
          }
      }
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  reloadChatData = async () => {
    this.setState({isLoading:true, chatDataResults: []})
    this.setState({isLoading:false, chatDataResults: await this.props.fetchChatsItems(this.props.attorney)})
    this.setState({lastRetrieved: 0})
    this.interval = setInterval(() => {
      this.setState({lastRetrieved: this.state.lastRetrieved + 1})
    }, 60000)
  }

  render() {
    const buildChatColumns = RTTools.columns.buildChatColumns
    const s = this.state.lastRetrieved > 1 ? "s" : ""
    let lastRetrieved = ""
    if (this.state.lastRetrieved <= 60) {
      lastRetrieved = this.state.lastRetrieved ? `${this.state.lastRetrieved} minute${s} ago` : "just now"
    } else {
      lastRetrieved = "over an hour ago"
      clearInterval(this.interval)
    }

    return (
      <>
        <Refresh>
          Retrieved {lastRetrieved} •&nbsp;
          <RefreshFakeLink onClick={this.reloadChatData}>
            Refresh
          </RefreshFakeLink>
        </Refresh>
        <Segment>
          <Error.Boundary>
            <ReactTable
              data={this.state.chatDataResults}
              defaultSorted={[{
                id: "unread_message_count",
                desc: true
              }]}
              noDataText={this.state.isLoading ? "Loading" : "No Chat Data Found"}
              columns={buildChatColumns(
                this.props.history,
                this.props.allChats?.length,
                this.props.setChatWindowShowing,
                this.props.setChatCurrentChannelUrl,
                this.props.selectAttorneyFromChannelMembers,
                this.props.attorney?.globalAdmin
                )}
                pageSize={this.state.pageSize}
                showPageSizeOptions={false}
                minRows={RTTools.preferences.minRows}
                className={RTTools.preferences.RowClassName}
                onPageChange={this.handlePageChange}
                ref={(r)=>this.reactTable=r}
              />
          </Error.Boundary>
        </Segment>
      </>
    )
  }

}

export default ChatsListComponent
