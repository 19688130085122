import types from "./types"

const INITIAL_STATE = {
  items: [],
  currentlyViewing: null,
}
const regionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_REGIONS: {
      const { value } = action
      return {
        ...state,
        items: value
      }
    }

    default:
      return state
  }
}

export default regionsReducer
