import types from './types.js'

const setCalls = (callType, value) => ({
  type: types.SET_CALLS,
  callType,
  value,
})

const setCallsRetrievedAt = callType => ({
  type: types.SET_CALLS_RETRIEVED_AT,
  callType,
})

const setCurrentlyViewing = value => ({
  type: types.SET_CURRENTLY_VIEWING,
  value,
})

const setCallRequestHistory = (ticketObjectId, callHistory) => ({
  type: types.SET_CALL_REQUEST_HISTORY,
  ticketObjectId,
  callHistory,
})

export default {
  setCurrentlyViewing,
  setCallsRetrievedAt,
  setCalls,
  setCallRequestHistory,
}
