import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
// import { CASE_WINIT_FEE } from '../../../../constants'
import { StyledElements } from '../../../common'

const PricePreviewComponent = ({ name, companyName, avatarUrl, pricing }) => {
  const { winItPrice, attorneyPrice } = pricing
  return (
    <List.Item>
      <List.Content>
        <List.Header>
          {name} ({companyName}){' '}
        </List.Header>
        <List.Description>
          {/* <div>Price to Fight: ${(winItPrice - CASE_WINIT_FEE).toFixed(2)}</div> */}
          {/* <div>WinIt Platform Fee: ${CASE_WINIT_FEE.toFixed(2)}</div> */}
          <div>
            <StyledElements.Italicize>
              User Price Quote:{' '}
            </StyledElements.Italicize>{' '}
            ${winItPrice.toFixed(2)}
          </div>
          <div>
            <StyledElements.Italicize>
              Attorney Price:{' '}
            </StyledElements.Italicize>{' '}
            ${attorneyPrice.toFixed(2)}
          </div>
        </List.Description>
      </List.Content>
    </List.Item>
  )
}

PricePreviewComponent.propTypes = {
  name: PropTypes.string,
  companyName: PropTypes.string,
  avatarUrl: PropTypes.string,
  pricing: PropTypes.object,
}

export default PricePreviewComponent
