import styled from 'styled-components'
import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'

import { editCustomerInfo } from '../duck/services'
import { useHistory } from 'react-router-dom'
import { emailValidation } from '../../../utilities'
import SharedHeader from '../shared/SharedHeader'
import SharedCloseBtn from '../shared/SharedCloseBtn'
import SharedSubmitBtn from '../shared/SharedSubmitBtn'

const EditCustomerInfo = ({ customer, success, error }) => {
  const [showCustomerModal, setShowCustomerModal] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState(customer.email || "")
  const history = useHistory()

  const onSubmitHandler = ev => {
    ev.preventDefault()

    editCustomerInfo(customer.id, {
      first_name: firstName,
      last_name: lastName,
      email: email,
    }).then(res => {
      if (res.status === 200) {
        setShowCustomerModal(false)
        success(res.message)
        setFirstName("")
        setLastName("")
        setEmail("")
        setTimeout(() => { history.go(0) }, 3000)
      } else if (res.status === 400) {
        setShowCustomerModal(false)
        error(res.error)
      }
    })
  }

  return (
    <>
      <SharedHeader onClick={() => setShowCustomerModal(true)} name={'Edit Customer'} />
      <Modal
        open={showCustomerModal}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>Edit Customer Info</Modal.Header>
        <Modal.Content>
          <ModalWrap>
            <form onSubmit={onSubmitHandler}>
              <label htmlFor="first_name">First Name</label>
              <input value={firstName} type="text" required onChange={(ev) => setFirstName(ev.target.value)} />
              <label htmlFor="last_name">Last Name</label>
              <input value={lastName} type="text" required onChange={(ev) => setLastName(ev.target.value)} />
              <label htmlFor="email">Email</label>
              <input value={customer.email} type="text" onChange={(ev) => setEmail(ev.target.value)} />
            </form>
            <BtnWrap>
              <SharedCloseBtn type='button' className='close' onClick={() => setShowCustomerModal(false)} name='Close' />
              <SharedSubmitBtn type='submit' className='submit' onClick={onSubmitHandler} disabled={!firstName || !lastName || !emailValidation(email)} value='Edit' />
            </BtnWrap>
          </ModalWrap>
        </Modal.Content>
      </Modal>
    </>
  )
}

const ModalWrap = styled.div`
  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
  }

  input {
    width: 250px;
    height: 30px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 0 10px;
    margin: 10px 0;
  }
`

const BtnWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`

export default EditCustomerInfo