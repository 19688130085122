import React, { Component } from 'react';
import { Header } from 'semantic-ui-react'

import { StyledElements } from '../../../common'
import {
  TICKET_STATUS_MAP,
  TICKET_STATUS_DESCRIPTION_MAP,
  TICKET_STATUS_ICON_MAP,
  POSSIBLE_STATUS_CHANGES
} from '../../../../constants'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';
import InlineTableEditButtons from '../../../common/react-table/InlineTableEditButtons';

class EditStatusInlineComponent extends Component {
  buildOptionsArray = (arrayOfKeys, currentStatus) => {
    const result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: TICKET_STATUS_MAP[key],
        content: <Header
          icon={TICKET_STATUS_ICON_MAP[key]}
          content={TICKET_STATUS_MAP[key]}
          subheader={TICKET_STATUS_DESCRIPTION_MAP[key]}
        />
      }
    })
    if (currentStatus) {
      result.push({
        key: currentStatus,
        value: currentStatus,
        text: TICKET_STATUS_MAP[currentStatus],
        content: <Header
          icon={TICKET_STATUS_ICON_MAP[currentStatus]}
          content={TICKET_STATUS_MAP[currentStatus]}
          subheader={TICKET_STATUS_DESCRIPTION_MAP[currentStatus]}
        />
      })
    }
    return result
  }

  getPossibleStatuses = () => {
    const { attorney } = this.props
    const { status } = this.props.ticket
    if (attorney.globalAdmin) {
      const allValues = Object.keys(TICKET_STATUS_MAP)
      return this.buildOptionsArray(allValues)
    }
    const possibleKeys = POSSIBLE_STATUS_CHANGES[status]
    if (!possibleKeys) return []
    const result = this.buildOptionsArray(possibleKeys, status)
    return result
  }

  handleCancel = (event) => {
    event.preventDefault()
    const { objectId } = this.props
    this.props.clearEditValue(objectId)
    return false
  }

  handleChange = (event) => {
    const { objectId } = this.props
    const { value } = event.target
    if (value) {
      this.props.editUpdate(objectId, value)
    }
  }

  updateStatus = (e) => {
    e.preventDefault()
    this.setState({ isEditing: false })
    const { objectId, editTicketField, ticket } = this.props
    if (editTicketField !== ticket.status) {
      this.props.update(objectId, { status: editTicketField })
    }
    return false
  }

  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    return <StyledElements.FillForm>
      <StyledElements.FillSelect onChange={this.handleChange} value={editTicketField || ticket.status}>
        {this.getPossibleStatuses().map(statusOption => {
          return <option
            key={statusOption.value}
            value={statusOption.value}
          >
            {statusOption.text}
          </option>
        })}
      </StyledElements.FillSelect>
      {
        editTicketField && ticket.status !== editTicketField ?
          <InlineTableEditButtons
            cancelFunc={this.handleCancel}
            updateFunc={this.updateStatus}
          />
          : null
      }
    </StyledElements.FillForm>
  }

  render() {
    const { objectId, ticket, attorney } = this.props
    const { status } = ticket
    const possibleChanges = POSSIBLE_STATUS_CHANGES[status] || attorney.globalAdmin
    const RenderEdit = this.renderEdit
    return (
      <SpecificOverlayContainer
        overlayKey={objectId}
        LoadingComponent={() => <StyledElements.Italicize>Loading...</StyledElements.Italicize>}
        ignoreError={() => <StyledElements.Italicize>Issue Updating Ticket...</StyledElements.Italicize>}
        UpdatingComponent={() => <StyledElements.Italicize>Updating...</StyledElements.Italicize>}
      >
        {
          possibleChanges ? <RenderEdit /> :
            status ?
              TICKET_STATUS_MAP[status]
              :
              <StyledElements.Italicize>
                No Status
              </StyledElements.Italicize>
        }
      </SpecificOverlayContainer>
    );
  }
}


export default EditStatusInlineComponent;
