import { connect } from 'react-redux'
import { authenticationSelectors } from '../../authentication/duck'
import { regionsSelectors } from '../../regions/duck'
import {
  processingThunks,
  processingActions,
  processingSelectors,
} from '../duck'
import { overlaySelectors } from '../../overlays/duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import RequiresApprovalListComponent from './RequiresApprovalListComponent'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.processing.requiresApproval

const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  regionFilter: processingSelectors.processingRegionFilterSelector(state),
  attorney: authenticationSelectors.attorneySelector(state),
  rtSettings: reactTableSelectors.settings(TICKET_TYPE, state),
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    TICKET_TYPE
  ),
  processing: regionsSelectors.regionsFilterBuilder(
    processingSelectors.requiresApprovalCasesSelector,
    processingSelectors.processingRegionFilterSelector
  )(state),
  retrievedAt: processingSelectors.retrievedAt(state, TICKET_TYPE),
})

const mapDispatchToProps = dispatch => ({
  fetchRequiresApprovalItems: () => dispatch(processingThunks.fetchRequiresApprovalItems()),
  setRegionFilter: region => dispatch(processingActions.setProcessingRegionFilter(region)),
  clearRegionFilter: () => dispatch(processingActions.setProcessingRegionFilter(null)),
  setResized: value => dispatch(reactTableActions.setResized(TICKET_TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TICKET_TYPE, value)),
  setFiltered: value => dispatch(reactTableActions.setFiltered(TICKET_TYPE, value)),
})

const RequiresApprovalListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequiresApprovalListComponent)

export default RequiresApprovalListContainer
