import React, { Component, Fragment } from 'react'
import { Form } from 'semantic-ui-react';

import SpecificOverlay from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import TrafficBondList from './TrafficBondList'
import { ScrollOverflowContent, StyledElements, Forms } from '../../../common'


class TrafficBondComponent extends Component {
  renderEdit = () => {
    return <StyledElements.EditContainer>
      <Form>
        <Forms.InlineEditButtons buttons={[
          {
            disabled: false,
            color: "green",
            text: `Charge Traffic Bond`,
            icon: "save",
            labelPosition: "right",
            onClick: () => this.props.handleCreateTrafficBond(),
            type: "submit"
          }
        ]} />
      </Form>
    </StyledElements.EditContainer>
  }
  render() {
    const { bonds, OVERLAY_KEY, isEditing, fetchTicket, upToDate } = this.props
    const RenderEdit = this.renderEdit
    return (
      <SpecificOverlay
        overlayKey={OVERLAY_KEY}
        centerFillStatus={true}
        ignoreError={true}
      >
        <Fragment>
          {
            isEditing ?
            <>
              <RenderEdit />
              <Forms.InlineEditButtons
                buttons={[
                  {
                    onClick: fetchTicket,
                    color: 'blue',
                    labelPosition: 'right',
                    icon: 'sync',
                    text: 'Update Traffic Bond List',
                  }
                ]}
              />
            </>
            : null
          }
          {
            bonds && bonds.length ? (
              <>
                { !upToDate && 
                  <StyledElements.Italicize>LIST NOT UP TO DATE</StyledElements.Italicize>
                }
                <ScrollOverflowContent>
                  <TrafficBondList bonds={bonds} />
                </ScrollOverflowContent>
              </>
            ) : (
              <>
                { !upToDate ? 
                  <StyledElements.Italicize>LIST NOT UP TO DATE</StyledElements.Italicize>
                  : <StyledElements.Italicize>No bond history.</StyledElements.Italicize>
                }
              </>
            )
          }
        </Fragment>
      </SpecificOverlay>
    )
  }
}

export default TrafficBondComponent
