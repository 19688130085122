import React, { Component } from 'react'
import { Dropdown, Popup } from 'semantic-ui-react'

class CreateTrafficTicketDropDown extends Component {
  AttorneysDropdown = () => {
    const { noMatchingCourt, attorneys, selectAttorney } = this.props
    return (
      <Dropdown
        style={{
          width: '300px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        disabled={noMatchingCourt}
        placeholder="Select quote"
        fluid
        selection
        clearable
        options={attorneys}
        onChange={selectAttorney}
      />
    )
  }
  PopupContent = () => {
    const { locationCourt } = this.props
    if (locationCourt) {
      return `Could not find a court matching name '${locationCourt}'. You can still create a Traffic Ticket, but
        you will need to manually set its County and Court once the ticket is created.`
    } else {
      return `There is no court on this ticket. You can still create a Traffic Ticket, but
        you will need to manually set its County and Court once the ticket is created.`
    }
  }
  render() {
    const { noMatchingCourt } = this.props
    // We want to add a tooltip on the (disabled) dropdown when no court is found
    if (noMatchingCourt) {
      return (
        <Popup
          content={this.PopupContent}
          trigger={
            <div>
              <this.AttorneysDropdown />
            </div>
          }
        />
      )
    }
    return <this.AttorneysDropdown />
  }
}

export default CreateTrafficTicketDropDown
