import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import ChatsRoutes from './ChatsRoutes'
import ViewChat from './ViewChat/ViewChatContainer'

const Wrapper = styled.div`
  position: relative;
`

class ChatsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contextRef: null
    }
  }

  componentDidMount = () => {
    this.props.attorneyChatEnabledCheck(this.props.attorney)
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  render() {
    const currentLocation = this.props.location.pathname
    let secondLevel = currentLocation.split('/').filter(v => v !== '')[1]
    if (!secondLevel) {
      secondLevel = 'all'
    }
    const menuName = secondLevel[0].toUpperCase() + secondLevel.slice(1)

    return (
      <Wrapper>
        <Menu stackable={true} tabular>
          <Menu.Item
            as={Link}
            name={menuName}
            active={
              secondLevel === 'all' || secondLevel === 'view'
            }
            to="/chats"
          >
            {menuName}
          </Menu.Item>
        </Menu>
        {this.props.chatWindowShowing ? (
          <ViewChat userId={this.props.attorney?.objectId || this.props.attorneyChatObjectId} /> 
        ) : null}
        <ChatsRoutes />
      </Wrapper>
    )
  }
}

export default ChatsComponent
