import { connect } from 'react-redux'
import { searchSelectors, searchActions } from './duck'
import SearchHomeComponent from './SearchHomeComponent'
import SEARCH_DESCRIBER from './SearchTypes'

const mapStateToProps = state => ({
  searchType: searchSelectors.type(state),
  search: SEARCH_DESCRIBER[searchSelectors.type(state)],
  loading: searchSelectors.loading(state),
  error: searchSelectors.error(state),
  // user: userSelector(state),
})

const mapDispatchToProps = dispatch => ({
  changeSearch: searchType => dispatch(searchActions.setSearchType(searchType)),
})

const SearchHomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchHomeComponent)

export default SearchHomeContainer
