import { connect } from 'react-redux'
import { attorneyThunks, attorneySelectors } from '../duck'
import { ticketsSelectors } from '../../tickets/duck'
import { ticketsThunks } from '../../tickets/duck'
import { overlaySelectors } from '../../overlays/duck'
import AssignAttorneyComponent from './AssignAttorneyComponent'
import { SPECIFIC_OVERLAYS_ITEMS } from '../../../constants'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  attorneys: attorneySelectors.attorneys(state),
  retrievedAt: attorneySelectors.retrievedAt(state),
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    SPECIFIC_OVERLAYS_ITEMS.attorneys
  ),
})

const mapDispatchToProps = dispatch => ({
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  fetchAttorneys: () => dispatch(attorneyThunks.fetchAttorneys()),
})

const AssignAttorneyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignAttorneyComponent)

export default AssignAttorneyContainer
