import moment from 'moment-timezone'
import {
  TICKET_STATUS,
  PROCESSING_PIPELINE_STATUS,
  FRONTEND_TICKET_TYPES,
  INTERNAL_REASONS,
} from '../constants'
import { getDate, makeApprovalDateForClassification } from './ticketTools'
import { leadsSelectors } from '../app/leads/duck'

/**
 * Builds an array of sections the ticket should be shown in
 * @param {TrafficTicket} ticket Ticket we would like to classify
 * @returns an array of classifications for the ticket
 */
const ticketClassifier = (ticket, state) => {
  if (!ticket) return null
  const result = []

  const UNASSIGNED_ACTIVE_STATUS = [
    TICKET_STATUS.temp,
    TICKET_STATUS.pendingQuote,
    TICKET_STATUS.pendingConf,
  ]

  // Unassigned Lead Check
  if (UNASSIGNED_ACTIVE_STATUS.includes(ticket.status) && !ticket.attorney) {
    result.push(FRONTEND_TICKET_TYPES.leads.unassigned)
    result.push(FRONTEND_TICKET_TYPES.leads.all)
  }

  // Active Lead Check
  if (UNASSIGNED_ACTIVE_STATUS.includes(ticket.status) && ticket.attorney) {
    result.push(FRONTEND_TICKET_TYPES.leads.active)
    result.push(FRONTEND_TICKET_TYPES.leads.all)
  }

  // Closed Lead Check
  if (
    ticket.status === TICKET_STATUS.processing &&
    ticket.processingPipeline === PROCESSING_PIPELINE_STATUS.requiresApproval
  ) {
    result.push(FRONTEND_TICKET_TYPES.leads.closed)
    result.push(FRONTEND_TICKET_TYPES.leads.all)
  }

  const hearingDate = getDate('hearingDate', ticket)

  // Closed Tickets Check
  const closedHearingDate = leadsSelectors.closedTicketsHearingDateSelector(
    state
  )
  const closedStart = new Date(closedHearingDate)
  closedStart.setHours(0, 0, 0, 0)
  const closedEnd = new Date(closedHearingDate)
  closedEnd.setHours(23, 59, 59, 999)
  if (
    ticket.status === TICKET_STATUS.closed &&
    hearingDate &&
    hearingDate >= closedStart &&
    hearingDate <= closedEnd
  ) {
    result.push(FRONTEND_TICKET_TYPES.leads.closedTickets)
    result.push(FRONTEND_TICKET_TYPES.leads.all)
  }

  // Processing Tickets Check
  if (
    ticket.status === TICKET_STATUS.processing &&
    ticket.processingPipeline === PROCESSING_PIPELINE_STATUS.approved
  ) {
    result.push(FRONTEND_TICKET_TYPES.processing.all)
    // Recently Approved Tickets
    const date = getDate('dateApproved', ticket)
    if (date) {
      const dateApproved = moment(date)
      const offsetWithUTC = moment.tz.zone('America/New_York').parse(new Date(date)) / 60; // offset with UTC in hours
      const startingHour = 16 + offsetWithUTC // Because we want ticket with dateApproved > 16 EST
      const targetDate =
        dateApproved.utc().hours() >= startingHour ? dateApproved.add(1, 'day') : dateApproved
      const category = moment(targetDate).format('L')
      result.push(makeApprovalDateForClassification(category))
    }
  }

  // Recent Hearing Tickets Check
  const today = new Date().setUTCHours(0, 0, 0, 0)
  const tomorrowBeforeMidnight = new Date().setTime(today + 86400000 + 86399999)
  if (
    hearingDate &&
    hearingDate <= tomorrowBeforeMidnight &&
    ticket.status === TICKET_STATUS.processing &&
    (ticket.processingPipeline === PROCESSING_PIPELINE_STATUS.approved ||
      !ticket.processingPipeline)
  ) {
    result.push(FRONTEND_TICKET_TYPES.hearings.recent)
  }

  // Needs Hearing Date Check
  if (
    ticket.status === TICKET_STATUS.processing &&
    !ticket.hearingDate &&
    (ticket.processingPipeline === PROCESSING_PIPELINE_STATUS.approved ||
      !ticket.processingPipeline)
  ) {
    result.push(FRONTEND_TICKET_TYPES.hearings.needsDate)
  }

  // Call Requests Check
  // const CALLS_STATUS = [
  //   TICKET_STATUS.processing,
  //   TICKET_STATUS.guilty,
  //   TICKET_STATUS.reduced
  // ]

  if (
    // CALLS_STATUS.includes(ticket.status) &&
    ticket.internalReason === INTERNAL_REASONS.call
  ) {
    result.push(FRONTEND_TICKET_TYPES.calls)
  }
  return result
}

export default ticketClassifier
