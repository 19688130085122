const encodeImageFileAsURL = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const processFile = async file => {
  const base64 = await encodeImageFileAsURL(file)
  return base64
}
