import FileSaver from 'file-saver'
import { stringTools } from './index'

export const saveAs = (fileSource, fileName) => {
  return FileSaver.saveAs(fileSource, fileName)
}

export const downloadImage = (imageUrl, fileName, prepend, append) => {
  const saveAsFileName = stringTools.buildFileName(
    imageUrl,
    fileName,
    prepend,
    append
  )
  return saveAs(imageUrl, saveAsFileName)
}
