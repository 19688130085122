import * as services from './services'
import { processingActions } from './index'
import { ticketsThunks, ticketsSelectors } from '../../tickets/duck'
import { overlaysActions } from '../../overlays/duck'
import { ticketTools, stringTools } from '../../../utilities'
import {
  NOTIFICATION_TYPES,
  FRONTEND_TICKET_TYPES,
  SPECIFIC_OVERLAYS_TYPE,
} from '../../../constants'

const updateProcessingList = () => (dispatch, getState) => {
  const state = getState()
  const PROCESSING_TYPES = [FRONTEND_TICKET_TYPES.processing.all]
  PROCESSING_TYPES.forEach(processingType => {
    const selector = ticketsSelectors.allTicketsByClassification(processingType)
    const processingIds = selector(state).map(ticketTools.mapTicketToObjectIds)
    const approvalsByDate = {}
    processingIds.forEach(id => {
      const ticket = ticketsSelectors.ticketByIdSelector(state, id)
      const category = ticketTools.getApprovalDateFromClassification(
        ticket.classifications
      )
      if (!approvalsByDate[category]) {
        approvalsByDate[category] = []
      }
      approvalsByDate[category].push(id)
    })
    Object.keys(approvalsByDate).forEach(category => {
      dispatch(
        processingActions.setProcessing(category, approvalsByDate[category])
      )
    })
    dispatch(processingActions.setProcessing(processingType, processingIds))
  })
}

const fetchProcessingItems = () => dispatch => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.processing.all
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getProccessingItems()
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(processingActions.setProcessingRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Processing Cases`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching processing items', error)
      dispatch(processingActions.setProcessingRetrievedAt(TICKET_TYPE))
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      // Status & Notification
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Processing Cases',
          message: error,
        })
      )
    })
}

const fetchRecentlyApprovedItems = dateString => dispatch => {
  const newDateString = stringTools.replaceAll(dateString, '/', '-')
  const TICKET_TYPE =
    FRONTEND_TICKET_TYPES.processing.recentlyApproved + '/' + dateString
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getRecentlyApprovedItems(newDateString)
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(processingActions.setProcessingRetrievedAt(dateString))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Recently Approved Cases for ${dateString}`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching processing items', error)
      dispatch(processingActions.setProcessingRetrievedAt(dateString))
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      // Status & Notification
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: `Issue Fetching Recently Approved Cases for ${dateString}`,
          message: error,
        })
      )
    })
}

const fetchSuspendedItems = () => dispatch => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.processing.suspended
  dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services
    .getSuspendedItems()
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(processingActions.setSuspendedCases(TICKET_TYPE, results))
      dispatch(processingActions.setProcessingRetrievedAt(TICKET_TYPE))
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: 'Fetched Suspended Cases',
        duration: 5
      }))
    })
    .catch(error => {
      console.error('Error fetching suspended items')
      dispatch(processingActions.setProcessingRetrievedAt(TICKET_TYPE))
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue Fetching Suspended Cases',
        message: error
      }))
  })
}

const fetchRequiresApprovalItems = () => dispatch => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.processing.requiresApproval
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getRequiresApprovalItems()
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(processingActions.setRequiresApprovalCases(TICKET_TYPE, results))
      dispatch(processingActions.setProcessingRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Cases Requiring Approval`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching items requiring approval', error)
      dispatch(processingActions.setProcessingRetrievedAt(TICKET_TYPE))
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      // Status & Notification
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Cases Requiring Approval',
          message: error,
        })
      )
    })
}

export default {
  fetchSuspendedItems,
  fetchRecentlyApprovedItems,
  fetchProcessingItems,
  fetchRequiresApprovalItems,
  updateProcessingList,
}
