import React from 'react'
import { Loader, Message } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { SPECIFIC_OVERLAYS_TYPE } from '../../../constants'
import { StyledElements } from '../../common'
import styled from 'styled-components'

const LoadingText = styled.p`
  padding: 10px;
  color: #b3b3b3;
  font-weight: bold;
  font-size: 1.4em;
`

const SpecificOverlayComponent = ({
  status,
  centerFillStatus,
  ignoreLoading,
  ignoreUpdating,
  ignoreError,
  loadingText,
  LoadingComponent,
  updatingText,
  UpdatingComponent,
  errorText,
  ErrorComponent,
  children,
  showSpinner,
  showSpinnerInline,
  spinnerSize,
}) => {
  if (status === SPECIFIC_OVERLAYS_TYPE.loading) {
    if (ignoreLoading) return children
    if (centerFillStatus) {
      return (
        <StyledElements.Centered>
          {showSpinner ? (
            <Loader size={spinnerSize} active inline={showSpinnerInline} />
          ) : null}
          {<LoadingText>{loadingText}</LoadingText>}
        </StyledElements.Centered>
      )
    }
    if (LoadingComponent) return <LoadingComponent />
    return (
      <div>
        {showSpinner ? <Loader active inline={showSpinnerInline} /> : null}
        {loadingText}
      </div>
    )
  }
  if (status === SPECIFIC_OVERLAYS_TYPE.updating) {
    if (ignoreUpdating) return children
    if (centerFillStatus) {
      return (
        <StyledElements.Centered>
          {showSpinner ? <Loader active inline={showSpinnerInline} /> : null}
          <LoadingText>{updatingText}</LoadingText>
        </StyledElements.Centered>
      )
    }
    if (UpdatingComponent) return <UpdatingComponent />
    return (
      <div>
        {showSpinner ? <Loader active inline={showSpinnerInline} /> : null}
        {updatingText}
      </div>
    )
  }
  if (status === SPECIFIC_OVERLAYS_TYPE.error) {
    if (ignoreError) return children
    if (centerFillStatus) {
      return (
        <StyledElements.Centered>
          <Message color="red">{errorText}</Message>
        </StyledElements.Centered>
      )
    }
    if (ErrorComponent) return <ErrorComponent />
    return <Message color="red">{errorText}</Message>
  }
  return children
}

SpecificOverlayComponent.defaultProps = {
  ignoreError: false,
  ignoreLoading: false,
  ignoreUpdating: false,
  showSpinner: true,
  showSpinnerInline: true,
  spinnerSize: 'medium',
  centerFillStatus: false,
  loadingText: 'Loading',
  errorText: 'Error performing action.',
  updatingText: 'Updating',
}

SpecificOverlayComponent.propTypes = {
  spinnerSize: PropTypes.string,
  showSpinner: PropTypes.bool,
  showSpinnerInline: PropTypes.bool,
  centerFillStatus: PropTypes.bool,
  status: PropTypes.oneOf(
    Object.keys(SPECIFIC_OVERLAYS_TYPE).map(key => SPECIFIC_OVERLAYS_TYPE[key])
  ),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  loadingText: PropTypes.string,
  LoadingComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  updatingText: PropTypes.string,
  UpdatingComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  errorText: PropTypes.string,
  ErrorComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  overlayKey: PropTypes.string.isRequired,
}

export default SpecificOverlayComponent
