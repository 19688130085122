import React from 'react'
import { Container } from 'semantic-ui-react'
import styled from 'styled-components'

const BasicContainer = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'start'};
`

const FillHeight = props => {
  if (props.basic) {
    return <BasicContainer>{props.children}</BasicContainer>
  } else {
    return <BasicContainer {...props}>
      <Container>
        {props.children}
      </Container>
    </BasicContainer>
  }
}

export default FillHeight
