import React, { Component, Fragment } from 'react'
import { Input } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'

class EditTicketIDComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.ticketIDInput = React.createRef()
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.ticketIDInput.inputRef.focus()
    }
  }
  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = event => {
    const { objectId } = this.props
    this.props.editUpdate(objectId, event.target.value)
  }
  updateTicketID = () => {
    const { objectId, editTicketField } = this.props
    if (editTicketField) {
      this.setState({ isEditing: false })
      this.props.update(objectId, { ticketID: editTicketField })
    }
  }
  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    return (
      <StyledElements.EditContainer>
        <form>
          <Input
            ref={input => {
              this.ticketIDInput = input
            }}
            name="ticketID"
            onChange={this.handleChange}
            value={
              editTicketField || editTicketField === ''
                ? editTicketField
                : ticket.ticketID
            }
            placeholder="TicketID"
            fluid
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                disabled: !this.props.editTicketField,
                color: 'green',
                text: 'Update TicketID',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updateTicketID,
              },
            ]}
          />
        </form>
      </StyledElements.EditContainer>
    )
  }
  render() {
    const { ticket, attorney } = this.props
    const { isEditing } = this.state
    if (!ticket || !attorney.globalAdmin) return null
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Ticket ID"
          isEditable={true}
          isEditing={isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <Fragment>
            {isEditing ? (
              <RenderEdit />
            ) : ticket.ticketID ? (
              ticket.ticketID
            ) : (
              <StyledElements.Italicize>
                No TicketID Set
              </StyledElements.Italicize>
            )}
          </Fragment>
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditTicketIDComponent
