import * as services from './services'
import { ticketsActions } from './index'
import { overlaysActions } from '../../overlays/duck'
import { ticketClassifier, specificOverlayTools } from '../../../utilities'
import {
  NOTIFICATION_TYPES,
  SPECIFIC_OVERLAYS_TYPE,
  SPECIFIC_OVERLAYS_CATEGORY,
} from '../../../constants'
import { leadsThunks } from '../../leads/duck'
import { processingThunks } from '../../processing/duck'
import { hearingsThunks } from '../../hearings/duck'
import { callsThunks } from '../../calls/duck'

const updateLists = () => dispatch => {
  dispatch(leadsThunks.updateLeadsLists())
  dispatch(processingThunks.updateProcessingList())
  dispatch(hearingsThunks.updateHearingsLists())
  dispatch(callsThunks.updateCallsLists())
}

const addAndClassifyTicket = ticket => (dispatch, getState) => {
  const state = getState()
  const newTicket = { ...ticket }
  newTicket.classifications = ticketClassifier(ticket, state)
  dispatch(ticketsActions.addTicket(newTicket))
  dispatch(updateLists())
}

const addAndClassifyManyTickets = tickets => (dispatch, getState) => {
  const state = getState()
  const classifiedTickets = tickets.map(ticket => {
    const newTicket = { ...ticket }
    newTicket.classifications = ticketClassifier(ticket, state)
    return newTicket
  })
  dispatch(ticketsActions.addManyTickets(classifiedTickets))
  dispatch(updateLists())
}

const addEvidenceImage = (objectId, ticketEvidence) => dispatch => {
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_CATEGORY.evidence
  )

  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      KEY,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )

  services
    .addTicketEvidence(objectId, ticketEvidence)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(ticketsActions.addNewTicketEvidence(objectId, result))
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      console.error('error adding evidence for a ticket', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: `Issue Adding Evidence for Ticket`,
          message: error,
        })
      )
    })
}

const addTicketImage = (objectId, ticketImage, ticketType) => dispatch => {
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      objectId,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )

  services
    .setTicketImage(objectId, ticketImage, ticketType)
    .then(result => {
      dispatch(addAndClassifyTicket(result))
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          objectId,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Updated Ticket #${result.ticketID}`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error updating a single ticket', error)
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          objectId,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Updating Ticket',
          message: error,
        })
      )
    })
  //Loading and error
}

const fetchTicket = objectId => dispatch => {
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      objectId,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getTicket(objectId)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          objectId,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(addAndClassifyTicket(result))
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Ticket #${result.ticketID}`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          objectId,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      console.error('error fetching a single ticket', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Ticket',
          message: error,
        })
      )
    })
}

const fetchTicketEvidence = objectId => dispatch => {
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_CATEGORY.evidence
  )
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      KEY,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getTicketEvidence(objectId)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(ticketsActions.addTicketEvidence(objectId, result))
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      console.error('error fetching evidence for a ticket', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: `Issue Fetching Evidence for Ticket`,
          message: error,
        })
      )
    })
}

const fetchTicketPortalEditHistory = objectId => dispatch => {
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_CATEGORY.portalEditHistory
  )
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      KEY,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getTicketPortalEditHistory(objectId)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(ticketsActions.addTicketPortalEditHistory(objectId, result))
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      console.error('error fetching portal edit history for a ticket', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: `Issue Fetching Portal Edit History for Ticket`,
          message: error,
        })
      )
    })
}

const fetchTicketPricePreview = objectId => dispatch => {
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_CATEGORY.pricePreview
  )
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      KEY,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  return services
    .getTicketPricePreview(objectId)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(ticketsActions.addTicketPricePreview(objectId, result))
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      dispatch(ticketsActions.addTicketPricePreview(objectId, []))
      console.error('error fetching price preview for a ticket', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: `Issue Fetching Price Preview for Ticket`,
          message: typeof error === 'string' ? error : error.message,
        })
      )
    })
}

const updateTicket = (objectId, updatesObject) => dispatch => {
    dispatch(
        overlaysActions.setSpecificOverlayStatus(
            objectId,
            SPECIFIC_OVERLAYS_TYPE.updating
        )
    )
    services
        .updateTicket(objectId, updatesObject)
        .then(result => {
            dispatch(addAndClassifyTicket(result))
            // Status & Notification
            dispatch(
                overlaysActions.setSpecificOverlayStatus(
                    objectId,
                    SPECIFIC_OVERLAYS_TYPE.success
                )
            )
            dispatch(
                overlaysActions.addNotification({
                    type: NOTIFICATION_TYPES.success,
                    title: `Updated Ticket #${result.ticketID}`,
                    duration: 5,
                })
            )
        })
        .catch(error => {
            console.error('error updating a single ticket', error)
            dispatch(
                overlaysActions.setSpecificOverlayStatus(
                    objectId,
                    SPECIFIC_OVERLAYS_TYPE.error
                )
            )
            dispatch(
                overlaysActions.addNotification({
                    type: NOTIFICATION_TYPES.error,
                    duration: 15,
                    title: 'Issue Updating Ticket',
                    message: error,
                })
            )
        })
}

const chargeTicket = ticketObjectId => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .chargeTicket(ticketObjectId)
    .then(updatedTicket => {
      dispatch(addAndClassifyTicket(updatedTicket))
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Charged User for Ticket #${updatedTicket.ticketID}`,
          duration: 10,
        })
      )
    })
    .catch(error => {
      console.error('error charging user for ticket', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Ticket Not Charged',
          message: error,
        })
      )
    })
}

const fetchTicketUserPaymentMethod = userObjectId => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .getTicketUserPaymentMethod(userObjectId)
    .then(paymentMethod => {
      dispatch(
        ticketsActions.addUsersPaymentMethod(userObjectId, paymentMethod)
      )
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched User Payment Method`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching users payment method', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Error Fetching User Payment Method',
          message: error,
        })
      )
    })
}

const createTrafficBond = ticket => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .createTrafficBond(ticket)
    .then(() => {
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Created TrafficBond`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching users payment method', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Error Fetching User Payment Method',
          message: error,
        })
      )
    })
}

const generateRetentionLetter = ticket => dispatch => {
  dispatch(overlaysActions.showLoader())
  services
    .generateRetentionLetter(ticket)
    .then((result) => {
      if (result) {
        dispatch(fetchTicket(ticket.objectId))
        dispatch(overlaysActions.hideLoader())
        dispatch(
          overlaysActions.addNotification({
            type: NOTIFICATION_TYPES.success,
            title: `Generated retention letter.`,
            duration: 5,
          })
        )
      }
    })
    .catch(error => {
      console.error('Error generating retention letter', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Error generating retention letter.',
          message: "Please contact the dev team if you encounter this error.",
        })
      )
    })
}

export default {
  addAndClassifyTicket,
  addAndClassifyManyTickets,
  fetchTicket,
  fetchTicketEvidence,
  fetchTicketPortalEditHistory,
  fetchTicketPricePreview,
  updateTicket,
  chargeTicket,
  fetchTicketUserPaymentMethod,
  addTicketImage,
  addEvidenceImage,
  createTrafficBond,
  generateRetentionLetter,
}
