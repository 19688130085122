import styled from 'styled-components'
import posed from 'react-pose'

const Modal = posed.div({
  open: {
    y: 0,
    // x: 0,
    // scale: 1,
    opacity: 1,
    transition: { duration: 1000 },
  },
  closed: {
    y: -100,
    // x: 300,
    // scale: 0,
    opacity: 0,
    transition: { duration: 1000 },
  },
})

const FloatingContainer = styled(Modal)`
  pointer-events: auto;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  z-index: 999999999999999;
  max-width: 300px;
  width: 100%;
  padding: 0.8em 0.8em 0.8em 0.8em;
  border-radius: ${props => props.theme.borderRadius || ''};
  color: ${props => props.theme.color || ''};
  background-color: ${props => props.theme.backgroundColor || ''};
  border: ${props => props.theme.border || ''};
`

export default FloatingContainer
