import React from 'react'
import { Route, Switch } from 'react-router-dom'

// import
import SearchHomeContainer from './SearchHomeContainer'
import SearchResultsContainer from './SearchResultsContainer'
import ViewTicket from './ViewSearchTicket/ViewSearchTicketContainer'
import NotFoundPage from '../404NotFound'

const SearchRoutes = () => {
  return (
    <Switch>
      <Route path="/search/view/:id" component={ViewTicket} />
      <Route path="/search/results" component={SearchResultsContainer} />
      <Route exact path="/search/" component={SearchHomeContainer} />
      <Route path="*" component={NotFoundPage} status={404} />
    </Switch>
  )
}

export default SearchRoutes
