import React from "react"
import { Header } from "semantic-ui-react"
import logo from '../Partially/Partially.png'
import styled from "styled-components"

const SharedHeader = ({ onClick, name }) => {
  return (
    <Header as="h4">
      <Header.Content>
        <Header.Subheader>
          <Button onClick={onClick}>
            <span>
              <img src={logo} alt="Partial.ly Logo" /> {name}
            </span>
          </Button>
        </Header.Subheader>
      </Header.Content>
    </Header>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  width: max-content;
  height: 60px;
  border-radius: 25px;
  padding: 15px;
  background-color: #21d4fd;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%);
  margin: 10px 0;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  img {
    width: 20px;
    margin: 0 10px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default SharedHeader