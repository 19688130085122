import React, { Component } from 'react'
import { Form, Dropdown } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import {
  LoadingComponent,
  UpdatingComponent,
} from '../../../tickets/DetailSection/ticketOverlays'
import ItemHeader from '../../../tickets/DetailSection/ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditGenderComponent extends Component {
  handleChange = (event, data) => {
    const { objectId, editUpdate } = this.props
    const genderInput = data.value
      ? data.value[0].toUpperCase() + data.value.slice(1).toLowerCase()
      : ''
    editUpdate(objectId, genderInput)
  }
  toggleEditStatus = () => {
    const { isEditing, setIsEditing, objectId } = this.props
    setIsEditing(objectId, !isEditing)
  }
  updateGender = () => {
    const {
      objectId,
      update,
      editUserField,
      setIsEditing,
      clearEdit,
    } = this.props

    update(objectId, { gender: editUserField })
    setIsEditing(objectId, false)
    clearEdit(objectId)
  }

  genderContent = gender => {
    if (!gender) {
      return 'No Gender Set'
    }
    if (gender[0] === 'M' || gender[0] === 'm') {
      return 'Male'
    } else if (gender[0] === 'F' || gender[0] === 'f') {
      return 'Female'
    }
  }

  renderEdit = () => {
    const { user, editUserField } = this.props
    const genderList = ['Male', 'Female']
    const options = genderList.map(gender => ({
      text: gender,
      value: gender,
    }))
    return (
      <StyledElements.EditContainer>
        <Form>
          <Dropdown
            fluid
            selection
            options={options}
            onChange={this.handleChange}
            value={
              editUserField === null
                ? this.genderContent(user.gender)
                : editUserField
            }
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                color: 'green',
                text: 'Update Gender',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updateGender,
              },
            ]}
          />
        </Form>
      </StyledElements.EditContainer>
    )
  }
  render() {
    const { isEditing, user, overlayKey } = this.props
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Gender"
          isEditable={true}
          isEditing={!!isEditing}
          toggleState={this.toggleEditStatus}
        />
        <SpecificOverlayContainer
          overlayKey={overlayKey}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {isEditing ? (
            <RenderEdit />
          ) : user.gender ? (
            <div>{this.genderContent(user.gender)}</div>
          ) : (
            <StyledElements.Italicize>No Gender Set</StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditGenderComponent
