import React, { useEffect, useState } from "react"
import SaasAgreement from "../../activation/Confirm/saas-agreement.md"
import Markdown from 'react-markdown'
import styled from "styled-components"

const SaasAgreementsComponent = ({ attorney }) => {
    const [saasAgreement, setSaasAgreement] = useState('')
    const dateJoined = attorney.dateJoined.slice(0, 10)

    useEffect(() => {
        fetch(SaasAgreement)
            .then(res => res.text())
            .then(text => setSaasAgreement(text))
    }, [])

    return (
        <>
            <Text>Agreement Signed on: {dateJoined}</Text>
            <Markdown children={saasAgreement} />
        </>
    )
}

const Text = styled.h3`
    display: flex;
    justify-content: flex-end;
`

export default SaasAgreementsComponent