import { connect } from 'react-redux'
import { leadsActions } from "../duck"
import { leadIdFromRouterSelector } from '../duck/selectors'
import { ticketByIdSelector } from '../../tickets/duck/selectors'
import { ticketsThunks } from "../../tickets/duck"
import ViewTicketContainer from '../../tickets/ViewTicket/ViewTicketContainer'

const mapStateToProps = (state, props) => ({
  currentId: leadIdFromRouterSelector(state, props),
  currentTicket: ticketByIdSelector(state, leadIdFromRouterSelector(state, props))
})

const mapDispatchToProps = dispatch => ({
  fetchTicket: (objectId) => dispatch(ticketsThunks.fetchTicket(objectId)),
  setCurrentlyViewing: (ticket) => dispatch(leadsActions.setCurrentlyViewing(ticket))
})

const ViewLeadContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTicketContainer)

export default ViewLeadContainer
