import React, { Component, Fragment } from 'react';
import { Item, Grid, List } from 'semantic-ui-react'
import moment from 'moment'
import { StyledElements } from '../../common'

class ViewProfileComponent extends Component {
  render() {
    const { attorney } = this.props
    return (<Fragment>
      <Grid stackable stretched columns={2}>
        <Grid.Column computer={8} tablet={8} verticalAlign={"top"}>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>Name</Item.Header>
                {/* <Item.Meta>This is the name customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {attorney && attorney.name ? attorney.name : <StyledElements.Italicize>No Name</StyledElements.Italicize>}
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Header>Summary</Item.Header>
                {/* <Item.Meta>This is the attorney summary customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {attorney && attorney.meta && attorney.meta.summary ? attorney.meta.summary : <StyledElements.Italicize>No summary</StyledElements.Italicize>}
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Header>Company Name</Item.Header>
                {/* <Item.Meta>This is the attorney summary customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {
                    attorney && attorney.createdAt ?
                      attorney.companyName
                      :
                      <StyledElements.Italicize>
                        No Company Name
                      </StyledElements.Italicize>
                  }
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} verticalAlign={"top"}>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>WinIt Member Since</Item.Header>
                {/* <Item.Meta>This is the name customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {
                    attorney && attorney.createdAt ?
                      moment(attorney.createdAt).format("LLL")
                      :
                      <StyledElements.Italicize>
                        The beginning of time
                      </StyledElements.Italicize>
                  }
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Header>Contact Email</Item.Header>
                {/* <Item.Meta>This is the attorney summary customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {
                    attorney && attorney.contactEmail ?
                      attorney.contactEmail
                      :
                      <StyledElements.Italicize>
                        No Email Address
                      </StyledElements.Italicize>
                  }
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Header>Contact Phone</Item.Header>
                {/* <Item.Meta>This is the attorney summary customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {
                    attorney && attorney.contactPhone ?
                      attorney.contactPhone
                      :
                      <StyledElements.Italicize>
                        No Phone Number
                      </StyledElements.Italicize>
                  }
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Header>Address</Item.Header>
                {/* <Item.Meta>This is the attorney summary customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {
                    attorney && attorney.meta && attorney.meta.address && attorney.meta.address.length ?
                      <List items={attorney.meta.address} />
                      :
                      <StyledElements.Italicize>
                        No Address
                      </StyledElements.Italicize>
                  }
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Header>Contact</Item.Header>
                {/* <Item.Meta>This is the attorney summary customers see on WinIt</Item.Meta> */}
                <Item.Description>
                  {
                    attorney && attorney.meta && attorney.meta.contact && attorney.meta.contact.length ?
                      <List items={attorney.meta.contact} />
                      :
                      <StyledElements.Italicize>
                        No Contact Information
                      </StyledElements.Italicize>}
                </Item.Description>
                {/* <Item.Extra>Additional Details</Item.Extra> */}
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
      </Grid>

    </Fragment>);
  }
}

export default ViewProfileComponent;
