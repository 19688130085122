import React from 'react'
import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import { Dropdown, Header } from 'semantic-ui-react'
import { SPECIFIC_OVERLAYS_ITEMS } from '../../../constants'
import WINIT_SVG from '../../../assets/images/navigation-bar-logo-portal.svg'
import LoadingAttorneys from './LoadingAttorneys'

class AssignAttorneyComponent extends React.Component {
  componentDidMount() {
    const { retrievedAt, loading, fetchAttorneys } = this.props
    if (!loading && !retrievedAt) {
      fetchAttorneys()
    }
  }

  buildAttorneyListItems = () => {
    const { attorneys } = this.props
    if (attorneys && attorneys.length) {
      return attorneys.map(attorney => ({
        key: attorney.objectId,
        value: attorney.objectId,
        text: attorney.name,
        content: (
          <Header
            size="tiny"
            image={
              attorney.avatar && attorney.avatar.url
                ? attorney.avatar.url
                : WINIT_SVG
            }
            content={attorney.name}
            subheader={attorney.companyName}
          />
        ),
      }))
    }
    return []
  }

  handleChange = (event, data) => {
    if (this.props.handleChange) {
      return this.props.handleChange(data.value)
    }
  }

  renderList = () => {
    // return "coming soon!";
    // const { attorneys } = this.props
    // console.log('attorneys', attorneys)
    return (
      <Dropdown
        fluid
        selection
        options={this.buildAttorneyListItems()}
        value={this.props.currentAttorney || undefined}
        onChange={this.handleChange}
      />
    )
  }

  render() {
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        overlayKey={SPECIFIC_OVERLAYS_ITEMS.attorneys}
        LoadingComponent={LoadingAttorneys}
        errorText="Issue fetching attorneys"
      >
        <RenderList />
      </SpecificOverlay>
    )
  }
}

export default AssignAttorneyComponent
