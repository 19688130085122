import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import ProcessingRoutes from './ProcessingRoutes'

import { TAB_DESCRIPTIONS } from '../../constants'
import { CountLabel, DescribeTab } from '../common'
import RegionFilterMenu from '../regions/FilterMenu/FilterMenuContainer'

class ProcessingComponent extends Component {
  render() {
    const currentLocation = this.props.location.pathname
    const secondLevel = currentLocation.split('/').filter(v => v !== '')[1]
    const { currentlyViewing } = this.props
    return (
      <Fragment>
        <Menu stackable={true} tabular>
          <Menu.Item
            as={Link}
            name="All"
            active={secondLevel === 'all'}
            to="/processing/all"
          >
            All
            <CountLabel
              key={`allProcessing-count-${this.props.allProcessing.length}`}
              in={!!this.props.allProcessingRetrievedAt}
              loading={this.props.allProcessingLoading}
              value={this.props.allProcessing.length}
            />
          </Menu.Item>
          <Menu.Item
            as={Link}
            name="Recently Approved"
            active={secondLevel === 'recent'}
            to="/processing/recent"
          >
            Recently Approved
            <CountLabel
              key={`recentlyApproved-count-${
                this.props.recentlyApproved.length
              }`}
              in={!!this.props.recentlyApprovedRetrievedAt}
              loading={this.props.recentlyApprovedLoading}
              value={this.props.recentlyApproved.length}
            />
          </Menu.Item>
          <Menu.Item
            as={Link}
            name="Requires Approval"
            active={secondLevel === 'requiresApproval'}
            to="/processing/requiresApproval"
          >
            Requires Approval
            <CountLabel
              key={`requiresApproval-count-${
                this.props.requiresApproval.length
              }`}
              in={!!this.props.requiresApproval}
              loading={this.props.requiresApprovalLoading}
              value={this.props.requiresApproval.length}
            />
          </Menu.Item>
          <Menu.Item
            as={Link}
            name="Suspensions"
            active={secondLevel === 'suspensions'}
            to="/processing/suspensions"
          >
            Suspended
            <CountLabel
              key={`suspensions-count-${
                this.props.suspended.length
              }`}
              in={!!this.props.suspendedRetrievedAt}
              loading={this.props.suspendedLoading}
              value={this.props.suspended.length}
            />
          </Menu.Item>
        </Menu>
        {['all', 'recent', 'suspensions, requiresApproval'].includes(secondLevel) ? (
          <DescribeTab title={secondLevel}>
            {TAB_DESCRIPTIONS.processing[secondLevel]}
          </DescribeTab>
        ) : null}
        {!currentlyViewing ? (
          <RegionFilterMenu
            vertical={false}
            active={this.props.regionFilter}
            setFilter={this.props.setRegionFilter}
          />
        ) : null}
        <ProcessingRoutes />
      </Fragment>
    )
  }
}

export default ProcessingComponent
