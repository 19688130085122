import React from 'react'
import { Route, Switch } from 'react-router-dom'

import CreateTicketContainer from './createTicket/CreateTicketContainer'
import CreateAttorneyContainer from './createAttorney/CreateAttorneyContainer'
import ExportsContainer from './Exports/ExportsContainer'
import LinkGeneratorContainer from './linkGenerator/LinkGeneratorContainer'
import AllowCCContainer from './allowCreditCard/AllowCCContainer'
import NotFoundPage from '../404NotFound'


const AdminRoutes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/admin/ticket" component={CreateTicketContainer} />
        <Route exact path="/admin/attorney" component={CreateAttorneyContainer} />
        <Route exact path="/admin/exports" component={ExportsContainer} />
        <Route exact path="/admin/linkGenerator" component={LinkGeneratorContainer} />
        <Route exact path="/admin/allowCC" component={AllowCCContainer} />
        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    </>
  )
}

export default AdminRoutes
