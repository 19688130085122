import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ticketsSelectors, ticketsThunks } from '../../tickets/duck'
import PartiallyComponent from "./PartiallyComponent";
import {usersThunks} from "../../users/duck";
import { overlaysActions } from '../../overlays/duck';
import { NOTIFICATION_TYPES } from '../../../constants';
import { attorneySelector } from '../../authentication/duck/selectors';

const mapStateToProps = (state, props) => {
  return {
    user: ticketsSelectors.userByTicketIdSelector(state, props.objectId),
    attorney: attorneySelector(state)
  }
}

const mapDispatchToProps = dispatch => ({
  fetchUser: objectId => dispatch(usersThunks.fetchUser(objectId)),
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  success: (title, message) => {
    dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        duration: 10,
        title,
        message
      })
    )
  },
  error: (title, message) => {
    dispatch(
      overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 10,
        title,
        message
      })
    )
  }
})

const PartiallyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartiallyComponent)

export default withRouter(PartiallyContainer)
