import { Segment, Button, Form } from 'semantic-ui-react'
import styled from 'styled-components'
import { colors } from '../../theme/colors'

export const FillForm = styled(Form)`
  width: 100%;
`
// export const FillForm = styled.form`
//   width: 100%;
// `

export const PaddedButton = styled(Button)`
  &&&& {
    margin: 5px 5px 0 0;
  }
`

export const FillSelect = styled.select`
  width: 100%;
  height: auto;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  background-color: white;
  border: solid 1px #e5e5e5;
  option {
    padding: 0 4px;
  }
  &:focus {
    outline: none;
    border: solid 1px #367bb8;
    box-shadow: ;
  }
`

export const CenteredSegment = styled(Segment)`
  &&& {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`

export const Centered = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Spacer = styled.div`
  height: ${props => props.height};
`
export const Divider = styled.div`
  height: 100%;
  width: 1px;
  display: inline-block;
  background: ${colors.label.background};
  margin: 0 10px 0 10px;
  height: 12px;
`

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  ${props =>
    props.isEditing
      ? `
  padding: 7px;
  background-color: #f7f7f8;
  border: solid 1px #c8c8c9;
  `
      : ``}
`

export const EditContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 0;
  ${props => (props.above ? 'z-index: 200;' : '')}
  ${props => (props.above ? 'position: fixed;' : '')}
`
export const Italicize = styled.span`
  font-style: italic;
  color: ${props => props.color || 'black'};
  opacity: ${props => (props.color ? 1 : props.opacity || 0.6)};
`

export const Bold = styled.span`
  font-style: normal;
  font-weight: bold;
  color: ${props => props.color || 'black'};
  opacity: ${props => (props.color ? 1 : props.opacity || 0.6)};
`

export const FakeLink = styled.span`
  color: #4183c4;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    color: #1e70bf;
    ${'' /* text-direction: underline; */}
  }
  font-style: ${props => (props.italicize ? 'italic' : 'normal')};
  font-size: ${props => props.size || '13px'};
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const TopRowSplit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
`
