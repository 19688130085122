import { connect } from 'react-redux'
import ViewChatComponent from './ViewChatComponent'
import {chatsSelectors, chatsThunks} from '../duck'

const mapStateToProps = (state, props) => ({
  currentChannelUrl: chatsSelectors.currentChannelUrl(state),
})

const mapDispatchToProps = dispatch => ({
  setChatWindowShowing: (bool) => dispatch(chatsThunks.setChatWindowShowing(bool)),
  setCurrentChannelUrl: (currentChannelUrl) => dispatch(chatsThunks.setChatCurrentChannelUrl(currentChannelUrl)),
})

const ViewChatContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewChatComponent)

export default ViewChatContainer
