import * as services from "./services"
import {
  NOTIFICATION_TYPES,
  FRONTEND_TICKET_TYPES,
  SPECIFIC_OVERLAYS_TYPE
} from "../../../constants"
import { overlaysActions } from "../../overlays/duck"
import { setTicket, setCreatedAttorney, setUnassignedActiveLeadsExport, setClosedTicketsExport, setLookupTicketsExport } from './actions'
import resolvedDataFilterCSV from '../../common/csv/resolvedDataFilterCSV'
import resolvedLookupDataFilterCSV from '../../common/csv/resolvedLookupDataFilterCSV'

const createTrafficTicket = (ticketInfo) => (dispatch, getState) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.lookupAll
  dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.createTrafficTicket(ticketInfo)
    .then(result => {
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: `Created Ticket: ${result.ticketID}`,
        duration: 10
      }))
      dispatch(setTicket(result.objectId))
    })
    .catch(error => {
      console.error('error creating traffic ticket: ', error)
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue Creating Traffic Ticket: ',
        message: error
      }))
    })
}


const createAttorney = (attorneyInfo) => (dispatch, getState) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.lookupAll
  dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.createAttorney(attorneyInfo)
    .then(result => {
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.success,
        title: `Created Attorney: ${result.name}`,
        duration: 10
      }))
      dispatch(setCreatedAttorney(result.name))
    })
    .catch(error => {
      console.error('error creating attorney: ', error)
      dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue Creating Attorney: ',
        message: error
      }))
    })
}

const getTrafficTicketsExport = (queryObject) => (dispatch, getState) => {
  const exportType = queryObject.exportType
  if (exportType === "Closed_Tickets") {
    dispatch(setClosedTicketsExport([]))
  } else if (exportType === "Unassigned_Active_Leads") {
    dispatch(setUnassignedActiveLeadsExport([]))
  }
  services.getTrafficTicketsExport(queryObject)
    .then(result => {
      if (result && !result.length) {
        throw "No tickets match search criteria"
      }
      const formattedTickets = resolvedDataFilterCSV(result)
      if (exportType === "Closed_Tickets") {
        dispatch(setClosedTicketsExport(formattedTickets))
      } else if (exportType === "Unassigned_Active_Leads") {
        dispatch(setUnassignedActiveLeadsExport(formattedTickets))
      }
    })
    .catch(error => {
      console.error('error fetching export data: ', error)
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue fetching export data: ',
        message: error
      }))
    })
}

const getLookupTicketsExport = (queryObject) => (dispatch, getState) => {
  dispatch(setLookupTicketsExport([]))
  services.getLookupTicketsExport(queryObject)
    .then(result => {
      if (result && !result.length) {
        throw "No tickets match search criteria"
      }
      const formattedTickets = resolvedLookupDataFilterCSV(result)
      dispatch(setLookupTicketsExport(formattedTickets))
    })
    .catch(error => {
      console.error('error fetching export data: ', error)
      dispatch(overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 15,
        title: 'Issue fetching export data: ',
        message: error
      }))
    })
}


export default {
  createTrafficTicket,
  createAttorney,
  getTrafficTicketsExport,
  getLookupTicketsExport
}
