import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import moment from 'moment'

import { DATE_FORMATS } from '../../../../constants'
import { StyledElements } from '../../../common'
import {
  POSSIBLE_INTERNAL_STATUS,
  CALLS_STATUS,
  CALLS_STATUS_TEXT,
} from '../../../../constants'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import ItemHeader from '../ItemHeader'

class EditCallStatusInlineComponent extends Component {
  buildOptionsArray = (arrayOfKeys, currentStatus) => {
    const result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: CALLS_STATUS_TEXT[key],
      }
    })
    return result
  }

  getPossibleStatuses = () => {
    const { internalReason } = this.props.ticket
    const possibleKeys = POSSIBLE_INTERNAL_STATUS[internalReason]
    if (!possibleKeys) return []
    const result = this.buildOptionsArray(possibleKeys, internalReason)
    return result
  }

  updateStatus = status => event => {
    event.preventDefault()
    const { objectId, ticket } = this.props
    if (status !== ticket.internalReason) {
      this.props.update(objectId, { internalReason: status })
    }
    return false
  }

  render() {
    const { ticket } = this.props
    if (ticket && ticket.internalReason === CALLS_STATUS.call) {
      let titleString = 'User Has Requested a Call'
      if (ticket.callRequestDate) {
        titleString += ` on ${moment(ticket.callRequestDate).format(
          DATE_FORMATS.FULL_DATE_TIME
        )}`
      }
      const RenderOptions = this.renderOptions
      return (
        <Segment color="orange" raised={true} compact>
          <ItemHeader
            title={titleString}
            isEditable={false}
            isEditing={false}
            toggleState={() => {}}
          />
          <RenderOptions />
        </Segment>
      )
    }
    return null
  }

  renderEdit = () => {
    // const { editTicketField, ticket } = this.props
    return (
      <StyledElements.FillForm>
        {this.getPossibleStatuses().map(statusOption => {
          return (
            <StyledElements.PaddedButton
              key={statusOption.value}
              onClick={this.updateStatus(statusOption.value)}
            >
              {statusOption.text}
            </StyledElements.PaddedButton>
          )
        })}
      </StyledElements.FillForm>
    )
  }

  renderOptions = () => {
    const { objectId, ticket, attorney } = this.props
    const { internalReason } = ticket
    const possibleChanges =
      POSSIBLE_INTERNAL_STATUS[internalReason] || attorney.globalAdmin
    const RenderEdit = this.renderEdit
    return (
      <SpecificOverlayContainer
        overlayKey={objectId}
        LoadingComponent={() => (
          <StyledElements.Italicize>Loading...</StyledElements.Italicize>
        )}
        ignoreError={() => (
          <StyledElements.Italicize>
            Issue Updating Ticket...
          </StyledElements.Italicize>
        )}
        UpdatingComponent={() => (
          <StyledElements.Italicize>Updating...</StyledElements.Italicize>
        )}
      >
        {possibleChanges ? (
          <RenderEdit />
        ) : internalReason ? (
          CALLS_STATUS[internalReason]
        ) : (
          <StyledElements.Italicize>No Call Status</StyledElements.Italicize>
        )}
      </SpecificOverlayContainer>
    )
  }
}

export default EditCallStatusInlineComponent
