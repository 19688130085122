import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ticketsThunks, ticketsSelectors } from '../../duck'
import { usersThunks } from '../../../users/duck'
import { authenticationSelectors } from '../../../authentication/duck'
import UserInfoComponent from './UserInfoComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  user: ticketsSelectors.userByTicketIdSelector(state, props.objectId),
  paymentDetails: ticketsSelectors.userByTicketIdSelector(state, props.objectId)
    ? ticketsSelectors.userByTicketIdSelector(state, props.objectId)
        .paymentDetails
    : null,
  attorney: authenticationSelectors.attorneySelector(state),
})

const mapDispatchToProps = dispatch => ({
  getPaymentData: userObjectId =>
    dispatch(ticketsThunks.fetchTicketUserPaymentMethod(userObjectId)),
  isUserBlocked: userObjectId =>
      dispatch(usersThunks.isUserBlocked(userObjectId)),
  refreshPayment: userObjectId =>
    dispatch(usersThunks.checkPaymentMethod(userObjectId)),
  warnMissingPaymentProcessor: user =>
    dispatch(usersThunks.warnMissingPaymentProcessor(user)),
})

const UserInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfoComponent)

export default withRouter(UserInfoContainer)
