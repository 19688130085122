import { connect } from 'react-redux'
import connectFuncs from './connectFunctions'

import EditEmailComponent from './EditEmailComponent'

const EDIT_KEY = 'email'

const { mapStateToProps, mapDispatchToProps } = connectFuncs(EDIT_KEY)

const EditEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmailComponent)

export default EditEmailContainer
