import React, { Component } from 'react'
import { Input, Form } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import { phoneNumbers } from '../../../../utilities'
import {
  LoadingComponent,
  UpdatingComponent,
} from '../../../tickets/DetailSection/ticketOverlays'
import ItemHeader from '../../../tickets/DetailSection/ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditPhoneNumberComponent extends Component {
  constructor(props) {
    super(props)
    this.phoneInput = React.createRef()
  }
  componentDidUpdate(prevProps) {
    const wasPreviouslyEditing = prevProps.isEditing
    const { isEditing } = this.props
    if (isEditing && !wasPreviouslyEditing) {
      this.phoneInput.current.focus()
    }
  }
  handleChange = event => {
    const { objectId, editUpdate } = this.props
    const phoneInputValue = event.target.value
    editUpdate(objectId, phoneInputValue)
  }
  toggleEditStatus = () => {
    const { isEditing, setIsEditing, objectId } = this.props
    setIsEditing(objectId, !isEditing)
  }
  updatePhoneNumber = () => {
    const {
      objectId,
      update,
      editUserField,
      setIsEditing,
      clearEdit,
    } = this.props

    update(objectId, { phoneNumber: editUserField })
    setIsEditing(objectId, false)
    clearEdit(objectId)
  }
  renderEdit = () => {
    const { user, editUserField } = this.props
    return (
      <StyledElements.EditContainer>
        <Form>
          <Input
            fluid
            ref={this.phoneInput}
            onChange={this.handleChange}
            value={phoneNumbers.usFormat(
              editUserField === null ? user.phoneNumber : editUserField
            )}
            placeholder="2123214321"
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                color: 'green',
                text: 'Update Phone Number',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updatePhoneNumber,
              },
            ]}
          />
        </Form>
      </StyledElements.EditContainer>
    )
  }
  render() {
    const { isEditing, user, overlayKey } = this.props
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Phone Number"
          isEditable={true}
          isEditing={!!isEditing}
          toggleState={this.toggleEditStatus}
        />
        <SpecificOverlayContainer
          overlayKey={overlayKey}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {isEditing ? (
            <RenderEdit />
          ) : user.phoneNumber ? (
            phoneNumbers.usFormat(user.phoneNumber)
          ) : (
            <StyledElements.Italicize>No Phone Number</StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditPhoneNumberComponent
