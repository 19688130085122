import React from 'react'
import moment from 'moment'
import { Image, Popup, Label, List } from 'semantic-ui-react'
import styled from 'styled-components'

import { StyledElements } from '../../../common'
import AttorneyLogo from '../../../../assets/images/navigation-bar-logo-portal.svg'

const Since = styled.div`
  font-style: italic;
  font-size: 0.9em;
  padding: 5px 0 0 0;
`

const Summary = styled.div`
  padding: 5px 0 0 0;
  font-size: 0.9em;
`

const Content = ({ attorney }) => {
  return (
    <div>
      {attorney.summary ? <Summary>{attorney.summary}</Summary> : ''}
      {attorney.createdAt ? (
        <Since>{`Member since ${moment(attorney.createdAt).format(
          'MMMM Qo YYYY'
        )}`}</Since>
      ) : (
        ''
      )}
    </div>
  )
}

const AttorneyImagePopup = ({ attorney, showObjectId }) => {
  return (
    <Popup
      trigger={
        <List>
          <List.Item>
            <Image
              avatar
              src={
                attorney && attorney.avatar && attorney.avatar.url
                  ? attorney.avatar.url
                  : AttorneyLogo
              }
            />
            <List.Content verticalAlign="middle">
              <List.Header>
                {attorney && attorney.name ? (
                  attorney.name
                ) : (
                  <StyledElements.Italicize>
                    Attorney Name Not Set
                  </StyledElements.Italicize>
                )}
              </List.Header>
              <List.Description>
                {showObjectId && attorney && attorney.objectId ? (
                  <Label basic size="tiny">
                    ObjectId
                    <Label.Detail>{attorney.objectId}</Label.Detail>
                  </Label>
                ) : null}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      }
      header={attorney && attorney.name ? attorney.name : 'No Name'}
      content={<Content attorney={attorney} />}
    />
  )
}

export default AttorneyImagePopup
