import { connect } from 'react-redux'
import {
  ticketByIdSelector,
  ticketEditFieldByIdSelector,
} from '../../duck/selectors'
import { authenticationSelectors } from '../../../authentication/duck'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditTicketIDComponent from './EditTicketIDComponent'

const FIELD = 'ticketID'

const mapStateToProps = (state, props) => ({
  attorney: authenticationSelectors.attorneySelector(state),
  ticket: ticketByIdSelector(state, props.objectId),
  editTicketField: ticketEditFieldByIdSelector(state, props.objectId, FIELD),
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, value) =>
    dispatch(ticketsActions.setEditValue(objectId, FIELD, value)),
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
})

const EditTicketIDContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTicketIDComponent)

export default EditTicketIDContainer
