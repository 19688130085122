import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { confirmPayment, createPayment } from '../duck/services'
import SharedSubmitBtn from '../shared/SharedSubmitBtn'

const ManualPayment = ({ details, schedule, setShowPlanModal, setShowDetailedPlan, success, error }) => {
  const [signature, setSignature] = useState('')
  const [amount, setAmount] = useState(0)
  const [switchBtn, setSwitchBtn] = useState(false)
  
  
  const contractBody = schedule.contract_body !== null
    ? <p style={{ whiteSpace: 'pre-line', fontSize: '15px' }}>{schedule.contract_body}</p>
    : <h3 style={{ textAlign: 'center' }}>No contract returned from Partial.ly</h3>
    
  const createPaymentHandler = () => {
    createPayment(details.id, { amount }).then(res => {
      if (res.status === 201) {
        setSwitchBtn(true)
      } else if (res.status === 400) {
        setShowPlanModal(false)
        setShowDetailedPlan(false)
        if (res.message && res.error) {
          error(res.message, res.error)
        } else if (res.message) {
          error('Failed to create payment', res.message)
        } else {
          error('Failed to create payment', res.error)
        }
      }
    })
  }
  
  const confirmPaymentHandler = () => {
    confirmPayment(details.id, { signature, amount }).then(res => {
      if (res.status === 201) {
        setShowPlanModal(false)
        setShowDetailedPlan(false) 
        success(`Manual Payment of $${amount} was submitted successfully`)
      } else if (res.status === 400) {
        setShowPlanModal(false)
        setShowDetailedPlan(false) 
        error('Failed to confirm payment', res.error)
      } 
    })
  }
  
  const differentButtons = !switchBtn
  ? <SharedSubmitBtn className='submit create' type='button' onClick={createPaymentHandler} value='Create' disabled={amount < 2} />
  : <SharedSubmitBtn className='submit' type='button' onClick={confirmPaymentHandler} value='Confirm' disabled={amount < 2 || signature.length < 2 || !signature} />

  return (
    <Wrapper>
      <div style={{ marginBottom: '30px' }}>{contractBody}</div>
      <form onSubmit={(ev) => ev.preventDefault()}>
        <label htmlFor='signature'>Signature</label>
        <FormDiv>
          <input type='text' className='signature' onChange={(ev) => setSignature(ev.target.value)} />
          <input type='number' placeholder='$$$' min={0} step='0.01' className='amount' onChange={(ev) => setAmount(ev.target.value)} />
          {differentButtons}
        </FormDiv>
      </form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  form {    
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: baseline;

    label {
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 20px;
    }

    input {
        &.signature {
          outline: none;
          border: 1px solid black;
          height: 35px;
          font-family: cursive;
          width: 150px;
          font-size: 16px;
          padding: 0 10px;
          border-radius: 10px;
          margin-right: 10px;
          background-color: #d5d5d5;
        }

        &.amount {
          outline: none;
          border: 1px solid black;
          height: 35px;
          font-family: cursive;
          width: 150px;
          font-size: 16px;
          padding: 0 10px;
          border-radius: 10px;
          margin-right: 10px;
          background-color: #d5d5d5;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin-right: 0;
          }
        }
    }
  }
`

const FormDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.actions {
    justify-content: flex-end;
    align-items: center;
  }
`
export default ManualPayment