import React, { Component } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import { createUser } from '../../../users/duck/services'

class CreateNewUser extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, errorMessage: '', error: false }
  }

  CreateButton = () => {
    const { success } = this.props
    return (
      <>
        <Button
          primary
          size="mini"
          loading={this.state.loading}
          onClick={this.show}
        >
          {success ? 'Success !' : `Create User`}
        </Button>
        <span style={{ color: 'red', fontWeight: 'normal' }}>
          {this.state.error ? `Error: ${this.state.errorMessage}` : ''}
        </span>
      </>
    )
  }

  show = () => this.setState({ open: true })

  handleCancel = () => this.setState({ open: false })

  handleConfirm = () => {
    this.setState({ open: false, error: false })
    this.createNewUser()
  }

  createNewUser = () => {
    const { contactInfo } = this.props
    const data = { contactInfoObjectId: contactInfo.objectId }
    this.setState({ open: false, loading: true })
    createUser(data)
      .then(response => {
        this.setState({
          loading: false,
          ticketCreated: true,
        })
        this.props.history.push(`/users/view/${response.objectId}`)
      })
      .catch(error => {
        this.setState({ loading: false, error: true, errorMessage: error })
      })
  }

  render() {
    const { contactInfo } = this.props
    const content = `You are going to create a user for ${contactInfo.fullName}, with email ${contactInfo.email} and phone number ${contactInfo.phoneNumber}. A 'reset password' email will be sent to that user to allow them to create a password for his account. Do you want to proceed?`
    return (
      <>
        <this.CreateButton />
        <Confirm
          open={this.state.open}
          content={content}
          header="Please confirm"
          cancelButton="No"
          confirmButton="Yes"
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          size="mini"
        />
      </>
    )
  }
}

export default CreateNewUser
