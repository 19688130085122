import React from 'react'
import styled from 'styled-components'
import { Modal } from 'semantic-ui-react'

import { useState } from 'react'
import { tokenizePaymentDetails, addPaymentDetails } from '../duck/services'
import { useHistory } from 'react-router-dom'
import SharedHeader from '../shared/SharedHeader'
import SharedCloseBtn from '../shared/SharedCloseBtn'
import SharedSubmitBtn from '../shared/SharedSubmitBtn'

const AddPaymentMethod = ({ customerAccount, allowCC, success, error }) => {
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  const openAddPaymentModal = () => setShowAddPaymentModal(true)
  const closeAddPaymentModal = () => setShowAddPaymentModal(false)

  const [cardNumber, setCardNumber] = useState("")
  const [expMonth, setExpMonth] = useState(1)
  const [expYear, setExpYear] = useState(2022)
  const [cvc, setCVC] = useState("")

  const [type, setType] = useState("card")
  const [tokenId, setTokenId] = useState("")
  const [message, setMessage] = useState("")

  const history = useHistory()

  const containsLetters = (str) => {
    return /[a-zA-Z]/.test(str)
  }

  const onSubmitHandler = ev => {
    ev.preventDefault()

    tokenizePaymentDetails({
      number: cardNumber,
      exp_month: expMonth,
      exp_year: expYear,
      cvc
    }).then(res => {
      if (res.status === 201) {
        setTokenId(res.data.id)
      } else if (res.status === 402) (
        setMessage(res.error)
      )
    })
  }

  const sendPaymentDetailsToPartial = () => {
    addPaymentDetails({
      customer_id: customerAccount.id,
      type,
      token_id: tokenId
    }).then(res => {
      if (res.status === 201) {
        if (res.paymentMethod.message) {
          setShowAddPaymentModal(false)
          success(res.paymentMethod.message)
        } else {
          success(res.message)
          setShowAddPaymentModal(false)
        }
        setTimeout(() => {history.go(0)}, 3500)
      } else if (res.status === 400) {
        setShowAddPaymentModal(false)
        if (res.error) {
          error(res.error)
        }
        error(res.message)
      }
    })
  }

  if (!customerAccount) return <p></p>

  return (
    <>
      <SharedHeader onClick={openAddPaymentModal} name={'Add Payment Method'} />
      <Modal
        open={showAddPaymentModal}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>Add Payment Method</Modal.Header>
        <Modal.Content>
          <form onSubmit={onSubmitHandler}>
          {!tokenId
          ? <FormWrap>
              <label htmlFor="card">Card Number</label>
              <label htmlFor="exp_month">Expiration Month</label>
              <label htmlFor="exp_year">Expiration Year</label>
              <label htmlFor="cvc">CVC</label>
              <input type="text" inputMode="numeric" placeholder='1234 1234 1234 1234' minLength={16} maxLength={19} onChange={(ev) => setCardNumber(ev.target.value)} />
              <input type="text" inputMode="numeric" placeholder='MM' min={1} max={12} maxLength={2} onChange={(ev) => setExpMonth(ev.target.value)} />
              <input type="text" inputMode="numeric" placeholder='YYYY' minLength={4} maxLength={4} onChange={(ev) => setExpYear(ev.target.value)} />
              <input type="text" inputMode="numeric" placeholder='CVC' minLength={3} maxLength={4} onChange={(ev) => setCVC(ev.target.value)} />
            </FormWrap>
          : <TokenWrap>
              <label htmlFor="customer_id">Customer ID</label>
              <input type="text" defaultValue={customerAccount.id} readOnly />
              <label htmlFor="token_id">Token ID</label>
              <input type="text" defaultValue={tokenId} readOnly />
            </TokenWrap>
          }
          <Message>{message}</Message>
            <BtnWrap>
              <SharedCloseBtn type="button" className="close" onClick={closeAddPaymentModal} name='Close' />
              {tokenId 
                ? <SharedSubmitBtn type="button" className="submit" onClick={sendPaymentDetailsToPartial} value='Send Details' />
                : <SharedSubmitBtn
                  disabled={
                    !cardNumber || cardNumber.length > 19 || containsLetters(cardNumber)
                    || !expMonth || expMonth.length > 2 || containsLetters(expMonth)
                    || !expYear || expYear.length > 4 || containsLetters(expYear)
                    || !cvc || cvc.length > 4 || containsLetters(cvc) || !allowCC}
                  type="submit"
                  className="submit"
                  value='Submit'
                />}
            </BtnWrap>
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}

const FormWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  
  label, input {
    margin: 5px 10px;
  }

  input {
    padding: 8px;
    outline: none;
    border: 1px solid black;
    border-radius: 8px;
    height: 35px;
  }
`

const TokenWrap = styled(FormWrap)`
  width: 650px;
  margin: auto;
  justify-items: center;

  input {
    width: 200px;
  }
`

const Message = styled.p`
  display: flex;
  margin: 10px 0;
  font-size: 20px;
`

const BtnWrap = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
  align-items: center;
`

export default AddPaymentMethod