import React, { Component, Fragment } from 'react'

import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import ViewAttorney from '../ViewAttorney/AttorneyItemComponent'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'

import AssignAttorney from '../../../attorney/edit/AssignAttorneyContainer'

class ViewAttorneyComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
  }
  toggleState = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleCancel = event => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    this.props.clearEditValue(this.props.objectId)
    this.toggleState()
  }
  handleChange = attorneyObjectId => {
    this.props.editUpdate(this.props.objectId, attorneyObjectId)
  }
  handleUpdate = event => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }
    if (this.props.editTicketField) {
      this.props.update(this.props.objectId, {
        attorney: this.props.editTicketField,
      })
      this.toggleState()
    }
  }
  render() {
    const { attorney, ticket } = this.props
    let admin = false
    if (attorney && attorney.globalAdmin) admin = true
    const { isEditing } = this.state
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Assigned Attorney"
          isEditable={admin}
          isEditing={isEditing}
          toggleState={this.toggleState}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {isEditing ? (
            <Fragment>
              <AssignAttorney
                handleChange={this.handleChange}
                currentAttorney={
                  this.props.editTicketField ||
                  (this.props.ticket.attorney
                    ? this.props.ticket.attorney.objectId
                    : null)
                }
              />
              <Forms.InlineEditButtons
                buttons={[
                  {
                    disabled: !this.props.editTicketField,
                    color: 'red',
                    text: 'Cancel',
                    icon: 'close',
                    labelPosition: 'right',
                    onClick: this.handleCancel,
                    // type: 'submit',
                  },
                  {
                    disabled: !this.props.editTicketField,
                    color: 'green',
                    text: 'Update',
                    icon: 'save',
                    labelPosition: 'right',
                    onClick: this.handleUpdate,
                    type: 'submit',
                  },
                ]}
              />
            </Fragment>
          ) : (
            <Fragment>
              {ticket.attorney ? (
                <ViewAttorney attorney={ticket.attorney} showObjectId={admin} />
              ) : (
                <StyledElements.Italicize>
                  No Attorney Assigned
                </StyledElements.Italicize>
              )}
            </Fragment>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default ViewAttorneyComponent
