import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Activation from './ActivationContainer'
import Login from '../authentication/AuthenticationContainer'


class Routes extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/activate/" component={Activation} />
          <Route path="/activate/:code" component={Activation} />
          <Route path="*" component={Login} />
        </Switch>
      </Router>
    )
  }
}

export default Routes
