import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Feed } from 'semantic-ui-react'
import EditHistoryEventItem from './EditHistoryEventItem'
import Toolbar from './Toolbar'
import { ScrollOverflowContent, StyledElements } from '../../../common'

const EditHistoryEventListComponent = ({ editHistory, fetch, retrievedAt }) => {
  return (
    <Fragment>
      <Toolbar fetch={fetch} retrievedAt={retrievedAt} />
      {editHistory && editHistory.length ? (
        <ScrollOverflowContent>
          <Feed>
            {editHistory
              .sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt)
              })
              .map(item => (
                <EditHistoryEventItem key={item.objectId} {...item} />
              ))}
          </Feed>
        </ScrollOverflowContent>
      ) : (
        <StyledElements.Italicize>
          No edit history available.
        </StyledElements.Italicize>
      )}
    </Fragment>
  )
}

EditHistoryEventListComponent.defaultProps = {
  editHistory: [],
  retrievedAt: null,
}

EditHistoryEventListComponent.propTypes = {
  editHistory: PropTypes.array,
  fetch: PropTypes.func.isRequired,
  retrievedAt: PropTypes.any,
}

export default EditHistoryEventListComponent
