import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Calls from './calls/CallsContainer'
import Chats from './chats/ChatsContainer'
import Hearings from './hearings/HearingsContainer'
import Home from './home/HomeContainer'
import Leads from './leads/LeadsContainer'
import ViewLead from './leads/ViewLead/ViewLeadContainer'
import Lookup from './lookup/LookupContainer'
import Logout from './authentication/LogoutContainer'
import NotFoundPage from './404NotFound'
import Processing from './processing/ProcessingContainer'
import Profile from './profile/ProfileContainer'
import Search from './search/SearchComponent'
import Users from './users/UserContainer'
import Admin from './admin/AdminContainer'

const Routes = ({ isAdmin }) => {
    return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/calls" component={Calls} />
      <Route path="/chats" component={Chats} />
      <Route path="/hearings" component={Hearings} />
      <Route path="/home" component={Home} />
      <Route path="/leads" component={Leads} />
      <Route path="/logout" component={Logout} />
      <Route path="/processing" component={Processing} />
      <Route path="/profile" component={Profile} />
      <Route path="/search" component={Search} />
      <Route path="/users" component={Users} />
      {isAdmin ? <Route path="/admin" component={Admin} /> : null}
      {isAdmin ? <Route path="/lookup" component={Lookup} /> : null}
      <Route path="/new-window/:id" component={ViewLead} />
      <Route path="*" component={NotFoundPage} status={404} />
    </Switch>
  )
}

export default Routes
