import { axios, axiosErrorResponse, localStorageUtils } from "../../../utilities"

export const login = (email, password) => {
  return axios.basic.post("/log-in", { email, password })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(axiosErrorResponse(response.data))
      }
      if (response.data.attorney.globalAdmin) {
        if (response.data.attorney.disable2FA) {
          if (!response.data.sessionToken) {
            return Promise.reject(axiosErrorResponse(response.data))
          }
          localStorageUtils.set('sessionToken', response.data.sessionToken)
          localStorageUtils.set('sessionTokenDate', new Date())
        } else {
          sessionStorage.setItem('secretKey', response.data.secret)
        }
      } else {
        if (response.data.attorney.disable2FA) {
          if (!response.data.sessionToken) {
            return Promise.reject(axiosErrorResponse(response.data))
          }
          localStorageUtils.set('sessionToken', response.data.sessionToken)
          localStorageUtils.set('sessionTokenDate', new Date())
        } else {
          sessionStorage.setItem('secretKey', response.data.secret)
        }
      }
      return response.data
    }).catch(error => {
      sessionStorage.setItem('error', true)
      return Promise.reject(axiosErrorResponse(error))
    })
}

export const twoFA = (email, password, secret, token) => {
  return axios.basic.post("/log-in/twofactor", { email, password, secret, token })
    .then(response => {
      if (response.status !== 200) {
        return Promise.reject(axiosErrorResponse(response.data))
      }
      localStorageUtils.set('sessionToken', response.data.sessionToken)
      localStorageUtils.set('sessionTokenDate', new Date())
      return response.data
    }).catch(error => {
      return Promise.reject(axiosErrorResponse(error))
  })
}

export const expiryDate = (sessionToken) => {
  return axios.put(`/log-in/expire/${sessionToken}`)
    .then(response => {
      if (response.status !== 201) {
        return Promise.reject(axiosErrorResponse(response.data))
      }
    return response.data
  }).catch(err => {
    return Promise.reject(axiosErrorResponse(err))
  })
}

export const autoLogin = () => {
  return axios.secure.get("/me")
    .then(res => res.data)
    .catch(error => {
      localStorageUtils.remove('sessionToken')
      localStorageUtils.remove('sessionTokenDate')
      sessionStorage.removeItem('secretKey')
      sessionStorage.removeItem('error')
      return Promise.reject(axiosErrorResponse(error))
    })
}

export const logout = () => {
  localStorageUtils.remove('sessionToken')
  localStorageUtils.remove('sessionTokenDate')
  sessionStorage.removeItem('secretKey')
  sessionStorage.removeItem('error')
}
