import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'

import { StyledElements } from '../../../common'
import InlineTableEditButtons from '../../../common/react-table/InlineTableEditButtons'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import { TICKET_STATUS } from '../../../../constants'

class EditStatusReasonInlineComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
  }

  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  buildOptionsArray = (arrayOfKeys, currentStatus) => {
    const result = arrayOfKeys.map(key => {
      return {
        key,
        value: key,
        text: key,
        content: <Header content={key} />,
      }
    })
    result.push({
      key: 'placeHolder',
      value: 'Select Reason',
      text: 'Select Reason',
    })
    return result
  }

  getPossibleStatuses = () => {
    const { status } = this.props.ticket
    const possibleKeys = ['generic', 'parking', 'type']
    const result = this.buildOptionsArray(possibleKeys, status)
    return result
  }

  handleChange = event => {
    const { objectId } = this.props
    const { value } = event.target
    if (value && value !== 'Select Reason') {
      this.props.editUpdate(objectId, value)
    }
  }
  handleCancel = event => {
    event.preventDefault()
    const { objectId } = this.props
    this.props.clearEditValue(objectId)
    return false
  }

  updateStatus = e => {
    if (e && e.preventDefault) e.preventDefault()
    this.setState({ isEditing: false })
    const { objectId, editTicketField, ticket } = this.props
    if (editTicketField !== ticket.status) {
      this.props.update(objectId, { statusReason: editTicketField })
    }
    return false
  }

  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    const { statusReason } = ticket
    if (ticket.status === TICKET_STATUS.processing) {
      return <StyledElements.Italicize>Already Paid</StyledElements.Italicize>
    }
    return (
      <StyledElements.FillForm>
        <StyledElements.FillSelect
          onChange={this.handleChange}
          value={
            !editTicketField && !statusReason
              ? 'Select Reason'
              : editTicketField || statusReason
          }
        >
          {this.getPossibleStatuses().map(statusOption => {
            return (
              <option key={statusOption.value} value={statusOption.value}>
                {statusOption.text}
              </option>
            )
          })}
        </StyledElements.FillSelect>
        {editTicketField && ticket.statusReason !== editTicketField ? (
          <InlineTableEditButtons
            cancelFunc={this.handleCancel}
            updateFunc={this.updateStatus}
            updateText="Close"
          />
        ) : null}
      </StyledElements.FillForm>
    )
  }

  render() {
    const { objectId } = this.props
    const RenderEdit = this.renderEdit
    return (
      <SpecificOverlayContainer
        overlayKey={objectId}
        ignoreError={true}
        LoadingComponent={() => (
          <StyledElements.Italicize>Loading...</StyledElements.Italicize>
        )}
        UpdatingComponent={() => (
          <StyledElements.Italicize>Updating...</StyledElements.Italicize>
        )}
      >
        <RenderEdit />
      </SpecificOverlayContainer>
    )
  }
}

export default EditStatusReasonInlineComponent
