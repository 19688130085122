const RESET = "RESET"
const SET_ALL_CONTACT_INFO = "SET_ALL_CONTACT_INFO"
const SET_SINGLE_CONTACT_INFO = "SET_SINGLE_CONTACT_INFO"
const SET_UPDATED_CONTACT_INFO = "SET_UPDATED_CONTACT_INFO"
const SET_HEARING_DATE_CONTACT_INFO = "SET_HEARING_DATE_CONTACT_INFO"

export default {
  RESET,
  SET_ALL_CONTACT_INFO,
  SET_SINGLE_CONTACT_INFO,
  SET_UPDATED_CONTACT_INFO,
  SET_HEARING_DATE_CONTACT_INFO
}
