import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import SEARCH_DESCRIBER from './SearchTypes'
import SearchResultsTable from './SearchResultsTableContainer'
import NoSearchResults from './NoSearchResults'
import LoadingSearchResults from './LoadingSearchResults'
import SearchResultsError from './SearchResultsError'

class SearchResultsComponent extends Component {
  constructor() {
    super()
    this.state = {
      searchType: ''
    }
  }

  componentDidMount() {
    // Check if we are currently searching
    if (this.props.loading) return null
    // build an object of URL params
    const parsed = queryString.parse(this.props.location.search)
    // get search type details
    const searchType = SEARCH_DESCRIBER[parsed.searchType]
    // If valid searchType check that we have all the valid keys
    const haveAllKeys =
      searchType && searchType.URLKeys
        ? searchType.URLKeys.reduce((accum, key) => {
            if (!accum) return accum
            return !!parsed[key]
          }, true)
        : false

    // If we are missing something, send the user to search page
    if (!parsed.searchType || !searchType || !haveAllKeys) {
      // console.log('parsed.searchType', parsed.searchType)
      // console.log('searchType', searchType)
      // console.log('haveAllKeys', haveAllKeys)
      console.log('missing search type or it is not a valid search type')
      return this.props.history.push('/search/')
    }
    // We have everything lets search
    return this.props.search(parsed)
  }

  render() {
    if (this.props.loading) return <LoadingSearchResults />
    if (this.props.error) return <SearchResultsError error={this.props.error} />
    if (!this.props.results.length) return <NoSearchResults />
    return <SearchResultsTable results={this.props.results} />
  }
}

export default withRouter(SearchResultsComponent)
