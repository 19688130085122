import * as types from './types'

export const addUser = user => ({
  type: types.ADD_USER,
  user,
})

export const setEditValue = (objectId, key, value) => ({
  type: types.SET_EDIT_USER_VALUE,
  objectId,
  key,
  value,
})

export const clearEditValue = (objectId, key) => ({
  type: types.CLEAR_EDIT_USER_VALUE,
  objectId,
  key,
})

export const setIsEditingUserField = (objectId, key, isEditing) => ({
  type: types.SET_IS_EDITING_USER_FIELD,
  objectId,
  key,
  isEditing,
})

export const setIsBlockedUser = (objectId, isUserBlocked) => ({
  type: types.IS_BLOCKED_USER,
  objectId,
  isUserBlocked,
})


