import { connect } from 'react-redux'
import { ticketByIdSelector } from '../../duck/selectors'
import { isLoadingSelector } from '../../../overlays/duck/selectors'
import { ticketsThunks } from '../../duck'
import ViewPaymentStatusComponent from './ViewPaymentStatusComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  isLoading: isLoadingSelector(state)
})

const mapDispatchToProps = dispatch => ({
  charge: (ticketObjectId) => dispatch(ticketsThunks.chargeTicket(ticketObjectId))
})

const ViewPaymentStatusContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPaymentStatusComponent)

export default ViewPaymentStatusContainer
