import { connect } from 'react-redux'
import ViewLookupComponent from './ViewLookupComponent'
import { lookupSelectors, lookupThunks } from '../duck'
import {
  getSelectedContactInfo
 } from '../duck/selectors'
import { setSingleContactInfo } from '../duck/actions'
// import { FRONTEND_TICKET_TYPES } from '../../../constants'


const mapStateToProps = (state, props) => ({
  allContactInfo: lookupSelectors.allContactInfoSelector(state),
  selectedContactInfo: getSelectedContactInfo(state, props),
})

const mapDispatchToProps = dispatch => ({
  fetchSingleContactInfo: (contactInfoObjectId, loading) => dispatch(lookupThunks.fetchSingleContactInfo(contactInfoObjectId, loading)),
  setSingleContactInfo: (contactInfoObject) => dispatch(setSingleContactInfo(contactInfoObject)),
  // setResized: value =>
  //   dispatch(reactTableActions.setResized(FRONTEND_TICKET_TYPES.leads.all, value)),
})

const ViewLookupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewLookupComponent)

export default ViewLookupContainer
