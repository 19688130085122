import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
// import { Helmet } from "react-helmet";
// import { Message } from 'semantic-ui-react'

// const list = [
//   'You can now do this',
//   'You can now do this and this',
//   'Added Attorney statistics',
//   'Bug Fixes'
// ]

// const MessageList = () => <Message header='New Site Features' list={list} />

class HomeComponent extends Component {
  render() {
    return <Redirect to="/processing/recent" />
    // return <Fragment>
    //   <Helmet title="Home" />
    //   <MessageList />
    // </Fragment>
  }
}

export default HomeComponent
