const SET_CHATS = 'SET_CHATS'
const SET_CHAT_WINDOW_SHOWING = 'SET_CHAT_WINDOW_SHOWING'
const SET_CHAT_ATTORNEY_ID = 'SET_CHAT_ATTORNEY_ID'
const SET_CHAT_CURRENT_CHANNEL_URL = 'SET_CHAT_CURRENT_CHANNEL_URL'
const RESET = 'RESET'

export default {
  SET_CHATS,
  SET_CHAT_WINDOW_SHOWING,
  SET_CHAT_ATTORNEY_ID,
  SET_CHAT_CURRENT_CHANNEL_URL,
  RESET,
}
