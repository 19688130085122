import { axios } from '../../../utilities'

export const getCallsItems = () => {
  return axios.secure.delayed.get('/ticket-data/calls').then(res => {
    return res.data
  })
}

export const getRequestHistory = ticketObjectId => {
  return axios.secure.delayed
    .get(`/ticket-data/callHistory/${ticketObjectId}`)
    .then(res => res.data)
}
