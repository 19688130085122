import React, { Component } from 'react'
import styled from 'styled-components'
import { Icon, Progress } from 'semantic-ui-react'
import FloatingContainer from './FloatingContainer'
import { overlays } from '../../../theme/overlays'

const TopRowSplit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h4 {
    margin-bottom: 0;
  }
`

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`

const StyledProgress = styled(Progress)`
  &&& {
    margin: 5px 0 0px 0;
    padding: 0px;
    opacity: 0.2;
  }
`

class NotificationComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      interval: null,
      remaining: 0,
      open: false,
    }
  }
  componentDidMount() {
    // this.setupInterval()
    setTimeout(this.setupInterval, 1000)
  }
  componentWillUnmount() {
    this.isCancelled = true
    if (this.state.interval) clearInterval(this.state.interval)
  }
  setupInterval = () => {
    if (this.props.notification.duration && !this.state.interval) {
      const interval = setInterval(this.intervalIteration, 50)
      if (!this.isCancelled)
        this.setState({
          open: true,
          interval,
          remaining: this.props.notification.duration,
        })
    }
  }
  intervalIteration = () => {
    if (this.state.remaining >= 0) {
      if (!this.isCancelled)
        this.setState({ remaining: this.state.remaining - 0.05 })
    } else {
      this.endInterval()
    }
  }
  endInterval = () => {
    clearInterval(this.state.interval)
    if (!this.isCancelled) this.setState({ open: false, interval: null })
    // setTimeout(this.closeNotification, 1000)
    this.closeNotification()
  }
  closeNotification = () => {
    this.setState({ open: false })
    setTimeout(this.props.closeFunc.bind(this, this.props.notification), 1000)

    // this.props.closeFunc(this.props.notification)
  }
  render() {
    const { notification } = this.props
    return (
      <FloatingContainer
        pose={this.state.open ? 'open' : 'closed'}
        theme={overlays[notification.type]}
      >
        <TopRowSplit>
          <h4>{notification.title}</h4>
          <ClickableIcon name="close" onClick={this.closeNotification} />
        </TopRowSplit>
        {notification.message}
        {notification.duration ? (
          <StyledProgress
            percent={(this.state.remaining / notification.duration) * 100}
            size="tiny"
            success={notification.type === 'success'}
            error={notification.type === 'error'}
          />
        ) : (
          ''
        )}
      </FloatingContainer>
    )
  }
}

export default NotificationComponent
