import { connect } from 'react-redux'
import { leadsActions, leadsSelectors } from './duck'
import { overlaySelectors } from '../overlays/duck'
import { FRONTEND_TICKET_TYPES } from '../../constants'
import { attorneySelector } from '../authentication/duck/selectors'

import LeadsComponent from './LeadsComponent'

const mapStateToProps = state => ({
  attorney: attorneySelector(state),
  allLeads: leadsSelectors.combinedLeadsSelector(state),
  allLeadsRetrieved: leadsSelectors.allLeadsRetrieved(state),
  allLeadsRetrievedAt: leadsSelectors.allLeadsRetrievedAtSelector(state),
  allLeadsLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, FRONTEND_TICKET_TYPES.leads.all),
  activeLeads: leadsSelectors.activeLeadsSelector(state),
  activeLeadsRetrievedAt: leadsSelectors.activeLeadsRetrievedAtSelector(state),
  activeLeadsLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, FRONTEND_TICKET_TYPES.leads.active),
  closedLeads: leadsSelectors.closedLeadsSelector(state),
  closedLeadsRetrievedAt: leadsSelectors.closedLeadsRetrievedAtSelector(state),
  closedLeadsLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, FRONTEND_TICKET_TYPES.leads.closed),
  unassignedLeads: leadsSelectors.unassignedLeadsSelector(state),
  closedTickets: leadsSelectors.closedTicketsSelector(state),
  closedTicketsRetrievedAt: leadsSelectors.closedTicketsRetrievedAtSelector(state),
  closedTicketsLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, FRONTEND_TICKET_TYPES.leads.closedTickets),
  unassignedLeadsRetrievedAt: leadsSelectors.unassignedLeadsRetrievedAtSelector(state),
  unassignedLeadsLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, FRONTEND_TICKET_TYPES.leads.unassigned),
  currentlyViewing: leadsSelectors.currentlyViewingSelector(state),
  regionFilter: leadsSelectors.leadsRegionFilterSelector(state)
})

const mapDispatchToProps = dispatch => ({
  setRegionFilter: (region) => dispatch(leadsActions.setLeadsRegionFilter(region))
})

const LeadsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsComponent)

export default LeadsContainer
