import React from "react"

const Contract = ({ schedule }) => {
  const contractBody = schedule.contract_body !== null
    ? <p style={{ whiteSpace: 'pre-line', fontSize: '16px' }}>{schedule.contract_body}</p>
    : <h3 style={{ textAlign: 'center' }}>No contract returned from Partial.ly</h3>
  
  const contractSignature = schedule.contract_signature !== null &&
    <p style={{ fontSize: '16px', fontWeight: 500, fontStyle: 'italic', textDecoration: 'underline' }}>Signed by {schedule.contract_signature}</p>
  
  return (
    <>
    <div>{contractBody}</div>
    <div>{contractSignature}</div>
    </>
  )
}

export default Contract