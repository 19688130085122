import React, { Fragment, useEffect, useState } from 'react'
import { Header, Grid, Icon } from 'semantic-ui-react'
import { phoneNumbers, stringTools } from '../../../../utilities'
import {
  // FRONTEND_TICKET_TYPES,
  STATES,
  // TICKET_STATUS,
} from '../../../../constants'
import { StyledElements } from '../../../common'
import { colors } from '../../../../theme/colors'
import AddPaymentMethodModal from '../../../modal/AddPaymentMethodModal'
import { FRONTEND_TICKET_TYPES } from '../../../../constants'

const UserInfoComponent = ({
  ticket,
  attorney,
  user,
  paymentDetails,
  getPaymentData,
  isUserBlocked,
  history,
  refreshPayment,
  warnMissingPaymentProcessor,
}) => {
  const [userBlocked, setUserBlocked] = useState(false)
  useEffect(() => {
    async function fetchBlockedUserData() {
      const result = await isUserBlocked(user.objectId)
      setUserBlocked(result.isUserBlocked)
    }
    fetchBlockedUserData()
  }, [])


  if (!ticket || !user) return null
  const gender = user && user.gender && user.gender.toLowerCase().trim()
  // Hide contact info if:
  // - there is no attorney
  // - user has a payment method (DISABLED WHILE hasPaymentMethod is not fully accurate)
  // - viewing user is not an admin
  // let hideContactInfo = ticket && !ticket.attorney
  // if (hideContactInfo && user.hasPaymentMethod) {
  //   hideContactInfo = false
  // }
  // if (
  //   hideContactInfo &&
  //   (attorney.globalAdmin ||
  //     attorney.objectId === process.env.REACT_APP_ISAAC_OBJECT_ID)
  // ) {
  //   hideContactInfo = false
  // }

  // new logic for hiding contact info
  let hideContactInfo = false
  let isLead = false
  ticket.classifications.forEach(classification => {
    if (Object.values(FRONTEND_TICKET_TYPES.leads).includes(classification)) {
      isLead = true
    }
  })
  if (!attorney || (!attorney.globalAdmin && isLead)) {
    hideContactInfo = true
  }
  return (
    <Fragment>
      <Header as="h3" dividing>
        Client Information{' '}
        {attorney && attorney.globalAdmin ? (
          ticket.user && user.objectId ? (
            <StyledElements.FakeLink
              style={{ marginLeft: '1em' }}
              // size="tiny"
              onClick={() => history.push(`/users/view/${user.objectId}`)}
            >
              edit
            </StyledElements.FakeLink>
          ) : null
        ) : null}
      </Header>
      <Grid stackable stretched columns={3} style={{ wordBreak: 'break-all' }}>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          {ticket.user &&  userBlocked ? (
          <Header as="h4">
            <Header.Content>
              User Status
              <Header.Subheader>
                    <span style={{ color: colors.error.color }}>
                    <Icon name="alarm" />
                    User Blocked
                  </span>
              </Header.Subheader>
            </Header.Content>
            </Header>) : null}
          {hideContactInfo ? null : (
            <Header as="h4">
              <Header.Content>
                Name
                <Header.Subheader>
                  {ticket.user && user.name ? (
                    user.name
                  ) : (
                    <StyledElements.Italicize>
                      Missing Name
                    </StyledElements.Italicize>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          )}
          {hideContactInfo ? null : (
            <Header as="h4">
              <Header.Content>
                Phone Number
                <Header.Subheader>
                  {ticket.user && user.phoneNumber ? (
                    phoneNumbers.usFormat(user.phoneNumber)
                  ) : (
                    <StyledElements.Italicize>
                      Missing Phone Number
                    </StyledElements.Italicize>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          )}
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          {hideContactInfo ? null : (
            <Header as="h4">
              <Header.Content>
                Email
                <Header.Subheader>
                  {ticket.user && user.email ? (
                    user.email
                  ) : (
                    <StyledElements.Italicize>
                      Missing Email Address
                    </StyledElements.Italicize>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          )}
          <Header as="h4">
            <Header.Content>
              Payment Method
              <Header.Subheader>
                {ticket.user && user.hasPaymentMethod ? (
                  <span style={{ color: colors.success.color }}>
                    <Icon name="checkmark" />
                    Yes
                    {ticket.paymentStatus !== 'good' ? (
                      <Fragment>
                        <StyledElements.Divider />
                        {paymentDetails
                          ? paymentDetails.paymentType === 'Card'
                            ? `${paymentDetails.cardType} ending in ${
                                paymentDetails.last4
                              }`
                            : 'PayPal'
                          : (
                            <StyledElements.FakeLink
                              onClick={() => getPaymentData(user.objectId)}
                            >
                              Get Payment Info
                            </StyledElements.FakeLink>
                          )}
                        <StyledElements.Divider />
                        <AddPaymentMethodModal user={user} warnMissingPaymentProcessor={warnMissingPaymentProcessor}/>
                      </Fragment>
                    ) : null}
                  </span>
                ) : (
                  <span style={{ color: colors.error.color }}>
                    <Icon name="x" />
                    None
                    <StyledElements.Divider />
                    <AddPaymentMethodModal user={user} warnMissingPaymentProcessor={warnMissingPaymentProcessor}/>
                    <StyledElements.Divider />
                    <StyledElements.FakeLink
                      onClick={() => refreshPayment(user.objectId)}
                    >
                      Refresh
                    </StyledElements.FakeLink>
                  </span>
                )}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Header as="h4">
            <Header.Content>
              License State
              <Header.Subheader>
                {ticket.user && user.licenseState ? (
                  STATES[user.licenseState] || user.licenseState
                ) : (
                  <StyledElements.Italicize>
                    Missing License State
                  </StyledElements.Italicize>
                )}
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Header as="h4">
            <Header.Content>
              License Number
              <Header.Subheader>
                {ticket.user && user.licenseID ? (
                  user.licenseID
                ) : (
                  <StyledElements.Italicize>
                    Missing License Number
                  </StyledElements.Italicize>
                )}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
        {user &&
        user.licenseState &&
        user.licenseState.toLowerCase() !== 'ny' ? (
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <Header as="h4">
              <Header.Content>
                Gender
                <Header.Subheader>
                  {gender ? (
                    <span>
                      <Icon name={gender} />{' '}
                      {stringTools.capitalizeFirstLetter(gender)}
                    </span>
                  ) : (
                    <StyledElements.Italicize>
                      Missing Gender
                    </StyledElements.Italicize>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
            <Header as="h4">
              <Header.Content>
                Date of Birth
                <Header.Subheader>
                  {ticket.user && user.dob ? (
                    user.dob
                  ) : (
                    <StyledElements.Italicize>
                      Missing Date of Birth
                    </StyledElements.Italicize>
                  )}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        ) : null}
      </Grid>
    </Fragment>
  )
}

export default UserInfoComponent
