import React, { useEffect, useState } from "react"
import PlatformAgreement from "../../activation/Confirm/platform-participation-agreement.md"
import Markdown from 'react-markdown'
import styled from "styled-components"

const PlatformAgreementsComponent = ({ attorney }) => {
    const [platformAgreement, setPlatformAgreement] = useState('')
    let dateJoined

    if (attorney.globalAdmin) {
        dateJoined = attorney.dateJoined.slice(0, 10)
    } else {
        dateJoined = attorney.createdAt.slice(0, 10)
    }
    
    useEffect(() => {
        fetch(PlatformAgreement)
            .then(res => res.text())
            .then(text => setPlatformAgreement(text))
    }, [])

    return (
        <>
            <Text>Agreement Signed on: {dateJoined}</Text>
            <Markdown children={platformAgreement} />
        </>
    )
}

const Text = styled.h3`
    display: flex;
    justify-content: flex-end;
`

export default PlatformAgreementsComponent