import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { Position } from '@blueprintjs/core'
import { TimePrecision } from '@blueprintjs/datetime'

import { StyledElements, Forms, BaseDateTimeInput } from '../../../common'
import ItemHeader from '../ItemHeader'
import Error from '../../../Error'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import { ticketTools } from '../../../../utilities'
import { DATE_FORMATS } from '../../../../constants'

class EditTicketDateComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.MIN_DATE = moment().subtract(30, 'years')
    this.MAX_DATE = moment().add(30, 'years')
  }

  getCurrentEditDate = () => {
    const { editTicketField } = this.props
    if (editTicketField) {
      return editTicketField === 'unset' ? null : editTicketField
    }
    return null
  }

  handleCancel = event => {
    if (event) event.preventDefault()
    const { objectId, dateKey } = this.props
    this.props.clearEditValue(objectId, dateKey)
    return false
  }

  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  handleChange = event => {
    const { objectId, dateKey } = this.props
    if (!event) {
      return this.props.editUpdate(objectId, dateKey, 'unset')
    }
    const input = moment(event)
    if (input.isValid() && input.isBetween(this.MIN_DATE, this.MAX_DATE)) {
      this.props.editUpdate(objectId, dateKey, input.toDate())
    }
  }

  updateDate = () => {
    const { editTicketField, ticket, dateKey, minValue, maxValue } = this.props
    let existingDate = ticketTools.getDate(
      dateKey,
      ticket,
      this.MIN_DATE,
      this.MAX_DATE
    )
    existingDate = existingDate ? new Date(existingDate) : null
    const newDate =
      editTicketField !== 'unset' ? new Date(editTicketField) : editTicketField

    if (editTicketField !== 'unset') {
      const input = moment(newDate)
      // Check if it is valid date
      const errorTitle = `Invalid Date for Ticket # ${ticket.ticketID}`
      if (minValue && input.isBefore(minValue)) {
        return this.props.error(
          errorTitle,
          `Date must be after ${minValue.format(DATE_FORMATS.DATE_TIME)}`
        )
      }
      if (maxValue && input.isAfter(maxValue)) {
        return this.props.error(
          errorTitle,
          `Date must be before ${maxValue.format(DATE_FORMATS.DATE_TIME)}`
        )
      }
    }

    this.setState({ isEditing: false })

    if (
      newDate === 'unset' ||
      !existingDate ||
      newDate.toISOString() !== existingDate.toISOString()
    ) {
      const updateObject = {}
      updateObject[this.props.dateKey] = newDate
      this.props.update(this.props.objectId, updateObject)
      this.handleCancel()
    }
  }

  renderEdit = () => {
    const { dateKey, ticket, editTicketField } = this.props
    const currentEditDate = this.getCurrentEditDate()
    const currentSetDate = ticketTools.getDate(
      dateKey,
      ticket,
      this.MIN_DATE,
      this.MAX_DATE
    )
    const currentValue = editTicketField ? currentEditDate : currentSetDate
    return (
      <StyledElements.EditContainer>
        <StyledElements.Row>
          <BaseDateTimeInput
            value={currentValue}
            onChange={this.handleChange}
            timePrecision={TimePrecision.MINUTE}
            popoverProps={{ position: Position.BOTTOM }}
            timePickerProps={{
              selectAllOnFocus: true,
              showArrowButtons: false,
              useAmPm: true,
            }}
          />
        </StyledElements.Row>
        <Forms.InlineEditButtons
          buttons={[
            {
              icon: 'save',
              labelPosition: 'right',
              text: `Update ${this.props.title} Date`,
              color: 'green',
              onClick: this.updateDate,
            },
          ]}
        />
      </StyledElements.EditContainer>
    )
  }
  render() {
    const { ticket, dateKey } = this.props
    const currentDate =
      ticket[dateKey] && ticket[dateKey].iso
        ? ticket[dateKey].iso
        : ticket[dateKey]
    const includeTime = this.props.includeTime || false
    const RenderEdit = this.renderEdit
    return (
      <Error.Boundary>
        <StyledElements.DataContainer isEditing={this.state.isEditing}>
          <ItemHeader
            title={this.props.title}
            isEditable={this.props.isEditable}
            isEditing={this.state.isEditing}
            toggleState={this.toggleStatus}
          />
          <SpecificOverlayContainer
            overlayKey={this.props.objectId}
            LoadingComponent={LoadingComponent}
            ignoreError={true}
            UpdatingComponent={UpdatingComponent}
          >
            {this.state.isEditing ? (
              <RenderEdit />
            ) : currentDate ? (
              moment(currentDate).format(
                includeTime ? 'dddd MMMM Do YYYY, h:mm a' : 'dddd MMMM Do YYYY'
              )
            ) : (
              <StyledElements.Italicize>
                No {this.props.title} Set
              </StyledElements.Italicize>
            )}
          </SpecificOverlayContainer>
        </StyledElements.DataContainer>
      </Error.Boundary>
    )
  }
}

export default EditTicketDateComponent
