import types from './types'

const INITIAL_STATE = {
  associatedUsers: [],
  attorneyStats: null, // will hold object when loaded

}
const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_ASSOCIATED_USERS: {
      const { value } = action
      return {
        ...state,
        associatedUsers: value,
      }
    }

    case types.SET_ATTORNEY_STATS: {
      const { value } = action
      return {
        ...state,
        attorneyStats: value,
      }
    }

    default:
      return state
  }
}

export default profileReducer
