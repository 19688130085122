import { axios } from '../../../utilities'

export const getProccessingItems = () => {
  return axios.secure.delayed.get('/ticket-data/approved').then(res => res.data)
}

export const getRequiresApprovalItems = () => {
  return axios.secure.delayed.get('/ticket-data/requiresApproval').then(res => res.data)
}

export const getRecentlyApprovedItems = dateString => {
  return axios.secure.delayed
    .get(`/ticket-data/recently-approved/${dateString}`)
    .then(res => res.data)
}

export const getSuspendedItems = () => {
  return axios.secure.delayed
    .get('/ticket-data/suspended')
    .then(res => res.data)
}
