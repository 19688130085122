import React from 'react'
import { search } from '../../../../utilities'
import { StyledElements } from '../..';

export const ATTORNEY_COLUMNS = {
  NAME: {
    Header: "Name",
    accessor: "attorney.name",
    width: 190,
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value }) => value || <StyledElements.Italicize>No Attorney</StyledElements.Italicize>
  },
  COMPANY_NAME: {
    Header: "Company",
    accessor: "attorney.companyName",
    width: 200,
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value }) => value || <StyledElements.Italicize>None</StyledElements.Italicize>
  },
}


export const attorneyColumn = (...attorneyColumns) => {
  return {
    Header: "Attorney Information",
    columns: [
      ...attorneyColumns
    ]
  }
}
