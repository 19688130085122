import { createSelector } from 'reselect'
import { ticketsSelectors } from '../../tickets/duck'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

export const currentlyViewingSelector = state => state.calls.currentlyViewing

export const callsIdsSelector = state =>
  state.calls[FRONTEND_TICKET_TYPES.calls].items

export const callsRetrievedAtSelector = state =>
  state.calls[FRONTEND_TICKET_TYPES.calls].retrievedAt

export const callsSelector = state =>
  ticketsSelectors.ticketsByIdsSelector(state, callsIdsSelector(state))

export const idFromRouterSelector = (state, props) => props.match.params.id

export const requestHistory = (state, ticketObjectId, key) => {
  const history = state.calls.requestHistory[ticketObjectId]
  return history ? history[key] : undefined
}

export const requestHistoryItems = (state, ticketObjectId) =>
  requestHistory(state, ticketObjectId, 'items')

export const requestHistoryRetrievedAt = (state, ticketObjectId) =>
  requestHistory(state, ticketObjectId, 'retrievedAt')

export const leadFromRouterSelector = createSelector(
  [callsSelector, idFromRouterSelector],
  (calls, id) => {
    const result = calls.filter(call => call.objectId === id)
    return result.length === 1 ? result[0] : null
  }
)
