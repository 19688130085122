import React, { Component, Fragment } from 'react';
import { Input } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays';

class EditTicketPointsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.pointsInput = React.createRef();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.pointsInput.inputRef.focus()
    }
  }
  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = (event) => {
    const { objectId } = this.props
    if (event.target.value === "") return this.props.editUpdate(objectId, "points", "")
    const points = parseInt(event.target.value)
    if (points || points === 0) {
      this.props.editUpdate(objectId, "points", points)
    }
  }
  updatePoints = () => {
    const { objectId, editTicketField, ticket } = this.props
    this.setState({ isEditing: false })
    const value = parseInt(editTicketField, 10)
    if (ticket.points !== value && Number.isInteger(value)) {
      this.props.update(objectId, { points: value })
    }
  }
  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    return <StyledElements.EditContainer>
      <form>
        <Input
          ref={(input) => { this.pointsInput = input; }}
          onChange={this.handleChange}
          value={editTicketField === null ? ticket.points : editTicketField}
          placeholder="Point Amount"
          fluid
        />
        <Forms.InlineEditButtons buttons={[
          {
            disabled: !Number.isInteger(parseInt(editTicketField, 10)),
            color: "green",
            text: "Update Points",
            icon: "save",
            labelPosition: "right",
            type: "submit",
            onClick: this.updatePoints
          }
        ]} />
      </form>
    </StyledElements.EditContainer>
  }
  render() {
    const { ticket } = this.props
    const { isEditing } = this.state
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={isEditing}>
        <ItemHeader
          title="Points"
          isEditable={true}
          isEditing={isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <Fragment>
            {isEditing ? <RenderEdit /> : ticket.points || ticket.points === 0 ? ticket.points : <StyledElements.Italicize>Points Not Set</StyledElements.Italicize>
            }
          </Fragment>
        </SpecificOverlayContainer>

      </StyledElements.DataContainer>
    );
  }
}

export default EditTicketPointsComponent;
