import moment from 'moment'
import { createSelector } from 'reselect'
import { FRONTEND_TICKET_TYPES } from '../../../constants'
import { ticketsByIdsSelector } from '../../tickets/duck/selectors'
import { attorneyIsAdminSelector } from '../../authentication/duck/selectors'

// get current region filter
export const leadsRegionFilterSelector = state => state.leads.regionFilter

// get the currently viewing lead
export const currentlyViewingSelector = state => state.leads.currentlyViewing

// New Modular Selectors
export const ids = (ticketType, state) => {
  return state.leads[ticketType].items
}

export const retrievedAt = (ticketType, state) => {
  return state.leads[ticketType].retrievedAt
}

export const byType = (state, ticketType) => {
  const leadIds = ids(ticketType, state)
  return ticketsByIdsSelector(state, leadIds)
}

export const buildLeadsSelectorByType = ticketType => state => {
  const leadIds = ids(ticketType, state)
  return ticketsByIdsSelector(state, leadIds)
}

// check if we have all the lead categories
export const allLeadsRetrieved = state => {
  const isAdmin = attorneyIsAdminSelector(state)
  const activeRetrieved = activeLeadsRetrievedAtSelector(state)
  const closedRetrieved = closedLeadsRetrievedAtSelector(state)
  const unassignedRetrieved = unassignedLeadsRetrievedAtSelector(state)
  const closedTicketsRetrieved = closedTicketsRetrievedAtSelector(state)
  return (
    activeRetrieved &&
    closedRetrieved &&
    unassignedRetrieved &&
    (isAdmin ? closedTicketsRetrieved : true)
  )
}

const LEAD_TYPES = FRONTEND_TICKET_TYPES.leads

export const activeLeadsIdsSelector = state => {
  return state.leads[LEAD_TYPES.active].items
}
export const activeLeadsRetrievedAtSelector = state => {
  return state.leads[LEAD_TYPES.active].retrievedAt
}
export const closedLeadsIdsSelector = state => {
  return state.leads[LEAD_TYPES.closed].items
}
export const closedLeadsRetrievedAtSelector = state => {
  return state.leads[LEAD_TYPES.closed].retrievedAt
}
export const unassignedLeadsIdsSelector = state => {
  return state.leads[LEAD_TYPES.unassigned].items
}
export const unassignedLeadsRetrievedAtSelector = state => {
  return state.leads[LEAD_TYPES.unassigned].retrievedAt
}
export const closedTicketsIdsSelector = state => {
  return state.leads[LEAD_TYPES.closedTickets].items
}
export const closedTicketsRetrievedAtSelector = state => {
  return state.leads[LEAD_TYPES.closedTickets].retrievedAt
}
export const closedTicketsHearingDateSelector = state => {
  return state.leads.closedTicketsHearingDate
}

export const allLeadsRetrievedAtSelector = createSelector(
  [
    attorneyIsAdminSelector,
    activeLeadsRetrievedAtSelector,
    closedLeadsRetrievedAtSelector,
    unassignedLeadsRetrievedAtSelector,
    closedTicketsRetrievedAtSelector,
  ],
  (isAdmin, active, closed, unassigned, closedTickets) => {
    const times = []
    if (active) times.push(moment(active))
    if (closed) times.push(moment(closed))
    if (unassigned) times.push(moment(unassigned))
    if (closedTickets) times.push(moment(closedTickets))
    if (times.length < (isAdmin ? 4 : 3)) return null
    const sorted = times.sort((b, a) => new Date(b) - new Date(a))
    return sorted[0].toDate()
  }
)

// get ids for active, closed, closed tickets, and unassigned leads
export const combinedLeadsIdsSelector = createSelector(
  [
    activeLeadsIdsSelector,
    closedLeadsIdsSelector,
    unassignedLeadsIdsSelector,
    closedTicketsIdsSelector,
  ],
  (activeIds, closedIds, unassignedIds, closedTicketsIds) => {
    return [...activeIds, ...closedIds, ...unassignedIds, ...closedTicketsIds]
  }
)

export const combinedLeadsSelector = state =>
  ticketsByIdsSelector(state, combinedLeadsIdsSelector(state))
export const activeLeadsSelector = state =>
  ticketsByIdsSelector(state, activeLeadsIdsSelector(state))
export const closedLeadsSelector = state =>
  ticketsByIdsSelector(state, closedLeadsIdsSelector(state))
export const closedTicketsSelector = state =>
  ticketsByIdsSelector(state, closedTicketsIdsSelector(state))
export const unassignedLeadsSelector = state =>
  ticketsByIdsSelector(state, unassignedLeadsIdsSelector(state))

export const leadIdFromRouterSelector = (state, props) => props.match.params.id

export const leadFromRouterSelector = createSelector(
  [combinedLeadsSelector, leadIdFromRouterSelector],
  (leads, id) => {
    const result = leads.filter(lead => lead.objectId === id)
    return result.length === 1 ? result[0] : null
  }
)
