import types from './types'

const INITIAL_STATE = {
  items: {},
}
const courtsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.ADD_MANY_COURTS: {
      const { countyId } = action
      const incomingCourts = action.value
      const updatedCourts = {}
      incomingCourts.forEach(court => {
        updatedCourts[court.objectId] = court
      })
      const items = { ...state.items }
      items[countyId] = updatedCourts
      return {
        ...state,
        items
      }
    }

    default:
      return state
  }
}

export default courtsReducer
