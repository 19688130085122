import { connect } from 'react-redux'
import {
  hearingsRegionFilterSelector,
  noDateHearingsSelector,
  noDateHearingsRetrievedAtSelector,
} from '../duck/selectors'
import { overlaySelectors } from '../../overlays/duck'
import { regionsSelectors } from '../../regions/duck'
import { hearingsThunks } from '../duck'
import HearingsListContainer from './HearingsListContainer'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.hearings.needsDate
const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(state, TICKET_TYPE),
  hearings: regionsSelectors.regionsFilterBuilder(
    noDateHearingsSelector,
    hearingsRegionFilterSelector
  )(state),
  retrievedAt: noDateHearingsRetrievedAtSelector(state),
})

const mapDispatchToProps = dispatch => ({
  fetchHearings: () => dispatch(hearingsThunks.fetchNoDateHearingsItems())
})

const NoHearingDateListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HearingsListContainer)

export default NoHearingDateListContainer
