import React from "react"

import withSendBird from "@sendbird/uikit-react/withSendbird"
import SBConversation from "@sendbird/uikit-react/Channel"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 100%;
`

function CustomizedSendbirdApp(props) {
  return (
    <Wrapper className="customized-app">
      <div className="sendbird-app__wrap">
        <div className="sendbird-app__conversation-wrap">
          <SBConversation channelUrl={props.currentChannelUrl} />
        </div>
      </div>
    </Wrapper>
  )
}

export default withSendBird(CustomizedSendbirdApp)
