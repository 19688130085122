import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

import LookupRoutes from './LookupRoutes'
import { CountLabel } from '../common'
import { FRONTEND_CONTACT_INFO_TYPES } from '../../constants'
import { ticketTools } from '../../utilities'


class LookupComponent extends Component {
  render() {
    const currentLocation = this.props.location.pathname
    let secondLevel = currentLocation.split('/').filter(v => v !== '')[1]
    if (!secondLevel) {
      secondLevel = 'all'
    }
    const { currentlyViewing } = this.props
    const currentlyViewingStatus = ticketTools.getTicketChildType(
      FRONTEND_CONTACT_INFO_TYPES.lookup,
      currentlyViewing
    )
    return (
      <Fragment>

        <Menu stackable={true} tabular>
          <Menu.Item
            as={Link}
            name="All"
            active={
              secondLevel === 'all' ||
              currentlyViewingStatus === FRONTEND_CONTACT_INFO_TYPES.all
            }
            to="/lookup"
          >
            All
            <CountLabel
              // key={`allContactInfo-count-${this.props.allContactInfo.length}`}
              // in={!!this.props.allContactInfoRetrieved}
              // loading={this.props.allContactInfoLoading}
              // value={this.props.allContactInfo.length}
            />
          </Menu.Item>

          <Menu.Item
            as={Link}
            name="hearingDate"
            active={
              secondLevel === 'hearingDate' ||
              currentlyViewingStatus === FRONTEND_CONTACT_INFO_TYPES.hearingDate
            }
            to="/lookup/hearingDate"
          >
            Hearing Date
            <CountLabel
              // key={`allContactInfo-count-${this.props.filteredContactInfo.length}`}
              // in={!!this.props.filteredContactInfoRetrieved}
              // loading={this.props.filteredContactInfoLoading}
              // value={this.props.filteredContactInfo.length}
            />
          </Menu.Item>
        </Menu>
        
        <LookupRoutes />

      </Fragment>
    )
  }
}

export default LookupComponent
