import { connect } from 'react-redux'
import { communicationsThunks } from '../duck'
import { ticketByIdSelector } from '../../tickets/duck/selectors'
import SendTrafficSubmissionMagicLinkComponent from './SendTrafficSubmissionMagicLinkComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
})

const mapDispatchToProps = dispatch => ({
  send: (ticket, header, message) => dispatch(
    communicationsThunks.sendSubmissionMagicLink(ticket, header, message)
  ),
  showUnavailableStateToast: (locationState) => dispatch(
    communicationsThunks.showUnavailableStateToast(locationState)
  ),
})

const SendTrafficSubmissionMagicLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendTrafficSubmissionMagicLinkComponent)

export default SendTrafficSubmissionMagicLinkContainer
