import React from 'react'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import ExportComponent from './Export/ExportComponent'

const Divider = styled.hr`
  margin: 22px 0;
  height: 3px;
  background-color: #000000;
`

class Exports extends React.Component {
  render() {
    return (
      <Grid centered={true} >
        <Grid.Column
          verticalAlign="middle"
          computer={13}
        >
        <br />

        <Grid.Row style={{margin: '10px'}}>
          <ExportComponent 
            name="Unassigned_Active_Leads"
            dataExport={this.props.unassignedActiveLeadsExport}
            getExport={this.props.getTrafficTicketsExport}
          />
        </Grid.Row>

        <Divider />

        <Grid.Row style={{margin: '10px'}}>
          <ExportComponent 
            name="Closed_Tickets"
            dataExport={this.props.closedTicketsExport}
            getExport={this.props.getTrafficTicketsExport}
          />
        </Grid.Row>

        <Divider/>

        <Grid.Row style={{margin: '10px'}}>
          <ExportComponent 
            name="Lookup_Tickets"
            dataExport={this.props.lookupTicketsExport}
            getExport={this.props.getLookupTicketsExport}
          />
        </Grid.Row>

        </Grid.Column>
      </Grid>
    )
  }
}


export default Exports