import { connect } from 'react-redux'
import { stepSelector, codeSelector, userSelector } from '../../duck/selectors'
import { isLoadingSelector } from '../../../overlays/duck/selectors'
import { activationThunks } from '../../duck'
import CreateUserComponent from './CreateUserComponent'

const mapStateToProps = state => ({
  step: stepSelector(state),
  user: userSelector(state),
  code: codeSelector(state),
  isLoading: isLoadingSelector(state)
})

const mapDispatchToProps = dispatch => ({
  validateUser: (...params) => dispatch(activationThunks.validateUser(...params)),
  complete: (userObjectId, activationCode) => dispatch(activationThunks.completeActivation(userObjectId, activationCode))
})

const CreateUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUserComponent)

export default CreateUserContainer
