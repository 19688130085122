import React from "react"
import styled from "styled-components"
import SharedActionWords from "../shared/SharedActionWords"

const Refunds = ({ refunds }) => {
  if (refunds.length === 0) return <SharedActionWords name='refunds' />

  return (
    <DetailsWrap>
      <PlanDetails className='refunds'>
          <p>Date {refunds.map(refund => {
            const date = new Date(refund.inserted_at)
            const options = { month: 'long', day: 'numeric', year: 'numeric' }
            let refundDate = date.toLocaleDateString('en-US', options) 
            return <span key={refund.id}>{refundDate}</span>
          })}</p>
          <p>Amount {refunds.map(refund => <span key={refund.id}>${refund.amount}</span>)}</p>
          <p>Reason {refunds.map(refund => <span key={refund.id}>{refund.reason}</span>)}</p>
          <p>Status {refunds.map(refund => <span key={refund.id} className='status'
              style={{ color: refund.status === 'succeeded' ? 'green' : 'red' }}>{refund.status}</span>)}</p>
          <p>Processor {refunds.map(refund => <span key={refund.id}>{refund.processor}</span>)}</p>
        </PlanDetails>
      </DetailsWrap>
    )
}

const DetailsWrap = styled.div`
  display: flex;
  justify-content: space-evenly;

  span {
    font-weight: 400;
  }

  .status {
    font-weight: 600;
  }

  p {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
  }
`

const PlanDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
  border: 2px solid lightgray;
  width: 90%;
  border-radius: 5px;

  p {
    display: flex;
    flex-direction: column;
  }

  span {
    padding: 4px 0;
  }
`

export default Refunds