import React from 'react'
import { StyledElements } from '../../../common'
import Dropzone from 'react-dropzone'

class EvidenceDropzone extends React.Component {
  handleImage = ticketEvidence => {
    this.props.addEvidence(this.props.objectId, ticketEvidence[0])
  }

  render() {
    return (
      <>
        <Dropzone multiple={false} onDrop={this.handleImage} accept="image/*">
          {({ getRootProps, getInputProps }) => {
            return (
              <>
                <input {...getInputProps()} />
                <StyledElements.FakeLink
                  {...getRootProps({ onClick: evt => evt.preventDefault() })}
                >
                  Add Evidence
                </StyledElements.FakeLink>
              </>
            )
          }}
        </Dropzone>
      </>
    )
  }
}

export default EvidenceDropzone
