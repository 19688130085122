import { axios } from '../../../utilities'

export const ticketIDSearch = searchObject => {
  return axios.secure
    .post(`/search/ticket-id/`, searchObject)
    .then(res => res.data)
}

export const licenseIDSearch = searchObject => {
  return axios.secure
    .post(`/search/license-id`, searchObject)
    .then(res => res.data)
}

export const phoneNumberSearch = searchObject => {
  return axios.secure
    .post(`/search/phone-number/`, searchObject)
    .then(res => res.data)
}

export const emailSearch = searchObject => {
  return axios.secure
    .post(`/search/email/`, searchObject)
    .then(res => res.data)
}