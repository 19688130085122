import React, { Component } from 'react'
import { StyledElements } from '../../../common'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import styled from 'styled-components'

const Separator = styled.span`
  color: rgb(0,0,0,0.5);
`

class ViewRetentionLetter extends Component {
  render() {
    const { ticket, objectId } = this.props
    const RenderLink = this.RenderLink
    return (
      <StyledElements.DataContainer>
        <ItemHeader
          title={'Client Retention Letter'}
          isEditable={false}
          isEditing={false}
          toggleState={() => {}}
        />
        <SpecificOverlayContainer
          overlayKey={objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {!ticket || !ticket.retentionLetter ? (
            <span>
              <StyledElements.Italicize>
                No retention letter available.
              </StyledElements.Italicize>
              <Separator>&nbsp;|&nbsp;</Separator>
              <StyledElements.FakeLink
                onClick={() => {this.props.generateRetentionLetter(this.props.ticket)}}
              >
                Generate
              </StyledElements.FakeLink>
            </span>
          ) : (
            <RenderLink />
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }

  RenderLink = () => {
    const { ticket } = this.props
    const { retentionLetter } = ticket
    if (!retentionLetter || !retentionLetter.url) {
      return (
        <StyledElements.Italicize>
          No download link available
        </StyledElements.Italicize>
      )
    }
    return (
      <a href={retentionLetter.url} target="_blank" rel="noopener noreferrer">
        View Retention File
      </a>
    )
  }
}

export default ViewRetentionLetter
