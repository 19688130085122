import React, { Fragment, useState } from "react"
import { useHistory } from "react-router-dom"
import { Modal } from "semantic-ui-react"
import styled from "styled-components"
import { CASE_WINIT_FEE } from "../../../constants"
import SharedCloseBtn from "../shared/SharedCloseBtn"
import SharedSubmitBtn from "../shared/SharedSubmitBtn"

const EditTicketPrice = ({ tickets, update, error, setShowPriceModal }) => {
  const [ticketID, setTicketID] = useState('')
  let [ticketPrice, setTicketPrice] = useState('' || CASE_WINIT_FEE)
  const [notifMessage, setNotifMessage] = useState('')
  
  const history = useHistory()
  
  const handleChange = (ev) => {
    tickets.map(ticket => {
      ticketPrice = ticket.winItPrice
      if (ev.target.value === '') {
        return setTicketPrice('')
      }
      if (
        ev.target.value.indexOf(".") === ev.target.value.length - 1 &&
        ev.target.value.slice(-1) === "." &&
        Number(ev.target.value.slice(0, -1))
      ) {
        return setTicketPrice(ev.target.value)
      }
      const price = Number(ev.target.value)
      if (price) return setTicketPrice(price)
    })
  }
  
  const updatePrice = () => {
    tickets.map(ticket => {      
      if (ticketID === ticket.ticketID && ticketPrice <= CASE_WINIT_FEE) {
        setNotifMessage(`Price must be greater than $${CASE_WINIT_FEE.toFixed(2)}`)
        return error(`Price must be greater than $${CASE_WINIT_FEE.toFixed(2)}`)
      }
      if  (ticketID === ticket.ticketID && ticketPrice !== ticket.winItPrice) {
        update(ticket.objectId, { winItPrice: ticketPrice })
        setNotifMessage(`Price for Ticket #${ticketID} is now $${ticketPrice}`)
      }
    })
  }
  
  return (
    <>
      <Modal.Header>Edit Ticket Prices</Modal.Header>
      <Modal.Content>
        <WarningDiv>
          <span>Follow these steps to correctly update prices:</span>
          <ol>
            <li>Enter a price for the correct ticket in the input</li>
            <li>Click on 'Set' once a price is set for the selected ticket</li>
            <li>If price is set for correct ticket, you should see a text appear on the bottom</li>
            <li>Repeat steps 1 to 3 if more tickets need to be updated</li>
            <li>Once prices are set, click on the 'Update' button to refresh the page</li>
            <li>If you skip one of these steps, prices will not update correctly!</li>
          </ol>
        </WarningDiv>
        <TicketWrap>
          {tickets.map(ticket => {
            return (
              <Fragment key={ticket.ticketID}>
              <Wrapper onClick={() => setTicketID(ticket.ticketID)}>
                <p>{ticket.ticketID} <span>${ticket.winItPrice}</span></p>
                <UpdatePrice>
                  <input type="number" onChange={handleChange} />
                  <button onClick={updatePrice}>Set</button>
                </UpdatePrice>
            </Wrapper>
            </Fragment>
            )
          })}
        </TicketWrap>
        <Message>{notifMessage}</Message>
      </Modal.Content>
      <Modal.Actions>
        <SharedCloseBtn name='Close' type='button' onClick={() => setShowPriceModal(false)} />
        <SharedSubmitBtn className='submit' type='button' onClick={() => history.go(0)} value='Update' />
      </Modal.Actions>
    </>
  )
}

const WarningDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: auto;
  padding: 20px 10px;
  background-color: #ffdede;
  border-radius: 10px;
  margin-bottom: 30px;

  span, li {
    font-weight: 500;
    font-size: 15px;
    color: #9f0000;
  }

  ol {
    margin: 10px 0 0 0;
  }
`

const TicketWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
`

const Wrapper = styled.div`
  display: flex;
  width: 170px;
  flex-wrap: wrap;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  justify-content: center;
  background-color: #e1e1e1;
  cursor: pointer;

  span {
    margin-left: 5px;
    color: green;
  }
`

const UpdatePrice = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 85px;
    margin-right: 5px;
    outline: none;
    padding: 0 5px;
    height: 25px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button {
    background-color: #21ba45;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 25px;
    color: white;
    font-weight: bold;
    font-size: 12px;

    &:hover {
    filter: brightness(90%);
    }
  }
`

const Message = styled.p`
  display: flex;
  margin: 10px 0;
  font-size: 20px;
`

export default EditTicketPrice