import React from "react"
import { useState } from "react"
import { Popup } from "semantic-ui-react"
import styled from "styled-components"
import { refundPayment } from "../duck/services"
import SharedActionWords from "../shared/SharedActionWords"

const Payments = ({ attorney, payments, setShowPlanModal, setShowDetailedPlan, success, error }) => {
  const [refundAmount, setRefundAmount] = useState(0)
  const [installmentId, setInstallmentId] = useState('')

  const refundPaymentHandler = () => {
    refundPayment(installmentId, { amount: refundAmount }).then(res => {
      if (res.status === 201) {
        setShowPlanModal(false)
        setShowDetailedPlan(false)
        success(`Refunded $${refundAmount} successfully`)
      } else {
        setShowPlanModal(false)
        setShowDetailedPlan(false)
        error('Refund Failed', res.error)
      }
    })
  }

  if (payments.length === 0) return <SharedActionWords name='Payments' />

  const statusReason =
    <p>Status {payments.map(payment => {
      if (payment.message !== null) {
        return <Popup key={payment.id} basic position="right center" content={payment.message} trigger={
        <span key={payment.id} className='status' style={{ color: payment.status === 'paid' ? 'green' : 'red', cursor: 'help' }}>{payment.status.toUpperCase()}</span>
      } />
      } else {
        return <span key={payment.id} className='status' style={{ color: payment.status === 'paid' ? 'green' : 'red' }}>{payment.status.toUpperCase()}</span>
      }
    })}</p>
  
  const datePaid =
    <p>Date Paid {payments.map(payment => {
      let date
      const options = { month: 'long', day: 'numeric', year: 'numeric' }

      if (payment.paid_at !== null) {
        const paidAt = new Date(payment.paid_at)
        date = paidAt.toLocaleDateString('en-us', options)
        return <span key={payment.id} >{date}</span>
      } else {
        const insertedAt = new Date(payment.inserted_at)
        date = insertedAt.toLocaleDateString('en-us', options)
        return <span key={payment.id} >{date}</span>
      }
    })}</p>
    
  return (
    <DetailsWrap>
      <PlanDetails>
        {statusReason}
        <p>Amount {payments.map(payment => <span key={payment.id}>${parseFloat(payment.amount).toFixed(2)}</span>)}</p>
        {datePaid}
        <p>Processor {payments.map(payment => <span key={payment.id}>{payment.processor}</span>)}</p>
      </PlanDetails>
      {attorney.globalAdmin && attorney.isSupervisor &&
        <PlanDetails className='refundDiv'>
        <p>Refunds</p>
        <form onSubmit={(ev) => ev.preventDefault()}>
          {payments.map(payment => {
            return (
              <DetailsWrap key={payment.id} className='refundForm'>
                <input onClick={() => setInstallmentId(payment.id)} type='number' placeholder='amount' step='.01' min={0} max={payment.amount} onChange={(ev) => setRefundAmount(ev.target.value)} />
                <Popup style={{ width: '150px', textAlign: 'center' }} on='click' basic header={`Refund $${refundAmount}`}
                  content={<button onClick={refundPaymentHandler} style={{ marginTop: '10px' }} type="submit">Confirm</button>} trigger={<button type='button'>Refund</button>} />
              </DetailsWrap>
            )
          })}
        </form>
      </PlanDetails>}
    </DetailsWrap>
  )
}

const DetailsWrap = styled.div`
  display: flex;
  justify-content: center;

  span {
    font-weight: 400;
  }

  .status {
    font-weight: 600;
  }

  p {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
  }
` 

const PlanDetails = styled.div`
  display: flex;
  width: 60%;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  border: 2px solid lightgray;
  padding: 10px;
  border-radius: 5px;

  p {
    display: flex;
    flex-direction: column;
  }

  span {
    padding: 4px 0;
  }

  &.refundDiv {
    display: flex;
    flex-direction: column;
    width: 20%;
    justify-content: flex-start;
    margin-left: 5px;

    input {
      width: 100px;
      outline: none;
      margin-bottom: 5px;
      border: 1px solid lightgrey;
      border-radius: 2.5px;
      height: 22px;
      padding: 3px;
    }

    button {
      outline: none;
      border: none;
      font-weight: 500;
      border-radius: 2px;
      height: 22px;
      margin-left: 3px;
      background-color: lightblue;
      cursor: pointer;

      &:hover {
        filter: brightness(90%);
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin-right: 0;
    }
  }

  &.submit {
    outline: none;
    border: none;
    font-weight: 500;
    border-radius: 2px;
    height: 22px;
    margin-left: 3px;
    background-color: lightblue;
    cursor: pointer;

    &:hover {
      filter: brightness(90%);
      }
  }
`

export default Payments