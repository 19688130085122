import React, { Fragment } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ActivationRoutes from './activation/ActivationRoutes'
import OverlaysComponent from './overlays/OverlaysComponent'
import MainContainer from './MainContainer'

const App = ({ isLoggedIn }) => {
  if (!isLoggedIn) {
    return (
      <Fragment>
        <OverlaysComponent />
        <ActivationRoutes />
      </Fragment>
    )
  }

  return (
    <Router>
      <MainContainer />
    </Router>
  )
}

export default App
