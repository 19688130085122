import React, { Component, Fragment } from 'react';
import { Header, Icon, Segment, Form, Button } from 'semantic-ui-react'

class CompleteComponent extends Component {
  handleSubmit = () => {
    this.props.history.push('/')
  }
  render() {
    return (
      <Fragment>

        <Header as="h4" textAlign="center" attached='top' inverted>
          Activation Complete
        </Header>
        <Segment attached>
          <Form size="large">
            <p>
              Activation was successfull. You can now login.
            </p>
            <Button
              type="submit"
              fluid
              size="large"
              onClick={this.handleSubmit}
              labelPosition="right"
              icon
            >
              <Icon name="angle right" />
              Login
            </Button>
          </Form>
        </Segment>
      </Fragment>
    );
  }
}

export default CompleteComponent;
