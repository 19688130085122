import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'

const LoadingText = styled.p`
  padding: 10px;
  font-weight: bold;
  font-size: 1.5em;
`

const LoadingComponent = ({ isLoading }) => {
  return (
    <Dimmer active={isLoading}>
      <Loader size="large" active>
        <LoadingText>Loading</LoadingText>
      </Loader>
    </Dimmer>
  )
}

export default LoadingComponent
