import React, { Component, Fragment } from 'react'
// import { Link } from 'react-router-dom'
// import { Menu } from 'semantic-ui-react'
// import ReactTable from 'react-table';
// import styled from 'styled-components'
// import moment from 'moment';
// import { TICKET_STATUS_MAP } from '../../constants'
import CallsRoutes from './CallsRoutes'

// const StyledSideMenu = styled(Menu)`
// &&& {
//   margin-top: 1em;
// }`

class CallsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contextRef: null
    }
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  render() {
    return (
      <Fragment>

        {/* <Grid columns='equal'>
          <Grid.Column>
            <Sticky context={contextRef}>
              <StyledSideMenu pointing={true} vertical borderless={true} fluid={true}>
                <Menu.Item><Menu.Header>Leads</Menu.Header></Menu.Item>
                <Menu.Item as={Link} name='Active Leads' active={secondLevel === 'active' || currentlyViewingStatus === 'active'} to="/leads/active">
                  <Label>{this.props.activeLeadsRetrievedAt ? this.props.activeLeads.length : '?'}</Label>
                  Active Leads
                  </Menu.Item>
                <Menu.Item as={Link} name='Closed Leads' active={secondLevel === 'closed' || currentlyViewingStatus === 'closed'} to="/leads/closed">
                  <Label>{this.props.closedLeadsRetrievedAt ? this.props.closedLeads.length : '?'}</Label>
                  Closed Leads
                  </Menu.Item>
              </StyledSideMenu>
            </Sticky>

          </Grid.Column>
          <Grid.Column width={13}>
            <div ref={this.handleContextRef}> */}
        <CallsRoutes />
        {/* </div>
          </Grid.Column>
        </Grid> */}

      </Fragment>
    )
  }
}


export default CallsComponent
