import React from 'react'
import { DateInput } from "@blueprintjs/datetime";
import { Position } from "@blueprintjs/core";
import moment from "moment"

import { DATE_FORMATS } from '../../constants'

const BaseDateTimeInput = (props) => {
  return <DateInput
    formatDate={(date, locale) => date ? moment(date).format(DATE_FORMATS.DATE_TIME) : null}
    popoverProps={{ position: Position.RIGHT }}
    parseDate={str => str ? new Date(Date.parse(str)) : null}
    placeholder="MM/DD/YYYY h:mm a"
    closeOnSelection={true}
    disabled={false}
    showActionsBar={true}
    todayButtonText="Today"
    canClearSelection={true}
    clearButtonText="Clear"
    maxDate={moment().add(30, "years").toDate()}
    minDate={moment().subtract(30, "years").toDate()}
    {...props}
  />
}

export default BaseDateTimeInput
