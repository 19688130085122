export const formatSearchCriteriaOnTickets = (contactInfo) => {
  const searches = contactInfo.searchCriteria
  searches.forEach((searchCriteria, index) => {
    const searchInfo = {}
    const searchResults = searchCriteria.results
    for (let key in searchCriteria) {
      switch(key) {
        case 'licenseID':
        case 'last4':
        case 'gender':
        case 'dob':
          searchInfo[key] = searchCriteria[key]
          break;
        default:
      }
    }
    contactInfo.searchCriteria[index].results = searchResults.map(result => {
      for (let key in searchInfo) {
        result[key] = searchInfo[key]
      }
      if (result.fetcherName === "NY_CRIMINAL") {
        result.charges = result.rawData.Charges
      }
      return result
    })
  })
  return contactInfo
}