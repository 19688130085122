import { connect } from 'react-redux'
import { ticketByIdSelector } from '../../duck/selectors'
import { ticketsThunks } from '../../duck'
import EditTicketDateComponent from './EditTicketDateComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId)
})

const mapDispatchToProps = dispatch => ({
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject))
})

const EditTicketDateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTicketDateComponent)

export default EditTicketDateContainer
