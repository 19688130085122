import { connect } from 'react-redux'

import { overlaySelectors } from '../overlays/duck'
import { hearingsActions, hearingsSelectors } from './duck'
import { FRONTEND_TICKET_TYPES } from '../../constants'

import HearingsComponent from './HearingsComponent'

const mapStateToProps = state => ({
  recentHearings: hearingsSelectors.recentHearingsSelector(state),
  recentHearingsRetrievedAt: hearingsSelectors.recentHearingsRetrievedAtSelector(state),
  recentHearingsLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, FRONTEND_TICKET_TYPES.hearings.recent),
  noDateHearings: hearingsSelectors.noDateHearingsSelector(state),
  noDateHearingsRetrievedAt: hearingsSelectors.noDateHearingsRetrievedAtSelector(state),
  noDateHearingsLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, FRONTEND_TICKET_TYPES.hearings.needsDate),
  currentlyViewing: hearingsSelectors.currentlyViewingSelector(state),
  regionFilter: hearingsSelectors.hearingsRegionFilterSelector(state)
})

const mapDispatchToProps = dispatch => ({
  setRegionFilter: (region) => dispatch(hearingsActions.setHearingsRegionFilter(region))
})

const HearingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HearingsComponent)

export default HearingsContainer
