import { axios } from "../../../utilities"

/**
 * Customer
 */
export const getPartiallyCustomers = (searchQuery) => {
    return axios.secure.post('/partially', {searchQuery}).then(res => res.data)
}

export const getPartiallyCustomer = (customerId) => {
    return axios.secure.get(`/partially/${customerId}`).then(res => res.data)
}

export const createPartiallyCustomer = (data) => {
    return axios.secure.post('/partially/new-customer', data).then(res => res.data)
}

export const editCustomerInfo = (customerId, data) => {
    return axios.secure.put(`/partially/${customerId}`, data).then(res => res.data)
}

/**
 * Payment Plans
 */
export const getPaymentPlans = (customerId) => {
    return axios.secure.get(`/partially/plans/${customerId}`).then(res => res.data)
}

export const getPaymentPlan = (planId) => {
    return axios.secure.get(`/partially/payment-plan/${planId}`).then(res => res.data)
}

export const createPaymentPlan = (data) => {
    return axios.secure.post('/partially/new-payment-plan', data).then(res => res.data)
}

export const sendSlackMessage = (data) => {
    return axios.secure.post('partially/new-payment-plan/slackMessage', data).then(res => res.data)
}

export const openPaymentPlan = (planId, data) => {
    return axios.secure.put(`/partially/payment-plan/open/${planId}`, data).then(res => res.data)
}

export const cancelPaymentPlan = (planId) => {
    return axios.secure.put(`/partially/payment-plan/cancel/${planId}`).then(res => res.data)
}

export const sendPlanRequest = (planId) => {
    return axios.secure.post(`partially/payment-plan/planRequest/${planId}`).then(res => res.data)
}

export const updatePaymentPlan = (planId, data) => {
    return axios.secure.put(`partially/payment-plan/addNotes/${planId}`, data).then(res => res.data)
}

/**
 * Payment Method
 */
export const getIsSupervisor = (attorneyId) => {
    return axios.secure.get(`/partially/payment-method/isSupervisor/${attorneyId}`).then(res => res.data)
}

export const updateIsAllowedCC = (attorneyUserJoinId, data) => {
    return axios.secure.put(`/partially/payment-method/allowCC/${attorneyUserJoinId}`, data).then(res => res.data)
}

export const getPaymentMethod = (customerId) => {
    return axios.secure.get(`/partially/payment-method/${customerId}`).then(res => res.data)
}

export const tokenizePaymentDetails = (data) => {
    return axios.secure.post('/partially/stripe/payment-method', data).then(res => res.data)
}

export const addPaymentDetails = (data) => {
    return axios.secure.post('/partially/add/payment-method', data).then(res => res.data)
}

/**
 * Refunds
 */
export const refundPayment = (paymentId, data) => {
    return axios.secure.post(`/partially/refund/${paymentId}`, data).then(res => res.data)
}

/**
 * Manual Payments
 */
export const createPayment = (planId, data) => {
    return axios.secure.post(`/partially/createPayment/${planId}`, data).then(res => res.data)
}

export const confirmPayment = (planId, data) => {
    return axios.secure.post(`/partially/confirmPayment/${planId}`, data).then(res => res.data)
}