import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'

const EditValueComponent = ({ editKey, editValue }) => {
  let safeValue
  let clearedField = false
  switch (typeof editValue) {
    case 'boolean':
      safeValue = editValue ? 'true' : 'false'
      break
    case 'object':
      if (editValue.__type === 'Date') {
        safeValue = editValue.iso
      } else {
        safeValue = JSON.stringify(editValue)
      }
      break
    case 'undefined':
      safeValue = <b style={{ textDecoration: 'underline' }}>Cleared Field</b>
      clearedField = true
      break
    case 'number':
    case 'string':
    default:
      safeValue = editValue
      break
  }
  if (clearedField) {
    return (
      <List.Item>
        <em>
          Cleared data in <b>{editKey}</b>
        </em>
      </List.Item>
    )
  }
  return (
    <List.Item>
      <b>
        <em>{editKey}: </em>
      </b>{' '}
      {safeValue}
    </List.Item>
  )
  // return (
  //   <List.Item>
  //     <em>{editKey}: </em> {safeValue}
  //   </List.Item>
  // )
}

EditValueComponent.defaultProps = {
  value: undefined,
}

EditValueComponent.propTypes = {
  editKey: PropTypes.string.isRequired,
  value: PropTypes.any,
}

export default EditValueComponent
