import React from 'react'
import { Message, Icon } from 'semantic-ui-react'

const LoadingSearchResults = () => {
  return (
    <Message icon>
      <Icon name="circle notched" loading />
      <Message.Content>
        <Message.Header>Just one second</Message.Header>
        Searching for your query.
      </Message.Content>
    </Message>
  )
}

export default LoadingSearchResults
