import { usersActions, usersServices } from './index'
import { ticketsActions } from '../../tickets/duck'
import { overlaysActions } from '../../overlays/duck'
import { specificOverlayTools } from '../../../utilities'
import {
  SPECIFIC_OVERLAYS_ITEMS,
  SPECIFIC_OVERLAYS_TYPE,
  NOTIFICATION_TYPES,
} from '../../../constants'

export const fetchUser = objectId => dispatch => {
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_ITEMS.users
  )
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      KEY,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  return usersServices
    .getUser(objectId)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(usersActions.addUser(result))
      return result
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      console.error('error fetching single user', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 20,
          title: `Issue Fetching User Data`,
          message: error,
        })
      )
    })
}

export const updateUser = (objectId, updatesObject) => dispatch => {
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_ITEMS.users
  )
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      KEY,
      SPECIFIC_OVERLAYS_TYPE.updating
    )
  )
  return usersServices
    .updateUser(objectId, updatesObject)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Updated User ID# ${result.objectId}`,
          duration: 5,
        })
      )
      dispatch(usersActions.addUser(result))
      dispatch(ticketsActions.updateUserOnTickets(result))
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      console.error('error updating a single user', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 20,
          title: `Issue Updating User ID# ${objectId}`,
          message: error,
        })
      )
    })
}

export const checkPaymentMethod = objectId => dispatch => {
  dispatch(overlaysActions.showLoader())
  return usersServices
    .checkUserPaymentMethod(objectId)
    .then(result => {
      dispatch(usersActions.addUser(result))
      dispatch(ticketsActions.updateUserOnTickets(result))
      dispatch(overlaysActions.hideLoader())
    })
    .catch(error => {
      dispatch(overlaysActions.hideLoader())
      console.error('error checking payment update fpr a user', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 20,
          title: `Issue Checking Updated Payment Method`,
          message: error,
        })
      )
    })
}

export const isUserBlocked = objectId => dispatch => {
    dispatch(overlaysActions.showLoader())
    return usersServices
        .isUserBlocked(objectId)
        .then(result => {
            dispatch(usersActions.setIsBlockedUser(objectId,result))
            dispatch(overlaysActions.hideLoader())
            return result
        })
        .catch(error => {
            dispatch(overlaysActions.hideLoader())
            console.error('error checking if user is blocked for a user', error)
            dispatch(
                overlaysActions.addNotification({
                    type: NOTIFICATION_TYPES.error,
                    duration: 20,
                    title: `Issue Checking User is Blocked`,
                    message: error,
                })
            )
        })
}

export const warnMissingPaymentProcessor = user => dispatch => {
  dispatch(
    overlaysActions.addNotification({
        type: NOTIFICATION_TYPES.error,
        duration: 20,
        title: `Warning`,
        message: `User ${user.objectId} missing payment processor.`,
    })
  )
}
