import { connect } from 'react-redux'
import { isLoadingSelector } from '../duck/selectors'
import LoadingComponent from './LoadingComponent'

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state)
})

const LoadingContainer = connect(mapStateToProps)(LoadingComponent)

export default LoadingContainer
