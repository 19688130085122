import { connect } from 'react-redux'

import { attorneyIsAdminSelector } from '../../../app/authentication/duck/selectors'
import { createdTicketSelector } from '../duck/selectors'
import CreateTicketComponent from './CreateTicketComponent'
import thunks from '../duck/thunks'
import { setTicket } from '../duck/actions'


const mapStateToProps = state => ({
  isAdmin: attorneyIsAdminSelector(state),
  createdTicket: createdTicketSelector(state),
})

const mapDispatchToProps = dispatch => ({
  createTrafficTicket: (ticketInfo) => dispatch(thunks.createTrafficTicket(ticketInfo)),
  setTicket: (ticket) => dispatch(setTicket(ticket)),
})

const CreateTicketContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTicketComponent)

export default CreateTicketContainer
