import React, { Component } from 'react'
import {
  createTicketFromDOTTicket,
  createTicketFromSearchResult,
} from '../../../tickets/duck/services'
import CreateTrafficTicketButton from './CreateTrafficTicketButton'
import CreateTrafficTicketDropDown from './CreateTrafficTicketDropdown'
import { overlaysActions } from '../../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../../constants'
import { connect } from 'react-redux'

class CreateTrafficTicket extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAttorney: '',
      loading: false,
      ticketCreated: false,
    }
  }

  selectAttorney = (event, { value }) => {
    this.setState({ selectedAttorney: value })
  }

  createTicket = {
    DOTTicket: createTicketFromDOTTicket,
    searchResult: createTicketFromSearchResult,
  }

  formatData = () => {
    const data = {
      attorneyObjectId: this.state.selectedAttorney,
    }
    if (this.props.ticketType === 'DOTTicket') {
      data.dotTicketObjectId = this.props.DOTTicket.objectId
    } else if (this.props.ticketType === 'searchResult') {
      data.searchResultObjectId = this.props.searchResult.objectId
      data.contactInfoObjectId = this.props.contactInfo.objectId
    }
    return data
  }

  createTrafficTicket = () => {
    this.setState({ loading: true })
    const data = this.formatData()
    this.createTicket[this.props.ticketType](data)
      .then(response => {
        this.setState({
          loading: false,
          ticketCreated: true,
        })
        this.props.history.push(`/search/view/${response.objectId}`)
      })
      .catch(error => {
        this.props.error('Could not create traffic ticket', error)
        this.setState({ loading: false })
      })
  }

  render() {
    let attorneys = this.props.options
    const { contactInfo, ticketType, DOTTicket } = this.props
    const user = contactInfo ? contactInfo.user : null
    const hasUserToAssociate =
      (user && user.objectId && ticketType === 'searchResult') ||
      (DOTTicket && ticketType === 'DOTTicket') // If on DOTTicket listing, we have always a user.

    let hasNoMatchingCourt = !this.props[ticketType].trafficCourt
    if (attorneys.length || hasNoMatchingCourt) {
      return (
        <>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <CreateTrafficTicketDropDown
                noMatchingCourt={hasNoMatchingCourt}
                locationCourt={this.props[ticketType].locationCourt}
                attorneys={attorneys}
                selectAttorney={this.selectAttorney}
              />
              <CreateTrafficTicketButton
                DOTTicket={this.props.DOTTicket}
                searchResult={this.props.searchResult}
                ticketType={this.props.ticketType}
                loading={this.state.loading}
                success={this.state.success}
                createTrafficTicket={this.createTrafficTicket}
                attorneySelected={!!this.state.selectedAttorney}
                hasUserToAssociate={hasUserToAssociate}
              />
            </div>
          </div>
        </>
      )
    }
    return (
      <span>
        No eligible attorney for this ticket.
        <br />
      </span>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  error: (title, message) => dispatch(overlaysActions.addNotification({
    type: NOTIFICATION_TYPES.error,
    duration: 15,
    title,
    message
  }))
})

const CreateTrafficTicketContainer = connect(
  null,
  mapDispatchToProps
)(CreateTrafficTicket)

export default CreateTrafficTicketContainer
