export const customStyles = {
  container: (base) => ({
    ...base,
  }),
  option: (base) => ({
    ...base,
    padding: 10,
    borderBottom: 'solid 1px #cececf',
    cursor: 'pointer'
  }),
  menu: (base) => ({
    ...base,
    zIndex: 100
  })
}
