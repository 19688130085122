import { connect } from 'react-redux'
import { overlaySelectors } from '../overlays/duck'
import { processingActions, processingSelectors } from './duck'
import ProcessingComponent from './ProcessingComponent'
import { FRONTEND_TICKET_TYPES } from '../../constants'

const PROCESSING = FRONTEND_TICKET_TYPES.processing
const mapStateToProps = state => {
  const currentCategory = processingSelectors.ids(PROCESSING.recentlyApproved, state)
  return {
    currentlyViewing: processingSelectors.currentlyViewingSelector(state),
    regionFilter: processingSelectors.processingRegionFilterSelector(state),
    allProcessing: processingSelectors.byType(state, PROCESSING.all),
    allProcessingRetrievedAt: processingSelectors.retrievedAt(state, PROCESSING.all),
    allProcessingLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, PROCESSING.all),
    recentlyApproved: processingSelectors.recentlyApprovedSelector(state),
    recentlyApprovedRetrievedAt: processingSelectors.retrievedAt(state, currentCategory),
    recentlyApprovedLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, PROCESSING.recentlyApproved + "/" + currentCategory),
    suspended: processingSelectors.suspendedCasesSelector(state),
    suspendedRetrievedAt: processingSelectors.suspendedCasesRetrievedAt(state),
    suspendedLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, PROCESSING.suspended),
    requiresApproval: processingSelectors.requiresApprovalCasesSelector(state),
    requiresApprovalRetrievedAt: processingSelectors.requiresApprovalCasesRetrievedAt(state),
    requiresApprovalLoading: overlaySelectors.specificOverlayLoadingStatusSelector(state, PROCESSING.requiresApproval),
  }
}

const mapDispatchToProps = dispatch => ({
  setRegionFilter: (region) => dispatch(processingActions.setProcessingRegionFilter(region))
})

const ProcessingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessingComponent)

export default ProcessingContainer
