import types from './types'

const INITIAL_STATE = {
  chats: [],
  chatWindowShowing: false,
  attorneyChatObjectId: "",
  currentChannelUrl: "",
}
const chatsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_CHATS: {
      const { value } = action
      return {
        ...state,
        allChats: value,
      }
    }

    case types.SET_CHAT_WINDOW_SHOWING: {
      const { value } = action
      return {
        ...state,
        chatWindowShowing: value,
      }
    }

    case types.SET_CHAT_ATTORNEY_ID: {
      const { value } = action
      return {
        ...state,
        attorneyChatObjectId: value,
      }
    }

    case types.SET_CHAT_CURRENT_CHANNEL_URL: {
      const { value } = action
      return {
        ...state,
        currentChannelUrl: value,
      }
    }

    default:
      return state
  }
}

export default chatsReducer
