import React from 'react'
import styled from 'styled-components'
import BaseDateInput from './BaseDateInput'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`

const Title = styled.div`
  font-size: 1.2em;
  padding: 5px;
  font-weight: bold;
`

const SectionDateSelector = ({ title, value, onChange }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <BaseDateInput
        defaultValue={value ? new Date(value) : undefined}
        onChange={onChange}
      />
    </Container>
  )
}

export default SectionDateSelector
