import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { Segment, Dropdown } from 'semantic-ui-react'
import ReactTable from 'react-table'

import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import {
  ListToolbarComponent,
  RTTools,
  StyledElements,
} from '../../common'
import '../../common/react-table/style.css'
import Error from '../../Error'
import SectionDateSelector from '../../common/SectionDateSelector'
import resolvedDataForProcessing from '../../common/csv/resolvedDataForProcessing'
import { CSVLink } from 'react-csv'

class RecentlyApprovedProcessingListComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
      CSVDataToDownload: []
    }
  }
  componentDidMount() {
    if (!this.props.retrievedAt && !this.props.loading) {
      this.props.fetchProcessingItems(this.props.currentCategory)
    }
  }
  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }
  getCSVData = () => {
    const currentRecords = this.reactTable.getResolvedState().data
    const currentRecordsFiltered = resolvedDataForProcessing(currentRecords)
    this.setState({ CSVDataToDownload: currentRecordsFiltered }, () => {
      this.csvLink.link.click()
    })
  }
  getRegionText = (prepend = 'in ') => {
    if (!this.props.regionFilter) return ''
    return `${prepend}${this.props.regionFilter.name}`
  }
  handleApprovalDateChange = event => {
    const input = moment(event)
    // const now = moment()
    const tomorrow = moment().add(1, 'days')
    const dateString = input.format('L')
    if (input.isSameOrBefore(tomorrow)) {
      this.props.setRecentlyApprovedDate(dateString)
      if (
        !this.props.allProcessing[dateString] ||
        !this.props.allProcessing[dateString].retrievedAt
      ) {
        return this.props.fetchProcessingItems(dateString)
      }
      return null
    }
    // if (input.isSameOrBefore(tomorrow)) {
    //   return this.props.setRecentlyApprovedDate(dateString)
    // }
    if (input.isAfter(tomorrow)) {
      return this.props.error(
        `Cannot Lookup Approvals Past ${tomorrow.format('L')}`
      )
    }
  }
  renderToolBars = () => {
    const toolbarItems = [
      {
        icon: 'law',
        text: `${this.props.processing.length} Cases Approved on ${
          this.props.currentCategory
        } ${this.getRegionText()}`,
      },
    ]
    return (
      <Fragment>
        <SectionDateSelector
          title="Approval Date"
          value={this.props.currentCategory}
          onChange={this.handleApprovalDateChange}
        />
        {this.props.processing.length ? (
          <ListToolbarComponent
            items={toolbarItems}
            update={this.props.fetchProcessingItems.bind(
              null,
              this.props.currentCategory
            )}
            refreshedAt={this.props.retrievedAt}
            getCSVData={this.getCSVData}
          />
        ) : null}
      </Fragment>
    )
  }

  render() {
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        centerFillStatus={true}
        overlayKey={this.props.pageType + '/' + this.props.currentCategory}
        showSpinner={false}
        errorText={`Issue fetching recently approved for ${this.props.currentCategory}`}
        loadingText={`Loading Approvals on ${this.props.currentCategory}`}
      >
        <RenderList />
      </SpecificOverlay>
    )
  }

  renderList = () => {
    const regionText = this.getRegionText()
    const Toolbars = this.renderToolBars
    if (!this.props.processing.length) {
      return (
        <Fragment>
          <Toolbars />
          <StyledElements.Centered>
            <Helmet
              title={`No Recently Processing Cases ${regionText} on ${this.props.currentCategory}`}
            />
            <h3>
              {`No Recently Approved Processing Cases ${regionText} on ${this.props.currentCategory}`}
            </h3>
            <ListToolbarComponent
              update={this.props.fetchProcessingItems.bind(
                null,
                this.props.currentCategory
              )}
              isLoading={this.props.isLoading}
              refreshedAt={this.props.retrievedAt}
            />
            {this.props.regionFilter ? (
              <StyledElements.FakeLink onClick={this.props.clearRegionFilter}>
                Try clearing the region filter...
              </StyledElements.FakeLink>
            ) : null}
          </StyledElements.Centered>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Helmet title="Processing Cases" />
        <Toolbars />
        <Segment>
          <Error.Boundary>
            <CSVLink
              data={this.state.CSVDataToDownload}
              ref={r => (this.csvLink = r)}
              filename={`Processing - ${moment().format('YYYY-MM-DD')}`}
            />
            <ReactTable
              data={this.props.processing}
              noDataText={`No Cases Found`}
              columns={RTTools.columns.buildProcessingRecentlyApproved(
                  this.props.attorney && this.props.attorney.globalAdmin,
                  this.props.attorney && !this.props.attorney.globalAdmin && !this.props.attorney.disableAttorneyPriceVisibility,
                  {
                    history: this.props.history,
                    baseUrl: '/processing/view',
                  }
              )}
              pageSize={this.state.pageSize}
              showPageSizeOptions={false}
              minRows={RTTools.preferences.minRows}
              className={RTTools.preferences.RowClassName}
              sorted={this.props.rtSettings.sort}
              filtered={this.props.rtSettings.filter}
              resized={this.props.rtSettings.size}
              onSortedChange={(newSorted, column, shiftKey) =>
                this.props.setSorted(newSorted)
              }
              onFilteredChange={(filtered, column) =>
                this.props.setFiltered(filtered)
              }
              onResizedChange={(newResized, event) =>
                this.props.setResized(newResized)
              }
              ref={(r)=>this.reactTable=r}
            />
          </Error.Boundary>
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default RecentlyApprovedProcessingListComponent
