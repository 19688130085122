import { connect } from 'react-redux'
import { ticketByIdSelector, ticketEditFieldByIdSelector } from '../../duck/selectors'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditTicketPointsComponent from './EditTicketPointsComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  editTicketField: ticketEditFieldByIdSelector(state, props.objectId, "points")
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, key, value) => dispatch(ticketsActions.setEditValue(objectId, key, value)),
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject))
})

const EditTicketPointsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTicketPointsComponent)

export default EditTicketPointsContainer
