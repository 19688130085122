import React, { Component } from 'react'
import styled from 'styled-components'
import { BaseDateInput } from '../../common'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`

const Title = styled.div`
  font-size: 1.2em;
  padding: 10px;
  font-weight: bold;
`

class HearingDateSelector extends Component {
  render() {
    return (
      <Container>
        <Title>Select Hearing Date:</Title>
        <BaseDateInput
          defaultValue={this.props.defaultValue}
          onChange={this.props.onChange}
        />
      </Container>
    )
  }
}

export default HearingDateSelector
