import { connect } from "react-redux"
import { attorneySelector } from "../../authentication/duck/selectors.js"
import SaasAgreementsComponent from "./SaasAgreementsComponent.jsx"

const mapStateToProps = (state, props) => ({
  attorney: attorneySelector(state),
}) 

// const mapDispatchToProps = dispatch => ({
// })

const SaasAgreementsContainer = connect(
  mapStateToProps,
  // mapDispatchToProps
)(SaasAgreementsComponent)
  
export default SaasAgreementsContainer