import * as services from './services'
import { leadsActions, leadsSelectors } from './index'
import {
  activeLeadsRetrievedAtSelector,
  closedLeadsRetrievedAtSelector,
  unassignedLeadsRetrievedAtSelector,
  closedTicketsRetrievedAtSelector,
} from './selectors'
import {
  ticketsThunks,
  ticketsSelectors,
  ticketsActions,
} from '../../tickets/duck'
import { overlaysActions } from '../../overlays/duck'
import {
  NOTIFICATION_TYPES,
  FRONTEND_TICKET_TYPES,
  SPECIFIC_OVERLAYS_TYPE,
} from '../../../constants'
import { ticketTools } from '../../../utilities'
import { attorneyIsAdminSelector } from '../../authentication/duck/selectors'

const updateLeadsLists = () => (dispatch, getState) => {
  const state = getState()
  const LEADS = [
    FRONTEND_TICKET_TYPES.leads.all,
    FRONTEND_TICKET_TYPES.leads.active,
    FRONTEND_TICKET_TYPES.leads.closed,
    FRONTEND_TICKET_TYPES.leads.closedTickets,
    FRONTEND_TICKET_TYPES.leads.unassigned,
  ]
  LEADS.forEach(leadType => {
    const selector = ticketsSelectors.allTicketsByClassification(leadType)
    const leads = selector(state).map(ticketTools.mapTicketToObjectIds)
    dispatch(leadsActions.setLeads(leadType, leads))
  })
}

const fetchAllLeads = () => (dispatch, getState) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.leads.all

  const state = getState()
  const isAdmin = attorneyIsAdminSelector(state)

  const activeRetrieved = activeLeadsRetrievedAtSelector(state)
  const closedRetrieved = closedLeadsRetrievedAtSelector(state)
  const unassignedRetrieved = unassignedLeadsRetrievedAtSelector(state)
  const closedTicketsRetrieved = closedTicketsRetrievedAtSelector(state)

  const alreadyFetchedAll =
    !!activeRetrieved && !!closedRetrieved && !!unassignedRetrieved

  const shouldFetchActive = !activeRetrieved || alreadyFetchedAll
  const shouldFetchUnassigned = !unassignedRetrieved || alreadyFetchedAll
  const shouldFetchClosed = !closedRetrieved || alreadyFetchedAll
  const shouldFetchClosedTickets = !closedTicketsRetrieved || alreadyFetchedAll

  const fetchAllFuncParams = [
    shouldFetchActive,
    shouldFetchClosed,
    shouldFetchUnassigned,
  ]
  fetchAllFuncParams.push(isAdmin ? shouldFetchClosedTickets : false)

  const fetchPromise = services.getAllLeads(...fetchAllFuncParams)

  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )

  return fetchPromise
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))

      // Set fetched statuses
      if (shouldFetchActive)
        dispatch(
          leadsActions.setLeadsRetrievedAt(FRONTEND_TICKET_TYPES.leads.active)
        )
      if (shouldFetchUnassigned)
        dispatch(
          leadsActions.setLeadsRetrievedAt(
            FRONTEND_TICKET_TYPES.leads.unassigned
          )
        )
      if (shouldFetchClosed)
        dispatch(
          leadsActions.setLeadsRetrievedAt(FRONTEND_TICKET_TYPES.leads.closed)
        )
      if (shouldFetchClosedTickets)
        dispatch(
          leadsActions.setLeadsRetrievedAt(
            FRONTEND_TICKET_TYPES.leads.closedTickets
          )
        )

      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched All Leads`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching all leads', error)
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching All Leads',
          message: error,
        })
      )
    })
}

const fetchActiveLeads = () => dispatch => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.leads.active
  // dispatch(overlaysActions.showLoader())
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getActiveLeads()
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Active Leads`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching active leads', error)
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Leads',
          message: error,
        })
      )
    })
}

const fetchClosedLeads = () => dispatch => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.leads.closed
  // dispatch(overlaysActions.showLoader())
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getClosedLeads()
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Closed Leads`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching closed leads', error)
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Leads',
          message: error,
        })
      )
    })
}

const fetchClosedTickets = () => (dispatch, getState) => {
  const state = getState()
  const hearingDate = leadsSelectors.closedTicketsHearingDateSelector(state)
  // do not load if we do not have a hearing date
  if (!hearingDate) return undefined
  const params = {
    hearingDate: hearingDate.toISOString(),
  }
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.leads.closedTickets
  // dispatch(overlaysActions.showLoader())
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  dispatch(ticketsActions.removeClassificationFromTickets(TICKET_TYPE))

  services
    .getClosedTickets(params)
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Closed Tickets`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching closed tickets', error)
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Leads',
          message: error,
        })
      )
    })
}

const fetchUnassignedLeads = () => dispatch => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.leads.unassigned
  // dispatch(overlaysActions.showLoader())
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getUnassignedLeads()
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Unassigned Leads`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error fetching unassigned leads', error)
      dispatch(leadsActions.setLeadsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Leads',
          message: error,
        })
      )
    })
}

export default {
  fetchActiveLeads,
  fetchAllLeads,
  fetchClosedLeads,
  fetchClosedTickets,
  fetchUnassignedLeads,
  updateLeadsLists,
}
