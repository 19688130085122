import { connect } from 'react-redux'
import {
  ticketByIdSelector,
  ticketEditFieldByIdSelector,
} from '../../duck/selectors'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditPointsReducedInlineComponent from './EditPointsReducedInlineComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  editTicketField: ticketEditFieldByIdSelector(
    state,
    props.objectId,
    'reducedPoints'
  ),
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, key, value) =>
    dispatch(ticketsActions.setEditValue(objectId, key, value)),
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  clearEditValue: (objectId, key) =>
    dispatch(ticketsActions.clearEditValue(objectId, key)),
})

const EditPointsReducedInlineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPointsReducedInlineComponent)

export default EditPointsReducedInlineContainer
