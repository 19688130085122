import { connect } from "react-redux"
import { attorneySelector } from "../../authentication/duck/selectors"
import PlatformAgreementsComponent from "./PlatformAgreementsComponent"

const mapStateToProps = (state, props) => ({
  attorney: attorneySelector(state)
})
  
// const mapDispatchToProps = dispatch => ({

// })

  
const PlatformAgreementsContainer = connect(
  mapStateToProps,
  // mapDispatchToProps
)(PlatformAgreementsComponent)
  
export default PlatformAgreementsContainer