import moment from 'moment'

const dateArrayFilter = (filter, row) => {
  if (!filter || !filter.value) return true
  if (!row[filter.id] || !row[filter.id].length || !row[filter.id][0].callDate) return false
  const time = moment(row[filter.id][0].callDate).startOf('day')
  const start = filter.value.startOf('day')
  return time.isSame(start);
}

export default dateArrayFilter
