import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Segment, Dropdown } from 'semantic-ui-react'
import ReactTable from 'react-table'
import { CSVLink } from 'react-csv'

import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import {
  buildPDF,
  ListToolbarComponent,
  RTTools,
  StyledElements,
} from '../../common'
import SectionDateSelector from '../../common/SectionDateSelector'
import '../../common/react-table/style.css'
import Error from '../../Error'
import { FRONTEND_TICKET_TYPES } from '../../../constants'
import resolvedDataFilterCSVLeads from '../../common/csv/resolvedDataFilterCSV'

class LeadsListComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
      CSVDataToDownload: [],
      currentPage: null,
      totalPages: null,
    }
  }
  componentDidMount() {
    if (!this.props.retrievedAt && !this.props.loading) {
      this.props.fetchLeads()
    }
  }
  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }
  getLeadType = () => {
    let leadType = this.props.location.pathname.split('/')[2]
    if (leadType === 'all') {
      return ''
    }
    return leadType.charAt(0).toUpperCase() + leadType.slice(1)
  }
  downloadPDF = () => {
    buildPDF(this.props.leads, this.getLeadType() + ' Leads')
  }
  getRegionText = (prepend = 'in ') => {
    if (!this.props.regionFilter) return ''
    return `${prepend}${this.props.regionFilter.name}`
  }
  handleRowClick = (state, rowInfo) => {
    return {
      onClick: () => {
        if (rowInfo && rowInfo.original) {
          this.props.history.push(`/leads/view/${rowInfo.original.objectId}`)
        }
      },
    }
  }
  getCSVData = () => {
    const currentRecords = this.reactTable.getResolvedState().data
    const currentRecordsFiltered = resolvedDataFilterCSVLeads(currentRecords)
    this.setState({ CSVDataToDownload: currentRecordsFiltered }, () => {
      this.csvLink.link.click()
    })
  }
  handlePageChange = page => {
    const totalPages = this.reactTable.getResolvedState().pages
    this.setState({
      currentPage: page + 1,
      totalPages: totalPages,
    })
  }

  render() {
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        centerFillStatus={true}
        overlayKey={this.props.pageType}
        // spinnerSize={"massive"}
        showSpinner={false}
        errorText={`Issue fetching`}
      >
        <>
          {this.props.pageType === FRONTEND_TICKET_TYPES.leads.closedTickets ? (
            <>
              <SectionDateSelector
                title="Hearing Date"
                value={this.props.closedTicketsHearingDate}
                onChange={this.props.setHearingDate}
              />
            </>
          ) : null}
          <RenderList />
        </>
      </SpecificOverlay>
    )
  }

  renderList = () => {
    const leadType = this.getLeadType()
    const regionText = this.getRegionText()
    if (!this.props.leads.length) {
      return (
        <StyledElements.Centered>
          <Helmet title={`No ${leadType} Leads ${regionText}`} />
          <h3>{`No ${leadType} Leads ${regionText}`}</h3>
          <ListToolbarComponent
            update={this.props.fetchLeads}
            isLoading={this.props.isLoading}
            refreshedAt={this.props.retrievedAt}
          />
          {this.props.regionFilter ? (
            <StyledElements.FakeLink onClick={this.props.clearRegionFilter}>
              Try clearing the region filter...
            </StyledElements.FakeLink>
          ) : null}
        </StyledElements.Centered>
      )
    }
    const toolbarItems = [
      {
        icon: 'binoculars',
        text: `${this.props.leads.length} ${leadType} Leads ${regionText}`,
      },
    ]
    let buildColumns
    switch (this.props.pageType) {
      case FRONTEND_TICKET_TYPES.leads.closed:
        buildColumns = RTTools.columns.buildLeadsClosed
        break
      case FRONTEND_TICKET_TYPES.leads.active:
        buildColumns = RTTools.columns.buildLeadsActive
        break
      case FRONTEND_TICKET_TYPES.leads.unassigned:
        buildColumns = RTTools.columns.buildLeadsUnassigned
        break
      case FRONTEND_TICKET_TYPES.leads.closedTickets:
        buildColumns = RTTools.columns.buildLeadsClosedTickets
        break
      default:
        buildColumns = RTTools.columns.buildLeads
        break
    }
    return (
      <Fragment>
        <Helmet
          title={`${leadType} Leads ${regionText ? `(${regionText})` : ''}`}
        />
        <ListToolbarComponent
          items={toolbarItems}
          update={this.props.fetchLeads}
          refreshedAt={this.props.retrievedAt}
          download={this.downloadPDF}
          getCSVData={this.getCSVData}
          pageSize={this.state.pageSize}
          totalItems={this.props.leads.length}
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
        />
        <Segment>
          <Error.Boundary>
            <CSVLink
              data={this.state.CSVDataToDownload}
              ref={r => (this.csvLink = r)}
              filename={this.props.pageType}
            />
            <ReactTable
              data={this.props.leads}
              noDataText={`No ${leadType} Leads ${regionText} Found`}
              columns={buildColumns(
                this.props.attorney && this.props.attorney.globalAdmin,
                {
                  history: this.props.history,
                  baseUrl: '/leads/view',
                },
                this.props.leads
              )}
              pageSize={this.state.pageSize}
              showPageSizeOptions={false}
              // defaultSorted={[RTTools.preferences.defaultSorted.created.recent]}
              // pageSizeOptions={RTTools.preferences.pageSizeOptions}
              minRows={RTTools.preferences.minRows}
              className={RTTools.preferences.RowClassName}
              // getTdProps={RTTools.tools.rowOnClick(
              //   '/leads/view/',
              //   this.props.history
              // )}
              sorted={this.props.rtSettings.sort}
              filtered={this.props.rtSettings.filter}
              resized={this.props.rtSettings.size}
              onSortedChange={(newSorted, column, shiftKey) =>
                this.props.setSorted(newSorted)
              }
              onFilteredChange={(filtered, column) =>
                this.props.setFiltered(filtered)
              }
              onResizedChange={(newResized, event) =>
                this.props.setResized(newResized)
              }
              onPageChange={this.handlePageChange}
              ref={r => (this.reactTable = r)}
            />
          </Error.Boundary>
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default LeadsListComponent
