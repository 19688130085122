import React, { useEffect } from 'react'
import { useRollbarPerson } from '@rollbar/react'
import Template from './template'
import Routes from './RoutesContainer'
import OverlaysComponent from './overlays/OverlaysComponent'

const Main = ({ user }) => {
  useRollbarPerson({ id: user.objectId, username: user.name, email: user.email })
  
  useEffect(() => {
    const identifyClarity = async (id) => {
      if (window.clarity) await window.clarity('identify', id)
    }

    identifyClarity(user.objectId)
  }, [])

  return (
    <Template>
      <OverlaysComponent />
      <Routes />
    </Template>
  )
}


export default Main
