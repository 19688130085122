import { connect } from 'react-redux'
import {
  ticketByIdSelector,
  ticketEditFieldByIdSelector,
} from '../../duck/selectors'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditViolationNameComponent from './EditViolationNameComponent'

const EDIT_KEY = 'violationName'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  editTicketField: ticketEditFieldByIdSelector(state, props.objectId, EDIT_KEY),
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, value) =>
    dispatch(ticketsActions.setEditValue(objectId, EDIT_KEY, value)),
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
})

const EditViolationNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditViolationNameComponent)

export default EditViolationNameContainer
