import React, { Component } from 'react'
import { Segment, Grid, Header } from 'semantic-ui-react'
import SearchTypeDropdown from './SearchTypeDropdown'

class SearchHomeComponent extends Component {
  render() {
    return (
      <div>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={4}>
              <SearchTypeDropdown
                active={this.props.searchType}
                onChange={this.props.changeSearch}
              />
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
        </Grid>
        <Segment placeholder>
          <Grid columns={2} stackable textAlign="center">
            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <div>
                  <Header icon size="huge">
                    {this.props.search.name} Search
                  </Header>
                </div>
                <div>
                  <this.props.search.Component />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    )
  }
}

export default SearchHomeComponent
