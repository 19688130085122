import React from 'react'
import moment from 'moment'
import { Button } from 'semantic-ui-react'
import { DATE_FORMATS } from '../../../../constants'
import DatePicker from '../DatePicker'
import { StyledElements } from '../..'
import { dateFilter, dateArrayFilter } from '../../../../utilities'
import { usFormat } from '../../../../utilities/phoneNumbers'
import { FakeLink } from '../../StyledElements'
import * as utils from '../utilities'
import { search } from '../../../../utilities'
import InlineButton from '../../../tickets/DetailSection/InlineButton/InlineButtonContainer'
import LookupQuotesColumn from '../../../lookup/lookupQuoteColumn'

export const LOOKUP_COLUMNS = {
  NAME: {
    Header: 'Name',
    accessor: 'fullName',
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    width: 200,
  },
  PHONE_NUMBER: {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    width: 200,
    filterable: true,
    filterMethod: (filter, row) =>
      search.phoneNumber(row[filter.id], filter.value),
    Cell: ({ value }) => {
      return <div>{usFormat(value)}</div>
    },
  },
  EMAIL: {
    Header: 'Email',
    accessor: 'email',
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    width: 200,
  },
  QUOTES: (contactInfo, history) => ({
    Header: 'Quotes',
    accessor: '',
    filterable: false,
    sortable: false,
    width: 490,
    getProps: () => {
      return {
        style: {
          overflow: 'visible', // So the dropdown's z-index is higher than the table line below
          justifyContent: 'center',
        },
      }
    },
    Cell: ({ value }) => {
      return (
        <LookupQuotesColumn
          result={value}
          contactInfo={contactInfo}
          history={history}
          buttonText="Show quotes"
        />
      )
    },
  }),
  GENDER: {
    Header: 'Gender',
    accessor: 'gender',
    width: 80,
  },
  DATE_OF_BIRTH: {
    Header: 'Date Of Birth',
    accessor: 'dob',
    width: 122,
  },
  LICENSE_ID: {
    Header: 'License ID',
    accessor: 'licenseID',
    width: 122,
  },
  LAST_4: {
    Header: 'Last 4 SSN',
    accessor: 'last4',
    width: 122,
  },
  CALL_HISTORY: {
    Header: 'Last Call Date',
    accessor: 'callHistory',
    width: 122,
    filterMethod: dateArrayFilter,
    filterable: true,
    Filter: ({ filter, onChange }) => {
      return <DatePicker filter={filter} onChange={onChange} />
    },
    Cell: ({ value }) => {
      if (value && value.length) {
        return moment(value[0].callDate).format(DATE_FORMATS.DATE_TIME)
      }
      return <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
    },
  },
  AMOUNT_OF_TICKETS: {
    Header: 'Tickets',
    accessor: 'searchCriteria',
    filterable: true,
    filterMethod: (filter, row) =>
      search.amountOfLookupTickets(row, filter.value),
    sortable: true,
    sortMethod: utils.sortNestedNumbers,
    width: 72,
    Cell: ({ value, original }) => {
      const onClick = () => {
        const url = '/lookup/view/' + original.objectId
        window.open(url, '_blank')
      }
      const totalResults = value.reduce((acc, cur) => {
        acc += cur.results.length
        return acc
      }, 0)
      return <FakeLink onClick={onClick}>{totalResults}</FakeLink>
    },
  },
  CREATED: {
    id: 'CREATED',
    Header: 'Created',
    accessor: 'createdAt',
    width: 150,
    filterMethod: dateFilter,
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        moment(value).format(DATE_FORMATS.DATE_TIME)
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  CLOSEST_HEARING_DATE: {
    Header: 'Closest Hearing',
    accessor: 'closestHearing',
    width: 150,
    sortable: true,
    Cell: ({ value }) =>
      value ? (
        moment(value).format(DATE_FORMATS.DATE_TIME)
      ) : (
        <StyledElements.Italicize>No Upcoming Hearing</StyledElements.Italicize>
      ),
  },
  LAST_UPDATED: {
    Header: 'Last Updated',
    accessor: 'updatedAt',
    filterable: true,
    filterMethod: dateFilter,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    width: 150,
    Cell: ({ value }) =>
      value ? (
        moment(value).format(DATE_FORMATS.DATE_TIME)
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  CLICK_TO_VIEW_TICKETS: (history, baseUrl) => ({
    Header: '',
    accessor: '',
    filterable: false,
    sortable: false,
    width: 80,
    Cell: ({ value }) => {
      const onClick = () => history.push(baseUrl + '/' + value.objectId)
      return (
        <Button animated circular color="blue" size="mini" onClick={onClick}>
          <Button.Content visible>View</Button.Content>
          <Button.Content hidden>Tickets</Button.Content>
        </Button>
      )
    },
  }),

  CLICK_TO_ADD_CALL_DATE: (history, baseUrl) => ({
    Header: 'Add Call Date',
    accessor: '',
    filterable: false,
    sortable: false,
    width: 122,
    Cell: ({ value }) => {
      return (
        <InlineButton
          buttonText="Add Call Date"
          fieldToUpdate="callHistory"
          collectionToUpdate="FetcherSearchContactInfo"
          updateValue={new Date()}
          objectId={value.objectId}
        />
      )
    },
  }),
}

const buildLookupColumns = (
  attorney,
  {
    history, // this.props.history
    baseUrl, // '/hearings/view'
  },
  data
) => {
  const contactInfo = [
    LOOKUP_COLUMNS.CLICK_TO_VIEW_TICKETS(history, baseUrl),
    LOOKUP_COLUMNS.AMOUNT_OF_TICKETS,
    LOOKUP_COLUMNS.NAME,
    LOOKUP_COLUMNS.PHONE_NUMBER,
    LOOKUP_COLUMNS.EMAIL,
    LOOKUP_COLUMNS.CREATED,
    LOOKUP_COLUMNS.CLOSEST_HEARING_DATE,
    LOOKUP_COLUMNS.LAST_UPDATED,
    LOOKUP_COLUMNS.CALL_HISTORY,
    LOOKUP_COLUMNS.CLICK_TO_ADD_CALL_DATE(history, baseUrl),
  ]
  const tableConfig = [
    {
      Header: 'Contact Info from Fetcher',
      columns: contactInfo,
    },
  ]
  return tableConfig
}

export default buildLookupColumns
