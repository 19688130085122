import React from 'react';
import { Input } from 'semantic-ui-react'


const PointsComponent = ({name, points, title, handleChange}) => {
  return (
    <Input name={name} size="mini" onChange={handleChange} label={title} defaultValue={points}/>
  )
}

export default PointsComponent;
