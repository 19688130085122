import React, { Component, Fragment } from 'react'
import { Icon } from 'semantic-ui-react'

import { LoadingComponent, UpdatingComponent } from '../../tickets/DetailSection/ticketOverlays'
import { StyledElements } from '../../common'
import SpecificOverlayContainer from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import ImageDrop from './ImageDropComponent'

class ViewAttorneyImageSectionPopupComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showImage: false
    }
  }

  showImage = () => {
    this.setState({ showImage: true })
  }

  hideImage = () => {
    this.setState({ showImage: false })
  }

  render() {
    const { attorneyImage, showIconOnly } = this.props
    return (
      <StyledElements.DataContainer>
        <SpecificOverlayContainer
          overlayKey={"attorney image"}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <span>
            {attorneyImage ? (
              <Fragment>
                <StyledElements.FakeLink onClick={this.showImage}>
                  <Icon name="picture" /> {!showIconOnly ? <span>Image Uploaded</span> : null}
                </StyledElements.FakeLink>
              </Fragment>
            ) : (
              <Fragment>
                <StyledElements.Italicize>
                  No Image
                </StyledElements.Italicize>
              </Fragment>
            )}
            <ImageDrop objectId={this.props.objectId} handleImageDrop={this.props.handleImageDrop}/>
          </span>
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default ViewAttorneyImageSectionPopupComponent
