import React, { Component } from 'react'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import ItemHeader from '../ItemHeader'
import { StyledElements } from '../../../common'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'
import EditHistoryList from './EditHistoryEventList'

class ViewPortalEditHistoryComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }

  toggleExpansion = () => {
    const { objectId, fetch, portalEditHistory, loading } = this.props
    const { expanded } = this.state
    if (
      !expanded &&
      (!portalEditHistory || !portalEditHistory.length) &&
      !loading
    ) {
      fetch(objectId)
    }
    this.setState({ expanded: !expanded })
  }

  render() {
    const {
      objectId,
      OVERLAY_KEY,
      portalEditHistory,
      fetch,
      retrievedAt,
      attorney,
    } = this.props
    const { expanded } = this.state

    if (attorney && !attorney.globalAdmin) return null
    return (
      <StyledElements.DataContainer isEditing={expanded}>
        <ItemHeader
          title="Portal Edit History"
          isEditable
          toggleState={this.toggleExpansion}
          expandText="show"
          collapseText="hide"
          isEditing={expanded}
        />
        <SpecificOverlayContainer
          overlayKey={objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          <SpecificOverlayContainer
            overlayKey={OVERLAY_KEY}
            ignoreError={true}
            centerFillStatus={true}
            // LoadingComponent={LoadingComponent}
            // UpdatingComponent={UpdatingComponent}
          >
            {expanded ? (
              <EditHistoryList
                editHistory={portalEditHistory}
                fetch={fetch.bind(null, objectId)}
                retrievedAt={retrievedAt}
              />
            ) : (
              <StyledElements.Italicize>
                View history of changes made to this ticket through the attorney
                portal
              </StyledElements.Italicize>
            )}
          </SpecificOverlayContainer>
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default ViewPortalEditHistoryComponent
