// Backend API URL reference
export const API_URL = process.env.REACT_APP_API_URL

export const CASE_WINIT_FEE = 12

/**
 * Supplemental Fetch Required Ticket Fields
 */
export const ADDITIONAL_TICKET_FIELD = {
  evidence: 'evidence',
  portalEditHistory: 'portalEditHistory',
  pricePreview: 'pricePreview',
}

/**
 * Tab Descriptions
 */
export const TAB_DESCRIPTIONS = {
  leads: {
    all:
      'Includes leads across all leads tabs. This list combines leads that are assigned to you and leads without a lawyer that are within your supported courts and counties. The list includes both paid and unpaid cases.',
    closed:
      'Leads where the user has paid and provided enough basic information to complete ticket submission. These tickets need to be manually approved before they can begin being processed.',
    unassigned:
      'Leads where the user began submitting their ticket but did not choose an attorney. The leads shown here are within your supported county and court list.',
    active:
      'Leads that have been assigned to you. These users have not paid yet, but they have selected you as their attorney.',
    'closed-tickets': 'Leads where the ticket has been closed.',
  },
  processing: {
    all: 'All current active cases.',
    recent:
      "Active cases by the date they were approved. Cases approved after 4:00 PM (EST) will appear on the following day's recently approved list",
    suspensions: "Cases that have been switched to 'processing' in the last 2 weeks and have a hearing date in the past."
  },
  hearings: {
    recent: 'Current active cases with a hearing before tomorrow.',
    'needs-date': 'Current active cases without a hearing date.',
  },
}

/**
 * State of requests for overlays
 */
export const SPECIFIC_OVERLAYS_ITEMS = {
  users: 'users',
  attorneys: 'attorneys',
  trafficRegions: 'trafficRegions',
}

/**
 * State of requests for overlays
 */
export const SPECIFIC_OVERLAYS_TYPE = {
  error: 'error',
  success: 'success',
  updating: 'updating',
  loading: 'loading',
}

/**
 * Search Types
 */
export const GENERAL_SEARCH_TYPES = {
  ticket: 'ticket',
  user: 'user',
}

/**
 * State of requests for overlays
 */
export const SPECIFIC_OVERLAYS_CATEGORY = {
  evidence: 'evidence',
  portalEditHistory: 'portalEditHistory',
  pricePreview: 'pricePreview',
  callHistory: 'callHistory',
  magicLink: 'magicLink',
  editTicket: 'editTicket'
}

/**
 * Frontend specific top level parents
 */
export const FRONTEND_PARENT_TYPES = {
  leads: 'leads',
  processing: 'processing',
  hearings: 'hearings',
  calls: 'calls',
  lookupAll: 'lookupAll',
  lookupSingle: 'lookupSingle',
}

/**
 * Frontend specific lookup types
 */
export const FRONTEND_PARENT_LOOKUP_TYPES = {
  lookup: 'lookup',
}

/**
 * Frontend specific categories for individual tickets
 */
export const FRONTEND_TICKET_TYPES = {
  leads: {
    all: `${FRONTEND_PARENT_TYPES.leads}/all`,
    unassigned: `${FRONTEND_PARENT_TYPES.leads}/unassigned`,
    active: `${FRONTEND_PARENT_TYPES.leads}/active`,
    closed: `${FRONTEND_PARENT_TYPES.leads}/closed`,
    closedTickets: `${FRONTEND_PARENT_TYPES.leads}/closed-tickets`,
  },
  processing: {
    all: `${FRONTEND_PARENT_TYPES.processing}/all`,
    recentlyApproved: `${FRONTEND_PARENT_TYPES.processing}/recentlyApproved`,
    suspended: `${FRONTEND_PARENT_TYPES.processing}/suspended`,
    requiresApproval: `${FRONTEND_PARENT_TYPES.processing}/requiresApproval`,
  },
  hearings: {
    recent: `${FRONTEND_PARENT_TYPES.hearings}/recent`,
    needsDate: `${FRONTEND_PARENT_TYPES.hearings}/needsDate`,
  },
  calls: FRONTEND_PARENT_TYPES.calls,
  lookupAll: FRONTEND_PARENT_TYPES.lookupAll,
  lookupSingle: FRONTEND_PARENT_TYPES.lookupSingle,
}

/**
 * Frontend specific categories for individual lookup contact info
 */
export const FRONTEND_CONTACT_INFO_TYPES = {
  lookup: {
    all: `${FRONTEND_PARENT_LOOKUP_TYPES.lookup}/all`,
    hearingDate: `${FRONTEND_PARENT_LOOKUP_TYPES.lookup}/hearingDate`
  },
}

// Moment.js date formats
export const DATE_FORMATS = {
  DATE_TIME: 'M/D/YY  h:mm A',
  DATE: 'M/D/YY',
  FULL_DATE_TIME: 'dddd MMMM Do YYYY, h:mm A',
  FULL_DATE: 'dddd MMMM Do YYYY',
}

// Possible Lead Statuses
export const LEAD_STATUS = {
  notInterested: 'notInterested',
  voicemail: 'voicemail',
  wrongNumber: 'wrongNumber',
  followUpReq: 'followUpReq',
  willSubmit: 'willSubmit',
  called: 'called',
  liveSale: 'liveSale',
  duplicate: 'duplicate',
}

// Possible Status Reasons
export const STATUS_REASON = {
  timeout: 'timeout',
  type: 'type',
  illegible: 'illegible',
  parking: 'parking',
  outstanding: 'outstanding',
  generic: 'generic',
  group: 'group',
  default: 'default',
  paid: 'paid',
  duplicate: 'duplicate',
  unavailable: 'unavailable',
  area: 'area',
  suspended: 'suspended',
  refunded: 'refunded'
}

// Lead Status Text
export const LEAD_STATUS_TEXT = {
  [LEAD_STATUS.notInterested]: 'Not Interested',
  [LEAD_STATUS.voicemail]: 'Left Voicemail',
  [LEAD_STATUS.wrongNumber]: 'Wrong Number',
  [LEAD_STATUS.followUpReq]: 'Follow Up Req',
  [LEAD_STATUS.willSubmit]: 'Will Submit',
  [LEAD_STATUS.called]: 'Called',
  [LEAD_STATUS.liveSale]: 'Live Sale',
  [LEAD_STATUS.duplicate]: 'Duplicate',
}

// Status Reason Text
export const STATUS_REASON_TEXT = {
  [STATUS_REASON.timeout]: 'timeout',
  [STATUS_REASON.type]: 'type',
  [STATUS_REASON.illegible]: 'illegible',
  [STATUS_REASON.parking]: 'parking',
  [STATUS_REASON.outstanding]: 'outstanding',
  [STATUS_REASON.generic]: 'generic',
  [STATUS_REASON.group]: 'group',
  [STATUS_REASON.default]: 'default',
  [STATUS_REASON.paid]: 'paid',
  [STATUS_REASON.duplicate]: 'duplicate',
  [STATUS_REASON.unavailable]: 'unavailable',
  [STATUS_REASON.area]: 'area',
  [STATUS_REASON.suspended]: 'suspended',
  [STATUS_REASON.refunded]: 'refunded'
}

// Possible responses for call requests
export const CALLS_STATUS = {
  call: 'call',
  'call-spokeWithClient': 'call-spokeWithClient',
  'call-voicemail': 'call-voicemail',
  'call-wrongNumber': 'call-wrongNumber',
  'call-other': 'call-other',
}

// Possible responses for call requests
export const CALLS_STATUS_TEXT = {
  [CALLS_STATUS.call]: 'Call Requested',
  [CALLS_STATUS['call-spokeWithClient']]: 'Spoke with Client',
  [CALLS_STATUS['call-voicemail']]: 'Left Voicemail',
  [CALLS_STATUS['call-wrongNumber']]: 'Wrong Number',
  [CALLS_STATUS['call-other']]: 'Other',
}

// Valid Internal Reasons
export const INTERNAL_REASONS = {
  call: 'call',
}

// Valid internal reasons (key current internal reason, value is possible)
export const POSSIBLE_INTERNAL_STATUS = {
  [INTERNAL_REASONS.call]: [
    CALLS_STATUS['call-spokeWithClient'],
    CALLS_STATUS['call-voicemail'],
    CALLS_STATUS['call-wrongNumber'],
    CALLS_STATUS['call-other'],
  ],
}

// Modals that can be popped up from anywhere on the site
// values must be unique.
export const MODAL_TYPES = {
  issue: 'issue',
}

// Notification types. Values must be unique.
export const NOTIFICATION_TYPES = {
  success: 'success',
  error: 'error',
}

// Map of actual processing pipeline statuses
export const PROCESSING_PIPELINE_STATUS = {
  requiresApproval: 'requiresApproval',
  approved: 'approved',
  reject: 'reject',
}

// Key is current processing pipeline status
// value is next available status you can change to
export const PROCESSING_PIPELINE_CHANGES = {
  [PROCESSING_PIPELINE_STATUS.requiresApproval]: [
    PROCESSING_PIPELINE_STATUS.approved,
    PROCESSING_PIPELINE_STATUS.reject,
  ],
}

// Map of human readable processing pipeline statuses
export const PROCESSING_PIPELINE_STATUS_TEXT_MAP = {
  [PROCESSING_PIPELINE_STATUS.requiresApproval]: 'Requires Approval',
  [PROCESSING_PIPELINE_STATUS.approved]: 'Approved',
  [PROCESSING_PIPELINE_STATUS.reject]: 'Rejected',
}

// Map of icons for processing pipeline statuses
export const PROCESSING_PIPELINE_STATUS_ICON_MAP = {
  [PROCESSING_PIPELINE_STATUS.requiresApproval]: 'eye',
  [PROCESSING_PIPELINE_STATUS.approved]: 'checkmark',
  [PROCESSING_PIPELINE_STATUS.reject]: 'close',
}

// Map of descriptions for processing pipeline statuses
export const PROCESSING_PIPELINE_STATUS_DESCRIPTION_MAP = {
  [PROCESSING_PIPELINE_STATUS.requiresApproval]:
    'Ticket needs to be approved manually.',
  [PROCESSING_PIPELINE_STATUS.approved]: 'Ticket is ready to be processed.',
  [PROCESSING_PIPELINE_STATUS.reject]: 'Ticket was rejected.',
}

// map of actual ticket status values
export const TICKET_STATUS = {
  pendingQuote: 'pendingQuote',
  temp: 'temp',
  pendingConf: 'pendingConf',
  processing: 'processing',
  cancelledByUser: 'cancelledByUser',
  closed: 'closed',
  group: 'group',
  reduced: 'reduced',
  reduced_ca_traffic_school: 'reduced_ca_traffic_school',
  guilty: 'guilty',
  dismissed: 'dismissed',
}

// map of possible ticket status changes for (attorneys)
export const POSSIBLE_STATUS_CHANGES = {
  [TICKET_STATUS.temp]: [
    TICKET_STATUS.closed,
    TICKET_STATUS.cancelledByUser,
    TICKET_STATUS.pendingConf,
    TICKET_STATUS.pendingQuote,
  ],
  [TICKET_STATUS.pendingQuote]: [TICKET_STATUS.processing],
  [TICKET_STATUS.pendingConf]: [
    TICKET_STATUS.closed,
    TICKET_STATUS.cancelledByUser,
    TICKET_STATUS.processing,
  ],
  [TICKET_STATUS.processing]: [
    TICKET_STATUS.guilty,
    TICKET_STATUS.dismissed,
    TICKET_STATUS.reduced,
    TICKET_STATUS.reduced_ca_traffic_school
  ],
  [TICKET_STATUS.guilty]: [
    TICKET_STATUS.processing,
    TICKET_STATUS.dismissed,
    TICKET_STATUS.reduced,
    TICKET_STATUS.reduced_ca_traffic_school
  ],
  [TICKET_STATUS.dismissed]: [
    TICKET_STATUS.processing,
    TICKET_STATUS.guilty,
    TICKET_STATUS.reduced,
    TICKET_STATUS.reduced_ca_traffic_school
  ],
  [TICKET_STATUS.reduced]: [
    TICKET_STATUS.processing,
    TICKET_STATUS.guilty,
    TICKET_STATUS.dismissed,
    TICKET_STATUS.reduced_ca_traffic_school
  ],
  [TICKET_STATUS.group]: [TICKET_STATUS.processing, TICKET_STATUS.closed],
}

export const CONFLICTED_STATUS_CHANGES = {
  [TICKET_STATUS.guilty]: [
    TICKET_STATUS.processing,
    TICKET_STATUS.dismissed,
    TICKET_STATUS.reduced,
    TICKET_STATUS.reduced_ca_traffic_school
  ],
  [TICKET_STATUS.dismissed]: [
    TICKET_STATUS.processing,
    TICKET_STATUS.guilty,
    TICKET_STATUS.reduced,
    TICKET_STATUS.reduced_ca_traffic_school
  ],
  [TICKET_STATUS.reduced]: [
    TICKET_STATUS.processing,
    TICKET_STATUS.guilty,
    TICKET_STATUS.dismissed,
    TICKET_STATUS.reduced_ca_traffic_school
  ],
}

// map of human readable ticket statuses
export const TICKET_STATUS_MAP = {
  [TICKET_STATUS.pendingQuote]: 'Pending Quote',
  [TICKET_STATUS.temp]: 'Temporary',
  [TICKET_STATUS.pendingConf]: 'Pending Confirmation',
  [TICKET_STATUS.processing]: 'Processing',
  [TICKET_STATUS.cancelledByUser]: 'Cancelled By User',
  [TICKET_STATUS.closed]: 'Closed',
  [TICKET_STATUS.group]: 'Grouped',
  [TICKET_STATUS.reduced]: 'Reduced',
  [TICKET_STATUS.reduced_ca_traffic_school]: 'Reduced CA Traffic School',
  [TICKET_STATUS.guilty]: 'Guilty',
  [TICKET_STATUS.dismissed]: 'Dismissed',
}

// map of icons for ticket statuses
export const TICKET_STATUS_ICON_MAP = {
  [TICKET_STATUS.pendingQuote]: 'dollar sign',
  [TICKET_STATUS.temp]: 'file',
  [TICKET_STATUS.pendingConf]: 'configure',
  [TICKET_STATUS.processing]: 'law',
  [TICKET_STATUS.cancelledByUser]: 'user cancel',
  [TICKET_STATUS.closed]: 'window close',
  [TICKET_STATUS.group]: 'object group',
  [TICKET_STATUS.reduced]: 'arrow down',
  [TICKET_STATUS.reduced_ca_traffic_school]: 'car',
  [TICKET_STATUS.guilty]: 'balance scale',
  [TICKET_STATUS.dismissed]: 'smile outline',
}

// Map of descriptions for ticket statuses
export const TICKET_STATUS_DESCRIPTION_MAP = {
  [TICKET_STATUS.pendingQuote]:
    'Ticket has not been assigned a quote because they did not complete the flow.',
  [TICKET_STATUS.temp]: 'User began submitting a ticket but did not finish.',
  [TICKET_STATUS.pendingConf]: 'Awaiting manual ticket approval.',
  [TICKET_STATUS.processing]:
    'Ready to be disputed, if processing pipeline is approved.',
  [TICKET_STATUS.cancelledByUser]:
    'The user that submitted this ticket has canceled. ',
  [TICKET_STATUS.closed]: 'Ticket was closed.',
  [TICKET_STATUS.group]: 'Ticket is grouped',
  [TICKET_STATUS.reduced]: 'Ticket penalty was reduced.',
  [TICKET_STATUS.reduced_ca_traffic_school]: 'Ticket penalty was reduced but you have to attend traffic school',
  [TICKET_STATUS.guilty]: 'No reduction was achieved.',
  [TICKET_STATUS.dismissed]: 'The ticket was successfully dismissed.',
}

// United States of 'murica
export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

// URL slugs for traffic submission flow (main webapp) specified by SEO Team:
export const TRAFFIC_STATES_URL_SLUGS = {
  "NY": "ny",
  "GA": "georgia",
  "NJ": "nj",
  "PA": "pa",
  "TX": "tx",
  "WA": "washington",
  "CA": "california",
  "FL": "florida",
}
