import React, { Component, Fragment } from 'react';
import { Header, Icon, Segment, Form, Button, Step, Checkbox } from 'semantic-ui-react'

import SAAS_AGREEMENT from './SAAS_AGREEMENT'
import PLATFORM_PARTICIPATION_AGREEMENT from './PLATFORM_PARTICIPATION_AGREEMENT'

class ConfirmComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agreed: false
    }
  }
  toggleAgreement = () => this.setState({ agreed: !this.state.agreed })
  handleSubmit = () => {
    this.props.complete(this.props.user.objectId, this.props.code)
  }
  render() {
    return (
      <Fragment>
        <Step.Group ordered fluid vertical size='small'>
          <Step completed>
            <Step.Content style={{ textAlign: 'left' }}>
              <Step.Title>Activation Code</Step.Title>
              <Step.Description>{this.props.code}</Step.Description>
            </Step.Content>
          </Step>
          <Step completed>
            <Step.Content style={{ textAlign: 'left' }}>
              <Step.Title>User Account</Step.Title>
              <Step.Description>{this.props.user ? this.props.user.username : ''}</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>

        <Header as="h4" textAlign="center" attached='top' inverted>
          Confirm Account Activation
        </Header>
        <Segment attached>
          <Form size="large">
            <Form.Field>
              <label>Subscription Agreement</label>
              <Form.TextArea
                value={SAAS_AGREEMENT}
                onChange={() => { }}
                rows={10}
              />
            </Form.Field>
            <Form.Field>
              <label>Platform Services Agreement</label>
              <Form.TextArea
                value={PLATFORM_PARTICIPATION_AGREEMENT}
                onChange={() => { }}
                rows={10}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={this.state.agreed}
                onChange={this.toggleAgreement}
                label='I agree to both the Subscription Agreement and the Platform Services Agreement.' />
            </Form.Field>
            <Button
              type="submit"
              disabled={!this.state.agreed || this.props.isLoading}
              fluid
              size="large"
              onClick={this.handleSubmit}
              labelPosition="right"
              icon
            >
              <Icon name="angle right" />
              Complete Activation
            </Button>
          </Form>
        </Segment>
      </Fragment>
    );
  }
}

export default ConfirmComponent;
