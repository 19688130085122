import React from 'react'
import moment from 'moment'
import { Button } from 'semantic-ui-react'

import { search, dateFilter } from '../../../../utilities'
import {
  TICKET_STATUS_MAP,
  DATE_FORMATS,
  LEAD_STATUS_TEXT,
  STATUS_REASON_TEXT
} from '../../../../constants'

import DatePicker from '../DatePicker'
import EditAttorneyNotesInlineContainer from '../../../tickets/DetailSection/EditAttorneyNotesInline/EditAttorneyNotesInlineContainer'
import EditStatusInline from '../../../tickets/DetailSection/EditStatusInline/EditStatusInlineContainer'
import EditStatusReasonInline from '../../../tickets/DetailSection/EditStatusReasonInline/EditStatusReasonInlineContainer'
import EditDateTimeInlineContainer from '../../../tickets/DetailSection/EditDateTimeInline/EditDateTimeInlineContainer'
import EditCallStatusInlineContainer from '../../../tickets/DetailSection/EditCallStatusInline/EditCallStatusInlineContainer'
import EditPointsReducedInlineContainer from '../../../tickets/DetailSection/EditPointsReducedInline/EditPointsReducedInlineContainer'
import EditLeadStatusInlineContainer from '../../../tickets/DetailSection/EditLeadStatusInline/EditLeadStatusInlineContainer'
import ViewTicketImagePopup from '../../../tickets/DetailSection/ViewTicketImagePopup/ViewTicketImageSectionPopupComponent'
import { StyledElements } from '../..'
import * as utils from '../utilities'
import { FakeLink } from '../../StyledElements'
import {Link} from "react-router-dom";

export const TICKET_COLUMNS = {
  CLICK_TO_VIEW: (history, baseUrl) => ({
    Header: '',
    accessor: 'objectId',
    filterable: false,
    sortable: false,
    width: 80,
    Cell: ({ value }) => {
      const onClick = () => history.push(baseUrl + '/' + value)
      return (
        <Button animated circular color="blue" size="mini" onClick={onClick}>
          <Button.Content visible>View</Button.Content>
          <Button.Content hidden>Details</Button.Content>
        </Button>
      )
    },
  }),
  APPROVAL_DATE: {
    Header: 'Approval Date',
    accessor: 'dateApproved.iso',
    width: 150,
    filterable: true,
    filterMethod: dateFilter,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        `${moment(value).format(DATE_FORMATS.DATE_TIME)}`
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  ATTORNEY_PRICE: {
    Header: 'Attorney Price',
    accessor: 'attorneyPrice',
    filterable: false,
    width: 130,
    Cell: ({ value }) =>
      value ? (
        `$${value.toFixed(2)}`
      ) : (
        <StyledElements.Italicize>Not Set</StyledElements.Italicize>
      ),
  },
  CALL_REQUEST_DATE: {
    Header: 'Call Request Date',
    accessor: 'callRequestDate',
    width: 150,
    filterable: true,
    filterMethod: dateFilter,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        `${moment(value).format(DATE_FORMATS.DATE_TIME)}`
      ) : (
        <StyledElements.Italicize>Not Available</StyledElements.Italicize>
      ),
  },
  ISSUE_DATE: {
    Header: 'Issue Date',
    accessor: 'issueDate.iso',
    width: 150,
    filterable: true,
    filterMethod: dateFilter,
    sortable: true,
    sortMethod: utils.sortColumnDateMethod,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        `${moment(value).format(DATE_FORMATS.DATE_TIME)}`
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  DATE_SUBMITTED: {
    Header: 'Submission Date',
    accessor: 'dateSubmitted.iso',
    width: 150,
    filterable: true,
    filterMethod: dateFilter,
    sortable: true,
    sortMethod: utils.sortColumnDateMethod,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        `${moment(value).format(DATE_FORMATS.DATE_TIME)}`
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  HEARING_DATE: {
    Header: 'Hearing Date',
    accessor: 'hearingDate.iso',
    width: 150,
    filterable: true,
    filterMethod: dateFilter,
    sortable: true,
    sortMethod: utils.sortColumnDateMethod,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        `${moment(value).format(DATE_FORMATS.DATE_TIME)}`
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  HEARING_DATE_EDITABLE: {
    Header: 'Hearing Date',
    accessor: 'hearingDate.iso',
    width: 170,
    filterable: true,
    filterMethod: dateFilter,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ original }) => {
      return (
        <EditDateTimeInlineContainer
          dateKey="hearingDate"
          objectId={original.objectId}
          title="Hearing Date"
          minValue={moment().startOf('day')}
        />
      )
    },
  },
  REDUCED_POINTS_EDITABLE: {
    Header: 'Reduced Points',
    accessor: 'reducedPoints',
    width: 170,
    filterable: true,
    Cell: ({ original }) => {
      return <EditPointsReducedInlineContainer objectId={original.objectId} />
    },
  },
  CALL_STATUS_EDITABLE: {
    Header: 'Call Status',
    accessor: 'internalReason',
    width: 170,
    filterable: false,
    Cell: ({ original }) => {
      return <EditCallStatusInlineContainer objectId={original.objectId} />
    },
  },
  LEAD_STATUS_EDITABLE: data => ({
    Header: 'Lead Status',
    accessor: 'leadStatus',
    width: 170,
    filterable: true,
    filterMethod: utils.filterMethodMulti,
    Filter: ({ filter, onChange }) =>
      utils.buildFilterMulti(
        onChange,
        filter,
        data,
        'leadStatus',
        LEAD_STATUS_TEXT
      ),
    Cell: ({ original }) => (
      <EditLeadStatusInlineContainer objectId={original.objectId} />
    ),
  }),
  LEAD_STATUS: {
    Header: 'Lead Status',
    accessor: 'leadStatus',
    width: 170,
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>No Lead Status Set</StyledElements.Italicize>
      ),
  },
  ID_ADMIN: () => ({
    Header: '#',
    accessor: 'ticketID',
    filterable: true,
    width: 115,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value, original }) => {
      const onClick = () => {
        const options = "width=850,height=750"
        const url = "/new-window/" + original.objectId
        window.open(url, '_blank', options)
      }
      return (
        <FakeLink onClick={onClick} >
          {value}
        </FakeLink>
      )
    }
  }),
  ID: {
    Header: '#',
    accessor: 'ticketID',
    filterable: true,
    width: 115,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
  },
  LAST_UPDATED: {
    Header: 'Last Updated',
    accessor: 'updatedAt',
    filterable: true,
    filterMethod: dateFilter,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    width: 150,
    Cell: ({ value }) =>
      value ? (
        moment(value).format(DATE_FORMATS.DATE_TIME)
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  CREATED: {
    id: 'CREATED',
    Header: 'Created',
    accessor: 'createdAt',
    width: 150,
    filterMethod: dateFilter,
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        moment(value).format(DATE_FORMATS.DATE_TIME)
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  SUBMITTED: {
    id: 'SUBMITTED',
    Header: 'Submitted',
    accessor: 'dateSubmitted.iso',
    width: 150,
    filterMethod: dateFilter,
    filterable: true,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        moment(value).format(DATE_FORMATS.DATE_TIME)
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  STATUS: {
    Header: 'Ticket Status',
    accessor: 'status',
    width: 170,
    filterable: true,
    Filter: ({ filter, onChange }) =>
      console.log('filter, onChange', filter, onChange),
    Cell: ({ value }) =>
      value ? (
        TICKET_STATUS_MAP[value]
      ) : (
        <StyledElements.Italicize>No Status Set</StyledElements.Italicize>
      ),
  },
  STATUS_FILTERABLE: data => ({
    Header: 'Ticket Status',
    accessor: 'status',
    width: 170,
    filterable: true,
    filterMethod: utils.filterMethod,
    Filter: ({ filter, onChange }) =>
      utils.buildFilter(onChange, filter, data, 'status', TICKET_STATUS_MAP),
    Cell: ({ value }) =>
      value ? (
        TICKET_STATUS_MAP[value]
      ) : (
        <StyledElements.Italicize>No Status Set</StyledElements.Italicize>
      ),
  }),
  STATUS_EDITABLE: {
    Header: 'Ticket Status',
    accessor: 'status',
    width: 170,
    Cell: ({ value, original }) => (
      <EditStatusInline objectId={original.objectId} />
    ),
  },
  STATUS_REASON_EDITABLE: data => ({
    Header: 'Close Ticket',
    accessor: 'statusReason',
    width: 150,
    filterable: true,
    filterMethod: utils.filterMethodMulti,
    Filter: ({ filter, onChange }) =>
    utils.buildFilterMulti(
      onChange,
      filter,
      data,
      'statusReason',
      STATUS_REASON_TEXT
    ),
    Cell: ({ value, original }) => (
      <EditStatusReasonInline objectId={original.objectId} />
    ),
  }),
  STATUS_UPDATED: {
    Header: 'Lead Status Updated',
    accessor: 'leadStatusUpdated.iso',
    width: 170,
    sortMethod: utils.sortColumnDateMethod,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ value }) =>
      value ? (
        moment(value).format(DATE_FORMATS.DATE_TIME)
      ) : (
        <StyledElements.Italicize>No Date Set</StyledElements.Italicize>
      ),
  },
  STATUS_REASON: {
    Header: 'Status Reason',
    accessor: 'statusReason',
    width: 150
  },
  ATTORNEY_NOTES_EDITABLE: {
    Header: 'Attorney Notes',
    accessor: 'attorneyNotes',
    width: 300,
    Cell: ({ value, original }) => (
      <EditAttorneyNotesInlineContainer objectId={original.objectId} />
    ),
  },
  VIOLATION_NAME: {
    Header: 'Violation Name',
    accessor: 'violationName',
    width: 170,
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>No Violation Set</StyledElements.Italicize>
      ),
  },
  TICKET_COURT_SCREENSHOT: {
    Header: 'Ticket Court Link',
    accessor: 'rawData.url',
    width: 170,
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value }) =>
        value ? (
            <Link to={{ pathname:value}} target="_blank"> Ticket Screenshot </Link>
        ) : (
            <StyledElements.Italicize>No Screenshot Set</StyledElements.Italicize>
        ),
  },
  CHARGES: {
    Header: 'Charges',
    accessor: 'charges',
    width: 210,
    filterable: true,
    filterMethod: (filter, row) => {
      const joinedCharges = row[filter.id].map(charge => charge.Charge).join()
      return search.string(joinedCharges, filter.value)
    },
    Cell: ({ value }) => {
      if (value) {
        return (
          <div>
            {value.map((charge) => <div key={charge.Charge}>{charge.Charge}</div>)}
          </div>
        )
      } else return (
        <StyledElements.Italicize>No Charges Set</StyledElements.Italicize>
      )
    },
  },
  LOCATION_COURT: {
    Header: 'Location Court',
    accessor: 'locationCourt',
    width: 170,
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>
          No Location Court Set
        </StyledElements.Italicize>
      ),
  },
  TRAFFIC_COURT: {
    Header: 'Traffic Court',
    accessor: 'trafficCourt.courtName',
    width: 170,
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>
          No Traffic Court Set
        </StyledElements.Italicize>
      ),
  },
  TRAFFIC_COUNTY: {
    Header: 'Traffic County',
    accessor: 'trafficCounty.name',
    width: 170,
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    Cell: ({ value }) =>
      value || (
        <StyledElements.Italicize>
          No Traffic Court Set
        </StyledElements.Italicize>
      ),
  },
  TICKET_IMAGE: {
    Header: '',
    accessor: '',
    filterable: false,
    sortable: false,
    width: 36,
    Cell: ({value}) => {
      return (
            <ViewTicketImagePopup ticketImage={value.ticketImage} ticketID={value.ticketID} objectId={value.objectId} showIconOnly={true} />
      )
    },
  },
  POINTS: {
    Header: 'Points',
    accessor: 'points',
    width: 65
  },
  CX_NOTES: {
    Header: 'CX Notes',
    accessor: 'cxNotes',
    width: 180,
    Cell: ({ value}) => {
      if (value) {
        return value
      }
      return <StyledElements.Italicize> No CX Note</StyledElements.Italicize>
    },
  },
  FOLLOW_UP_ON_DATE_EDITABLE: {
    Header: 'Follow Up On Date',
    accessor: 'followUpOnDate.iso',
    width: 170,
    filterable: true,
    filterMethod: dateFilter,
    sortMethod: utils.sortColumnDateMethod,
    Filter: ({ filter, onChange }) => (
      <DatePicker filter={filter} onChange={onChange} />
    ),
    Cell: ({ original }) => {
      return (
        <EditDateTimeInlineContainer
          dateKey="followUpOnDate"
          objectId={original.objectId}
          title="Follow Up On Date"
          minValue={moment().startOf('day')}
        />
      )
    },
  },
  ASSIGNED: {
    Header: 'Assigned Rep.',
    accessor: 'assignedTo.name',
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id], filter.value),
    width: 170,
  },
  MODIFIED: {
    Header: 'Modified',
    accessor: 'modified',
    width: 170,
  }
}

export const ticketColumn = (...attorneyColumns) => {
  return {
    Header: 'Ticket Information',
    columns: [...attorneyColumns],
  }
}

