import React from 'react'
import { Modal } from 'semantic-ui-react'
import { sendSlackMessage } from '../duck/services'
import SharedCloseBtn from '../shared/SharedCloseBtn'
import SharedSubmitBtn from '../shared/SharedSubmitBtn'

const DownPaymentException = ({ user, attorney, paymentPlans, downPayment, ticketArray, amount, submitPlan, setShowDPModal }) => {
  let agentName
  let prevPlanStatus

  if (attorney.globalAdmin) agentName = attorney.agentName
  if (paymentPlans && paymentPlans[0]) prevPlanStatus = paymentPlans[0].status.toUpperCase()

  const sendMessageHandler = () => {
    sendSlackMessage({
      agentName,
      userID: user.objectId,
      userName: user.name,
      userEmail: user.email,
      ticketIDs: ticketArray.join(" - "),
      numTicks: ticketArray.length,
      planAmount: amount,
      downPaymentAmount: downPayment,
      prevPlanStatus
    })
  }
  
  return (
    <>
      <Modal.Header>Down Payment is lower than usual</Modal.Header>
      <Modal.Content style={{ fontSize: '16px' }}>
        <p>The current down payment is set to <b style={{ color: 'red' }}>${downPayment}</b> instead of {' '}
          <b style={{ color: 'green' }}>${20 * ticketArray.length}</b> ($20/ticket minimum)</p>
        <p>Are you sure you want to continue?</p>
        <p>A message will be sent to the dev team and supervisors via Slack.</p>
      </Modal.Content>
      <Modal.Actions>
        <SharedCloseBtn name='Not Approved' onClick={() => setShowDPModal(false)} />
        <SharedSubmitBtn type='submit' onClick={(ev) => { sendMessageHandler(); submitPlan(ev) }} className='submit' value='Approved' />    
      </Modal.Actions>
    </>
  )
}

export default DownPaymentException