import React from 'react'
import ReactDOM from 'react-dom'
import { composeWithDevTools } from 'redux-devtools-extension' //eslint-disable-line
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'
import AppContainer from './app/AppContainer'
import * as serviceWorker from './serviceWorker'
import rootReducer from './reducers'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import './index.css'
import './integrations/clarity'
import { rollbarConfig } from './integrations/rollbar'

const essentialReduxMiddleware = [thunk]

let middleware
if (process.env.NODE_ENV !== 'production') {
  middleware = composeWithDevTools(applyMiddleware(...essentialReduxMiddleware))
} else {
  middleware = applyMiddleware(...essentialReduxMiddleware)
}

const store = createStore(rootReducer, middleware)

ReactDOM.render(
  <Provider store={store}>
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <AppContainer />
      </ErrorBoundary>
    </RollbarProvider>
  </Provider>,
  document.getElementById('root')
)
serviceWorker.unregister()
