import React, { Component } from 'react';
// import PropTypes from 'prop-types'
import moment from 'moment'
import { Position } from "@blueprintjs/core";
import { TimePrecision } from "@blueprintjs/datetime";

import { DATE_FORMATS } from '../../../../constants'
import { StyledElements, BaseDateInput } from '../../../common'
import Error from '../../../Error';
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';
import InlineTableEditButtons from '../../../common/react-table/InlineTableEditButtons';
import { ticketTools } from '../../../../utilities'

class EditDateTimeInlineComponent extends Component {
  constructor(props) {
    super(props)
    this.MIN_DATE = moment().subtract(30, "years")
    this.MAX_DATE = moment().add(30, "years")
  }

  getCurrentEditDate = () => {
    const { editTicketField } = this.props
    if (editTicketField) {
      return editTicketField === "unset" ? null : editTicketField
    }
    return null
  }

  handleCancel = (event) => {
    if (event) event.preventDefault()
    const { objectId, dateKey } = this.props
    this.props.clearEditValue(objectId, dateKey)
    return false
  }

  handleChange = (event) => {
    const { objectId, dateKey } = this.props
    if (!event) {
      return this.props.editUpdate(objectId, dateKey, "unset")
    }
    const input = moment(event)
    if (
      input.isValid() &&
      input.isBetween(this.MIN_DATE, this.MAX_DATE)
    ) {
      this.props.editUpdate(objectId, dateKey, input.toDate())
    }
  }

  updateDate = (event) => {
    if (event && event.preventDefault) event.preventDefault()
    const { editTicketField, dateKey, ticket, minValue, maxValue } = this.props
    let existingDate = ticketTools.getDate(dateKey, ticket, this.MIN_DATE, this.MAX_DATE)
    existingDate = existingDate ? new Date(existingDate) : null
    const newDate = editTicketField !== "unset" ? new Date(editTicketField) : editTicketField

    if (editTicketField !== "unset") {
      const input = moment(newDate)
      // Check if it is valid date
      const errorTitle = `Invalid Date for Ticket # ${ticket.ticketID}`
      if (minValue && input.isBefore(minValue)) {
        return this.props.error(
          errorTitle,
          `Date must be after ${minValue.format(DATE_FORMATS.DATE_TIME)}`
        )
      }
      if (maxValue && input.isAfter(maxValue)) {
        return this.props.error(
          errorTitle,
          `Date must be before ${maxValue.format(DATE_FORMATS.DATE_TIME)}`
        )
      }
    }

    if (
      newDate === "unset" ||
      !existingDate ||
      newDate.toISOString() !== existingDate.toISOString()
    ) {
      const updateObject = {}
      updateObject[this.props.dateKey] = newDate
      this.props.update(this.props.objectId, updateObject)
      this.handleCancel()
    }
  }

  renderEdit = () => {
    const { dateKey, ticket, editTicketField } = this.props
    const currentEditDate = this.getCurrentEditDate()
    const currentSetDate = ticketTools.getDate(dateKey, ticket, this.MIN_DATE, this.MAX_DATE)
    const currentValue = editTicketField ? currentEditDate : currentSetDate
    return <StyledElements.FillForm>
      <BaseDateInput
        value={currentValue}
        onChange={this.handleChange}
        timePrecision={TimePrecision.MINUTE}
        popoverProps={{ position: Position.RIGHT }}
        timePickerProps={{
          selectAllOnFocus: true,
          showArrowButtons: false,
          useAmPm: true
        }}
        name="HearingDate"
        closeOnSelection={false}
        minDate={this.MIN_DATE.toDate()}
        maxDate={this.MAX_DATE.toDate()}
        placeholder="MM/DD/YYYY h:mm a"
        formatDate={(date, locale) => date ? moment(date).format(DATE_FORMATS.DATE_TIME) : null}
      />
      {
        editTicketField ||
          (currentEditDate && !currentSetDate) ||
          (currentEditDate && currentEditDate.toISOString() !== currentSetDate.toISOString())
          ?
          <InlineTableEditButtons
            cancelFunc={this.handleCancel}
            updateFunc={this.updateDate}
          />
          : null
      }
    </StyledElements.FillForm>
  }
  render() {
    const { objectId } = this.props
    const RenderEdit = this.renderEdit
    return (
      <Error.Boundary>
        <SpecificOverlayContainer
          overlayKey={objectId}
          LoadingComponent={() => <StyledElements.Italicize>Loading...</StyledElements.Italicize>}
          ignoreError={() => <StyledElements.Italicize>Issue Updating Ticket...</StyledElements.Italicize>}
          UpdatingComponent={() => <StyledElements.Italicize>Updating...</StyledElements.Italicize>}
        >
          <RenderEdit />
        </SpecificOverlayContainer>
      </Error.Boundary>
    );
  }
}

export default EditDateTimeInlineComponent;
