import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container, Grid, Segment, Button, Icon } from 'semantic-ui-react'

const NoticeText = styled.div`
  color: #878787;
  font-size: 13px;
  padding: 3px 0 3px 0;
  text-align: center;
`

class Footer extends Component {
  render() {
    return (
      <Segment inverted vertical>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  size="tiny"
                  color="orange"
                  onClick={this.props.showIssueModal}
                >
                  <Icon name="bug" />
                  Report an Issue
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <NoticeText>
                  © {new Date().getFullYear()} WinIt LLC, 1239 Broadway,
                  New York, NY 10001
                </NoticeText>
                <NoticeText>
                  Your access to our website and mobile application is subject
                  to our Terms of Use and Privacy Policy.
                </NoticeText>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  }
}

export default Footer
