const dateUSFormat = (date) => {
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear().toString()
  return `${month}/${day}/${year}`
}

const resolvedLookupDataFilterCSV = (data) => {
  return data.map(row => {
    const newRow = {}
    for (let key in row) {
      switch(key) {
        case 'ticketID':
        case 'violationName':
        case 'points':
        case 'fullName':
        case 'email':
        case 'phoneNumber':
        case 'category':
        case 'agent':
        case 'status':
          newRow[key] = row[key]
          break
        case 'issueDate':
          let issueDate = new Date(row[key].iso)
          newRow.issueDate = dateUSFormat(issueDate)
          break
        case 'createdAt':
          let createdAt = new Date(row[key])
          newRow.createdAt = dateUSFormat(createdAt)
          break
        case 'hearingDate':
          if (row[key]) {
            let hearingDate = new Date(row[key].iso)
            newRow.hearingDate = dateUSFormat(hearingDate)
          } else {
            newRow[key] = row[key]
          }
          break
        case 'trafficCourt':
          newRow[key] = row[key].courtName
          break
        case 'searchCriteria':
          var contactInfo = row[key][0].contactInfo
          if (contactInfo && contactInfo.length > 0) {
            newRow['callHistory'] = contactInfo[0].callHistory
            newRow['objectId'] = contactInfo[0].objectId
            newRow['email'] = contactInfo[0].email
          }
          newRow.licenseID = row[key][0].licenseID
          newRow.firstName = row[key][0].firstName
          newRow.middleName = row[key][0].middleName
          newRow.lastName = row[key][0].lastName
          newRow.gender = row[key][0].gender
          newRow.dob = row[key][0].dob
          newRow.last4 = row[key][0].last4
          break
        default:
          break
      }
    }
    return newRow
  })
}

export default resolvedLookupDataFilterCSV
