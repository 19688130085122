import types from './types.js'

const updateUserOnTickets = user => ({
  type: types.UPDATE_USER_ON_TICKETS,
  user,
})

const setEditValue = (objectId, key, value) => ({
  type: types.SET_EDIT_TICKET_VALUE,
  objectId,
  key,
  value,
})

const clearEditValue = (objectId, key) => ({
  type: types.CLEAR_EDIT_TICKET_VALUE,
  objectId,
  key,
})

const addTicket = value => ({
  type: types.ADD_TICKET,
  value,
})

const addTicketEvidence = (ticketObjectId, evidence) => ({
  type: types.ADD_TICKET_EVIDENCE,
  ticketObjectId,
  evidence,
})

const addNewTicketEvidence = (ticketObjectId, evidence) => ({
  type: types.ADD_NEW_TICKET_EVIDENCE,
  ticketObjectId,
  evidence,
})

const addTicketPortalEditHistory = (ticketObjectId, editHistory) => ({
  type: types.ADD_TICKET_PORTAL_EDIT_HISTORY,
  ticketObjectId,
  editHistory,
})

const addTicketPricePreview = (ticketObjectId, priceArr) => ({
  type: types.ADD_TICKET_PRICE_PREVIEW,
  ticketObjectId,
  priceArr,
})

const addManyTickets = value => ({
  type: types.ADD_MANY_TICKETS,
  value,
})

const addUsersPaymentMethod = (userObjectId, paymentMethod) => ({
  type: types.ADD_USERS_PAYMENT_METHOD,
  userObjectId,
  paymentMethod,
})

const setTicketImage = (ticketId, image, ticketType) => ({
  type: types.SET_TICKET_IMAGE,
  ticketId,
  image,
  ticketType
})

const removeClassificationFromTickets = classification => ({
  type: types.REMOVE_CLASSIFICATION_FROM_TICKETS,
  classification,
})

export default {
  setEditValue,
  clearEditValue,
  addTicket,
  addTicketEvidence,
  addNewTicketEvidence,
  addTicketPortalEditHistory,
  addTicketPricePreview,
  addManyTickets,
  addUsersPaymentMethod,
  updateUserOnTickets,
  setTicketImage,
  removeClassificationFromTickets,
}
