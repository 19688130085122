import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import NotFoundPage from "../404NotFound"
import RecentHearingsList from "./HearingsList/RecentHearingsListContainer"
import NoDateHearingsList from './HearingsList/NoHearingDateListContainer'
import ViewHearing from './ViewHearing/ViewHearingContainer'

class LeadsRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/hearings/recent/" component={RecentHearingsList} />
        <Route exact path="/hearings/needs-date/" component={NoDateHearingsList} />
        <Route path="/hearings/view/:id" component={ViewHearing} />
        {/* <Route path="/edit/:id" component={ViewLead} /> */}
        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    )
  }
}

export default LeadsRoutes
