import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table'
import { withRouter } from 'react-router-dom'
// import { lowerCase } from 'lodash'
import { Segment, Header, Dropdown } from 'semantic-ui-react'
import { RTTools, StyledElements } from '../common'
import '../common/react-table/style.css'

import queryString from 'query-string'

import SEARCH_DESCRIBER from './SearchTypes'

class SearchResultsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
      searchType: '',
    }
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search)
    // get search type details
    const searchType = SEARCH_DESCRIBER[parsed.searchType]
    this.setState({searchType: searchType.searchType})
  }
  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }

  render() {
    const resultUrl = 
      this.state.searchType === 'ticketId' ? '/search/view/' : '/users/view/'
    return (
      <Fragment>
        <Header>Search Results</Header>
        {/* <DescribeTab>{this.props.searchObject}</DescribeTab> */}
        <Segment>
          <ReactTable
            data={this.props.results}
            noDataText={`No Cases Found`}
            columns={this.state.searchType ? RTTools.columns.buildSearchTable(
              this.props.attorney && this.props.attorney.globalAdmin,
              this.props.results,
              this.state.searchType
            ) : []}
            pageSize={this.state.pageSize}
            showPageSizeOptions={false}
            minRows={RTTools.preferences.minRows}
            className={RTTools.preferences.clickableRowClassName}
            sorted={this.props.rtSettings.sort}
            filtered={this.props.rtSettings.filter}
            resized={this.props.rtSettings.size}
            onSortedChange={(newSorted, column, shiftKey) =>
              this.props.setSorted(newSorted)
            }
            onFilteredChange={(filtered, column) =>
              this.props.setFiltered(filtered)
            }
            onResizedChange={(newResized, event) =>
              this.props.setResized(newResized)
            }
            getTdProps={RTTools.tools.rowOnClick(
              resultUrl,
              this.props.history,
            )}
          />
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default withRouter(SearchResultsTable)
