import React, { Component, Fragment } from 'react'
import { Modal, Checkbox, Button } from 'semantic-ui-react'
import { StyledElements } from '../common'

class LeadStatusModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }
  setModal = showModal => this.setState({ ...this.state, showModal })
  showModal = () => this.setModal(true)
  hideModal = () => this.setModal(false)
  toggleModal = () => this.setModal(!this.state.showModal)

  render() {
    const { value, options } = this.props
    let statusString
    if (!value || !value.length) statusString = 'None'
    if (value && value.length === options.length) statusString = 'All'
    if (value && value.length < options.length && value.length > 0)
      statusString = value.length
    const RenderModal = this.renderModal
    return (
      <Fragment>
        <Button
          size="mini"
          content={`Filter (${statusString})`}
          onClick={this.showModal}
          icon="filter"
        />
        <RenderModal />
      </Fragment>
    )
  }

  handleToggle = (event, data) => {
    const currentValues = [...this.props.value]
    const index = currentValues.indexOf(data.value)
    const shouldRemove = index > -1
    if (shouldRemove) {
      currentValues.splice(index, 1)
    } else {
      currentValues.push(data.value)
    }
    this.props.handleChange(currentValues)
  }

  selectAll = () => {
    const { options } = this.props
    this.props.handleChange(options.map(item => item.value))
  }

  deselectAll = () => {
    this.props.handleChange([])
  }

  renderModal = () => {
    const { showModal } = this.state
    const { options, value } = this.props
    return (
      <Modal
        size="mini"
        open={showModal}
        onClose={this.hideModal}
        closeIcon
        closeOnEscape
      >
        <Modal.Header>Lead Status</Modal.Header>
        <Modal.Content>
          <div style={{ paddingBottom: 10, color: 'gray' }}>
            Select which lead statuses to show.
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {options.map(item => (
              <Checkbox
                style={{ padding: '3px 0 3px 0' }}
                label={item.text}
                value={item.value}
                key={item.key}
                checked={value.includes(item.value)}
                onChange={this.handleToggle}
              />
            ))}
          </div>
          {options && options.length > 1 ? (
            <div style={{ paddingTop: 10 }}>
              <StyledElements.FakeLink onClick={this.selectAll}>
                Select All
              </StyledElements.FakeLink>{' '}
              <StyledElements.Divider />{' '}
              <StyledElements.FakeLink onClick={this.deselectAll}>
                Deselect All
              </StyledElements.FakeLink>
            </div>
          ) : null}
        </Modal.Content>
        {/* <Modal.Actions>
          <Button
            positive
            // icon="checkmark"
            labelPosition="right"
            content="Done"
            onClick={this.hideModal}
          />
        </Modal.Actions> */}
      </Modal>
    )
  }
}

export default LeadStatusModal
