import React, { Component } from 'react';
import { TextArea, Form } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';
import { LoadingComponent, UpdatingLargeComponent } from '../ticketOverlays';

class EditUserCommentComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      comment: props.ticket.comment
    }
    this.notesInput = React.createRef();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.notesInput.focus()
    }
  }
  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = (event) => {
    this.setState({ comment: event.target.value })
  }
  updateSupportNotes = () => {
    this.setState({ isEditing: false })
    if (this.state.comment !== this.props.ticket.comment) {
      this.props.update(this.props.objectId, { comment: this.state.comment })
    }
  }
  renderEdit = () => {
    return <StyledElements.EditContainer>
      <Form>
        <TextArea
          ref={(input) => { this.notesInput = input; }}
          autoHeight
          onChange={this.handleChange}
          value={this.state.comment}
          placeholder="Enter your notes for this case"
          rows={5}
        />
      </Form>
      <Forms.InlineEditButtons
        buttons={[
          {
            onClick: this.updateSupportNotes,
            color: "green",
            labelPosition: "right",
            icon: "save",
            text: "Save Notes",
            type: "submit"
          }
        ]}
      />
    </StyledElements.EditContainer>
  }
  render() {
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="Client Comments"
          isEditable={false}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingLargeComponent}
        >
          {this.state.isEditing ?
            <RenderEdit />
            : this.props.ticket.comment ?
              this.props.ticket.comment
              :
              <StyledElements.Italicize>
                User did supply any comments.
            </StyledElements.Italicize>
          }
        </SpecificOverlayContainer>

      </StyledElements.DataContainer>
    );
  }
}

export default EditUserCommentComponent;
