import { axios, stringTools } from '../../../utilities'

/**
 * Get all leads
 * @param {boolean} active Should we fetch active leads
 * @param {boolean} closed Should we fetch closed leads
 * @param {boolean} unassigned Should we fetch unassigned leads
 * @param {boolean} closedTickets Should we fetch closed ticket leads
 */
export const getAllLeads = (active, closed, unassigned, closedTickets) => {
  const promiseArr = []
  if (active) promiseArr.push(getActiveLeads())
  if (closed) promiseArr.push(getClosedLeads())
  if (unassigned) promiseArr.push(getUnassignedLeads())
  if (closedTickets) promiseArr.push(getClosedTickets())
  return Promise.all(promiseArr).then(data => {
    return data.reduce((prev, curr) => {
      return prev.concat(curr)
    })
  })
}

export const getActiveLeads = () => {
  return axios.secure.delayed.get('/ticket-data/leads').then(res => res.data)
}
export const getClosedLeads = () => {
  return axios.secure.delayed.get('/ticket-data/pending').then(res => res.data)
}
export const getClosedTickets = params => {
  const URLParams = stringTools.objectToURLParams(params)
  return axios.secure.delayed
    .get(`/ticket-data/closed${URLParams}`)
    .then(res => res.data)
}

export const getUnassignedLeads = () => {
  return axios.secure.delayed
    .get('/ticket-data/unassigned-leads')
    .then(res => res.data)
}
