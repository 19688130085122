import React, { Component, Fragment } from 'react'
import { Input, Grid, Form } from 'semantic-ui-react'

import {
  CASE_WINIT_FEE,
  TICKET_STATUS,
  // FRONTEND_PARENT_TYPES,
} from '../../../../constants'
import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditPriceComponent extends Component {
  constructor(props) {
    super(props)
    this.DEFAULT_START_PRICE = CASE_WINIT_FEE
    this.state = {
      isEditing: false,
      price: props.ticket.winItPrice || this.DEFAULT_START_PRICE,
      platformFee: CASE_WINIT_FEE,
    }
    this.priceInput = React.createRef()
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.priceInput.inputRef.focus()
    }
  }
  toggleStatus = () => {
    if (this.state.isEditing) {
      this.setState({
        price: this.props.ticket.winItPrice || this.DEFAULT_START_PRICE,
      })
    }
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = event => {
    // allow to set empty value
    if (event.target.value === '') {
      return this.setState({ price: '' })
    }
    // allow entering number followed by a decimal point
    if (
      event.target.value.indexOf('.') === event.target.value.length - 1 &&
      event.target.value.slice(-1) === '.' &&
      Number(event.target.value.slice(0, -1))
    ) {
      return this.setState({ price: event.target.value })
    }
    // normal conditions
    const price = Number(event.target.value)
    if (price) {
      this.setState({ price })
    }
  }

  updatePrice = () => {
    if (this.state.price <= CASE_WINIT_FEE) {
      return this.props.error(
        `Price must be greater than $${CASE_WINIT_FEE.toFixed(2)}`
      )
    }
    this.setState({ isEditing: false })
    if (this.state.price !== this.props.ticket.winItPrice) {
      this.props.update(this.props.objectId, { winItPrice: this.state.price })
    }
  }

  render() {
    const { ticket, attorney } = this.props
    let showPrice = false
    const RenderEdit = this.renderEdit
    if (
        (attorney && attorney.globalAdmin || !attorney.disableAttorneyPriceVisibility) &&
        ((attorney && attorney.globalAdmin) ||
            (ticket &&
                ticket.attorney &&
                ticket.status === TICKET_STATUS.pendingConf) ||
            (ticket && !ticket.winItPrice) ||
            (ticket && ticket.attorneySetPrice) ||
            (ticket && ticket.status === TICKET_STATUS.processing)
        )) {
      showPrice = true
    }
    if (!showPrice) return null
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="Price"
          isEditable={true}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {this.state.isEditing ? (
            <RenderEdit />
          ) : this.props.ticket.winItPrice ? (
            <span>
              {`$${this.props.ticket.winItPrice.toFixed(2)}`}
              {this.props.ticket.attorneySetPrice ? (
                <Fragment>
                  <StyledElements.Divider />
                  <StyledElements.Italicize>
                    {this.props.attorney.globalAdmin
                      ? 'Attorney set price'
                      : 'You manually set this price'}
                  </StyledElements.Italicize>
                </Fragment>
              ) : null}
            </span>
          ) : (
            <StyledElements.Italicize>No Price Set</StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }

  renderEdit = () => {
    return (
      <StyledElements.EditContainer>
        <Form>
          <Grid
            style={{ paddingBottom: '5px' }}
            columns={2}
            verticalAlign="middle"
            padded={false}
          >
            <Grid.Row stretched style={{ padding: '3px' }}>
              <Grid.Column>Price to fight</Grid.Column>
              <Grid.Column>
                ${(this.state.price - this.state.platformFee).toFixed(2)}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched style={{ padding: '3px' }}>
              <Grid.Column>WinIt Platform Fee</Grid.Column>
              <Grid.Column>${this.state.platformFee.toFixed(2)}</Grid.Column>
            </Grid.Row>
            <Grid.Row stretched style={{ padding: '3px' }}>
              <Grid.Column>
                <b>Total Charge Amount</b>
              </Grid.Column>
              <Grid.Column>
                <Input
                  ref={input => {
                    this.priceInput = input
                  }}
                  onChange={this.handleChange}
                  value={this.state.price}
                  placeholder="Price to charge"
                  labelPosition="left"
                  label="$"
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Forms.InlineEditButtons
            buttons={[
              {
                disabled: this.state.price - this.state.platformFee <= 0,
                color: 'green',
                text: 'Update Price',
                icon: 'save',
                labelPosition: 'right',
                onClick: this.updatePrice,
                type: 'submit',
              },
            ]}
          />
        </Form>
      </StyledElements.EditContainer>
    )
  }
}

export default EditPriceComponent
