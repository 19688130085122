import { connect } from 'react-redux'
import { userSelector } from './authentication/duck/selectors'
import MainComponent from './MainComponent'

const mapStateToProps = state => ({
  user: userSelector(state)
})

const MainContainer = connect(mapStateToProps)(MainComponent)

export default MainContainer
