const PNF = require('google-libphonenumber').PhoneNumberFormat;
const phoneNumberUtil = require('google-libphonenumber').PhoneNumberUtil

export const usFormat = (phoneNumber) => {
  if (!phoneNumber) return ""
  let result
  try {
    const phoneUtil = phoneNumberUtil.getInstance();
    const number = phoneUtil.parse(phoneNumber, 'US');
    result = phoneUtil.format(number, PNF.NATIONAL)
  } catch (error) {
    console.log('error parsing phone number', error)
  } finally {
    return result || phoneNumber
  }
}
