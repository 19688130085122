import { SPECIFIC_OVERLAYS_TYPE } from '../../../constants'

export const isLoadingSelector = state => state.overlays.isLoading

export const specificOverlaySelector = (state, props) =>
  state.overlays.specificOverlays[props.overlayKey]
export const specificOverlayStatusSelector = (state, props) => {
  const target = specificOverlaySelector(state, props)
  return target ? target.status : undefined
}

export const specificOverlayLoadingStatusSelector = (state, overlayKey) => {
  const target = state.overlays.specificOverlays[overlayKey]
  return (
    target && target.status && target.status === SPECIFIC_OVERLAYS_TYPE.loading
  )
}

export const modalSelector = state => state.overlays.modal
export const notificationsSelector = state => state.overlays.notifications
