import { connect } from 'react-redux'

import { attorneyIsAdminSelector, attorneySelector } from '../../app/authentication/duck/selectors'
import AdminComponent from './AdminComponent'


const mapStateToProps = state => ({
  isAdmin: attorneyIsAdminSelector(state),
  attorney: attorneySelector(state)
})

const AdminContainer = connect(
  mapStateToProps
)(AdminComponent)

export default AdminContainer
