import React, { Component, Fragment } from 'react'
import {
  Button,
  Form,
  Input,
  Header,
  Segment,
  Icon
} from 'semantic-ui-react'

class EnterCodeComponent extends Component {
  handleSubmit = () => this.props.validateCode(this.props.code)
  handleChange = (event) => {
    const { value } = event.target
    if (value) {
      this.props.setCode(value)
    }
  }

  render() {
    return (
      <Fragment>
        <Header as="h4" textAlign="center" attached='top' inverted>
          Activation Code
        </Header>
        <Segment attached>
          <Form size="large">
            <Form.Field>
              <Input
                fluid
                name="code"
                onChange={this.handleChange}
                icon="barcode"
                iconPosition="left"
                placeholder="Activation Code"
                value={this.props.code}
              />
            </Form.Field>
            <Button
              disabled={this.props.isLoading}
              fluid
              size="large"
              onClick={this.handleSubmit}
              labelPosition="right"
              icon
            >
              <Icon name="angle right" />
              Proceed
            </Button>
          </Form>
        </Segment>
      </Fragment>
    )
  }
}

export default EnterCodeComponent
