import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { Segment, Dropdown } from 'semantic-ui-react'
import ReactTable from 'react-table'

import SpecificOverlay from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import {
  ListToolbarComponent,
  RTTools,
  StyledElements,
} from '../../common'
import '../../common/react-table/style.css'
import Error from '../../Error'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import resolvedDataForProcessing from '../../common/csv/resolvedDataForProcessing'

class RequiresApprovalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: RTTools.preferences.getDefaultLength(),
      CSVDataToDownload: []
    }
  }
  componentDidMount() {
    if (!this.props.retrievedAt && !this.props.loading) {
      this.props.fetchRequiresApprovalItems()
    }
  }
  handlePageSizeChange = (_, event) => {
    RTTools.preferences.setDefaultLength(event.value)
    this.setState({ pageSize: event.value })
  }
  getCSVData = () => {
    const currentRecords = this.reactTable.getResolvedState().data
    const currentRecordsFiltered = resolvedDataForProcessing(currentRecords)
    this.setState({ CSVDataToDownload: currentRecordsFiltered }, () => {
      this.csvLink.link.click()
    })
  }
  getRegionText = (prepend = 'in ') => {
    if (!this.props.regionFilter) return ''
    return `${prepend}${this.props.regionFilter.name}`
  }
  renderToolBars = () => {
    const toolbarItems = [
      {
        icon: 'law',
        text: `${this.props.processing.length} Cases ${this.getRegionText()}`,
      },
    ]
    return (
      <Fragment>
        <ListToolbarComponent
          items={toolbarItems}
          update={this.props.fetchRequiresApprovalItems}
          refreshedAt={this.props.retrievedAt}
          getCSVData={this.getCSVData}
        />
      </Fragment>
    )
  }

  render() {
    const RenderList = this.renderList
    return (
      <SpecificOverlay
        centerFillStatus={true}
        overlayKey={this.props.pageType}
        showSpinner={false}
        errorText={`Issue fetching`}
      >
      <RenderList />
      </SpecificOverlay>
    )
  }

  renderList = () => {
    const regionText = this.getRegionText()
    if (!this.props.processing.length) {
      const RenderToolBars = this.renderToolBars
      return (
        <Fragment>
          <RenderToolBars />
          <StyledElements.Centered>
            <Helmet title={`No Processing Cases ${regionText}`} />
            <h3>{`No Processing Cases ${regionText}`}</h3>
            <ListToolbarComponent
              update={this.props.fetchRequiresApprovalItems}
              isLoading={this.props.isLoading}
              refreshedAt={this.props.retrievedAt}
            />
            {this.props.regionFilter ? (
              <StyledElements.FakeLink onClick={this.props.clearRegionFilter}>
                Try clearing the region filter...
              </StyledElements.FakeLink>
            ) : null}
          </StyledElements.Centered>
        </Fragment>
      )
    }
    const RenderToolBars = this.renderToolBars
    return (
      <Fragment>
        <Helmet title="Processing Cases" />
        <RenderToolBars />
        <Segment>
          <Error.Boundary>
            <CSVLink
              data={this.state.CSVDataToDownload}
              ref={r => (this.csvLink = r)}
              filename={`Processing - ${moment().format('YYYY-MM-DD')}`}
            />
            <ReactTable
              data={this.props.processing}
              noDataText={`No Cases Found`}
              columns={RTTools.columns.buildProcessing(
                this.props.attorney && this.props.attorney.globalAdmin,
                  this.props.attorney && !this.props.attorney.globalAdmin && !this.props.attorney.disableAttorneyPriceVisibility,
              )}
              pageSize={this.state.pageSize}
              showPageSizeOptions={false}
              minRows={RTTools.preferences.minRows}
              className={RTTools.preferences.clickableRowClassName}
              getTdProps={RTTools.tools.rowOnClick(
                '/processing/view/',
                this.props.history
              )}
              sorted={this.props.rtSettings.sort}
              filtered={this.props.rtSettings.filter}
              resized={this.props.rtSettings.size}
              onSortedChange={(newSorted, column, shiftKey) =>
                this.props.setSorted(newSorted)
              }
              onFilteredChange={(filtered, column) =>
                this.props.setFiltered(filtered)
              }
              onResizedChange={(newResized, event) =>
                this.props.setResized(newResized)
              }
              ref={(r)=>this.reactTable=r}
            />
          </Error.Boundary>
          <StyledElements.Centered>
            <Dropdown
              value={this.state.pageSize}
              options={RTTools.preferences.options}
              selection
              onChange={this.handlePageSizeChange}
            />
          </StyledElements.Centered>
        </Segment>
      </Fragment>
    )
  }
}

export default RequiresApprovalComponent
