import * as services from './services'
import { callsActions } from './index'
import { ticketsThunks, ticketsSelectors } from '../../tickets/duck'
import { overlaysActions } from '../../overlays/duck'
import {
  NOTIFICATION_TYPES,
  FRONTEND_TICKET_TYPES,
  SPECIFIC_OVERLAYS_TYPE,
  SPECIFIC_OVERLAYS_CATEGORY,
} from '../../../constants'
import { ticketTools, specificOverlayTools } from '../../../utilities'

const updateCallsLists = () => (dispatch, getState) => {
  const state = getState()
  const CALLS = [FRONTEND_TICKET_TYPES.calls]
  CALLS.forEach(callType => {
    const selector = ticketsSelectors.allTicketsByClassification(callType)
    const ticketIds = selector(state).map(ticketTools.mapTicketToObjectIds)
    dispatch(callsActions.setCalls(callType, ticketIds))
  })
}

const fetchCallsItems = () => dispatch => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.calls
  // dispatch(overlaysActions.showLoader())
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      TICKET_TYPE,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  services
    .getCallsItems()
    .then(results => {
      dispatch(ticketsThunks.addAndClassifyManyTickets(results))
      dispatch(callsActions.setCallsRetrievedAt(TICKET_TYPE))
      // Status and Notifications
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Fetched Call Cases`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      dispatch(callsActions.setCallsRetrievedAt(TICKET_TYPE))
      // Status & Notifications
      console.error('error fetching calls items', error)
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          TICKET_TYPE,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      // dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Fetching Call Cases',
          message: error,
        })
      )
    })
}

const fetchTicketCallHistory = objectId => dispatch => {
  const KEY = specificOverlayTools.buildKey(
    objectId,
    SPECIFIC_OVERLAYS_CATEGORY.callHistory
  )
  dispatch(
    overlaysActions.setSpecificOverlayStatus(
      KEY,
      SPECIFIC_OVERLAYS_TYPE.loading
    )
  )
  return services
    .getRequestHistory(objectId)
    .then(result => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.success
        )
      )
      dispatch(callsActions.setCallRequestHistory(objectId, result))
    })
    .catch(error => {
      dispatch(
        overlaysActions.setSpecificOverlayStatus(
          KEY,
          SPECIFIC_OVERLAYS_TYPE.error
        )
      )
      console.error('error fetching call request history for a ticket', error)
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: `Issue Fetching Call Request History for Ticket`,
          message: error,
        })
      )
    })
}

export default {
  fetchCallsItems,
  updateCallsLists,
  fetchTicketCallHistory,
}
