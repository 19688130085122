import { connect } from 'react-redux'
import { attorneySelector } from '../../authentication/duck/selectors'
import ViewTicketComponent from './ViewTicketComponent'

const mapStateToProps = state => ({
  attorney: attorneySelector(state)
})

const ViewLeadContainer = connect(mapStateToProps)(ViewTicketComponent)

export default ViewLeadContainer
