const SET_FILTERED = 'SET_FILTERED'
const SET_RESIZED = 'SET_RESIZED'
const SET_SORTED = 'SET_SORTED'
const UNSET_TABLE_PARAMETER = 'UNSET_TABLE_PARAMETER'

export default {
  SET_FILTERED,
  SET_RESIZED,
  SET_SORTED,
  UNSET_TABLE_PARAMETER,
}
