export const SET_USER_PAYMENT_CLIENT_TOKEN = 'SET_USER_PAYMENT_CLIENT_TOKEN'
export const CLEAR_USER_PAYMENT_CLIENT_TOKEN = 'CLEAR_USER_PAYMENT_CLIENT_TOKEN'

export const SET_USER_PAYMENT_NONCE = 'SET_USER_PAYMENT_NONCE'
export const CLEAR_USER_PAYMENT_NONCE = 'CLEAR_USER_PAYMENT_NONCE'

export const SET_USER_PAYMENT_READY = 'SET_USER_PAYMENT_READY'
export const CLEAR_USER_PAYMENT_READY = 'CLEAR_USER_PAYMENT_READY'

export const SET_USER_PAYMENT_SUCCESS = 'SET_USER_PAYMENT_SUCCESS'
export const CLEAR_USER_PAYMENT_SUCCESS = 'CLEAR_USER_PAYMENT_SUCCESS'

export const SET_USER_PAYMENT_ERROR = 'SET_USER_PAYMENT_ERROR'
export const CLEAR_USER_PAYMENT_ERROR = 'CLEAR_USER_PAYMENT_ERROR'
