import types from './types'

const INITIAL_STATE = {
  createdTicket: "",
  createdAttorney: "",
  unassignedActiveLeadsExport: [],
  closedTicketsExport: [],
  lookupTicketsExport: []
}

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_TICKET: {
      return {
        ...state,
        createdTicket: action.value
      }
    }
    case types.SET_CREATED_ATTORNEY: {
      return {
        ...state,
        createdAttorney: action.value
      }
    }
    case types.SET_UNASSIGNED_ACTIVE_EXPORT: {
      return {
        ...state,
        unassignedActiveLeadsExport: action.value
      }
    }
    case types.SET_CLOSED_TICKETS_EXPORT: {
      return {
        ...state,
        closedTicketsExport: action.value
      }
    }
    case types.SET_LOOKUP_TICKETS_EXPORT: {
      return {
        ...state,
        lookupTicketsExport: action.value
      }
    }
    default:
      return state
  }
}

export default adminReducer
