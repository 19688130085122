// dispatched from any store
const RESET = 'RESET'

// Specific to current store
const SET_LEADS = 'SET_LEADS'
const SET_LEADS_RETRIEVED_AT = 'SET_LEADS_RETRIEVED_AT'
const SET_CURRENTLY_VIEWING = 'SET_CURRENTLY_VIEWING'
const SET_LEADS_REGION_FILTER = 'SET_LEADS_REGION_FILTER'

const SET_CLOSED_TICKET_HEARING_DATE = 'SET_CLOSED_TICKET_HEARING_DATE'

export default {
  // dispatched from any store
  RESET,
  // Specific to current store
  SET_LEADS,
  SET_LEADS_RETRIEVED_AT,
  SET_CURRENTLY_VIEWING,
  SET_LEADS_REGION_FILTER,
  SET_CLOSED_TICKET_HEARING_DATE,
}
