import { connect } from 'react-redux'
import { ticketByIdSelector } from '../../duck/selectors'
import ViewRetentionLetterComponent from './ViewRetentionLetterComponent'
import thunks from "../../duck/thunks"

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
})

const mapDispatchToProps = dispatch => ({
  generateRetentionLetter: (ticket) => dispatch(thunks.generateRetentionLetter(ticket)),
})

const ViewRetentionLetterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewRetentionLetterComponent)

export default ViewRetentionLetterContainer
