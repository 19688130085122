import React, { Component, Fragment } from 'react'
import { Confirm, Modal } from 'semantic-ui-react'
import { StyledElements } from '../common'
import BasicAddForm from '../payments/addDefaultPaymentMethod/BasicAddFormContainer'

class LeadStatusModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showWarningModal: false,
    }
  }
  setModal = showModal => this.setState({ ...this.state, showModal })
  showModal = () => this.setModal(true)
  showWarningModal = () => this.setWarningModal(true)
  setWarningModal = showWarningModal => this.setState({ ...this.state, showWarningModal })
  handleConfirm = () => {
    this.setState({
      ...this.state,
      showWarningModal: false,
      showModal: true,
    })
  }
  handleCancel = () => this.setState({ ...this.state, showWarningModal: false })
  hideModal = () => this.setModal(false)
  render() {
    const { user, warnMissingPaymentProcessor } = this.props
    if (!user.paymentProcessor) {
      warnMissingPaymentProcessor(user)
    }
    
    const isStripeUser = user.paymentProcessor?.type === 'stripe'
    const showWarning = isStripeUser && user.hasPaymentMethod
    const RenderModal = this.state.showWarningModal
      ? this.warningModal
      : this.renderModal
    return (
      <Fragment>
        <StyledElements.FakeLink onClick={showWarning? this.showWarningModal: this.showModal}>
          Add Payment Method
        </StyledElements.FakeLink>
        <RenderModal />
      </Fragment>
    )
  }

  renderModal = () => {
    const { showModal } = this.state
    const { user } = this.props
    return (
      <Modal
        size="small"
        open={showModal}
        onClose={this.hideModal}
        closeIcon={false}
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Header>
          Add Payment Method {user && user.name ? `for ${user.name}` : null}
        </Modal.Header>
        <Modal.Content>
          <BasicAddForm user={user} hideModal={this.hideModal} />
        </Modal.Content>
      </Modal>
    )
  }
  warningModal = () => {
    return (
      <Confirm
        open={this.state.showWarningModal}
        content={`This user currently has a payment method registered on the Stripe payment processor. If you add a payment method through the attorney portal, the user will be migrated to the Braintree payment processor, and all his current payment methods will be reset. Are you sure you want to continue? (No payment methods will be reset until you click "save" on the next screen)`}
        header="Please confirm"
        cancelButton="No"
        confirmButton="Yes"
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
        size="mini"
      />
    )
  }
}


export default LeadStatusModal
