import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'

import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';

class EditCaseProtectionActivation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false
    }
  }

  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  updateCaseProtectionActivated = (ticket) => {
    this.setState({ isEditing: false })
    this.props.update(this.props.objectId, { caseProtectionActivated: !ticket.caseProtectionActivated })
  }

  renderEdit = () => {
    const enableOrDisableCaseProtection = this.props.ticket && this.props.ticket.caseProtectionActivated ? 'Disable' : 'Enable'
    return <StyledElements.EditContainer>
      <Form>
        <Forms.InlineEditButtons buttons={[
          {
            disabled: false,
            color: "green",
            text: `${enableOrDisableCaseProtection} Case Protection`,
            icon: "save",
            labelPosition: "right",
            onClick: () => this.updateCaseProtectionActivated(this.props.ticket),
            type: "submit"
          }
        ]} />
      </Form>
    </StyledElements.EditContainer>
  }

  render() {
    if (!this.props.isAdmin) {return <div />}
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="Case Protection Activated"
          isEditable={true}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {this.state.isEditing ?
            <RenderEdit /> : String(!!this.props.ticket.caseProtectionActivated)
          }
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    );
  }
}

export default EditCaseProtectionActivation;
