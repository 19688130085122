import { connect } from 'react-redux'
import { searchSelectors, searchActions, searchThunks } from './duck'
import SearchResultsComponent from './SearchResultsComponent'
import SEARCH_DESCRIBER from './SearchTypes'

const mapStateToProps = state => ({
  searchType: searchSelectors.type(state),
  search: SEARCH_DESCRIBER[searchSelectors.type(state)],
  loading: searchSelectors.loading(state),
  error: searchSelectors.error(state),
  results: searchSelectors.results(state),
})

const mapDispatchToProps = dispatch => ({
  changeSearch: searchType => dispatch(searchActions.setSearchType(searchType)),
  search: searchCriteria =>
    dispatch(searchThunks.fetchSearchResults(searchCriteria)),
})

const SearchResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsComponent)

export default SearchResultsContainer
