import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Grid, Header, Loader } from 'semantic-ui-react'

import logo from './Partially.png'
import CreateNewCustomer from '../Button/CreateNewCustomer'
import EditCustomerInfo from '../Button/EditCustomerInfo'
import CreateNewPlan from '../Button/CreateNewPlan'
import SeePlan from '../Button/SeePlan'
import { getPartiallyCustomer, getPartiallyCustomers, getPaymentPlans } from '../duck/services'
import { isUserBlocked } from '../../users/duck/services'
import AddPaymentMethod from '../Button/AddPaymentMethod'
import SharedHeader from '../shared/SharedHeader'

const PartiallyComponent = ({ user, attorney, fetchUser, update, error, success }) => {
  const [showSection, setShowSection] = useState(false)
  const showSectionHandler = () => setShowSection(true)
  const closeSectionHandler = () => setShowSection(false)

  const [customers, setCustomers] = useState(null)
  const [customerAccount, setCustomerAccount] = useState(null)
  const [userWithTix, setUserWithTix] = useState(null)
  const [paymentPlans, setPaymentPlans] = useState(null)
  const [userBlocked, setUserBlocked] = useState(false)
  const [allowCC, setAllowCC] = useState(false)

  useEffect(() => {
    getPartiallyCustomers(user.email).then(res => {
      setCustomers(res.customers)
    })

    async function fetchUserData() {
      if (user) {
        const result = await fetchUser(user.objectId)
        setUserWithTix(result)
      }
    }
    fetchUserData()

    const fetchBlockedUserData = async () => {
      const result = await isUserBlocked(user.objectId)
      setUserBlocked(result.isUserBlocked)
    }
    fetchBlockedUserData()

    if (attorney.globalAdmin && attorney.allowCC) {
      setAllowCC(attorney.allowCC)
    }
  }, [])

  if (!customers) return <Loader active />

  const mappedCustomers = customers.map(customer => {
    if (customer.email === user.email) {
      return customer.id
    }
  })

  const getId = mappedCustomers.filter(id => {
    if (id) {
      return id
    }
  })

  const getPartiallyData = () => {
    if (getId[0]) {
      getPartiallyCustomer(getId[0]).then(res => {
        setCustomerAccount(res)
      })

      getPaymentPlans(getId[0]).then(res => {
        setPaymentPlans(res.payment_plans)
      })
    }
  }

  const mainPartiallyButton = !showSection
    ? <SharedHeader onClick={() => { showSectionHandler(); getPartiallyData() }} name='Open Partial.ly' />
    : <SharedHeader onClick={closeSectionHandler} name='Close Partial.ly' />

  const customerButton = customerAccount && customerAccount.id
    ? <EditCustomerInfo customer={customerAccount} error={error} success={success} />
    : <CreateNewCustomer user={user} error={error} success={success} />

  {
    if (!userBlocked) {
      return (
        <>
          <Grid.Column mobile={16} tablet={8} computer={4}>
            {mainPartiallyButton}
          </Grid.Column>

          {showSection && (
            <>
              <Header as="h3" dividing>
                Partial.ly Integration
              </Header>
              <Grid
                stackable
                stretched
                columns={2}
                style={{ wordBreak: 'break-all' }}
              >
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  {customerButton}
                  {paymentPlans && paymentPlans.length > 0 && <SeePlan user={userWithTix} attorney={attorney} paymentPlans={paymentPlans} customerAccount={customerAccount} allowCC={allowCC} error={error} success={success} />}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                  <CreateNewPlan user={userWithTix} attorney={attorney} update={update} paymentPlans={paymentPlans} customerAccount={customerAccount} userBlocked={userBlocked} error={error} success={success} />
                  {allowCC === true && <AddPaymentMethod user={user} customerAccount={customerAccount} allowCC={allowCC} error={error} success={success} />}
                </Grid.Column>
              </Grid>
            </>
          )}
        </>
      )
    } else {
      return (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Header as="h4">
            <Header.Content style={{ marginTop: '20px' }}>
              <Header.Subheader>
                <UserBlockedButton><img src={logo} alt="Partial.ly Logo" />Open Partial.ly</UserBlockedButton>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
      )
    }
  }
}

const UserBlockedButton = styled.button`
  display: flex;
  align-items: center;
  width: max-content;
  height: 60px;
  border-radius: 25px;
  padding: 15px;
  margin: 10px 0;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: not-allowed;
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), 
  radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;

  &:focus {
    outline: none;
  }

  img {
    width: 20px;
    margin: 0 10px;
  }
`
export default PartiallyComponent
