import { connect } from 'react-redux'
import { activationActions } from './duck'
import { stepSelector, userSelector, attorneySelector } from './duck/selectors'
import ActivationComponent from './ActivationComponent'

const mapStateToProps = state => ({
  step: stepSelector(state),
  user: userSelector(state),
  attorney: attorneySelector(state),
})

const mapDispatchToProps = dispatch => ({
  setCode: (code) => dispatch(activationActions.setCode(code))
})

const ActivationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivationComponent)

export default ActivationContainer
