import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import HeaderComponent from './HeaderComponent'
import { authenticationThunks } from '../authentication/duck'
import { chatsThunks } from '../chats/duck'
import {
  userSelector,
  attorneySelector,
  attorneyIsAdminSelector
} from '../authentication/duck/selectors'

const mapStateToProps = state => ({
  user: userSelector(state),
  attorney: attorneySelector(state),
  isAdmin: attorneyIsAdminSelector(state)
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authenticationThunks.logout()),
  getSendbirdAttorneyUser: (attorney) => dispatch(chatsThunks.getSendbirdAttorneyUser(attorney)),
})

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent)

export default withRouter(HeaderContainer)
