import React from 'react'
import { Message } from 'semantic-ui-react'

const SearchResultsError = ({ error }) => {
  return (
    <Message negative>
      <Message.Header>
        We're sorry we couldn't perform the search.
      </Message.Header>
      <p>{error}</p>
    </Message>
  )
}

export default SearchResultsError
