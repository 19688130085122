import { axios, axiosErrorResponse } from "../../../utilities"

export const CheckCode = (code) => {
  return axios.basic.post("/activation/check-code", { code })
    .then(response => {
      return response.data
    }).catch(err => {
      return Promise.reject(axiosErrorResponse(err))
    })
}

export const CreateUser = (email, password, name) => {
  return axios.basic.post("/activation/create-user", { email, password, name })
    .then(response => {
      return response.data
    }).catch(err => {
      return Promise.reject(axiosErrorResponse(err))
    })
}

export const LoginUser = (email, password) => {
  return axios.basic.post("/activation/login", { email, password })
    .then(response => {
      return response.data
    }).catch(err => {
      return Promise.reject(axiosErrorResponse(err))
    })
}

export const completeActivation = (userObjectId, activationCode) => {
  return axios.basic.post("/activation/", { user: userObjectId, code: activationCode })
    .then(response => {
      return response.data
    }).catch(err => {
      return Promise.reject(axiosErrorResponse(err))
    })
}
