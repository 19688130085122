import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Image, Label } from 'semantic-ui-react'
import styled from 'styled-components'
import AttorneyLogo from '../../assets/images/navigation-bar-logo-portal.svg'
import { AttorneyImagePopup } from '../common'

const { REACT_APP_ENV } = process.env
const ENV_LABEL_MAP = {
  production: '',
  staging: 'black',
  development: 'green',
}

const StyledMenu = styled(Menu)`
  &&& {
    margin: 0;
    z-index: 10;
  }
`

const Spread = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { sendbirdAttorneyUser: null }
  }

  componentDidMount = async () => {
    if (!this.props.attorney.globalAdmin && this.props.attorney.chatEnabled) {
      const sendbirdAttorneyUser = await this.props.getSendbirdAttorneyUser(this.props.attorney)
      if (sendbirdAttorneyUser) {
        this.setState({ sendbirdAttorneyUser })
      }
    }
  }

  render() {
    const { attorney } = this.props
    const currentLocation = this.props.location.pathname
    const baseComponent = currentLocation.split('/').filter(v => v !== '')[0]
    const sendbirdUnreadCount = this.state.sendbirdAttorneyUser?.unread_message_count
    return (
      <StyledMenu
        fluid
        pointing
        secondary
        borderless
        stackable
        style={
          process.env.NODE_ENV !== 'production'
            ? { backgroundColor: '#f1f1f1' }
            : {}
        }
      >
        <Menu.Item as={Link} header to="/" active={currentLocation === '/'}>
          <Image
            src={AttorneyLogo}
            style={{ height: '25px', width: 'auto', marginRight: '6px' }}
          />
          Attorney Platform
          {REACT_APP_ENV !== 'production' ? (
            <Label color={ENV_LABEL_MAP[REACT_APP_ENV]} size="mini">
              {REACT_APP_ENV.toUpperCase()}
            </Label>
          ) : null}
        </Menu.Item>
        <Spread />
        {this.props.isAdmin ?
          <Menu.Item
          active={baseComponent === 'admin'}
          as={Link}
          name="Admin"
          to="/admin/ticket"
        >
          Admin
        </Menu.Item>
        : null
        }
        {this.props.isAdmin ?
          <Menu.Item
          active={baseComponent === 'lookup'}
          as={Link}
          name="Lookup"
          to="/lookup"
        >
          Lookup
        </Menu.Item>
        : null
        }
        <Menu.Item
          active={baseComponent === 'leads'}
          as={Link}
          name="Leads"
          to={attorney && attorney.globalAdmin ? "/leads/active" : "/leads/closed"}
        >
          Leads
        </Menu.Item>
        <Menu.Item
          active={baseComponent === 'processing'}
          as={Link}
          name="Processing"
          to="/processing/recent"
        >
          Processing
        </Menu.Item>
        <Menu.Item
          active={baseComponent === 'hearings'}
          as={Link}
          name="Hearings"
          to="/hearings/recent"
        >
          Hearings
        </Menu.Item>
        <Menu.Item
          active={baseComponent === 'calls'}
          as={Link}
          name="Calls"
          to="/calls"
        >
          Calls
        </Menu.Item>
        <Menu.Item
          active={baseComponent === 'chats'}
          as={Link}
          name="Chats"
          to="/chats"
        >
          Chats {sendbirdUnreadCount ? `(${sendbirdUnreadCount})` : null}
        </Menu.Item>
        <Menu.Item
          active={baseComponent === 'search'}
          as={Link}
          name="Search"
          to="/search"
        >
          Search
        </Menu.Item>
        <AttorneyImagePopup
          attorney={this.props.attorney}
          user={this.props.user}
        />
        <Menu.Item>
          {attorney && attorney.globalAdmin ? (
            <Label style={{ margin: 0 }} size="tiny" color="red">
              ADMIN
            </Label>
          ) : null}
        </Menu.Item>
      </StyledMenu>
    )
  }
}

export default Header
