import * as services from './services'
import { overlaysActions } from '../../overlays/duck'
import { NOTIFICATION_TYPES } from '../../../constants'

const sendSubmissionMagicLink = (ticket, header, message) => dispatch => {
  if (
    !ticket.attorney || 
    !ticket.attorneyPrice || 
    !ticket.winItPrice || 
    !ticket.trafficCounty ||
    !ticket.trafficCourt
    ) {
    return dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.error,
      duration: 15,
      title: `Issue Generating User Traffic Submission Magic Link`,
      message: `Ticket must have attorney, price, county, and court.`,
    }))
  }

  dispatch(overlaysActions.showLoader())
  services
    .sendSubmissionMagicLink(ticket.objectId, header, message)
    .then(() => {
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Traffic Submission Magic Link Sent`,
          duration: 5,
        })
      )
    })
    .catch(error => {
      console.error('error sending user submission magic link', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.error,
          duration: 15,
          title: 'Issue Sending User Traffic Submission Magic Link',
          message: error,
        })
      )
    })
}

const showUnavailableStateToast = (locationState) => dispatch => {
  dispatch(
    overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.error,
      duration: 15,
      title: 'Invalid Magic Link Configuration',
      message: `Traffic state ${locationState} is not available for traffic submission.`,
    })
  )
}

export default {
  sendSubmissionMagicLink,
  showUnavailableStateToast,
}
