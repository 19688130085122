import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'

class ProfileMenu extends Component {
  render() {
    const { active } = this.props
    return (
      <Menu secondary>
        <Menu.Item name="Summary" active={!active} as={Link} to="/profile/" />
        <Menu.Item
          name="Statistics"
          active={active === 'stats'}
          as={Link}
          to="/profile/stats"
        />
        <Menu.Item
          name="Associated Users"
          active={active === 'users'}
          as={Link}
          to="/profile/users"
        />
        <Menu.Item
          name="Legal Docs"
          active={active === 'agreements'}
          as={Link}
          to="/profile/agreements"
        />
        {/* <Menu.Item name='photos' active={activeItem === 'photos'} onClick={this.handleItemClick} /> */}
      </Menu>
    )
  }
}

export default ProfileMenu
