import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Header, Icon, Segment } from 'semantic-ui-react'

const NoSearchResults = ({ history }) => {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name="search" />
        We couldn't find any results matching your query.
      </Header>
      <Segment.Inline>
        <Button primary onClick={() => history.push('/search/')}>
          Try Another Search
        </Button>
      </Segment.Inline>
    </Segment>
  )
}

export default withRouter(NoSearchResults)
