export const set = (key, value) => {
  let safeValue = value
  if (typeof value !== "string") {
    safeValue = JSON.stringify(value)
  }
  localStorage.setItem(key, safeValue);
}

/**
 *
 * @param {string} key The key to retrieve
 * @param {boolean} parseJSON Would you like to JSON.parse() the result
 */
export const get = (key, parseJSON) => {
  const savedValue = localStorage.getItem(key)
  return parseJSON ? JSON.parse(savedValue) : savedValue;
}

export const remove = (key) => {
  localStorage.removeItem(key)
}
