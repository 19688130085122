import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ViewUser from './ViewUser/ViewUserContainer'
import NotFoundPage from '../404NotFound'

const UserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/users/" component={() => <div>users</div>} />
      <Route exact path="/users/view/:id" component={ViewUser} />
      {/* <Route exact path="/users/users" component={AssociatedUsers} /> */}
      {/* <Route exact path="/users/stats" component={AttorneyStats} /> */}
      <Route path="*" component={NotFoundPage} status={404} />
    </Switch>
  )
}

export default UserRoutes
