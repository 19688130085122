import { connect } from 'react-redux'
import { attorneySelector } from '../../../authentication/duck/selectors'
import { ticketByIdSelector, ticketEditFieldByIdSelector } from '../../duck/selectors'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditStatusInlineComponent from './EditStatusInlineComponent'

const EDIT_KEY = "status"

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  editTicketField: ticketEditFieldByIdSelector(state, props.objectId, EDIT_KEY),
  attorney: attorneySelector(state)
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, value) => dispatch(ticketsActions.setEditValue(objectId, EDIT_KEY, value)),
  clearEditValue: (objectId) => dispatch(ticketsActions.clearEditValue(objectId, EDIT_KEY)),
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject))
})

const EditStatusInlineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditStatusInlineComponent)

export default EditStatusInlineContainer
