import types from './types'

const setAllAttorneys = attorneys => ({
  type: types.SET_ALL_ATTORNEYS,
  attorneys
})

export default {
  setAllAttorneys
}
