const exportLookupHeaderOrderCSV = [
  { label: "Ticket #", key: "ticketID" },
  { label: "ObjectID", key: "objectId" },
  { label: "Violation Name", key: "violationName" },
  { label: "Points", key: "points" },
  { label: "Ticket Status", key: "status" },
  { label: "Hearing Date", key: "hearingDate" },
  { label: "Issue Date", key: "issueDate" },
  { label: `Call History`, key: "callHistory" },
  { label: "Name", key: "fullName" },
  { label: "Phone", key: "phoneNumber" },
  { label: "Email", key: "email" },
  { label: `Traffic Court`, key: "trafficCourt" },
  { label: "Agent", key: "agent" },
  { label: "CX Notes", key: "cxNotes" },
  { label: `Created At`, key: "createdAt" },
  { label: "Category", key: "category" },
  { label: "License ID", key: "licenseID" },
  { label: "First Name", key: "firstName" },
  { label: "Middle Name", key: "middleName" },
  { label: "Last Name", key: "lastName" },
  { label: "Gender", key: "gender" },
  { label: "Dob", key: "dob" },
  { label: "Last 4", key: "last4" },
]


export default exportLookupHeaderOrderCSV
