const axiosErrorResponse = error => {
  // if (process.env.NODE_ENV === "development") {
  console.error('Requests error response:', error) // eslint-disable-line
  // }
  // if we send our own error object in response
  if (typeof error === 'string') {
    return error
  }
  if (error.response && error.response.data && error.response.data.error) {

    let message
    const target = error.response.data.error
    if (typeof target === 'string') {
      message = target
    }
    if (typeof target === 'object') {
      if (target.message) {
        message = target.message
      } else {
        message = JSON.stringify(target)
      }
    }

    if (message) {
      if(error.response.headers && error.response.headers["x-correlation-id"]){
        message += ` - You may contact support with the following ID: ${error.response.headers["x-correlation-id"]}`
      }
      return message
    }
    return 'Error from backend reported.'
  }
  // if the error is not our response
  return error.message
}

export default axiosErrorResponse
