import { connect } from 'react-redux'
import lookupThunks from '../../../lookup/duck/thunks'
import InlineButtonComponent from './InlineButtonComponent'
// import { ticketsSelectors } from '../../duck'
// import { ticketsThunks } from '../../duck'


const mapStateToProps = (state, props) => ({
  // ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
})

const mapDispatchToProps = dispatch => ({
  // updateTrafficTicket: (objectId, updateObject) =>
  //   dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  updateFetcherContactInfo: (objectId, updateObject) =>
    dispatch(lookupThunks.updateSingleContactInfo(objectId, updateObject)),
})

const InlineButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InlineButtonComponent)

export default InlineButtonContainer
