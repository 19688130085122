import React, { Component } from 'react'
import { StyledElements } from '../../../common'
import ItemHeader from '../ItemHeader'
import SendTrafficSubmissionMagicLinkContainer from '../../../communications/SendTrafficSubmissionMagicLink/SendTrafficSubmissionMagicLinkContainer'

class ViewMagicLinkCommunicationComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }
  toggleExpansion = () => this.setState({ expanded: !this.state.expanded })
  render() {
    const { expanded } = this.state
    if (!this.props.isAdmin) return null
    return (
      <StyledElements.DataContainer isEditing={expanded}>
        <ItemHeader
          title="Magic Link"
          isEditable
          isEditing={expanded}
          toggleState={this.toggleExpansion}
          expandText="show"
          collapseText="hide"
        />
        {expanded ? (
          <SendTrafficSubmissionMagicLinkContainer
            objectId={this.props.objectId}
            toggleState={this.toggleExpansion}
          />
        ) : (
          <StyledElements.Italicize>
            Send this user a a magic link to complete the ticket submission.
          </StyledElements.Italicize>
        )}
      </StyledElements.DataContainer>
    )
  }
}

export default ViewMagicLinkCommunicationComponent
