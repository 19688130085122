import { connect } from 'react-redux'
import { lookupSelectors, lookupThunks } from '../duck'
import { authenticationSelectors } from '../../authentication/duck'
import AllLookupComponent from './AllLookupComponent'
import { setHearingDateContactInfo } from '../duck/actions'
import { 
  reactTableSelectors, 
  // reactTableActions 
} from '../../react-table/duck'
// import { FRONTEND_TICKET_TYPES } from '../../../constants'


const mapStateToProps = state => ({
  attorney: authenticationSelectors.attorneySelector(state),
  allContactInfo: lookupSelectors.allContactInfoSelector(state),
  filteredContactInfo: lookupSelectors.hearingDateFilteredContactInfoSelector(state),
  rtSettings: reactTableSelectors.settings('lookup', state),
})

const mapDispatchToProps = dispatch => ({
  fetchContactInfo: (created_at) => dispatch(lookupThunks.fetchAllContactInfo(created_at)),
  setHearingDateFilteredContactInfo: (hearingDateContactInfo) => dispatch(setHearingDateContactInfo(hearingDateContactInfo))
  // setResized: value =>
  //   dispatch(reactTableActions.setResized(FRONTEND_TICKET_TYPES.leads.all, value)),
})

const AllLookupContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllLookupComponent)

export default AllLookupContainer
