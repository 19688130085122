import { connect } from 'react-redux'
import { processingActions } from "../duck"
import { idFromRouterSelector } from '../duck/selectors'
import { ticketByIdSelector } from '../../tickets/duck/selectors'
import { ticketsThunks } from "../../tickets/duck"
import ViewTicketContainer from '../../tickets/ViewTicket/ViewTicketContainer'

const mapStateToProps = (state, props) => ({
  currentId: idFromRouterSelector(state, props),
  currentTicket: ticketByIdSelector(state, idFromRouterSelector(state, props))
})

const mapDispatchToProps = dispatch => ({
  fetchTicket: (objectId) => dispatch(ticketsThunks.fetchTicket(objectId)),
  setCurrentlyViewing: (ticket) => dispatch(processingActions.setCurrentlyViewing(ticket))
})

const ViewProcessingTicketContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTicketContainer)

export default ViewProcessingTicketContainer
