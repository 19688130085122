import { connect } from 'react-redux'
import { authenticationSelectors } from '../../../authentication/duck'
import { ticketsSelectors } from '../../duck'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditLeadStatusInlineComponent from './EditLeadStatusInlineComponent'

const EDIT_KEY = 'leadStatus'

const mapStateToProps = (state, props) => ({
  EDIT_KEY,
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  leadStatus: ticketsSelectors.ticketFieldById(state, props.objectId, EDIT_KEY),
  editTicketField: ticketsSelectors.ticketEditFieldByIdSelector(
    state,
    props.objectId,
    EDIT_KEY
  ),
  attorney: authenticationSelectors.attorneySelector(state),
})

const mapDispatchToProps = dispatch => ({
  editUpdate: (objectId, value) =>
    dispatch(ticketsActions.setEditValue(objectId, EDIT_KEY, value)),
  clearEditValue: objectId =>
    dispatch(ticketsActions.clearEditValue(objectId, EDIT_KEY)),
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
})

const EditLeadStatusInlineContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLeadStatusInlineComponent)

export default EditLeadStatusInlineContainer
