import React, { Component } from 'react'
import { StyledElements } from '../../../common'
import ItemHeader from '../ItemHeader'
import CallRequestHistory from '../../../calls/RequestHistory/RequestHistoryContainer'

class ViewCallRequestHistoryComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
    }
  }
  toggleExpansion = () => this.setState({ expanded: !this.state.expanded })
  render() {
    const { expanded } = this.state
    return (
      <StyledElements.DataContainer isEditing={expanded}>
        <ItemHeader
          title="Call Request History"
          isEditable
          isEditing={expanded}
          toggleState={this.toggleExpansion}
          expandText="show"
          collapseText="hide"
        />
        {expanded ? (
          <CallRequestHistory objectId={this.props.objectId} />
        ) : (
          <StyledElements.Italicize>
            See the history of call requests for this ticket.
          </StyledElements.Italicize>
        )}
      </StyledElements.DataContainer>
    )
  }
}

export default ViewCallRequestHistoryComponent
