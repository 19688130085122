import React, { Component, Fragment } from 'react'
import { Form, Header, Grid } from 'semantic-ui-react'


class TicketFormComponent extends Component {
  constructor() {
    super()
    this.state = {
      userObjectId: '',
      ticketID: ''
    }
  }

  componentDidUpdate = () => {
    if (this.props.createdTicket) {
      this.props.history.push(`/leads/view/${this.props.createdTicket}`)
      this.props.setTicket("")
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.createTrafficTicket({
      user: this.state.userObjectId,
      ticketID: this.state.ticketID
    })
    
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <Fragment>

        <Grid centered={true}>
          <Grid.Column
            verticalAlign="middle"
            computer={10}
          >

            <Header
              style={{marginBottom: "25px", marginTop: "15px"}}
            >
              Create Traffic Ticket
            </Header>

            <Form size="large" onSubmit={this.handleSubmit}>
              <Form.Input
                fluid
                name="userObjectId"
                icon="user"
                iconPosition="left"
                placeholder="User ObjectId"
                onChange={this.handleChange}
                value={this.state.userObjectId}
              />

              <Form.Input
                fluid
                name="ticketID"
                icon="ticket"
                iconPosition="left"
                placeholder="Ticket ID"
                onChange={this.handleChange}
                value={this.state.ticketID}
              />

              <Form.Button
                style={{width:"100%", height: "45px"}}
                type="submit"
              >
                Submit
              </Form.Button>

            </Form>
          </Grid.Column>
        </Grid>
      </Fragment>
    )
  }
}

export default TicketFormComponent
