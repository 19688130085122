import types from './types'
import { ADDITIONAL_TICKET_FIELD } from '../../../constants'

const INITIAL_STATE = {
  items: {},
  editItems: {},
}

const ticketsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.REMOVE_CLASSIFICATION_FROM_TICKETS: {
      const newItems = { ...state.items }
      for (let id in newItems) {
        const item = newItems[id]
        item.classifications = item.classifications.filter(
          item => item !== action.classification
        )
      }
      return {
        ...state,
        items: newItems,
      }
    }

    case types.UPDATE_USER_ON_TICKETS: {
      const items = { ...state.items }
      const { user } = action
      const keys = Object.keys(items)
      keys.forEach(ticketObjectId => {
        if (
          items[ticketObjectId].user &&
          items[ticketObjectId].user.objectId &&
          items[ticketObjectId].user.objectId === user.objectId
        ) {
          items[ticketObjectId].user = user
        }
      })
      return {
        ...state,
        items,
      }
    }

    case types.SET_EDIT_TICKET_VALUE: {
      const { value, objectId, key } = action
      const editItems = { ...state.editItems }
      let target = editItems[objectId]
      if (!target) {
        target = {}
      }
      target[key] = value
      editItems[objectId] = target
      return {
        ...state,
        editItems,
      }
    }

    case types.CLEAR_EDIT_TICKET_VALUE: {
      const { objectId, key } = action
      const editItems = { ...state.editItems }
      let target = editItems[objectId] // holds update key/values
      if (target && key in target) {
        delete target[key] // delete current key/value
        editItems[objectId] = target // set updated key/value
      }
      return {
        ...state,
        editItems,
      }
    }

    case types.ADD_TICKET: {
      const { value } = action
      const items = { ...state.items }
      const editItems = { ...state.editItems }
      let newItem = {}
      if (editItems[value.objectId]) {
        newItem = { ...editItems[value.objectId] }
      }
      newItem = { ...newItem, ...value }
      items[value.objectId] = newItem
      return {
        ...state,
        items,
      }
    }

    case types.ADD_MANY_TICKETS: {
      const incomingTickets = action.value
      const items = { ...state.items }

      const editItems = { ...state.editItems }
      incomingTickets.forEach(ticket => {
        let newItem = {}
        if (items[ticket.objectId]) {
          newItem = { ...items[ticket.objectId] }
        }
        newItem = {
          ...newItem,
          ...ticket,
        }
        items[ticket.objectId] = newItem
        if (editItems[ticket.objectId]) {
          delete editItems[ticket.objectId]
        }
      })
      return {
        ...state,
        items,
      }
    }

    case types.ADD_USERS_PAYMENT_METHOD: {
      const { userObjectId, paymentMethod } = action
      const items = { ...state.items }
      for (let ticketId in items) {
        if (
          items.hasOwnProperty(ticketId) &&
          items[ticketId].user &&
          items[ticketId].user.objectId === userObjectId
        ) {
          items[ticketId].user.paymentDetails = paymentMethod
        }
      }
      return {
        ...state,
        items,
      }
    }

    case types.ADD_TICKET_EVIDENCE: {
      const { ticketObjectId, evidence } = action
      const items = { ...state.items }
      if (items[ticketObjectId]) {
        items[ticketObjectId][ADDITIONAL_TICKET_FIELD.evidence] = evidence
      }
      return {
        ...state,
        items,
      }
    }

    case types.ADD_NEW_TICKET_EVIDENCE: {
      const { ticketObjectId, evidence } = action
      const items = { ...state.items }
      if (items[ticketObjectId]) {
        const previousEvidence = items[ticketObjectId][ADDITIONAL_TICKET_FIELD.evidence]
        items[ticketObjectId][ADDITIONAL_TICKET_FIELD.evidence] = previousEvidence ?
         [...previousEvidence, evidence] :
         [evidence]
      }
      return {
        ...state,
        items,
      }
    }

    case types.ADD_TICKET_PORTAL_EDIT_HISTORY: {
      const { ticketObjectId, editHistory } = action
      const items = { ...state.items }
      if (items[ticketObjectId]) {
        items[ticketObjectId][ADDITIONAL_TICKET_FIELD.portalEditHistory] = {
          editHistory,
          retrievedAt: new Date(),
        }
      }
      return {
        ...state,
        items,
      }
    }

    case types.ADD_TICKET_PRICE_PREVIEW: {
      const { ticketObjectId, priceArr } = action
      const items = { ...state.items }
      if (items[ticketObjectId]) {
        items[ticketObjectId][ADDITIONAL_TICKET_FIELD.pricePreview] = priceArr
      }
      return {
        ...state,
        items,
      }
    }

    default:
      return state
  }
}

export default ticketsReducer
