import { connect } from 'react-redux'
import { ticketsSelectors } from '../../duck'
import { ticketsThunks, ticketsActions } from '../../duck'
import EditAttorneyNotesComponent from './EditAttorneyNotesComponent'

const EDIT_KEY = 'attorneyNotes'

const mapStateToProps = (state, props) => ({
  ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
  editTicketField: ticketsSelectors.ticketEditFieldByIdSelector(
    state,
    props.objectId,
    EDIT_KEY
  ),
})

const mapDispatchToProps = dispatch => ({
  update: (objectId, updateObject) =>
    dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  clearEditValue: objectId =>
    dispatch(ticketsActions.clearEditValue(objectId, EDIT_KEY)),
  editUpdate: (objectId, value) =>
    dispatch(ticketsActions.setEditValue(objectId, EDIT_KEY, value)),
})

const EditAttorneyNotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAttorneyNotesComponent)

export default EditAttorneyNotesContainer
