import { connect } from 'react-redux'
import { regionsSelectors } from '../../regions/duck'
import { overlaySelectors } from '../../overlays/duck'
import { leadsThunks, leadsSelectors } from '../duck'
import { reactTableSelectors, reactTableActions } from '../../react-table/duck'
import LeadsListContainer from './LeadsListContainer'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.leads.closed
const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    TICKET_TYPE
  ),
  leads: regionsSelectors.regionsFilterBuilder(
    leadsSelectors.buildLeadsSelectorByType(TICKET_TYPE),
    leadsSelectors.leadsRegionFilterSelector
  )(state),
  retrievedAt: leadsSelectors.closedLeadsRetrievedAtSelector(state),
  rtSettings: reactTableSelectors.settings(TICKET_TYPE, state),
})

const mapDispatchToProps = dispatch => ({
  fetchLeads: () => dispatch(leadsThunks.fetchClosedLeads()),
  setResized: value =>
    dispatch(reactTableActions.setResized(TICKET_TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TICKET_TYPE, value)),
  setFiltered: value =>
    dispatch(reactTableActions.setFiltered(TICKET_TYPE, value)),
})

const ClosedLeadsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsListContainer)

export default ClosedLeadsListContainer
