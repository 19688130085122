import { connect } from 'react-redux'
import { reactTableSelectors, reactTableActions } from '../react-table/duck'
import { authenticationSelectors } from '../authentication/duck'
import { GENERAL_SEARCH_TYPES } from '../../constants'

import SearchResultsTableComponent from './SearchResultsTableComponent'

const TYPE = GENERAL_SEARCH_TYPES.ticket

const mapStateToProps = state => ({
  attorney: authenticationSelectors.attorneySelector(state),
  rtSettings: reactTableSelectors.settings(TYPE, state),
})

const mapDispatchToProps = dispatch => ({
  setResized: value => dispatch(reactTableActions.setResized(TYPE, value)),
  setSorted: value => dispatch(reactTableActions.setSorted(TYPE, value)),
  setFiltered: value => dispatch(reactTableActions.setFiltered(TYPE, value)),
})

const SearchResultsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsTableComponent)

export default SearchResultsTableContainer
