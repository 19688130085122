import { connect } from 'react-redux'
import { courtsThunks } from '../../courts/duck'
import { courtsSelector } from '../../courts/duck/selectors'
import { countiesSelector } from '../duck/selectors'
import { countiesThunks } from '../duck'
import { ticketByIdSelector } from '../../tickets/duck/selectors'
import { ticketsThunks } from '../../tickets/duck'
import EditCountyComponent from './EditCountyComponent'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  counties: countiesSelector(state),
  courts: courtsSelector(state)
})

const mapDispatchToProps = dispatch => ({
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  fetchCounties: (stateShortName) => dispatch(countiesThunks.fetchCountiesByStateShortName(stateShortName)),
  fetchCourts: (countyId) => dispatch(courtsThunks.fetchCourtsByCountyId(countyId)),
})

const EditCountyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCountyComponent)

export default EditCountyContainer
