import * as services from "./services"
import { hearingsActions } from "./index"
import { ticketsThunks, ticketsSelectors } from "../../tickets/duck"
import { overlaysActions } from "../../overlays/duck"
import {
  NOTIFICATION_TYPES,
  FRONTEND_TICKET_TYPES,
  SPECIFIC_OVERLAYS_TYPE
} from "../../../constants"
import { ticketTools } from '../../../utilities'

const updateHearingsLists = () => (dispatch, getState) => {
  const state = getState()
  const HEARINGS = [
    FRONTEND_TICKET_TYPES.hearings.needsDate,
    FRONTEND_TICKET_TYPES.hearings.recent,
  ]
  HEARINGS.forEach(hearingType => {
    const selector = ticketsSelectors.allTicketsByClassification(hearingType)
    const ticketIds = selector(state).map(ticketTools.mapTicketToObjectIds)
    dispatch(hearingsActions.setHearings(hearingType, ticketIds))
  })
}

const fetchRecentHearingsItems = () => (dispatch) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.hearings.recent
  // dispatch(overlaysActions.showLoader())
  dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.getRecentHearings().then(results => {
    dispatch(ticketsThunks.addAndClassifyManyTickets(results))
    dispatch(hearingsActions.setHearingsRetrievedAt(TICKET_TYPE))
    // Status & Notifications
    // dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.success,
      title: `Fetched Recent Hearing Cases`,
      duration: 5
    }))
    dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
  }).catch(error => {
    dispatch(hearingsActions.setHearingsRetrievedAt(TICKET_TYPE))
    console.error('error fetching recent hearings', error)
    // dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.error,
      duration: 15,
      title: 'Issue Fetching Recent Hearings Cases',
      message: error
    }))
    dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
  })
}

const fetchNoDateHearingsItems = () => (dispatch) => {
  const TICKET_TYPE = FRONTEND_TICKET_TYPES.hearings.needsDate
  // dispatch(overlaysActions.showLoader())
  dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.getNoDateHearings().then(results => {
    dispatch(ticketsThunks.addAndClassifyManyTickets(results))
    dispatch(hearingsActions.setHearingsRetrievedAt(TICKET_TYPE))
    // Status and Notifications
    dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
    // dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.success,
      title: `Fetched Needs Hearing Date Cases`,
      duration: 5
    }))
  }).catch(error => {
    dispatch(hearingsActions.setHearingsRetrievedAt(TICKET_TYPE))
    // Status and Notifications
    console.error('error fetching no date hearings', error)
    dispatch(overlaysActions.setSpecificOverlayStatus(TICKET_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
    // dispatch(overlaysActions.hideLoader())
    dispatch(overlaysActions.addNotification({
      type: NOTIFICATION_TYPES.error,
      duration: 15,
      title: 'Issue Fetching Needs Hearing Cases',
      message: error
    }))
  })
}

export default {
  fetchRecentHearingsItems,
  fetchNoDateHearingsItems,
  updateHearingsLists
}
