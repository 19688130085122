export const createdTicketSelector = state => state.admin.createdTicket

export const createdAttorneySelector = state => state.admin.createdAttorney

export const unassignedActiveLeadsExportSelector = state => state.admin.unassignedActiveLeadsExport

export const closedTicketsExportSelector = state => state.admin.closedTicketsExport

export const lookupTicketsExportSelector = state => state.admin.lookupTicketsExport

