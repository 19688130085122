// Order of fields to be returned
// Ticket #, Violation Name, Points, Ticket Status, Lead Status, *Lead Status Date*^, Date Created, *Hearing Date*, *Name*, *Phone Number*, *Payment Method*, #Close Ticket#, Attorney Info, Court, CX Note
const dateUSFormat = (date) => {
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear().toString()
  return `${month}/${day}/${year}`
}

const resolvedDataFilterCSV = (data) => {
  return data.map(row => {
    const newRow = {}
    for (let key in row) {
      switch(key) {
        case 'ticketID':
        case 'objectId':
        case 'violationName':
        case 'points':
        case 'status':
        case 'leadStatus':
        case 'category':
        case 'agent':
        case 'cxNotes':
          newRow[key] = row[key]
          break
        case 'hearingDate':
          if (row[key]) {
            let hearingDate = new Date(row[key].iso)
            newRow.hearingDate = dateUSFormat(hearingDate)
          } else {
            newRow[key] = row[key]
          }
          break
        case 'createdAt':
          let createdAt = new Date(row[key])
          newRow.createdAt = dateUSFormat(createdAt)
          break
        case 'trafficCourt':
          newRow[key] = row[key].courtName
          break
        case 'attorney':
          newRow.attorneyName = row[key].name
          newRow.attorneyCompany = row[key].companyName
          break
        case 'assignedTo':
          newRow.assignedToName = row[key].name
          break
        case 'issueDate':
          let issueDate = new Date(row[key].iso)
          newRow.issueDate = dateUSFormat(issueDate)
          break
        case 'user':
          newRow.userName = row[key].name
          newRow.userPhoneNumber = row[key].phoneNumber
          newRow.userEmail = row[key].email
          newRow.userObjectId = row[key].objectId
          newRow.licenseID = row[key].licenseID
          newRow.firstName = row[key].firstName
          newRow.lastName = row[key].lastName
          let fullName = row[key].name.split(' ');
          newRow.firstName = fullName[0];
          newRow.lastName = fullName[fullName.length - 1];
          newRow.dob = row[key].dob
          newRow.last4ssn = row[key].last4ssn
          if (row[key].hasPaymentMethod) {
            newRow.hasPaymentMethod = 'true'
          } else if (!row[key].hasPaymentMethod) {
            newRow.hasPaymentMethod = 'false'
          }
          break
        default:
          break
      }
    }
    return newRow
  })
}

export default resolvedDataFilterCSV
