import { connect } from 'react-redux'
import { leadsActions, leadsSelectors } from '../duck'
import { authenticationSelectors } from '../../authentication/duck'
import LeadsListComponent from './LeadsListComponent'

const mapStateToProps = state => ({
  attorney: authenticationSelectors.attorneySelector(state),
  regionFilter: leadsSelectors.leadsRegionFilterSelector(state),
})

const mapDispatchToProps = dispatch => ({
  clearRegionFilter: () => dispatch(leadsActions.setLeadsRegionFilter(null)),
})

const LeadsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadsListComponent)

export default LeadsListContainer
