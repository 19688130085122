import types from './types'
import _ from 'lodash'

const INITIAL_STATE = {
  isLoading: false,
  notifications: [],
  specificOverlays: {},
  modal: null,
}
const overlaysReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SPECIFIC_OVERLAY_STATUS: {
      const specificOverlays = { ...state.specificOverlays }
      const { key, status } = action
      const target = _.get(specificOverlays, key, {})
      target.status = status
      _.set(specificOverlays, key, target)
      return {
        ...state,
        specificOverlays
      }
    }
    case types.SHOW_LOADER: {
      return {
        ...state,
        isLoading: true
      }
    }

    case types.HIDE_LOADER: {
      return {
        ...state,
        isLoading: false
      }
    }

    case types.SET_MODAL: {
      return {
        ...state,
        modal: action.modal
      }
    }

    case types.HIDE_MODAL: {
      return {
        ...state,
        modal: null
      }
    }

    case types.RESET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: []
      }
    }

    case types.ADD_NOTIFICATION: {
      const newNotifications = [...state.notifications]
      const newNotificationItem = { ...action.value }
      newNotificationItem.id = idGenerate()
      newNotifications.push(newNotificationItem)
      return {
        ...state,
        notifications: newNotifications
      }
    }

    case types.REMOVE_NOTIFICATION: {
      const { notifications } = state
      const newNotifications = notifications.length
        ? notifications.filter(notification => {
          if (
            action &&
            action.value &&
            action.value.id &&
            notification &&
            notification.id
          ) {
            return action.value.id !== notification.id
          }
          return false
        })
        : []
      return {
        ...state,
        notifications: newNotifications
      }
    }

    default:
      return state
  }
}

const now = () => {
  const now = new Date()
  return now
}

const idGenerate = () => {
  const time = now().getTime()
  const random = Math.random() * 100
  return `${time}${random}`
}

export default overlaysReducer
