const resolvedDataForProcessing = (data) => {
  return data.map(row => {
    const newRow = {}
    for (let key in row) {
      switch(key) {
        case 'ticketID':
        case 'violationName':
        case 'points':
        case 'status':
        case 'attorneyPrice':
        case 'winItPrice':
        case 'fineAmount':
          newRow[key] = row[key]
          break
        case 'attorney':
          newRow.attorneyName = row[key].name
          newRow.companyName = row[key].companyName
          break
        case 'user': 
          newRow.userName = row[key].name
          newRow.userEmail = row[key].email
          newRow.userPhone = row[key].phoneNumber
          break
        case 'trafficCourt':
         newRow[key] = row[key].courtName
        default:
          break
      }
    }
    return newRow
  })
}

export default resolvedDataForProcessing