import React, { Component } from 'react';

import { StyledElements } from '../../../common'
import ItemHeader from '../ItemHeader'
import TrafficBondList from './TrafficBondListWrapper'
import { specificOverlayTools } from '../../../../utilities'
import { SPECIFIC_OVERLAYS_CATEGORY } from '../../../../constants'


class EditTrafficBond extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      upToDate: true
    }
  }

  toggleExpansion = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  fetchTicket = async () => {
    const ticket = await this.props.fetchTicket(this.props.ticket.objectId)
    this.props.setCurrentlyViewing(ticket)
    this.setState({
      upToDate: true
    })
  }

  handleCreateTrafficBond = async () => {
    this.setState({ 
      isEditing: false,
      upToDate: false
    })
    await this.props.createTrafficBond(this.props.ticket)
  }

  render() {
    const bonds = this.props.ticket.trafficBonds
    const OVERLAY_KEY = specificOverlayTools.buildKey(
      this.props.ticket.objectId,
      SPECIFIC_OVERLAYS_CATEGORY.editTicket
    )
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="Traffic Bonds"
          isEditable
          isEditing={this.state.isEditing}
          toggleState={this.toggleExpansion}
          expandText="show"
          collapseText="hide"
        />
        {this.state.isEditing ? (
          <TrafficBondList 
            bonds={bonds}
            OVERLAY_KEY={OVERLAY_KEY}
            isEditing={this.state.isEditing}
            handleCreateTrafficBond={this.handleCreateTrafficBond}
            fetchTicket={this.fetchTicket}
            upToDate={this.state.upToDate}
          />
        ) : (
          <StyledElements.Italicize>
            See traffic bond history & charge bond.
          </StyledElements.Italicize>
        )}
      </StyledElements.DataContainer>
    );
  }
}

export default EditTrafficBond
