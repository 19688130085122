import React from 'react'
import { TextArea, Form, Input } from 'semantic-ui-react'
import {TRAFFIC_STATES_URL_SLUGS} from '../../../constants'

import { StyledElements, Forms } from '../../common'
import SpecificOverlayContainer from '../../overlays/SpecificOverlay/SpecificOverlayContainer'
import {
  LoadingComponent,
  UpdatingLargeComponent,
} from '../../tickets/DetailSection/ticketOverlays'

class SendTrafficSubmissionMagicLinkComponent extends React.Component {
  constructor(props) {
    super(props)
    const messageText = props.ticket.ticketID ? `traffic ticket ID: ${props.ticket.ticketID}` : "your traffic ticket"

    this.state = {
      header: `Hello ${props.ticket.user.name},`,
      message: `You can use this link to complete your submission for ${messageText}. Feel free to reach out to us if you have any questions.`,
    }
    this.headerInput = React.createRef()
    this.messageInput = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.messageInput.focus()
    }
  }

  componentDidMount = () => {
    if (!Object.keys(TRAFFIC_STATES_URL_SLUGS).includes(this.props.ticket.locationState)) {
      this.props.showUnavailableStateToast(this.props.ticket.locationState)
    }
  }

  get canSend() {
    const { header, message } = this.state
    if (!header || !header.trim().length) {
      return false
    }
    if (!message || !message.trim().length) {
      return false
    }
    if (!Object.keys(TRAFFIC_STATES_URL_SLUGS).includes(this.props.ticket.locationState)) {
      return false
    }
    return true
  }

  handleCancel = event => {
    if (event && event.preventDefault) event.preventDefault()
    this.props.toggleState()
    return false
  }

  handleChange = key => event => {
    // if (event && event.preventDefault) event.preventDefault()
    const { value } = event.target
    const newState = {
      ...this.state,
    }
    newState[key] = value
    this.setState(newState)
  }

  handleSubmit = event => {
    if (event && event.preventDefault) event.preventDefault()
    this.props.toggleState()
    this.props.send(this.props.ticket, this.state.header, this.state.message)
  }

  renderEdit = () => {
    const { ticket } = this.props
    return (
      <StyledElements.EditContainer>
        <div>
          The magic link will be sent to this users email address (
          <b>{ticket.user.email}</b>). The ability for the link to sign in the
          user without a username or password is only valid for 7 days. The
          email subject is: <b>The link you requested</b> and will include a
          blue button that says <b>Complete Your Traffic Ticket Submission</b>.
        </div>
        <StyledElements.Spacer height="10px" />
        <Form onSubmit={this.handleSubmit}>
          <h4>Header</h4>
          <Input
            ref={input => {
              this.headerInput = input
            }}
            name="Header"
            onChange={this.handleChange('header')}
            value={this.state.header}
            placeholder="Header for your message"
            fluid
          />
          <h4>Message</h4>
          <TextArea
            ref={input => {
              this.messageInput = input
            }}
            autoHeight
            onChange={this.handleChange('message')}
            value={this.state.message}
            placeholder="A message to include to the user..."
            rows={6}
          />
        </Form>
        <Forms.InlineEditButtons
          buttons={[
            {
              onClick: this.handleCancel,
              color: 'red',
              labelPosition: 'right',
              icon: 'close',
              text: 'Cancel Changes',
            },
            {
              onClick: this.handleSubmit,
              disabled: !this.canSend,
              color: 'green',
              labelPosition: 'right',
              icon: 'send',
              text: 'Send Email',
              type: 'submit',
            },
          ]}
        />
      </StyledElements.EditContainer>
    )
  }
  render() {
    const RenderEdit = this.renderEdit
    return (
      <SpecificOverlayContainer
        overlayKey={this.props.objectId}
        LoadingComponent={LoadingComponent}
        ignoreError={true}
        UpdatingComponent={UpdatingLargeComponent}
      >
        <RenderEdit />
      </SpecificOverlayContainer>
    )
  }
}

export default SendTrafficSubmissionMagicLinkComponent
