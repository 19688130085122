import { connect } from 'react-redux'
import { attorneySelector } from '../../../authentication/duck/selectors'
import { ticketByIdSelector } from '../../duck/selectors'
import { ticketsThunks } from '../../duck'
import EditNewFineAmountComponent from './EditNewFineAmountComponent'
import { overlaysActions } from "../../../overlays/duck"
import { NOTIFICATION_TYPES } from "../../../../constants"

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  attorney: attorneySelector(state)
})

const mapDispatchToProps = dispatch => ({
  update: (objectId, updateObject) => dispatch(ticketsThunks.updateTicket(objectId, updateObject)),
  error: (message) => dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.error, duration: 15, title: message }))
})

const EditNewFineAmountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNewFineAmountComponent)

export default EditNewFineAmountContainer
