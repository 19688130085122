import { connect } from 'react-redux'
import { ticketsSelectors } from '../../duck'
import { ticketsThunks } from '../../duck'
import { overlaySelectors } from '../../../overlays/duck'
import { authenticationSelectors } from '../../../authentication/duck'
import {
  SPECIFIC_OVERLAYS_CATEGORY,
  ADDITIONAL_TICKET_FIELD,
} from '../../../../constants'
import { specificOverlayTools } from '../../../../utilities'

import ViewPortalEditHistoryComponent from './ViewPortalEditHistoryComponent'

const mapStateToProps = (state, props) => {
  const OVERLAY_KEY = specificOverlayTools.buildKey(
    props.objectId,
    SPECIFIC_OVERLAYS_CATEGORY.portalEditHistory
  )
  const portalEditHistory = ticketsSelectors.ticketFieldById(
    state,
    props.objectId,
    ADDITIONAL_TICKET_FIELD.portalEditHistory
  )
  return {
    OVERLAY_KEY,
    ticket: ticketsSelectors.ticketByIdSelector(state, props.objectId),
    portalEditHistory: portalEditHistory
      ? portalEditHistory.editHistory
      : undefined,
    attorney: authenticationSelectors.attorneySelector(state),
    retrievedAt: portalEditHistory ? portalEditHistory.retrievedAt : undefined,
    loading: overlaySelectors.specificOverlayLoadingStatusSelector(
      state,
      OVERLAY_KEY
    ),
  }
}

const mapDispatchToProps = dispatch => ({
  fetch: objectId =>
    dispatch(ticketsThunks.fetchTicketPortalEditHistory(objectId)),
})

const ViewPortalEditHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPortalEditHistoryComponent)

export default ViewPortalEditHistoryContainer
