import types from './types';
import activationSteps from '../activationSteps'

const INITIAL_STATE = {
  step: activationSteps.code,
  code: '',
  user: null,
  attorney: null
}
const activationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case types.SET_STEP: {
      const { value } = action;
      return {
        ...state,
        step: value
      }
    }

    case types.SET_CODE: {
      const { value } = action;
      return {
        ...state,
        code: value
      }
    }

    case types.SET_USER: {
      const { value } = action;
      return {
        ...state,
        user: value
      }
    }

    case types.SET_ATTORNEY: {
      const { value } = action;
      return {
        ...state,
        attorney: value
      }
    }

    default: return state;
  }
}

export default activationReducer;
