import React, { Component } from 'react'
import { Input, Form } from 'semantic-ui-react'

import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import { StyledElements, Forms } from '../../../common'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer'

class EditViolationNameComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
    this.nameInput = React.createRef()
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.nameInput.inputRef.focus()
    }
  }
  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }
  handleChange = event => {
    const { objectId } = this.props
    const violationName = event.target.value
    this.props.editUpdate(objectId, violationName)
  }
  updateViolationName = () => {
    const { objectId, editTicketField, ticket } = this.props
    this.setState({ isEditing: false })
    if (editTicketField !== ticket.violationName) {
      this.props.update(objectId, { violationName: editTicketField })
    }
  }
  renderEdit = () => {
    const { editTicketField, ticket } = this.props
    return (
      <StyledElements.EditContainer>
        <Form>
          <Input
            fluid
            ref={input => {
              this.nameInput = input
            }}
            onChange={this.handleChange}
            value={
              editTicketField === null ? ticket.violationName : editTicketField
            }
            placeholder={ticket.violationName || 'Violation Name'}
          />
          <Forms.InlineEditButtons
            buttons={[
              {
                color: 'green',
                text: 'Update Violation Name',
                icon: 'save',
                labelPosition: 'right',
                type: 'submit',
                onClick: this.updateViolationName,
              },
            ]}
          />
        </Form>
      </StyledElements.EditContainer>
    )
  }
  render() {
    const RenderEdit = this.renderEdit
    return (
      <StyledElements.DataContainer isEditing={this.state.isEditing}>
        <ItemHeader
          title="Violation Name"
          isEditable={true}
          isEditing={this.state.isEditing}
          toggleState={this.toggleStatus}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {this.state.isEditing ? (
            <RenderEdit />
          ) : this.props.ticket.violationName ? (
            this.props.ticket.violationName
          ) : (
            <StyledElements.Italicize>
              Violation Not Set
            </StyledElements.Italicize>
          )}
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    )
  }
}

export default EditViolationNameComponent
