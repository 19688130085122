import { connect } from 'react-redux'
import { ticketByIdSelector } from '../../duck/selectors'
import { ticketsThunks } from '../../duck'
import ViewTicketEvidenceComponent from './ViewTicketEvidenceComponent'
import { ADDITIONAL_TICKET_FIELD } from '../../../../constants'

const mapStateToProps = (state, props) => ({
  ticket: ticketByIdSelector(state, props.objectId),
  evidence: ticketByIdSelector(state, props.objectId)[
    ADDITIONAL_TICKET_FIELD.evidence
  ],
})

const mapDispatchToProps = dispatch => ({
  fetchEvidence: objectId =>
    dispatch(ticketsThunks.fetchTicketEvidence(objectId)),
})

const ViewTicketEvidenceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewTicketEvidenceComponent)

export default ViewTicketEvidenceContainer
