import React, { Component } from 'react';
import { Grid, Header, Image } from 'semantic-ui-react'
import styled from 'styled-components'
import { Helmet } from "react-helmet";

import { FillHeight } from '../common'
import AttorneyLogo from '../../assets/images/navigation-bar-logo-portal.svg'
import activationSteps from './activationSteps'

import EnterCode from './Code/EnterCodeContainer'
import Account from './Account/AccountContainer'
import AttorneyInfo from './Attorney/AttorneyInfoContainer';
import Confirm from './Confirm/ConfirmContainer'
import Complete from './Complete/CompleteContainer'

const LoginImage = styled(Image)`
  max-height: 100px;
`

class ActivationComponent extends Component {
  componentDidMount() {
    const { code } = this.props.match.params
    if (code) {
      this.props.setCode(code)
    }
  }
  renderStep = () => {
    switch (this.props.step) {
      default:
      case activationSteps.code:
        return <EnterCode />
      case activationSteps.user:
        return <Account />
      case activationSteps.attorney:
        return <AttorneyInfo />
      case activationSteps.confirm:
        return <Confirm />
      case activationSteps.complete:
        return <Complete />
    }
  }
  render() {
    const RenderStep = this.renderStep
    return (
      <FillHeight justifyContent="center">
        <Helmet title="Activate New Attorney Account - WinIt" />
        <Grid
          style={{ height: '100%' }}
          textAlign="center"
          verticalAlign="middle"
        >
          <Grid.Row>
            <Grid.Column>
              <LoginImage src={AttorneyLogo} />
              <Header as="h2" textAlign="center">
                New Attorney
              </Header>
            </Grid.Column>

          </Grid.Row>
          <Grid.Row>
            <Grid.Column style={{ maxWidth: '450px' }}>
              <RenderStep />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </FillHeight>
    );
  }
}

export default ActivationComponent;
