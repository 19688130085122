import { connect } from 'react-redux'
import {
  recentHearingsSelector,
  recentHearingsRetrievedAtSelector,
  hearingsRegionFilterSelector
} from '../duck/selectors'
import { regionsSelectors } from '../../regions/duck'
import { overlaySelectors } from '../../overlays/duck'
import { hearingsThunks } from '../duck'
import HearingsListContainer from './HearingsListContainer'
import { FRONTEND_TICKET_TYPES } from '../../../constants'

const TICKET_TYPE = FRONTEND_TICKET_TYPES.hearings.recent
const mapStateToProps = state => ({
  pageType: TICKET_TYPE,
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(state, TICKET_TYPE),
  hearings: regionsSelectors.regionsFilterBuilder(
    recentHearingsSelector,
    hearingsRegionFilterSelector
  )(state),
  retrievedAt: recentHearingsRetrievedAtSelector(state),
  regionFilter: hearingsRegionFilterSelector(state)
})

const mapDispatchToProps = dispatch => ({
  fetchHearings: () => dispatch(hearingsThunks.fetchRecentHearingsItems())
})

const RecentHearingsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HearingsListContainer)

export default RecentHearingsListContainer
