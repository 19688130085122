import types from './types'

const INITIAL_STATE = {
  attorneys: {
    items: [],
    retrievedAt: null
  }
}
const attorneysReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_ALL_ATTORNEYS: {
      const items = action.attorneys
      const retrievedAt = new Date()
      const attorneys = { items, retrievedAt }
      return {
        ...state,
        attorneys
      }
    }

    default:
      return state
  }
}

export default attorneysReducer
