import buildCalls from './calls'
import buildHearing from './hearing'
import buildLeads from './leads'
import buildLeadsActive from './leadsActive'
import buildLeadsClosed from './leadsClosed'
import buildLeadsClosedTickets from './leadsClosedTickets'
import buildLeadsUnassigned from './leadsUnassigned'
import buildProcessing from './processing'
import buildProcessingRecentlyApproved from './processingRecentlyApproved'
import buildSearchTable from './ticketSearch'
import buildLookupColumns from './lookup'
import buildChatColumns from './chat'
import buildLookupTickets from './viewLookupTickets'

export default {
  buildCalls,
  buildHearing,
  buildLeads,
  buildLeadsActive,
  buildLeadsClosed,
  buildLeadsUnassigned,
  buildLeadsClosedTickets,
  buildProcessing,
  buildProcessingRecentlyApproved,
  buildSearchTable,
  buildChatColumns,
  buildLookupColumns,
  buildLookupTickets,
}
