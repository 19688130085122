import types from './types'
import { FRONTEND_TICKET_TYPES } from '../../../constants'
import moment from 'moment'

const INITIAL_STATE = {
  [FRONTEND_TICKET_TYPES.processing.all]: {
    items: [],
    retrievedAt: null
  },
  [FRONTEND_TICKET_TYPES.processing.recentlyApproved]: {
    items: moment().format("L")
  },
  [FRONTEND_TICKET_TYPES.processing.suspended]: {
    items: [],
    retrievedAt: null
  },
  [FRONTEND_TICKET_TYPES.processing.requiresApproval]: {
    items: [],
    retrievedAt: null
  },
  [moment().format("L")]: {
    items: [],
    retrievedAt: null,
  },
  currentlyViewing: null,
  regionFilter: null,
}
const processingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RESET: {
      return INITIAL_STATE
    }

    case types.SET_PROCESSING_REGION_FILTER: {
      return {
        ...state,
        regionFilter: action.value
      }
    }

    case types.SET_RECENTLY_APPROVED_CATEGORY: {
      const { value } = action
      const newState = { ...state }
      if (!state[value]) {
        newState[value] = {
          items: [],
          retrievedAt: null
        }
      }
      newState[FRONTEND_TICKET_TYPES.processing.recentlyApproved].items = value
      return newState
    }

    case types.SET_PROCESSING: {
      const { value, processingType } = action
      const newProcessingType = { ...state[processingType] }
      newProcessingType.items = value
      return {
        ...state,
        [processingType]: newProcessingType
      }
    }

    case types.SET_PROCESSING_RETRIEVED_AT: {
      const { processingType } = action
      const newProcessingType = { ...state[processingType] }
      newProcessingType.retrievedAt = new Date()
      return {
        ...state,
        [processingType]: newProcessingType
      }
    }

    case types.SET_SUSPENDED_CASES: {
      const { value, processingType } = action
      const newProcessingType = { ...state[processingType] }
      newProcessingType.items = value
      return {
        ...state,
        [processingType]: newProcessingType
      }
    }

    case types.SET_REQUIRES_APPROVAL_CASES: {
      const { value, processingType } = action
      const newProcessingType = { ...state[processingType] }
      newProcessingType.items = value
      return {
        ...state,
        [processingType]: newProcessingType
      }
    }

    case types.SET_CURRENTLY_VIEWING: {
      const { value } = action
      return {
        ...state,
        currentlyViewing: value
      }
    }

    default:
      return state
  }
}

export default processingReducer
