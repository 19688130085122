import { connect } from 'react-redux'

import {lookupSelectors } from './duck'
import { attorneyIsAdminSelector } from '../../app/authentication/duck/selectors'
import LookupComponent from './LookupComponent'


const mapStateToProps = state => ({
  isAdmin: attorneyIsAdminSelector(state),
  allContactInfo: lookupSelectors.allContactInfoSelector(state),
  filteredContactInfo: lookupSelectors.hearingDateFilteredContactInfoSelector(state)
})

const LookupContainer = connect(
  mapStateToProps
)(LookupComponent)

export default LookupContainer
