export const objectToArray = (object) => {
  if (!object || typeof object !== 'object') {
    return []
  }
  const keys = Object.keys(object)
  return keys.map(key => object[key])
}

export const reverseObject = object => {
  const newObject = {}
  for (let key in object) {
    const newKey = object[key]
    newObject[newKey] = key
  }
  return newObject
}
