import moment from 'moment'

const delayedResponse = (invokeFunction, minimumTime) => new Promise((resolve, reject) => {
  const requestStartTime = moment();
  invokeFunction().then(result => {
    buildTimeout(() => {
      resolve(result)
    }, requestStartTime, minimumTime)
  }).catch(error => {
    buildTimeout(() => {
      reject(error)
    }, requestStartTime, minimumTime)
  })
})

const buildTimeout = (invokeFunction, startTime, minimumTime) => {
  const endTime = moment();
  const elapsedTime = endTime.diff(startTime);
  if (elapsedTime < minimumTime) {
    return setTimeout(() => {
      invokeFunction()
    }, minimumTime - elapsedTime);
  }
  return invokeFunction()
}

export default delayedResponse
