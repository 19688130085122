import { connect } from 'react-redux'
import { attorneyStatsSelector } from '../duck/selectors'
import { attorneySelector } from '../../authentication/duck/selectors'
import { profileThunks } from '../duck'
import AttorneyStatsComponent from './AttorneyStatsComponent'

const mapStateToProps = (state, props) => ({
  attorneyStats: attorneyStatsSelector(state),
  attorney: attorneySelector(state)
})

const mapDispatchToProps = dispatch => ({
  fetchAttorneyStats: () => dispatch(profileThunks.fetchAttorneyStats()),
})

const AttorneyStatsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AttorneyStatsComponent)

export default AttorneyStatsContainer
