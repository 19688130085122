import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import NotFoundPage from "../404NotFound"
import AllProcessingItems from "./AllProcessingList/AllProcessingItems"
import ViewProcessingTicket from "./ViewProcessingTicket/ViewProcessingTicketContainer"
import RecentlyApprovedProcessingListContainer from './RecentlyApprovedProcessingList/RecentlyApprovedProcessingListContainer'
import SuspendedProcessingListContainer from './SuspendedProcessingList/SuspendedProcessingListContainer'
import RequiresApprovalListContainer from './RequiresApprovalList/RequiresApprovalListContainer'

class ProcessingRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/processing/requiresApproval" component={RequiresApprovalListContainer} />
        <Route exact path="/processing/suspensions" component={SuspendedProcessingListContainer} />
        <Route exact path="/processing/recent" component={RecentlyApprovedProcessingListContainer} />
        <Route exact path="/processing/all" component={AllProcessingItems} />
        <Route path="/processing/view/:id" component={ViewProcessingTicket} />
        <Route path="*" component={NotFoundPage} status={404} />
      </Switch>
    )
  }
}

export default ProcessingRoutes
