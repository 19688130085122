import React, { Component, Fragment } from 'react';
import { Icon, Header, Button, Modal } from 'semantic-ui-react'
import { colors } from '../../../../theme/colors'
import { StyledElements } from '../../../common'
import { TICKET_STATUS, TICKET_STATUS_MAP } from '../../../../constants'
import { LoadingComponent, UpdatingComponent } from '../ticketOverlays'
import ItemHeader from '../ItemHeader'
import SpecificOverlayContainer from '../../../overlays/SpecificOverlay/SpecificOverlayContainer';

class ViewPaymentStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showConfirmation: false
    }
  }

  chargeUser = () => {
    this.props.charge(this.props.ticket.objectId)
    this.setState({ showConfirmation: false })
  }

  toggleConfirmation = () => this.setState({ showConfirmation: !this.state.showConfirmation })

  RenderConfirmation = () => {
    return <Modal size="small" open={this.state.showConfirmation} onClose={this.close}>
      <Modal.Header><Header icon='warning' content='Confirm Charge' /> </Modal.Header>
      <Modal.Content>
        <h4>
          You are about to the charge {this.props.ticket.user.name} ${this.props.ticket.winItPrice}.
        </h4>
        <p>
          Have you confirmed this charge with the user?
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.toggleConfirmation} negative>
          No
        </Button>
        <Button
          onClick={this.chargeUser}
          disabled={this.props.isLoading}
          color='green'
          positive
        >
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  }

  RenderStatus = () => {
    const RenderOptions = this.RenderOptions
    switch (this.props.ticket.paymentStatus) {
      case 'good':
        return <span style={{ color: colors.success.color }}>
          <Icon name="checkmark" />Paid
        </span>
      default:
        return <Fragment>
          <span style={{ color: colors.error.color }}>
            <Icon name="x" />Not Paid
          </span>
          <StyledElements.Divider />
          <RenderOptions />
        </Fragment>
    }
  }

  RenderOptions = () => {
    switch (this.props.ticket.paymentStatus) {
      case 'good':
        return <span />
      default:
      case undefined:
        if (this.props.ticket.user.hasPaymentMethod) {
          if (this.props.ticket.status === TICKET_STATUS.pendingConf) {
            if (this.props.ticket.winItPrice) {
              return <StyledElements.FakeLink onClick={this.toggleConfirmation}>
                Charge User
            </StyledElements.FakeLink>
            }
            return <StyledElements.Italicize>
              Set a price to charge the user
            </StyledElements.Italicize>
          }
          return <StyledElements.Italicize color={colors.darkText}>
            Can only charge tickets with a {TICKET_STATUS_MAP[TICKET_STATUS.pendingConf]} status.
          </StyledElements.Italicize>
        }
        if (this.props.ticket.status !== TICKET_STATUS.pendingConf) {
          return <StyledElements.Italicize color={colors.darkText}>
            Can only charge tickets with a {TICKET_STATUS_MAP[TICKET_STATUS.pendingConf]} status.
          </StyledElements.Italicize>
        }
        return <StyledElements.Italicize color={colors.darkText}>
          No payment method on file.
        </StyledElements.Italicize>
    }
  }

  render() {
    const RenderStatus = this.RenderStatus
    const RenderConfirmation = this.RenderConfirmation
    return (
      <StyledElements.DataContainer>
        <ItemHeader
          title={"Payment Status"}
          isEditable={false}
          isEditing={false}
          toggleState={() => { }}
        />
        <SpecificOverlayContainer
          overlayKey={this.props.objectId}
          LoadingComponent={LoadingComponent}
          ignoreError={true}
          UpdatingComponent={UpdatingComponent}
        >
          {
            !this.props.ticket.user ?
              <StyledElements.Italicize>
                No user attached to ticket.
              </StyledElements.Italicize>
              :
              <span>
                <RenderStatus />
                <RenderConfirmation />
              </span>
          }
        </SpecificOverlayContainer>
      </StyledElements.DataContainer>
    );
  }
};

export default ViewPaymentStatus;
