import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ButtonContainer = styled.div`
  ${props => (props.fitted ? '' : 'margin: 10px 0 0 0;')}
`

const InlineEditButtons = ({ buttons, fitted }) => {
  return (
    <ButtonContainer fitted={fitted}>
      <Button.Group size="tiny" fluid={true}>
        {buttons.map(button => {
          const props = { ...button }
          props.key = button.text
          if (!props.type) props.type = 'button'
          if (props.icon) {
            props.icon = true
          } else {
            props.icon = false
          }
          return (
            <Button {...props}>
              {props.icon ? <Icon name={button.icon} fitted /> : null}
              {button.text}
            </Button>
          )
        })}
      </Button.Group>
    </ButtonContainer>
  )
}

export default InlineEditButtons

InlineEditButtons.defaultProps = {
  buttons: [],
}

InlineEditButtons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string,
      labelPosition: PropTypes.string.isRequired,
    })
  ).isRequired,
}
