import React from 'react'

import { StyledElements } from '../../../common'
import ItemHeader from '../ItemHeader'

const ViewAssigneeComponent = ({assignee}) => {
  return (
    <StyledElements.DataContainer>
      <ItemHeader
        title={'Assigned to'}
        isEditable={false}
        isEditing={false}
        toggleState={() => {}}
      />

      {assignee ? (
        <StyledElements.Italicize>{assignee}</StyledElements.Italicize>
      ) : (
        <StyledElements.Italicize>No one assigned</StyledElements.Italicize>
      )}
    </StyledElements.DataContainer>
  )
}

export default ViewAssigneeComponent
