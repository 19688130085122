import * as services from "./services"
import { regionsActions } from "./index"
import { overlaysActions } from "../../overlays/duck"
import { NOTIFICATION_TYPES, SPECIFIC_OVERLAYS_TYPE, SPECIFIC_OVERLAYS_ITEMS } from "../../../constants"

const fetchRegions = () => (dispatch) => {
  const OVERLAY_TYPE = SPECIFIC_OVERLAYS_ITEMS.trafficRegions
  dispatch(overlaysActions.setSpecificOverlayStatus(OVERLAY_TYPE, SPECIFIC_OVERLAYS_TYPE.loading))
  services.getRegions().then(results => {
    dispatch(regionsActions.setRegions(results))
    dispatch(overlaysActions.setSpecificOverlayStatus(OVERLAY_TYPE, SPECIFIC_OVERLAYS_TYPE.success))
  }).catch(error => {
    dispatch(overlaysActions.setSpecificOverlayStatus(OVERLAY_TYPE, SPECIFIC_OVERLAYS_TYPE.error))
    console.error('error fetching regions', error)
    dispatch(overlaysActions.addNotification({ type: NOTIFICATION_TYPES.error, duration: 15, title: 'Issue Fetching Regions', message: error }))
  })
}

export default {
  fetchRegions,
}
