import { connect } from 'react-redux'
import { SPECIFIC_OVERLAYS_ITEMS } from '../../../constants'
import { attorneySelectors, attorneyThunks } from '../../attorney/duck'
import { countiesThunks } from '../../counties/duck'
import { countiesSelector } from '../../counties/duck/selectors'
import { courtsThunks } from '../../courts/duck'
import { courtsSelector } from '../../courts/duck/selectors'
import { overlaySelectors } from '../../overlays/duck'
import LinkGeneratorComponent from './LinkGeneratorComponent'

const mapStateToProps = state => ({
  attorneys: attorneySelectors.attorneys(state),
  counties: countiesSelector(state),
  courts: courtsSelector(state),
  retrievedAt: attorneySelectors.retrievedAt(state),
  loading: overlaySelectors.specificOverlayLoadingStatusSelector(
    state,
    SPECIFIC_OVERLAYS_ITEMS.attorneys
  ),
})


const mapDispatchToProps = dispatch => ({
  fetchAttorneys: () => dispatch(attorneyThunks.fetchAttorneys()),
  fetchCounties: (stateShortName) => dispatch(countiesThunks.fetchCountiesByStateShortName(stateShortName)),
  fetchCourts: (countyId) => dispatch(courtsThunks.fetchCourtsByCountyId(countyId)),
})


const LinkGeneratorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkGeneratorComponent)

export default LinkGeneratorContainer
