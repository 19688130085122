export const editFieldById = (state, objectId, key) => {
  const editUser = state.users.editItems[objectId]
  return editUser ? editUser[key] : null
}

export const userById = (state, objectId) => {
  return state.users.items[objectId]
}

export const isEditingField = (state, objectId, key) => {
  return state.users.isEditing[objectId] && state.users.isEditing[objectId][key]
}

export const idFromRouter = (state, props) => props.match.params.id
