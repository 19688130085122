const exportHeaderOrderCSV = [
  { label: "Ticket #", key: "ticketID" },
  { label: "Violation Name", key: "violationName" },
  { label: "Points", key: "points" },
  { label: "Ticket Status", key: "status" },
  { label: "Hearing Date", key: "hearingDate" },
  { label: "Issue Date", key: "issueDate" },
  { label: "Name", key: "userName" },
  { label: "Phone", key: "userPhoneNumber" },
  { label: "Email", key: "userEmail" },
  { label: `Has Payment Method`, key: "hasPaymentMethod" },
  { label: `Attorney Name`, key: "attorneyName" },
  { label: `Traffic Court`, key: "trafficCourt" },
  { label: `Lead Status`, key: "leadStatus" },
  { label: "Agent", key: "agent" },
  { label: "User Object ID", key: "userObjectId" }, // USER OBJECT ID
  { label: "CX Notes", key: "cxNotes" },
  { label: `Created At`, key: "createdAt" },
  { label: "Category", key: "category" },
  { label: "License ID", key: "licenseID" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Dob", key: "dob" },
  { label: "Last 4 SSN", key: "last4ssn" },
]

export default exportHeaderOrderCSV
