import React, { Component } from 'react'
import moment from 'moment'
import { List } from 'semantic-ui-react'
// import { List, Icon } from "semantic-ui-react"
import styled from 'styled-components'

const HoverFadeIn = styled(List)`
  &&&&& {
    opacity: 0.9;
    transition: 0.5s;
    &:hover {
      opacity: 1;
    }
  }
`

class LeadsListToolbarComponent extends Component {
  constructor(props) {
    super(props)
    const canRefresh = this.canRefresh(props)
    this.state = {
      lastButtonPress: null,
      canRefresh,
      interval: null,
    }
  }
  componentDidMount() {
    if (!this.state.interval) {
      this.updateInterval()
      this.setupInterval()
    }
  }
  componentWillUnmount() {
    this.cancelInterval()
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshedAt !== this.props.refreshedAt) {
      this.updateInterval(nextProps)
    }
  }
  canRefresh = props => {
    const now = moment()
    const pressedAt = moment(props ? props.refreshedAt : this.props.refreshedAt)
    return now.diff(pressedAt, 'minutes') >= 1
  }
  updateInterval = newProps => {
    if (newProps || this.props.refreshedAt) {
      const lastButtonPress = moment(
        newProps ? newProps.refreshedAt : this.props.refreshedAt
      ).fromNow()
      const canRefresh = this.canRefresh(newProps ? newProps : this.props)
      this.setState({ lastButtonPress, canRefresh })
    }
  }
  setupInterval = () => {
    const interval = setInterval(this.updateInterval, 60000)
    this.setState({ interval })
  }
  cancelInterval() {
    clearInterval(this.state.interval)
    this.setState({ interval: null })
  }
  render() {
    const { totalItems, pageSize, totalPages, currentPage, items, download, getCSVData, update } = this.props
    let calculatedTableItems;
    if (pageSize > totalItems) {
      calculatedTableItems = totalItems + ' Shown'
    } else if (currentPage && currentPage === totalPages && totalItems % pageSize !== 0) {
      calculatedTableItems = totalItems % pageSize + ' Shown'
    } else {
      calculatedTableItems = pageSize + ' Shown'
    }
    return (
      <div>
        <HoverFadeIn bulleted horizontal>
          {items
            ? items.map(item => (
                <List.Item key={`${item.icon}+${item.text}`}>
                  <List.Content>
                    <List.Header>
                      {/* item.icon ? <Icon name={item.icon} /> : '' */}{' '}
                      {item.text}
                    </List.Header>
                  </List.Content>
                </List.Item>
              ))
            : null}
            {pageSize ? 
                <List.Item>
                  <List.Content>
                    <List.Header>
                      {calculatedTableItems}
                    </List.Header>
                  </List.Content>
                </List.Item>
            : null}
          {download ? (
            <List.Item>
              <List.Content>
                <List.Header as="a" onClick={download}>
                  {/* <Icon name="file pdf" />  */}
                  Download PDF
                </List.Header>
              </List.Content>
            </List.Item>
          ) : (
            ''
          )}
          {getCSVData ? (
            <List.Item>
              <List.Content>
                <List.Header as="a" onClick={getCSVData}>
                  Download CSV
                </List.Header>
              </List.Content>
            </List.Item>
          ) : (
            ''
          )}
        </HoverFadeIn>
        <HoverFadeIn floated="right" bulleted horizontal>
          {this.state.lastButtonPress ? (
            <List.Item>
              <List.Content>
                <List.Header>
                  {/* <Icon name="time" /> */}
                  Retrieved {this.state.lastButtonPress}
                </List.Header>
              </List.Content>
            </List.Item>
          ) : (
            ''
          )}
          {this.state.canRefresh ? (
            <List.Item>
              <List.Content>
                <List.Header as="a" onClick={update}>
                  {/* <Icon loading={this.props.isLoading} name="refresh" /> */}
                  Refresh
                </List.Header>
              </List.Content>
            </List.Item>
          ) : (
            ''
          )}
        </HoverFadeIn>
      </div>
    )
  }
}

export default LeadsListToolbarComponent
