import { connect } from 'react-redux'

import { attorneyIsAdminSelector } from '../../../app/authentication/duck/selectors'
import CreateAttorneyComponent from './CreateAttorneyComponent'
import thunks from '../duck/thunks'
import { createdAttorneySelector } from '../duck/selectors'


const mapStateToProps = state => ({
  isAdmin: attorneyIsAdminSelector(state),
  createdAttorney: createdAttorneySelector(state)
})

const mapDispatchToProps = dispatch => ({
  createAttorney: (attorneyInfo) => dispatch(thunks.createAttorney(attorneyInfo))
})

const CreateAttorneyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAttorneyComponent)

export default CreateAttorneyContainer
