import React from 'react'
import { withRouter } from 'react-router-dom'
import { Input, Message, Form } from 'semantic-ui-react'

// import { stringTools } from '../../utilities'
import { SEARCH_TYPE } from './SearchTypes'

class PhoneNumberSearchComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: '',
      error: '',
    }
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    if (this.inputRef && this.inputRef.current && this.inputRef.current.focus) {
      this.inputRef.current.focus()
    }
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value })
  }

  handleSubmit = event => {
    if (event && event.preventDefault) event.preventDefault()
    const phoneNumber = this.state.phoneNumber.trim()

    if (phoneNumber.length < 5) {
      return this.setState({
        error: 'Phone number must be 5 numeric characters.',
      })
    } else {
      this.setState({ error: '' })
    }

    return this.props.history.push(
      `/search/results?searchType=${
        SEARCH_TYPE.phoneNumber
      }&phoneNumber=${phoneNumber}`
    )
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit} error={!!this.state.error.length}>
        <Message error content={this.state.error} />
        <Form.Group grouped>
          <Input
            name="phoneNumber"
            onChange={this.handleChange}
            size="huge"
            fluid
            placeholder="207456789"
            value={this.state.phoneNumber}
            ref={this.inputRef}
          />
        </Form.Group>
        <Form.Group>
          <Form.Button
            size="huge"
            content="Search"
            onClick={this.handleSubmit}
          />
        </Form.Group>
      </Form>
    )
  }
}

export default withRouter(PhoneNumberSearchComponent)
