import { axios } from '../../../utilities'

export const getUser = objectId => {
  return axios.secure.get(`/user-data/${objectId}`).then(res => res.data)
}

export const createUser = data => {
  return axios.secure.post(`/user-data`, data).then(res => res.data)
}

export const updateUser = (objectId, updatesObject) => {
  return axios.secure
    .put(`/user-data/${objectId}`, updatesObject)
    .then(res => res.data)
}

export const checkUserPaymentMethod = objectId => {
  return axios.secure
    .get(`payments/paymentMethodUpdate/${objectId}`)
    .then(res => res.data)
}

export const isUserBlocked = objectId => {
  return axios.secure.get(`/user-data/isUserBlocked/${objectId}`).then(res => res.data)
}
