import moment from 'moment'

const dateFilter = (filter, row) => {
  if (!filter || !filter.value) return true
  if (!row[filter.id]) return false
  const time = moment(row[filter.id]).startOf('day')
  const start = filter.value.startOf('day')
  return time.isSame(start);
}

export default dateFilter
