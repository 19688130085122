import { ticketsSelectors } from '../../tickets/duck'
import { FRONTEND_TICKET_TYPES } from '../../../constants'
const HEARING_TYPES = FRONTEND_TICKET_TYPES.hearings

// get current region filter
export const hearingsRegionFilterSelector = state => state.hearings.regionFilter

// get currently viewing hearing
export const currentlyViewingSelector = state => state.hearings.currentlyViewing

// get array of ids for recent hearings
export const recentHearingsIdsSelector = state => state.hearings[HEARING_TYPES.recent].items
// get recent hearings retrieved at
export const recentHearingsRetrievedAtSelector = state => state.hearings[HEARING_TYPES.recent].retrievedAt

// get no date hearings array of ids
export const noDateHearingsIdsSelector = state => state.hearings[HEARING_TYPES.needsDate].items
// get no date hearings retrieved at
export const noDateHearingsRetrievedAtSelector = state => state.hearings[HEARING_TYPES.needsDate].retrievedAt

// get full tickets for for array of hearing ids: [id]
export const recentHearingsSelector = state => ticketsSelectors.ticketsByIdsSelector(state, recentHearingsIdsSelector(state))
export const noDateHearingsSelector = state => ticketsSelectors.ticketsByIdsSelector(state, noDateHearingsIdsSelector(state))

// get react router id from props
export const idFromRouterSelector = (state, props) => props.match.params.id
