import { connect } from 'react-redux'
import { countiesSelector } from '../../duck/selectors'
import { countiesThunks } from '../../duck'
import SelectCountyComponent from './SelectCountyComponent'

const mapStateToProps = (state, props) => ({
  counties: countiesSelector(state),
})

const mapDispatchToProps = dispatch => ({
  fetchCounties: (stateShortName) => dispatch(countiesThunks.fetchCountiesByStateShortName(stateShortName)),
})

const EditCourtContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectCountyComponent)

export default EditCourtContainer
