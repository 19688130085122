import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router';
import { Modal, Button, Form, TextArea, Icon, Message } from 'semantic-ui-react'
import * as services from '../../duck/services'

const ISSUE_STATUS = {
  compose: 'compose',
  loading: 'loading',
  error: 'error',
  success: 'success'
}

class LoadingComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: ISSUE_STATUS.compose,
      text: '',
      textError: '',
      error: null
    }
    this.textInput = React.createRef();
  }
  componentDidMount() {
    this.textInput.focus()
  }
  handleInput = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  handleSubmit = () => {
    this.postIssue()
  }
  appendData = () => {
    const { match, location, history } = this.props;
    let text = `\n----------------- Client Route Data -----------------\n`
    text += `match: ${JSON.stringify(match)} \n`
    text += `location: ${JSON.stringify(location)} \n`
    text += `history: ${JSON.stringify(history)} \n`
    text += `\n---------------------------------------------------------\n`
    return text
  }
  postIssue = () => {
    this.setState({ status: ISSUE_STATUS.loading })
    const message = this.state.text + this.appendData()
    return services.postIssueMessage(message)
      .then((res) => {
        this.setState({ status: ISSUE_STATUS.success })
      })
      .catch(error => {
        console.log('error posting message:', error)
        this.setState({ status: ISSUE_STATUS.error, error })
      })
  }

  renderInput = () => {
    return <Fragment>
      <Modal.Header>
        <Icon name="bug" />
        Report an Issue
        </Modal.Header>
      <Modal.Content>
        <p>To help us fix bugs faster, please describe in detail: what you were doing when the bug happened, which user/ticket/actions you were preforming, and what you experienced.</p>
        <Form>
          {this.state.textError.length > 0 ? <Message
            error
            content={this.state.textError}
          /> : ''}
          <TextArea
            name="text"
            ref={(input) => { this.textInput = input }}
            onChange={this.handleInput}
            value={this.state.text}
            autoHeight
            placeholder='Describe what happened and what you were trying to do.'
            rows={8}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={this.props.hideModal}
          negative
        >
          Cancel
        </Button>
        <Button
          disabled={this.state.text.length === 0 || this.state.status !== ISSUE_STATUS.compose}
          positive
          icon='send'
          labelPosition='right'
          content='Submit Issue'
          onClick={this.handleSubmit}
        />
      </Modal.Actions>
    </Fragment>
  }

  renderSuccess = () => {
    return <Fragment>
      <Modal.Header>
        <Icon name="bug" />
        Issue Successfully Submitted
        </Modal.Header>
      <Modal.Content>
        <p>Thank you for reporting an issue. Our team will review it and attempt to fix it as soon as possible.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.props.hideModal}>Close</Button>
      </Modal.Actions>
    </Fragment>
  }

  renderLoading = () => {
    return <Fragment>
      <Modal.Header>
        <Icon name="bug" />
        Submitting Issue
        </Modal.Header>
      <Modal.Content>
        <h1><Icon name="refresh" loading={true} size="large" /> Submitting</h1>
        <p>Please wait while our servers acknowledge your request...</p>
      </Modal.Content>
    </Fragment>
  }

  renderError = () => {
    return <Fragment>
      <Modal.Header>
        <Icon name="bug" />
        Issue Not Submitted
        </Modal.Header>
      <Modal.Content>
        <p>We are sorry but it seems like our issue reporting is currently down. Please email us or try again later</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={this.props.hideModal}>Ok</Button>
      </Modal.Actions>
    </Fragment>
  }

  renderSwitch = () => {
    const RenderInput = this.renderInput
    const RenderLoading = this.renderLoading
    const RenderSuccess = this.renderSuccess
    const RenderError = this.renderError
    switch (this.state.status) {
      default:
      case ISSUE_STATUS.compose:
        return <RenderInput />
      case ISSUE_STATUS.loading:
        return <RenderLoading />
      case ISSUE_STATUS.success:
        return <RenderSuccess />
      case ISSUE_STATUS.error:
        return <RenderError />
    }
  }

  render() {
    const RenderSwitch = this.renderSwitch
    return (
      <Modal size="small" open={true}>
        <RenderSwitch />
      </Modal>
    )
  }
}

export default withRouter(LoadingComponent)
