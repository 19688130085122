import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'

class InlineButtonComponent extends Component {
  update = e => {
    if (e && e.preventDefault) e.preventDefault()
    const { objectId, fieldToUpdate, updateValue, collectionToUpdate } = this.props
    if (collectionToUpdate === "FetcherSearchContactInfo") {
      this.props.updateFetcherContactInfo(objectId, { [fieldToUpdate]: updateValue })
    }
    // if (collectionToUpdate === "TrafficTicket") {
    //   this.props.updateTrafficTicket(objectId, { [fieldToUpdate]: updateValue })
    // }
    return false
  }

  render() {
    const { buttonText } = this.props
    return (
      <Button color="green" size="mini" onClick={this.update}>
        <Button.Content>{buttonText}</Button.Content>
      </Button>
    )
  }
}

export default InlineButtonComponent
