import * as services from './services'
import * as actions from './actions'
import * as selectors from './selectors'
import { overlaysActions } from '../../overlays/duck'
import { ticketsActions } from '../../tickets/duck'
import { NOTIFICATION_TYPES } from '../../../constants'

export const fetchToken = userObjectId => dispatch => {
  dispatch(overlaysActions.showLoader())
  return services
    .getClientToken(userObjectId)
    .then(res => {
      const { clientToken } = res
      dispatch(actions.setUserPaymentClientToken(clientToken))
      dispatch(overlaysActions.hideLoader())
    })
    .catch(error => {
      console.error('Error fetching token for user', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(actions.setUserPaymentError(error || 'Unable to fetch token'))
    })
}

export const addPaymentMethod = userObjectId => (dispatch, getState) => {
  const state = getState()
  const nonce = selectors.nonce(state)
  dispatch(overlaysActions.showLoader())
  return services
    .addPaymentMethod(userObjectId, nonce)
    .then(res => {
      dispatch(ticketsActions.updateUserOnTickets(res))
      dispatch(actions.setUserPaymentSuccess())
      dispatch(
        overlaysActions.addNotification({
          type: NOTIFICATION_TYPES.success,
          title: `Added payment method to user`,
          duration: 5,
        })
      )
      dispatch(overlaysActions.hideLoader())
    })
    .catch(error => {
      dispatch(actions.clearUserPaymentReady())
      console.error('Error adding payment method for a user', error)
      dispatch(overlaysActions.hideLoader())
      dispatch(actions.setUserPaymentError(error || 'Payment Method Not Added'))
    })
}
