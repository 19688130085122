import { connect } from 'react-redux'
import { stepSelector, codeSelector, userSelector, attorneySelector } from '../duck/selectors'
import { isLoadingSelector } from '../../overlays/duck/selectors'
// import { activationActions } from '../duck'
import { activationThunks } from '../duck'
import ConfirmComponent from './ConfirmComponent'

const mapStateToProps = state => ({
  step: stepSelector(state),
  code: codeSelector(state),
  user: userSelector(state),
  attorney: attorneySelector(state),
  isLoading: isLoadingSelector(state)
})

const mapDispatchToProps = dispatch => ({
  complete: (userObjectId, activationCode) => dispatch(activationThunks.completeActivation(userObjectId, activationCode))
})

const ConfirmContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmComponent)

export default ConfirmContainer
