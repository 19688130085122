const SET_CURRENTLY_VIEWING = 'SET_CURRENTLY_VIEWING'
const SET_CALLS = 'SET_CALLS'
const SET_CALLS_RETRIEVED_AT = 'SET_CALLS_RETRIEVED_AT'
const SET_CALL_REQUEST_HISTORY = 'SET_CALL_REQUEST_HISTORY'
const RESET = 'RESET'

export default {
  RESET,
  SET_CURRENTLY_VIEWING,
  SET_CALLS,
  SET_CALLS_RETRIEVED_AT,
  SET_CALL_REQUEST_HISTORY,
}
