import { connect } from 'react-redux'
import { stepSelector, codeSelector, userSelector } from '../duck/selectors'
import { isLoadingSelector } from '../../overlays/duck/selectors'
// import { activationActions } from '../duck'
// import { activationThunks } from '../duck'
import AttorneyInfoComponent from './AttorneyInfoComponent'

const mapStateToProps = state => ({
  step: stepSelector(state),
  code: codeSelector(state),
  user: userSelector(state),
  isLoading: isLoadingSelector(state)
})

// const mapDispatchToProps = dispatch => ({ })

const AttorneyInfoContainer = connect(
  mapStateToProps,
  // mapDispatchToProps
)(AttorneyInfoComponent)

export default AttorneyInfoContainer
