import { connect } from 'react-redux'
import { ticketsThunks } from '../../duck'
import EvidenceDropzone from './EvidenceDropzone'

const mapStateToProps = null

const mapDispatchToProps = dispatch => ({
  addEvidence: (ticketObjectId, ticketImage) => {
    dispatch(ticketsThunks.addEvidenceImage(ticketObjectId, ticketImage))
  },
})

const EvidenceDropzoneContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EvidenceDropzone)

export default EvidenceDropzoneContainer
