const ADD_TICKET = 'ADD_TICKET'
const ADD_TICKET_EVIDENCE = 'ADD_TICKET_EVIDENCE'
const ADD_NEW_TICKET_EVIDENCE = 'ADD_NEW_TICKET_EVIDENCE'
const ADD_TICKET_PRICE_PREVIEW = 'ADD_TICKET_PRICE_PREVIEW'
const ADD_TICKET_PORTAL_EDIT_HISTORY = 'ADD_TICKET_PORTAL_EDIT_HISTORY'
const ADD_MANY_TICKETS = 'ADD_MANY_TICKETS'
const ADD_USERS_PAYMENT_METHOD = 'ADD_USERS_PAYMENT_METHOD'
const UPDATE_USER_ON_TICKETS = 'UPDATE_USER_ON_TICKETS'
const RESET = 'RESET'
const SET_EDIT_TICKET_VALUE = 'SET_EDIT_TICKET_VALUE'
const CLEAR_EDIT_TICKET_VALUE = 'CLEAR_EDIT_TICKET_VALUE'
const SET_TICKET_IMAGE = 'SET_TICKET_IMAGE'
const REMOVE_CLASSIFICATION_FROM_TICKETS = 'REMOVE_CLASSIFICATION_FROM_TICKETS'

export default {
  ADD_TICKET,
  ADD_TICKET_EVIDENCE,
  ADD_NEW_TICKET_EVIDENCE,
  ADD_TICKET_PORTAL_EDIT_HISTORY,
  ADD_TICKET_PRICE_PREVIEW,
  ADD_MANY_TICKETS,
  ADD_USERS_PAYMENT_METHOD,
  RESET,
  SET_EDIT_TICKET_VALUE,
  CLEAR_EDIT_TICKET_VALUE,
  UPDATE_USER_ON_TICKETS,
  SET_TICKET_IMAGE,
  REMOVE_CLASSIFICATION_FROM_TICKETS,
}
