import React from "react"
import { search } from "../../../../utilities"
import { Button, darkColors, lightColors } from 'react-floating-action-button'
import { Button as RegButton } from 'semantic-ui-react'

import 'font-awesome/css/font-awesome.min.css'

export const CHAT_COLUMNS = {
  CUSTOMER: {
    Header: "Customer",
    accessor: "name",
    filterable: true,
    filterMethod: (filter, row) => {
      return search.string(row[filter.id], filter.value)
    },
    width: "25%",
    Cell: ({ value }) => {
      return <>{value.replace(/_\w+_channel/,"")}</>
    },
  },

  LAST_MESSAGE: {
    Header: "Last Message",
    accessor: "last_message",
    width: "50%",
    filterable: true,
    filterMethod: (filter, row) => search.string(row[filter.id].message, filter.value),
    Cell: ({ value }) => {
      return (
        <>
          {
            value.user?.role !== 'operator' ?
              <p>{value?.message} <b>-client</b></p> :
              <p>{value?.message} <b>-{value.user.nickname}</b></p>
          }
        </>
      )
    },
  },

  UNREAD_MESSAGE_COUNT: {
    Header: "Unread",
    accessor: "unread_message_count",
    width: 75,
    Cell: ({ value }) => {
      return (
        <div style={{
          display: 'flex',
          width: '75px',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: `${value}` >= 1 && 'bolder',
          fontSize: `${value}` >= 1 && '16px',
          color: `${value}` >= 1 && 'red'
        }}>
          {value}
        </div>
      )
    },
  },

  VIEW_TICKET: (history) => ({
    Header: "",
    accessor: "",
    width: "50%",
    Cell: ({ value }) => {
      const onClick = () => {
        const ticketId = value.channel_url.split('_')[1]
        window.open(`/processing/view/${ticketId}`, "_blank", "width=850,height=750")
      }
      return (
          <RegButton
              style={{ width: '75%', height: '100%', margin: "0 auto"}}
              primary
              onClick={onClick}>
              View Traffic Ticket
          </RegButton>
      )
    },
  }),

  VIEW_CONVERSATION: (
      setChatWindowShowing,
      setChatCurrentChannelUrl,
      selectAttorneyFromChannelMembers,
      isGlobalAdmin
    ) => ({
    Header: "",
    accessor: "",
    width: "25%",
    Cell: ({ value }) => {
      const onClick = () => {
        setChatWindowShowing(true)
        setChatCurrentChannelUrl(value.channel_url)
        if (isGlobalAdmin) {
          selectAttorneyFromChannelMembers(value.members)
        }
      }
      return (
        <Button
          tooltip="View chat"
          icon="fa fa-comments"
          rotate
          styles={{
            backgroundColor: lightColors.blue,
            color: darkColors.orange,
            width: "40px",
            height: "40px",
            margin: "0 auto",
          }}
          onClick={onClick}
        />
      )
    },
  }),

}

const buildChatColumns = (history, numChats, setChatWindowShowing, setChatCurrentChannelUrl, selectAttorneyFromChannelMembers, isGlobalAdmin) => {
  const contactInfo = [
    CHAT_COLUMNS.CUSTOMER,
    CHAT_COLUMNS.LAST_MESSAGE,
    CHAT_COLUMNS.UNREAD_MESSAGE_COUNT,
    CHAT_COLUMNS.VIEW_TICKET(history),
    CHAT_COLUMNS.VIEW_CONVERSATION(setChatWindowShowing, setChatCurrentChannelUrl, selectAttorneyFromChannelMembers, isGlobalAdmin),
  ]
  let Header = `Conversation List`
  if (numChats > 0) {
    Header += ` (${numChats})`
  }
  const tableConfig = [
    {
      Header,
      columns: contactInfo,
    },
  ]
  return tableConfig
}

export default buildChatColumns
