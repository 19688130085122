import types from './types.js'

const setRegions = value => ({
  type: types.SET_REGIONS,
  value,
})

export default {
  setRegions,
}
