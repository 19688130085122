import types from './types.js'

const addManyCourts = (countyId, value) => ({
  type: types.ADD_MANY_COURTS,
  countyId,
  value
})

export default {
  addManyCourts
}
