import { connect } from 'react-redux'
import { modalSelector } from '../duck/selectors'
import ModalsComponent from './ModalsComponent'

const mapStateToProps = state => ({
  modal: modalSelector(state)
})

const ModalsContainer = connect(mapStateToProps)(ModalsComponent)

export default ModalsContainer
