import { connect } from 'react-redux'
import { usersSelectors, usersThunks, usersActions } from '../../duck'
import { specificOverlayTools } from '../../../../utilities'
import { SPECIFIC_OVERLAYS_ITEMS } from '../../../../constants'

import EditPhoneNumberComponent from './EditPhoneNumberComponent'

const EDIT_KEY = 'phoneNumber'

const mapStateToProps = (state, props) => ({
  overlayKey: specificOverlayTools.buildKey(
    props.objectId,
    SPECIFIC_OVERLAYS_ITEMS.users
  ),
  isEditing: usersSelectors.isEditingField(state, props.objectId, EDIT_KEY),
  user: usersSelectors.userById(state, props.objectId),
  editUserField: usersSelectors.editFieldById(state, props.objectId, EDIT_KEY),
})

const mapDispatchToProps = dispatch => ({
  setIsEditing: (objectId, value) =>
    dispatch(usersActions.setIsEditingUserField(objectId, EDIT_KEY, value)),
  editUpdate: (objectId, value) =>
    dispatch(usersActions.setEditValue(objectId, EDIT_KEY, value)),
  clearEdit: objectId =>
    dispatch(usersActions.clearEditValue(objectId, EDIT_KEY)),
  update: (objectId, updateObject) =>
    dispatch(usersThunks.updateUser(objectId, updateObject)),
})

const EditPhoneNumberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPhoneNumberComponent)

export default EditPhoneNumberContainer
