import React, { Component } from 'react';
import { Modal, Header, Icon, Button } from 'semantic-ui-react'

import { StyledElements } from '../../common'
import ItemHeader from '../../tickets/DetailSection/ItemHeader'
import SelectCourt from './SelectCourtContainer'
import { LoadingComponent, UpdatingComponent } from '../../tickets/DetailSection/ticketOverlays'
import SpecificOverlayContainer from '../../overlays/SpecificOverlay/SpecificOverlayContainer';
import ErrorBoundaryComponent from '../../Error/Boundary';

class EditCourtComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      step: 'court',
      court: props.ticket.trafficCourt,
    }
  }
  resetState = () => {
    this.setState({
      isEditing: false,
      step: 'court',
      court: this.props.ticket.trafficCourt,
    })
  }
  courtSelected = (courtId) => {
    this.setState({ court: this.props.courts[this.props.ticket.trafficCounty.objectId][courtId], step: 'confirm' })
  }
  toggleStatus = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }
  updateCourt = () => {
    this.props.update(this.props.objectId, {
      trafficCourt: this.state.court.objectId
    })
    this.resetState()
  }
  renderStep = () => {
    if (!this.props.ticket.trafficCounty) {
      return <StyledElements.Italicize>
        Please set a county before setting a court.
      </StyledElements.Italicize>
    }
    switch (this.state.step) {
      case 'court':
        return <StyledElements.EditContainer>
          <SelectCourt
            county={this.props.ticket.trafficCounty}
            value={this.props.ticket.trafficCounty.objectId ? this.props.ticket.trafficCourt : undefined}
            save={this.courtSelected}
          />
        </StyledElements.EditContainer>
      case 'confirm':
        return (<Modal open={true} basic size='small'>
          <Header icon='edit outline' content='Confirm Court Change' />
          <Modal.Content>
            <p><b>New Court:</b> {this.state.court.courtName}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.resetState} basic color='red'>
              <Icon name='remove' /> Cancel
            </Button>
            <Button onClick={this.updateCourt} color='green' inverted>
              <Icon name='checkmark' /> Confirm Changes
            </Button>
          </Modal.Actions>
        </Modal>)
      case 'state':
      default:
        return <div />
    }
  }
  renderCourt = () => {
    if (this.props.ticket.trafficCourt && this.props.ticket.trafficCourt.courtName) {
      return this.props.ticket.trafficCourt.courtName
    }
    return <StyledElements.Italicize>
      No Court Set
    </StyledElements.Italicize>
  }
  render() {
    const RenderCourt = this.renderCourt
    const RenderStep = this.renderStep
    return (
      <ErrorBoundaryComponent>
        <StyledElements.DataContainer isEditing={this.state.isEditing}>
          <ItemHeader
            title="Court"
            isEditable={true}
            isEditing={this.state.isEditing}
            toggleState={this.toggleStatus}
          />
          <SpecificOverlayContainer
            overlayKey={this.props.objectId}
            LoadingComponent={LoadingComponent}
            ignoreError={true}
            UpdatingComponent={UpdatingComponent}
          >
            {this.state.isEditing ?
              <RenderStep />
              :
              <RenderCourt />
            }
          </SpecificOverlayContainer>
        </StyledElements.DataContainer>
      </ErrorBoundaryComponent>
    );
  }
}

export default EditCourtComponent;
