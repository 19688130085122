import { connect } from 'react-redux'

import { attorneyIsAdminSelector, userSelector } from './authentication/duck/selectors'
import Routes from './Routes'

const mapStateToProps = state => ({
  isAdmin: attorneyIsAdminSelector(state),
  user: userSelector(state),
})

const RoutesContainer = connect(
  mapStateToProps,
)(Routes)

export default RoutesContainer
